import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {selectMultiStageServices} from "../../store/linksReducer";
import c from './MultiStepIndicator.module.scss'
import {
    onSetCurrentPlatformIndex,
    selectFacebookStepData,
    selectGoogleStepData,
    selectTikTokStepData
} from "../../store/multiStepReducer";


const multiStepLine = require('../../img/icons/multiStepLine.png')
const multiStepDot = require('../../img/icons/multiStepDot.png')
const activeMultiStepDot = require('../../img/icons/activeMultiStepDot.png')
const doneMultiStepDot = require('../../img/icons/multiStepDone.png')
const multiStepLineActive = require('../../img/icons/multiStepLineActive.png')

interface MultiStepIndicatorPropsType {
    currentStage: string
    editMode: boolean | undefined
}


const MultiStepIndicator = ({currentStage, editMode}: MultiStepIndicatorPropsType) => {
    const dispatch = useAppDispatch()
    const multiStageServices = useAppSelector(selectMultiStageServices)
    const facebookStepData = useAppSelector(selectFacebookStepData)
    const googleStepData = useAppSelector(selectGoogleStepData)
    const tikTokStepData = useAppSelector(selectTikTokStepData)

    const onSetCurrentService = (index: number) => {
        dispatch(onSetCurrentPlatformIndex(index))
    }

    const [screenWidth, setScreenWidth] = useState<number>(0)

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        !editMode
            ?
            <div className={`${c.inner} ${multiStageServices?.length === 1 ? c.inner_single_service : ''}`}>
                {multiStageServices.map((s, index) => {
                    return (
                        s === currentStage
                            ?
                            <>
                                {
                                    index !== multiStageServices.length - 1
                                        ?
                                        <div className={c.item} onClick={() => onSetCurrentService(index)}>
                                            <img
                                                className={c.item__lineImg}
                                                src={multiStepLine} alt="line"
                                                style={{
                                                    position: 'absolute',
                                                    width: multiStageServices.length <= 2 && screenWidth > 500 ? '356px'
                                                        : multiStageServices.length > 2 && screenWidth > 500 ? '165px'
                                                            : multiStageServices.length <= 2 && screenWidth <= 500 ? '236px'
                                                                : ' 103px',
                                                    left: '44px'
                                                }}
                                            />
                                            <div className={c.item__dot}>
                                                <img src={activeMultiStepDot} alt="active_dot"
                                                     className={c.item__dotImg}/>
                                                <p>{s}</p>
                                            </div>
                                        </div>
                                        :
                                        <div className={c.item} onClick={() => onSetCurrentService(index)}>
                                            <div className={c.item__dot}>
                                                <img src={activeMultiStepDot} alt="active_dot"
                                                     className={c.item__dotImg}/>
                                                <p>{s}</p>
                                            </div>
                                        </div>
                                }
                            </>
                            :
                            <>
                                {
                                    index !== multiStageServices.length - 1
                                        ?
                                        <div className={c.item} onClick={() => onSetCurrentService(index)}>
                                            <img
                                                className={c.item__lineImg}
                                                src={
                                                    s === 'Facebook' && Object.keys(facebookStepData).length
                                                        ?
                                                        multiStepLineActive
                                                        :
                                                        s === 'Google' && Object.keys(googleStepData).length
                                                            ?
                                                            multiStepLineActive
                                                            :
                                                            s === 'TikTok' && Object.keys(tikTokStepData).length
                                                                ?
                                                                multiStepLineActive
                                                                :
                                                                multiStepLine
                                                }
                                                alt="line"
                                                style={{
                                                    position: 'absolute',
                                                    width: multiStageServices.length <= 2 && screenWidth > 500 ? '356px'
                                                        : multiStageServices.length > 2 && screenWidth > 500 ? '165px'
                                                            : multiStageServices.length <= 2 && screenWidth <= 500 ? '236px'
                                                                : ' 103px',
                                                    left: '44px'
                                                }}
                                            />
                                            <div className={c.item__dot}>
                                                {
                                                    s === 'Facebook' && Object.keys(facebookStepData).length
                                                        ?
                                                        <>
                                                            <img src={doneMultiStepDot} alt="dot"
                                                                 className={c.item__dotImg}/>
                                                            <p>{s}</p>
                                                        </>
                                                        :
                                                        s === 'Google' && Object.keys(googleStepData).length
                                                            ?
                                                            <>
                                                                <img src={doneMultiStepDot} alt="dot"
                                                                     className={c.item__dotImg}/>
                                                                <p>{s}</p>
                                                            </>
                                                            :
                                                            s === 'TikTok' && Object.keys(tikTokStepData).length
                                                                ?
                                                                <>
                                                                    <img src={doneMultiStepDot} alt="dot"
                                                                         className={c.item__dotImg}/>
                                                                    <p>{s}</p>
                                                                </>
                                                                :
                                                                <>
                                                                    <img src={multiStepDot} alt="dot"
                                                                         className={c.item__dotImg}/>
                                                                    <p>{s}</p>
                                                                </>
                                                }

                                            </div>
                                        </div>
                                        :
                                        <div onClick={() => onSetCurrentService(index)}>
                                            <div className={c.item__dot}>
                                                <img src={multiStepDot} alt="dot" className={c.item__dotImg}/>
                                                <p>{s}</p>
                                            </div>
                                        </div>

                                }
                            </>

                    )
                })}
            </div>
            :
            <div className={`${c.inner} ${multiStageServices?.length === 1 ? c.inner_single_service : ''}`}>
                {multiStageServices.map((s, index) => {
                    return (
                        s === currentStage
                            ?
                            <>
                                {
                                    index !== multiStageServices.length - 1
                                        ?
                                        <div className={c.item} onClick={() => onSetCurrentService(index)}>
                                            <img
                                                className={c.item__lineImg}
                                                src={multiStepLineActive} alt="line"
                                                style={{
                                                    position: 'absolute',
                                                    width: multiStageServices.length <= 2 && screenWidth > 500 ? '356px'
                                                        : multiStageServices.length > 2 && screenWidth > 500 ? '165px'
                                                            : multiStageServices.length <= 2 && screenWidth <= 500 ? '236px'
                                                                : ' 103px',
                                                    left: '44px'
                                                }}
                                            />
                                            <div className={c.item__dot}>
                                                <img src={activeMultiStepDot} alt="active_dot"
                                                     className={c.item__dotImg}/>
                                                <p>{s}</p>
                                            </div>
                                        </div>
                                        :
                                        <div className={c.item} onClick={() => onSetCurrentService(index)}>
                                            <div className={c.item__dot}>
                                                <img src={activeMultiStepDot} alt="active_dot"
                                                     className={c.item__dotImg}/>
                                                <p>{s}</p>
                                            </div>
                                        </div>
                                }
                            </>
                            :
                            <>
                                {
                                    index !== multiStageServices.length - 1
                                        ?
                                        <div className={c.item} onClick={() => onSetCurrentService(index)}>
                                            <img
                                                className={c.item__lineImg}
                                                src={multiStepLineActive} alt="line"
                                                style={{
                                                    position: 'absolute',
                                                    width: multiStageServices.length <= 2 && screenWidth > 500 ? '356px'
                                                        : multiStageServices.length > 2 && screenWidth > 500 ? '165px'
                                                            : multiStageServices.length <= 2 && screenWidth <= 500 ? '236px'
                                                                : ' 103px',
                                                    left: '44px'
                                                }}
                                            />
                                            <div className={c.item__dot}>
                                                <img src={doneMultiStepDot} alt="active_dot"
                                                     className={c.item__dotImg}/>
                                                <p>{s}</p>
                                            </div>
                                        </div>
                                        :
                                        <div className={c.item} onClick={() => onSetCurrentService(index)}>
                                            <div className={c.item__dot}>
                                                <img src={doneMultiStepDot} alt="active_dot"
                                                     className={c.item__dotImg}/>
                                                <p>{s}</p>
                                            </div>
                                        </div>
                                }
                            </>
                    )
                })}
            </div>
    );
};

export default MultiStepIndicator;
