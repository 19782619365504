import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AsyncThunkConfig, RootState} from "./store";
import {multiStepApi} from "../api/api";
import {AppStatusType} from "./appStatusReducer";
import {
    setCurrentGoogleModalLinkPermissions
} from "./googleReducer";
import {
    changeAddAccountConfigurations,
    changeCatalogAccountConfigurations,
    changeInstagramAccountConfigurations,
    changePageAccountConfigurations,
    changePixelAccountConfigurations,
    setSelectedConfigurations
} from "./permissionConfigurationReducer";
import {setCurrentTikTokModalLinkPermissions} from "./tikTokReducer";
import {GetAllServicesLinksThunk} from "./linksReducer";

interface FacebookStepData {
    name: string
    description: string
    business_id: {
        id: number
    }
    permission_id_list: string[]
}

interface GoogleStepData {
    description: string
    permission_id_list: string[]
}

interface TikTokStepData {
    description: string
    permission_id_list: string[],
    is_multi_service_configuration: boolean
}

interface MultiStepLink {
    id: number;
    name: string;
    description: string;
    url: string;
    facebook_configuration: {
        id: number;
        name: string;
        description: string;
        url: string;
        business: {
            id: number;
            business_id: string;
            name: string;
            logo_url: string;
        };
        permissions: {
            id: number;
            name: string;
            description: string;
            role_name: string;
            is_active: boolean;
        }[];
        is_multi_service_configuration: boolean;
    };
    google_configuration: {
        id: number;
        name: string;
        description: string;
        url: string;
        permissions: {
            id: number;
            name: string;
            description: string;
            service_type: string;
            is_active: boolean;
        }[];
        is_multi_service_configuration: boolean;
    };
    tiktok_configuration: {
        id: number;
        name: string;
        description: string;
        url: string;
        permissions: {
            id: number;
            name: string;
            description: string;
            asset_type: string;
            is_active: boolean;
        }[];
        is_multi_service_configuration: boolean;
    };
}


export interface MultiStepRequestData {
    name: string
    description: string
    facebook_configuration_id: number | null
    google_configuration_id: number | null
    tiktok_configuration_id: number | null
}

interface InitialStateType {
    facebookStepData: FacebookStepData | {}
    googleStepData: GoogleStepData | {}
    tikTokStepData: TikTokStepData | {}
    currentPlatformIndex: number
    multiStepLinks: MultiStepLink[] | []
    multiStepRequestData: MultiStepRequestData,
    multiStepLinksTotalCount: number
    currentMultiStepLink: null | MultiStepLink
    clientLoginLinks: ClientLoginLink[] | []
    userCurrentPlatformIndex: number
    generalLinkCommonName: string
    generalLinkCommonDescription: string
}

const initialState: InitialStateType = {
    facebookStepData: {},
    googleStepData: {},
    tikTokStepData: {},
    currentPlatformIndex: 0,
    multiStepLinks: [],
    multiStepRequestData: {
        name: '',
        description: 'General Integration - Full Access',
        facebook_configuration_id: null,
        google_configuration_id: null,
        tiktok_configuration_id: null
    },
    multiStepLinksTotalCount: 0,
    currentMultiStepLink: null,
    clientLoginLinks: [],
    userCurrentPlatformIndex: 0,
    generalLinkCommonName: '',
    generalLinkCommonDescription: ''
}


export interface ClientLoginLink {
    login_link: string
    agency_name: string
    service_type: string
}

export const multiStepSlice = createSlice({
    name: 'multiStep',
    initialState,
    reducers: {
        setFacebookStepData: (state, action: PayloadAction<FacebookStepData | {}>) => {
            state.facebookStepData = action.payload
        },
        setGoogleStepData: (state, action: PayloadAction<GoogleStepData | {}>) => {
            state.googleStepData = action.payload
        },
        setTikTokStepData: (state, action: PayloadAction<TikTokStepData | {}>) => {
            state.tikTokStepData = action.payload
        },
        onSetCurrentPlatformIndex: (state, action: PayloadAction<number>) => {
            state.currentPlatformIndex = action.payload
        },
        onSetRequestDataFacebookId: (state, action: PayloadAction<number>) => {
            state.multiStepRequestData.facebook_configuration_id = action.payload
        },
        onSetRequestDataGoogleId: (state, action: PayloadAction<number>) => {
            state.multiStepRequestData.google_configuration_id = action.payload
        },
        onSetRequestDataTikTokId: (state, action: PayloadAction<number>) => {
            state.multiStepRequestData.tiktok_configuration_id = action.payload
        },
        onSetRequestDataName: (state, action: PayloadAction<MultiStepLink[]>) => {
            state.multiStepLinks = action.payload
        },
        onSetCurrentMultiStepLink: (state, action: PayloadAction<MultiStepLink | null>) => {
            state.currentMultiStepLink = action.payload
        },
        onSetUserCurrentPlatformIndex: (state, action: PayloadAction<number>) => {
            state.userCurrentPlatformIndex = action.payload
        },
        onChangeGeneralLinkName: (state, action: PayloadAction<string>) => {
            state.generalLinkCommonName = action.payload
        },
        onChangeGeneralLinkDescription: (state, action: PayloadAction<string>) => {
            state.generalLinkCommonDescription = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetMultiStepLinksThunk.fulfilled, (state, action) => {
                state.multiStepLinks = action.payload.configurations
                state.multiStepLinksTotalCount = action.payload.total_count
            })
            .addCase(GetMultiStepLinkByIdThunk.fulfilled, (state, action) => {
                state.currentMultiStepLink = action.payload
            })
            .addCase(GetMultiStepLoginServicesThunk.fulfilled, (state, action) => {
                state.clientLoginLinks = action.payload
            })
    }
})

export const {
    setFacebookStepData,
    setGoogleStepData,
    onSetCurrentPlatformIndex,
    setTikTokStepData,
    onSetRequestDataFacebookId,
    onSetRequestDataGoogleId,
    onSetRequestDataTikTokId,
    onSetCurrentMultiStepLink,
    onChangeGeneralLinkName,
    onChangeGeneralLinkDescription
} = multiStepSlice.actions

export const selectFacebookStepData = (state: RootState): FacebookStepData | {} => state.multiStep.facebookStepData
export const selectGoogleStepData = (state: RootState): GoogleStepData | {} => state.multiStep.googleStepData
export const selectTikTokStepData = (state: RootState): TikTokStepData | {} => state.multiStep.tikTokStepData
export const selectCurrentPlatformIndex = (state: RootState): number => state.multiStep.currentPlatformIndex
export const selectMultiStepLinks = (state: RootState): MultiStepLink[] => state.multiStep.multiStepLinks
export const selectMultiStepLinksReqData = (state: RootState): MultiStepRequestData => state.multiStep.multiStepRequestData
export const selectMultiStepCurrentLinkData = (state: RootState): MultiStepLink | null => state.multiStep.currentMultiStepLink
export const selectMultiStepClientLoginLinks = (state: RootState): ClientLoginLink[] => state.multiStep.clientLoginLinks
export const selectUserCurrentPlatformIndex = (state: RootState): number => state.multiStep.userCurrentPlatformIndex
export const selectGeneralLinkCommonName = (state: RootState): string => state.multiStep.generalLinkCommonName
export const selectGeneralLinkCommonDescription = (state: RootState): string => state.multiStep.generalLinkCommonDescription

export const GetMultiStepLinksThunk = createAsyncThunk<{ total_count: number, configurations: MultiStepLink[] }, void, AsyncThunkConfig>(
    'multiStep/getMultiStepLinks',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await multiStepApi.getMultiStepLinks()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(null)
            }
        } catch (error: any) {
            return thunkAPI.fulfillWithValue({}, {appStatus: AppStatusType.idle})
        }
    }
)

export const CreateMultiStepLinksThunk = createAsyncThunk<MultiStepLink, MultiStepRequestData, AsyncThunkConfig>(
    'multiStep/createMultiStepLinks',
    async (reqData, thunkAPI) => {
        try {

            const currentState = thunkAPI.getState();
            const req: any = {
                ...reqData,
                name: currentState.multiStep.generalLinkCommonName,
                description: currentState.multiStep.generalLinkCommonDescription
            }
            const {status, data} = await multiStepApi.createMultiStepLink(req)
            if (status === 200 && data) {
                console.log(data)
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(null)
            }
        } catch (error: any) {
            return thunkAPI.fulfillWithValue({}, {appStatus: AppStatusType.idle})
        } finally {
            thunkAPI.dispatch(GetMultiStepLinksThunk())
        }
    }
)

export const UpdateMultiStepLinksThunk = createAsyncThunk<MultiStepLink, MultiStepRequestData, AsyncThunkConfig>(
    'multiStep/updateMultiStepLinks',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await multiStepApi.updateMultiStepLink(reqData)
            if (status === 200 && data) {
                console.log(data)
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(null)
            }
        } catch (error: any) {
            return thunkAPI.fulfillWithValue({}, {appStatus: AppStatusType.idle})
        } finally {
            thunkAPI.dispatch(GetMultiStepLinksThunk())
        }
    }
)

export const DeleteMultiStepTableLinkThunk = createAsyncThunk<null, number, AsyncThunkConfig>(
    'multiStep/deleteMultiStepTableLink',
    async (configId, thunkAPI) => {
        try {
            const {status} = await multiStepApi.deleteMultiStepLink(configId)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(null, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: 'Deleted successfully'
                })
            } else {
                return thunkAPI.rejectWithValue(null)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(GetMultiStepLinksThunk())
            thunkAPI.dispatch(GetAllServicesLinksThunk())
        }
    }
)

export const GetMultiStepLinkByIdThunk = createAsyncThunk<MultiStepLink, number, AsyncThunkConfig>(
    'multiStep/getMultiStepLinkById',
    async (configId, thunkAPI) => {
        try {
            const {status, data} = await multiStepApi.getMultiStepLinkById(configId)
            if (status === 200 && data) {
                if (data && data.facebook_configuration && Object.keys(data.facebook_configuration)
                    && data.google_configuration && Object.keys(data.google_configuration)
                    && data.tiktok_configuration && Object.keys(data.tiktok_configuration)
                ) {
                    data.facebook_configuration.permissions.forEach((a: any) => {
                        thunkAPI.dispatch(changeAddAccountConfigurations(a))
                        thunkAPI.dispatch(changeCatalogAccountConfigurations(a))
                        thunkAPI.dispatch(changeInstagramAccountConfigurations(a))
                        thunkAPI.dispatch(changePageAccountConfigurations(a))
                        thunkAPI.dispatch(changePixelAccountConfigurations(a))
                        thunkAPI.dispatch(setSelectedConfigurations({isEdit: true, id: a.id}))
                    })
                    thunkAPI.dispatch(setCurrentGoogleModalLinkPermissions(data.google_configuration))
                    thunkAPI.dispatch(setCurrentTikTokModalLinkPermissions(data.tiktok_configuration))
                } else if (data && data.google_configuration && Object.keys(data.google_configuration)
                    && data.facebook_configuration && Object.keys(data.facebook_configuration)) {
                    data.facebook_configuration.permissions.forEach((a: any) => {
                        thunkAPI.dispatch(changeAddAccountConfigurations(a))
                        thunkAPI.dispatch(changeCatalogAccountConfigurations(a))
                        thunkAPI.dispatch(changeInstagramAccountConfigurations(a))
                        thunkAPI.dispatch(changePageAccountConfigurations(a))
                        thunkAPI.dispatch(changePixelAccountConfigurations(a))
                        thunkAPI.dispatch(setSelectedConfigurations({isEdit: true, id: a.id}))
                    })
                    thunkAPI.dispatch(setCurrentGoogleModalLinkPermissions(data.google_configuration))
                } else if (data && data.tiktok_configuration && Object.keys(data.tiktok_configuration)
                    && data.facebook_configuration && Object.keys(data.facebook_configuration)) {
                    data.facebook_configuration.permissions.forEach((a: any) => {
                        thunkAPI.dispatch(changeAddAccountConfigurations(a))
                        thunkAPI.dispatch(changeCatalogAccountConfigurations(a))
                        thunkAPI.dispatch(changeInstagramAccountConfigurations(a))
                        thunkAPI.dispatch(changePageAccountConfigurations(a))
                        thunkAPI.dispatch(changePixelAccountConfigurations(a))
                        thunkAPI.dispatch(setSelectedConfigurations({isEdit: true, id: a.id}))
                    })
                    thunkAPI.dispatch(setCurrentTikTokModalLinkPermissions(data.tiktok_configuration))
                } else if (data && data.tiktok_configuration && Object.keys(data.tiktok_configuration)
                    && data.google_configuration && Object.keys(data.google_configuration)) {
                    thunkAPI.dispatch(setCurrentTikTokModalLinkPermissions(data.tiktok_configuration))
                    thunkAPI.dispatch(setCurrentGoogleModalLinkPermissions(data.google_configuration))
                }
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const GetMultiStepLoginServicesThunk = createAsyncThunk<ClientLoginLink[], number, AsyncThunkConfig>(
    'multiStep/getMultiStepLoginServices',
    async (configId, thunkAPI) => {
        try {
            const {status, data} = await multiStepApi.getMultiStepLoginServices(configId)
            if (status === 200 && data) {
                console.log(data)
                return thunkAPI.fulfillWithValue(data.client_login_links, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(null)
            }
        } catch (error: any) {
            return thunkAPI.fulfillWithValue({}, {appStatus: AppStatusType.idle})
        }
    }
)

export default multiStepSlice.reducer