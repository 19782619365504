import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AsyncThunkConfig, RootState} from "./store";
import {emailApi, linksApi, LinkSentViaEmailType} from "../api/api";
import {AppStatusType} from "./appStatusReducer";

interface LinkType {
    id: number
    name: string
    service_type: string
    description: string
    url: string
}

interface InitialStateType {
    allServicesLinks: LinkType[],
    allServicesLinksTotalCount: number,
    multiStageServices: string[],
    generatedLinkUrl: string
}

const initialState: InitialStateType = {
    allServicesLinks: [],
    allServicesLinksTotalCount: 0,
    multiStageServices: [],
    generatedLinkUrl: ''
}

export const linksSlice = createSlice({
    name: 'links',
    initialState,
    reducers: {
        setAllServicesLinks: (state, action: PayloadAction<LinkType[]>) => {
            state.allServicesLinks = action.payload
        },
        setAllServicesLinksTotalCount: (state, action: PayloadAction<number>) => {
            state.allServicesLinksTotalCount = action.payload
        },
        setMultiStageServices: (state, action: PayloadAction<string[]>) => {
            state.multiStageServices = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAllServicesLinksThunk.fulfilled, (state, action) => {
                state.allServicesLinks = action.payload.permission_configuration_list
                state.allServicesLinksTotalCount = action.payload.total_count
            })
            .addCase(GetAllServicesLinksMultiThunk.fulfilled, (state, action) => {
                state.allServicesLinks = action.payload.permission_configuration_list
                state.allServicesLinksTotalCount = action.payload.total_count
            })
            .addCase(SendLinkViaEmailThunk.fulfilled, (state, action) => {
                state.generatedLinkUrl = action.payload
            })
            .addCase(ResendLinkViaEmailThunk.fulfilled, (state, action) => {
                state.generatedLinkUrl = action.payload
            })

    }
})

export const { setAllServicesLinks, setAllServicesLinksTotalCount, setMultiStageServices } = linksSlice.actions

export const selectAllServicesLinks = (state: RootState): LinkType[] => state.links.allServicesLinks
export const selectAllServicesLinksTotalCount = (state: RootState): number => state.links.allServicesLinksTotalCount
export const selectMultiStageServices = (state: RootState): string[] => state.links.multiStageServices
export const selectGeneratedLinkUrl = (state: RootState): string => state.links.generatedLinkUrl

export const GetAllServicesLinksThunk = createAsyncThunk<{total_count: number, permission_configuration_list: LinkType[]}, void, AsyncThunkConfig>(
    'links/getAllServicesLinks',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await linksApi.getAllServicesLinks()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            }
            else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.fulfillWithValue({}, {appStatus: AppStatusType.idle})
        }
    }
)

export const GetAllServicesLinksMultiThunk = createAsyncThunk<{total_count: number, permission_configuration_list: LinkType[]}, number, AsyncThunkConfig>(
    'links/getAllServicesLinksMulti',
    async (linkId, thunkAPI) => {
        try {
            const {status, data} = await linksApi.getActivityMultiLinkLinksDivided(linkId)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            }
            else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.fulfillWithValue({}, {appStatus: AppStatusType.idle})
        }
    }
)

export const SendLinkViaEmailThunk = createAsyncThunk<string, {reqData: LinkSentViaEmailType, isOurSystem: boolean, client_id: number, type?: string}, AsyncThunkConfig>(
    'links/sendLinkViaEmail',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await emailApi.sendLinkWithEmail(requestData.reqData, requestData.client_id)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.message, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: requestData.type !== 'Copy link' ? 'Your link was sent successfully' : 'Your link was generated successfully, now you can copy and send it the way you need'
                })
            } else {
                return thunkAPI.rejectWithValue(data.message)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const ResendLinkViaEmailThunk = createAsyncThunk<string, {reqData: LinkSentViaEmailType, isOurSystem: boolean, client_id: number}, AsyncThunkConfig>(
    'links/resendLinkViaEmail',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await emailApi.resendLinkWithEmail(requestData.reqData, requestData.client_id)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.message, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: 'Your link was resent successfully'
                })
            } else {
                return thunkAPI.rejectWithValue(data.message)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        finally {
            setTimeout(() => {
                window.location.reload()
            }, 1000)

        }
    }
)

export default linksSlice.reducer
