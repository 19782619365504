import React, {useEffect, useState} from 'react';
import {Button, Form, Input, message, Progress, Select, Tooltip, Upload} from "antd";
import authS from "../Auth/Auth.module.scss";
import {useAppDispatch, useAppSelector} from "../../hooks";
import CommonModal from "../CommonModal/CommonModal";
import {
    selectCurrentClientModalStep,
    selectIsSendLinkModalOpened,
    setCurrentClientModalStep,
    setIsSendLinkModalOpened
} from "../../store/utilityReducer";
import {ResendLinkViaEmailThunk, selectGeneratedLinkUrl, SendLinkViaEmailThunk} from "../../store/linksReducer";
import s from './SendLinkModal.module.scss'
import ClientModalService from "../Client/ClientModalService/ClientModalService";
import {
    CreateNewClientThunk, GetClientsThunk, onSetClientServices, selectCheckedGoogleServiceIds,
    selectClientForIntegrationLinkCreation, selectClientServices, selectCurrentClientServices, selectCurrentPage,
    selectResendLinkMode, selectSearchValue,
} from "../../store/clientReducer";
import {
    CreateGoogleAccountLinkThunk,
    GetGoogleTablePermissionsThunk, selectGoogleAccount, UpdateGoogleAccountLinkThunk,
} from "../../store/googleReducer";
import {CreateGoogleAndTikTokLink, LinkActivityReqData} from "../../api/api";
import {
    CreateTikTokAccountLinkThunk,
    selectTikTokAccount,
    UpdateTikTokAccountLinkThunk
} from "../../store/tikTokReducer";
import {ClientServiceType} from "../../types/clientsTypes";
import {CreateActivityLinkThunk, UpdateActivityLinkThunk} from "../../store/activityTrackerReducer";
import {beforeUpload, dummyRequest, normFile} from "../../helpers/fileHelper";


const closeIcon = require('../../img/icons/close.svg')


const copyIcon = require('../../img/icons/copy.svg')
const emailIcon = require('../../img/icons/sendViaEmailIcon.svg')
const clientIcon = require('../../img/icons/clientModalIcon.svg')
const googleLogo = require('../../img/icons/google.svg')
const tiktokLogo = require('../../img/icons/tiktok.svg')
const uploadIcon = require('../../img/icons/upload.svg')
const imageUploadIcon = require('../../img/icons/fileUpload.png')
const deleteIcon = require('../../img/icons/delete.svg')
const SendLinkModal = () => {
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const isSendLinkModalOpened = useAppSelector(selectIsSendLinkModalOpened)
    const [isCopyLinkModalOpened, setIsCopyLinkModalOpened] = useState(false)
    const generatedLink = useAppSelector(selectGeneratedLinkUrl)
    const clientModalStep = useAppSelector(selectCurrentClientModalStep)
    // const paginationCurrentPage = useAppSelector(selectCurrentPage)
    const clientForIntegrationLinkCreation = useAppSelector(selectClientForIntegrationLinkCreation)
    let clientServices = useAppSelector(selectClientServices)
    const currentClientServices = useAppSelector(selectCurrentClientServices)
    const checkedGoogleClientIds = useAppSelector(selectCheckedGoogleServiceIds)
    const [form] = Form.useForm()
    const resendLinkMode = useAppSelector(selectResendLinkMode)
    const tikTokAccount = useAppSelector(selectTikTokAccount)
    const googleAccount = useAppSelector(selectGoogleAccount)
    const [clientEmail, setClientEmail] = useState('')
    const paginationCurrentPage = useAppSelector(selectCurrentPage)
    const searchValue = useAppSelector(selectSearchValue)
    // const clientId = clientLoginDataHelper.getCurrentClientDataId()
    clientServices = clientServices.filter((s: string) => Object.keys(googleAccount).length === 0 ? s !== 'Google' : Object.keys(tikTokAccount).length === 0 ? s !== 'Tik Tok' : s !== '')
    useEffect(() => {
        if (clientModalStep === 'Create link for client' || clientModalStep === 'Configs') {
            dispatch(GetGoogleTablePermissionsThunk())
        }
    }, [clientModalStep, dispatch])

    useEffect(() => {
        form.setFieldsValue({email: clientForIntegrationLinkCreation.email})
    }, [clientForIntegrationLinkCreation, form])

    const onFinish = (sentLinkData: { email: string, message: string, full_name: string, logo: any }) => {
        setIsLoading(true)

        // formData.append('pagination', new Blob([JSON.stringify({
        //     page: paginationCurrentPage,
        //     size: 250
        // }, null, 2)], {type: 'application/json'}))

        if (!resendLinkMode) {
            if (clientModalStep === 'Create client') {
                const formData = new FormData()

                if (!!file) {
                    formData.append('logo', file)
                }
                formData.append('client', new Blob([JSON.stringify({
                    name: sentLinkData.full_name,
                    email: sentLinkData.email
                })], {type: 'application/json'}))
                dispatch(CreateNewClientThunk(formData))
                    .then(() => {
                        const formData = new FormData()
                        formData.append('filter', new Blob([JSON.stringify({search_value: searchValue})], {type: 'application/json'}))
                        formData.append('pagination', new Blob([JSON.stringify({page: paginationCurrentPage, size: 250})], {type: 'application/json'}))
                        dispatch(GetClientsThunk(formData))
                            // .then((res) => {
                            //     // @ts-ignore
                            //     dispatch(onSetClientsTotalCount(res.payload.total_count))
                            // })
                    })
                dispatch(setCurrentClientModalStep('Configs'))
                setIsLoading(false)
            } else if (clientModalStep === 'Copy link') {
                setIsLoading(false)
                dispatch(SendLinkViaEmailThunk({
                    reqData: {
                        email: sentLinkData.email,
                        message: sentLinkData.message,
                        is_email: true
                    },
                    isOurSystem: true,
                    client_id: clientForIntegrationLinkCreation.id,

                }))
                    .then(() => setIsLoading(false))
                    .then(() => setIsCopyLinkModalOpened(true))
                    .then(() => dispatch(onSetClientServices([])))

            } else {
                dispatch(SendLinkViaEmailThunk({
                    reqData: {
                        email: sentLinkData.email,
                        message: sentLinkData.message,
                        is_email: true
                    },
                    isOurSystem: true,
                    client_id: clientForIntegrationLinkCreation.id,

                }))
                    .then(() => setIsLoading(false))
                    .then(() => dispatch(setIsSendLinkModalOpened(false)))
                    .then(() => setIsSendLinkModalOpened(false))
            }
        } else {
            dispatch(ResendLinkViaEmailThunk({
                reqData: {
                    email: sentLinkData.email,
                    message: '',
                    is_email: true,
                },
                isOurSystem: true,
                client_id: clientForIntegrationLinkCreation.id,

            }))
                .then(() => setIsLoading(false))
                .then(() => dispatch(setIsSendLinkModalOpened(false)))
                .then(() => dispatch(onSetClientServices([])))
        }

    }

    const onCopyLink = async (url: string) => {
        try {
            await navigator.clipboard.writeText(url);
            message.success('Link was copied successfully', 3)
        } catch (err) {
            message.error('Failed to copy link');
        }
    }

    const onCloseCopyLinkModal = () => {
        dispatch(setIsSendLinkModalOpened(false))
        setIsCopyLinkModalOpened(false)
    }

    const services = [
        // {serviceName: 'Facebook', img: facebookLogo.default},
        {serviceName: 'Google', img: googleLogo.default},
        {serviceName: 'Tik Tok', img: tiktokLogo.default}
    ]

    const onCloseModal = () => {
        dispatch(setIsSendLinkModalOpened(false))
        dispatch(onSetClientServices([]))
    }


    const onCreateLinkConfig = (sendingType: 'Copy link' | 'Send link', buttonSendOption?: string) => {
        if (Object.keys(googleAccount).length !== 0 || Object.keys(tikTokAccount).length !== 0) {
            if (!clientServices.length) {
                message.warn('You need to select at least one service to create link')
            } else {
                setIsLoading(true)
                const googleRequestBody: CreateGoogleAndTikTokLink = {
                    name: '',
                    description: '',
                    permission_id_list: checkedGoogleClientIds,
                    is_multi_service_configuration: false,
                    client_id: clientForIntegrationLinkCreation.id
                }
                const tikTokRequestBody: CreateGoogleAndTikTokLink = {
                    name: '',
                    description: '',
                    is_multi_service_configuration: false,
                    client_id: clientForIntegrationLinkCreation.id
                }
                if (!resendLinkMode) {
                    (async () => {
                        if (!resendLinkMode) {
                            const apiRequests = clientServices.map((s) => {
                                if (s === 'Google') {
                                    return dispatch(CreateGoogleAccountLinkThunk({request: googleRequestBody}));
                                } else if (s === 'Tik Tok') {
                                    return dispatch(CreateTikTokAccountLinkThunk({request: tikTokRequestBody}));
                                }
                                return null;
                            });

                            try {
                                await Promise.all(apiRequests)
                                if (sendingType === 'Copy link') {
                                    setIsLoading(false)
                                    dispatch(SendLinkViaEmailThunk({
                                        reqData: {
                                            email: clientEmail,
                                            message: '',
                                            is_email: false,
                                        },
                                        isOurSystem: false,
                                        client_id: clientForIntegrationLinkCreation.id,
                                        type: 'Copy link'

                                    }))
                                        .then(() => setIsLoading(false))
                                        .then(() => setIsCopyLinkModalOpened(true))
                                        .then(() => dispatch(onSetClientServices([])))
                                        .then(() => dispatch(setCurrentClientModalStep('Send link')))
                                } else {
                                    if (buttonSendOption === 'Another email') {
                                        setIsLoading(false)
                                        dispatch(setCurrentClientModalStep('Send link'))

                                    } else {
                                        dispatch(SendLinkViaEmailThunk({
                                            reqData: {
                                                email: clientEmail,
                                                message: '',
                                                is_email: true,
                                            },
                                            isOurSystem: true,
                                            client_id: clientForIntegrationLinkCreation.id,

                                        }))
                                            .then(() => setIsLoading(false))
                                            .then(() => setIsSendLinkModalOpened(false))
                                            .then(() => dispatch(setIsSendLinkModalOpened(false)))
                                    }
                                }
                            } catch (error) {
                                console.error('Error in API requests:', error);
                            }
                        }
                    })();
                }
            }
        } else {
            message.warn(`You need to integrate one of your services, to send link with configs to the client`)
        }
    }


    const onUpdateLinkConfig = () => {
        const currentGoogleService = currentClientServices.find((s: ClientServiceType) => s.service_type === 'GOOGLE');
        const currentTikTokService = currentClientServices.find((s: ClientServiceType) => s.service_type === 'TIKTOK');
        const googleRequestBody: CreateGoogleAndTikTokLink = {
            name: '',
            description: '',
            permission_id_list: checkedGoogleClientIds,
            is_multi_service_configuration: false,
            client_id: clientForIntegrationLinkCreation.id
        };
        const tikTokRequestBody: CreateGoogleAndTikTokLink = {
            name: '',
            description: '',
            is_multi_service_configuration: false,
            client_id: clientForIntegrationLinkCreation.id
        };
        if(clientServices.length){
            (async () => {
                const apiRequests = clientServices.map((s) => {
                    if (s === 'Google' && currentGoogleService) {
                        return dispatch(UpdateGoogleAccountLinkThunk({
                            req: googleRequestBody,
                            config_id: currentGoogleService.config_id
                        }))
                            .then(() => {
                                // @ts-ignore
                                const linkActivityReqData: LinkActivityReqData = {
                                    link_id: currentGoogleService.config_id,
                                    platform_name: 'GOOGLE',
                                    user_name: clientForIntegrationLinkCreation.name,
                                    user_email: clientForIntegrationLinkCreation.email,
                                    status: 'SENT'
                                }
                                // @ts-ignore
                                dispatch(UpdateActivityLinkThunk({link_activity_id: currentGoogleService?.link_id,
                                    linkActivityReqData: linkActivityReqData
                                }))
                                dispatch(setCurrentClientModalStep('Send link'))
                                dispatch(setIsSendLinkModalOpened(false))
                            })
                    } else if (s === 'Google' && !currentGoogleService) {
                        return dispatch(CreateGoogleAccountLinkThunk({request: googleRequestBody}))
                            .then((res) => {
                                // @ts-ignore
                                const req = {link_id: res.payload.id,
                                    platform_name: 'GOOGLE',
                                    user_name: clientForIntegrationLinkCreation.name,
                                    user_email: clientForIntegrationLinkCreation.email,
                                    status: 'SENT'
                                }
                                dispatch(CreateActivityLinkThunk(req))
                            })
                    } else if (s === 'Tik Tok' && !currentTikTokService) {
                        return dispatch(CreateTikTokAccountLinkThunk({request: tikTokRequestBody}))
                            .then((res) => {
                                // @ts-ignore
                                const req = {link_id: res.payload.id,
                                    platform_name: 'TIKTOK',
                                    user_name: clientForIntegrationLinkCreation.name,
                                    user_email: clientForIntegrationLinkCreation.email,
                                    status: 'SENT'
                                }

                                dispatch(CreateActivityLinkThunk(req))

                            })
                    } else if (s === 'Tik Tok' && currentTikTokService) {
                        const linkActivityReqData: LinkActivityReqData = {
                            link_id: currentTikTokService.config_id,
                            platform_name: 'TIKTOK',
                            user_name: clientForIntegrationLinkCreation.name,
                            user_email: clientForIntegrationLinkCreation.email,
                            status: 'SENT'
                        }
                        return dispatch(UpdateTikTokAccountLinkThunk({
                            req: tikTokRequestBody,
                            config_id: currentTikTokService.config_id
                        }))
                            .then(() => {
                                // @ts-ignore
                                dispatch(UpdateActivityLinkThunk({link_activity_id: currentTikTokService?.link_id,
                                    linkActivityReqData: linkActivityReqData
                                }))
                                dispatch(setCurrentClientModalStep('Send link'))
                                dispatch(setIsSendLinkModalOpened(false))
                            })
                    }
                    return null;
                });

                try {
                    await Promise.all(apiRequests)

                        .then(() => setIsLoading(false))
                        .then(() => setIsSendLinkModalOpened(false))
                        .then(() => dispatch(setIsSendLinkModalOpened(false)))
                        .then(() => message.success('Link configs was updated successfully', 3))
                        .then(() => window.location.reload())


                } catch (error) {
                    console.error('Error in API requests:', error);
                }

            })();
        }
        else{
            message.warn('You need to select at least one service to update its configs')
        }
    }

    const onSetClientEmail = (value: any) => {
        setClientEmail(value.target.value)
    }


    const onChangeButtonSendOption = (value: string) => {
        onCreateLinkConfig('Send link', value)
    }
    const [file, setFile] = useState<any>()


    const addPhoto = async (file: any) => {
        setFile(file.originFileObj)
    }

    const onDeleteImage = () => {
        setFile('')
    }

    return (
        <CommonModal active={isSendLinkModalOpened}
                     height={
                         !isCopyLinkModalOpened && clientModalStep !== 'Create client' && clientModalStep !== 'Create link for client'
                             ? '350px'
                             : '370px'
                     }
                     width={'470px'}
                     padding={"24px"}
                     backgroundColor={'#fff'}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    position: 'relative'
                }}
            >
                <button className={s.modal__closeBtn} onClick={onCloseModal}>
                    <closeIcon.ReactComponent/>
                </button>
                {
                    clientModalStep === 'Create client' || clientModalStep === 'Configs'
                        ?
                        <clientIcon.ReactComponent/>
                        :
                        <emailIcon.ReactComponent/>
                }
                {
                    clientModalStep === 'Create client'
                        ?
                        <h1 className={s.title}>Create new Client</h1>
                        :
                        clientModalStep === 'Configs' && !resendLinkMode
                            ?
                            <h1 className={s.title_bg}>Create link for new Client</h1>
                            :
                            clientModalStep === 'Configs' && resendLinkMode
                                ?
                                <h1>Update link configs</h1>
                                :
                                <h1 className={s.title}>{!isCopyLinkModalOpened ? 'Send link by another Email' : 'Copy link'} </h1>
                }
                {
                    clientModalStep === 'Send link'
                        ?
                        !isCopyLinkModalOpened
                            ?
                            <p className={s.text}>Enter the email address to send the link to</p>
                            :
                            <p className={s.text}>Copy the link and send it. You can track the results of the link in
                                the
                                activity menu</p>
                        :
                        clientModalStep === 'Configs'
                            ?
                            <p className={s.text_client}>Select the networks to include in the link:</p>
                            :
                            null
                }

            </div>
            {
                clientModalStep === 'Send link'
                    ?
                    !isCopyLinkModalOpened
                        ?
                        <Form
                            name='sendLinkViaEmail'
                            onFinish={onFinish}
                            autoComplete='off'
                            form={form}
                            validateTrigger='onBlur'
                            className={authS.formBlock__form}
                        >
                            <div className={authS.formBlock__label}>
                                Enter new email
                            </div>
                            <Form.Item
                                name='email'
                                rules={[
                                    {required: true, message: 'Please enter your email!'},
                                    {type: 'email', message: 'Invalid e-mail!'},
                                ]}
                            >
                                <Input
                                    placeholder='Enter your email'
                                    className={authS.formBlock__input}

                                />
                            </Form.Item>

                            <div className={s.formButtons}>
                                <Form.Item style={{
                                    marginBottom: '0px',
                                    width: '100%'
                                }}>
                                    <Button type='primary' htmlType='submit' className={authS.formBlock__submit}
                                            loading={isLoading}>
                                        Send
                                    </Button>
                                </Form.Item>
                            </div>

                        </Form>

                        :
                        <div>
                            <div className={authS.formBlock__label}>
                                Share link
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '32px'
                            }}>
                                <Input
                                    className={`sendLinkUrlInput ${authS.formBlock__input}`}
                                    value={generatedLink}
                                    disabled={true}
                                    style={{
                                        width: '100%',
                                        marginRight: '14px'
                                    }}
                                />
                                <copyIcon.ReactComponent
                                    style={
                                        {
                                            marginRight: '15px',
                                            cursor: 'pointer',
                                            fontSize: '18px'
                                        }
                                    }
                                    color="action"
                                    onClick={() => {
                                        onCopyLink(generatedLink)
                                    }}
                                />
                            </div>
                            <Button type='primary'
                                    htmlType='submit'
                                    className={authS.formBlock__submit}
                                    loading={isLoading}
                                    onClick={onCloseCopyLinkModal}
                            >
                                Complete
                            </Button>
                        </div>
                    :
                    clientModalStep === 'Create client'
                        ?
                        <>
                            <Form
                                name='sendLinkViaEmail'
                                onFinish={onFinish}
                                autoComplete='off'
                                validateTrigger='onBlur'
                                className={authS.formBlock__form}
                            >
                                <div
                                    className={authS.formBlock__label}
                                    style={{
                                        marginTop: '12px'
                                    }}
                                >
                                    Full Name
                                </div>
                                <Form.Item
                                    name='full_name'
                                    rules={[
                                        {required: true, message: 'Please enter your full name!'},
                                    ]}
                                    style={{
                                        marginBottom: '12px',
                                    }}
                                >
                                    <Input
                                        placeholder='Enter your full name'
                                        className={authS.formBlock__input}

                                    />
                                </Form.Item>
                                <div className={authS.formBlock__label}>
                                    Email
                                </div>
                                <Form.Item
                                    name='email'
                                    rules={[
                                        {required: true, message: 'Please enter your email!'},
                                        {type: 'email', message: 'Invalid e-mail!'},
                                    ]}
                                >
                                    <Input
                                        placeholder='Enter your full email'
                                        className={authS.formBlock__input}
                                        onChange={onSetClientEmail}

                                    />
                                </Form.Item>
                                <div className={authS.formBlock__label}>
                                    Logo
                                </div>
                                <Form.Item
                                    name='logo'
                                    valuePropName='fileList'
                                    getValueFromEvent={(e) => normFile(e, addPhoto)}
                                >
                                    <Upload.Dragger
                                        name='files'
                                        customRequest={dummyRequest}
                                        listType={'picture'}
                                        accept={'image/png'}
                                        showUploadList={false}
                                        className={s.blockWrapper__upload}
                                        beforeUpload={beforeUpload}
                                    >

                                        <div className={s.blockWrapper__upload__text}>
                                            <uploadIcon.ReactComponent/>
                                            <div>
                                    <span style={{fontWeight: 700, marginRight: '5px'}}>
                                        Click to upload
                                    </span>
                                                or drag and drop
                                            </div>
                                            <span>
                                    JPEG, PNG, JPG (max. 800x400px)
                                </span>
                                        </div>

                                    </Upload.Dragger>
                                </Form.Item>
                                {
                                    file
                                        ?
                                        <div className={'sendLinkImageLoader'}>
                                            <div className={s.sendLinkImageLoader__header}>
                                                <div style={{
                                                    display: 'flex'
                                                }}>
                                                    <div>
                                                        <img src={imageUploadIcon} alt="upload"/>
                                                    </div>
                                                    <div className={s.sendLinkImageLoader__imgInfo}>

                                                        <p>{file.name}</p>


                                                        <p>{file.size} KB</p>

                                                    </div>
                                                </div>
                                                <div className={s.imageLoaderCheck} onClick={onDeleteImage}>
                                                    {/*<svg width="10" height="10" viewBox="0 0 10 10" fill="none"*/}
                                                    {/*     xmlns="http://www.w3.org/2000/svg">*/}
                                                    {/*    <g id="check">*/}
                                                    {/*        <path id="Icon" d="M8.33341 2.5L3.75008 7.08333L1.66675 5"*/}
                                                    {/*              stroke="white" strokeWidth="1.66667"*/}
                                                    {/*              strokeLinecap="round" strokeLinejoin="round"/>*/}
                                                    {/*    </g>*/}
                                                    {/*</svg>*/}
                                                    <deleteIcon.ReactComponent/>
                                                </div>
                                            </div>

                                            <div>
                                                <Progress percent={100} width={277} style={{
                                                    marginLeft: '50px'
                                                }} strokeColor={['#0A5BEB', '#9233F4']}/>
                                                <span className={s.percentage}>100%</span>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                                <div className={s.formButtons}>
                                    <Form.Item style={{
                                        marginBottom: '0px',
                                        marginRight: '12px',
                                        width: '100%'
                                    }}>
                                        <button
                                            type='reset'
                                            className={s.cancelBtn}
                                            onClick={() => dispatch(setIsSendLinkModalOpened(false))}
                                        >
                                            Cancel
                                        </button>
                                    </Form.Item>
                                    <Form.Item style={{
                                        marginBottom: '0px',
                                        width: '100%'
                                    }}>
                                        <Button type='primary' htmlType='submit' className={authS.formBlock__submit}
                                                loading={isLoading}>
                                            Next
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </>
                        :
                        clientModalStep === 'Configs'
                            ?
                            <>
                                {
                                    services.map((service: any) => {
                                        return (
                                            <ClientModalService image={service.img} serviceName={service.serviceName}/>
                                        )
                                    })
                                }
                                <div className={s.clientButtons}>
                                    {
                                        !resendLinkMode
                                            ?
                                            <>
                                                <button
                                                    type='reset'
                                                    className={s.cancelBtn}
                                                    onClick={() => onCreateLinkConfig('Copy link')}
                                                    style={{
                                                        marginRight: '12px',
                                                        height: '46px'
                                                    }}
                                                >
                                                    Copy link
                                                </button>
                                                {
                                                    !clientServices.length
                                                        ?
                                                        <Tooltip title={'Select at least one service please'} style={{
                                                            width: '200px'
                                                        }}>
                                                            <Select
                                                                placeholder={'Send by email'}
                                                                style={{width: 205}}
                                                                options={[{
                                                                    value: `${clientEmail}`,
                                                                    label: `${clientEmail}`
                                                                }, {value: `Another email`, label: 'Another email'}]}
                                                                onChange={onChangeButtonSendOption}
                                                                className={'sendLinkButton'}
                                                                loading={isLoading}
                                                                disabled={true}
                                                            />
                                                        </Tooltip>
                                                        :
                                                        <Select
                                                            placeholder={'Send by email'}
                                                            style={{width: 205}}
                                                            options={[{
                                                                value: `${clientEmail}`,
                                                                label: `${clientEmail}`
                                                            }, {value: `Another email`, label: 'Another email'}]}
                                                            onChange={onChangeButtonSendOption}
                                                            className={'sendLinkButton'}
                                                            loading={isLoading}
                                                        />
                                                }


                                                {/*<Button type='primary' htmlType='submit'*/}
                                                {/*        className={authS.formBlock__submit}*/}
                                                {/*        loading={isLoading}*/}
                                                {/*        onClick={() => onSetSendLinkModalFinalStep('Send link')}*/}
                                                {/*>*/}
                                                {/*    Send by Email*/}
                                                {/*</Button>*/}
                                            </>
                                            :
                                            <>
                                                <Button type='primary' htmlType='submit'
                                                        className={authS.formBlock__submit}
                                                        loading={isLoading}
                                                        onClick={onUpdateLinkConfig}
                                                >
                                                    Save
                                                </Button>
                                            </>
                                    }

                                </div>
                            </>
                            :
                            <></>
            }

        </CommonModal>
    );
};

export default SendLinkModal;