import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {useAppDispatch, useAppSelector} from "../../hooks";
import s from "./Auth.module.scss";
import AnimatedSignInBtn from "./AnimatedSignInBtn";
import {clientLoginDataHelper} from "../../helpers/localStorageHelper";
import {GetClientServicesThunk, selectCurrentClientServices} from "../../store/clientReducer";
import {ClientServiceType} from "../../types/clientsTypes";
import {googleIntegration, LinkActivityReqData, tikTokIntegration} from "../../api/api";
import {UpdateActivityLinkThunk} from "../../store/activityTrackerReducer";
import {Progress} from 'antd';
import Tooltip from "../Tootlip/Tooltip";

const oneclickLogo = require('../../assets/img/oneclick-logo.svg')
const authPageDecoration = require('../../img/authPageDecoration.svg')
const warningIcon = require('../../img/icons/problemWithAddressIcon.svg')
const googleIcon = require('../../img/icons/google-logo-small.svg')
const tiktokIcon = require('../../img/icons/tiktok-logo-small.svg')


const MultiStepSignIn = () => {
    const dispatch = useAppDispatch()
    const {id} = useParams<{ id: string }>()
    // const clientLoginLinks = useAppSelector(selectMultiStepClientLoginLinks)
    const services = useAppSelector(selectCurrentClientServices)
    // eslint-disable-next-line
    const [agencyName, setAgencyName] = useState('')
    const [agencyLogo, setAgencyLogo] = useState('')

    clientLoginDataHelper.setCurrentClientRedirectUrl(window.location.href)
    useEffect(() => {
        services.forEach((s: ClientServiceType) => {
            if (s.service_type === 'GOOGLE') {
                googleIntegration.getGoogleAccountClientRedirectLink(Number(s.config_id))
                    .then((res) => {
                        setAgencyName(res.data.agency_name)
                        setAgencyLogo(res.data.agency_logo)
                    })
            } else if (s.service_type === 'TIKTOK') {
                tikTokIntegration.getTikTokAccountClientRedirectLink(Number(s.config_id))
                    .then((res) => {
                        setAgencyName(res.data.agency_name)
                        setAgencyLogo(res.data.agency_logo)
                    })
            }
        })
    }, [services, id])

    const onGoogleLogin = () => {
        services.forEach((s: ClientServiceType) => {
            if (s.service_type === 'GOOGLE') {
                clientLoginDataHelper.setActivityLinkId(s.link_id)
                clientLoginDataHelper.setConfigId(s.config_id)
                clientLoginDataHelper.setLinkId(id)
                const linkActivityReqData: LinkActivityReqData = {
                    status: 'OPENED'
                }
                dispatch(UpdateActivityLinkThunk({
                    link_activity_id: Number(s.link_id),
                    linkActivityReqData: linkActivityReqData
                }))
                googleIntegration.getGoogleAccountClientRedirectLink(Number(s.config_id))
                    .then((res) => {
                        console.log(res)
                        let redirect_uri = res.data.login_link.split('redirect_uri=')[1]
                        redirect_uri = redirect_uri.split('&')[0]
                        clientLoginDataHelper.setRedirectUri(redirect_uri)
                        window.location.replace(res.data.login_link)
                    })
            }
        })
    }

    const onTikTokLogin = () => {
        services.forEach((s: ClientServiceType) => {
            if (s.service_type === 'TIKTOK') {
                clientLoginDataHelper.setActivityLinkId(s.link_id)
                clientLoginDataHelper.setConfigId(s.config_id)
                clientLoginDataHelper.setLinkId(id)
                const linkActivityReqData: LinkActivityReqData = {
                    status: 'OPENED'
                }
                dispatch(UpdateActivityLinkThunk({
                    link_activity_id: Number(s.link_id),
                    linkActivityReqData: linkActivityReqData
                }))
                tikTokIntegration.getTikTokAccountClientRedirectLink(Number(s.config_id))
                    .then((res) => {
                        window.location.replace(res.data.login_link)
                    })
            }
        })
    }

    useEffect(() => {
        if (id) {
            dispatch(GetClientServicesThunk(Number(id)))
        }
    }, [dispatch, id])
    // eslint-disable-next-line
    const [screenWidth, setScreenWidth] = useState<number>(0)

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const integratedCount = services.filter((s: ClientServiceType) => s.status === 'INTEGRATED')
    const integratedPercentage = (integratedCount.length / services.length) * 100;



    return (
        <>
            <div className={s.inner}>
                <authPageDecoration.ReactComponent className={s.inner__decoration}/>
                <div className={s.inner__multiStepBeginning}>
                    {
                        agencyLogo && agencyLogo !== null
                            ?
                            <div style={{
                                maxWidth: '250px',
                                maxHeight: '300px'
                            }}>
                                <img src={agencyLogo} alt="logo" style={{
                                    objectFit: 'contain',
                                    width: '100%',
                                    height: '100%'
                                }
                                }/>
                            </div>
                            :
                            <oneclickLogo.ReactComponent/>
                    }

                    <p className={s.inner__bigText}>
                        We're thrilled to be working with you on promoting your business in the exciting world of social
                        media. As part of our efforts, we need access to your Facebook Google and Tik Tok Business
                        Centers
                        so we can start promoting your brand to your target audience. To simplify the process, just Sign
                        in
                        to your accounts, and we'll take care of the rest.
                    </p>
                    <p className={s.inner__smallText}>
                        Please note that we take your privacy and security very seriously, and we will only use the
                        permissions granted to us for the sole purpose of promoting your brand on social media. Rest
                        assured
                        that we will never share your information with any third parties.
                    </p>
                    <Progress percent={integratedPercentage}/>
                    <div className={s.inner__multiStepBeginning_steps}>
                        {
                            services.map((service: ClientServiceType) => {
                                return (
                                    screenWidth > 650
                                    ?
                                    <div className={s.tableRow}>
                                        <p>{service.service_type}</p>
                                        {service.service_type === 'GOOGLE' ? <googleIcon.ReactComponent/> : <tiktokIcon.ReactComponent/>}
                                        <p
                                            style={{
                                                color: service.status === 'SENT' || service.status === 'OPENED' || service.status === null ? '#B54708' : '#027A48',
                                                fontFamily: 'Inter, sans-serif',
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                cursor: 'pointer',
                                                borderRadius: '16px',
                                                padding: '2px 8px',
                                                backgroundColor: service.status === 'SENT' || service.status === 'OPENED' || service.status === null ? '#FFFAEB' : '#ECFDF3'
                                            }}
                                        > {service.status === 'INTEGRATED' ? 'Access granted' : 'Pending access'}
                                        </p>
                                        {
                                            service.status !== 'INTEGRATED'
                                                ?
                                                <AnimatedSignInBtn
                                                    onLogin={service.service_type === 'GOOGLE' ? onGoogleLogin : onTikTokLogin}
                                                    isVisible={true}/>
                                                :
                                                service.error_message
                                                    ?
                                                    <div style={{
                                                        width: '220px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Tooltip text={`${service.error_message}`}
                                                                 classname={'addressButtonsTooltip '}>
                                                            <div className={s.warningIcon}>
                                                                <warningIcon.ReactComponent/>
                                                            </div>
                                                        </Tooltip>
                                                    </div>

                                                    :
                                                    <div style={{
                                                        width: '215px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>

                                                    </div>
                                        }


                                    </div>
                                        :
                                        <div className={s.tableRow}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: '20px'
                                            }}>
                                                <p style={{
                                                    marginRight: '15px'
                                                }}>{service.service_type}</p>
                                                {service.service_type === 'GOOGLE' ? <googleIcon.ReactComponent/> : <tiktokIcon.ReactComponent/>}
                                                <p
                                                    style={{
                                                        color: service.status === 'SENT' || service.status === 'OPENED' || service.status === null ? '#B54708' : '#027A48',
                                                        fontFamily: 'Inter, sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        cursor: 'pointer',
                                                        borderRadius: '16px',
                                                        padding: '2px 8px',
                                                        backgroundColor: service.status === 'SENT' || service.status === 'OPENED' || service.status === null ? '#FFFAEB' : '#ECFDF3',
                                                        marginLeft: '15px'
                                                    }}
                                                > {service.status === 'INTEGRATED' ? 'Access granted' : 'Pending access'}
                                                </p>
                                            </div>
                                            {
                                                service.status !== 'INTEGRATED'
                                                    ?
                                                    <AnimatedSignInBtn
                                                        onLogin={service.service_type === 'GOOGLE' ? onGoogleLogin : onTikTokLogin}
                                                        isVisible={true}/>
                                                    :
                                                    service.error_message
                                                        ?
                                                        <div style={{
                                                            width: '220px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}>
                                                            <Tooltip text={`${service.error_message}`}
                                                                     classname={'addressButtonsTooltip '}>
                                                                <div className={s.warningIcon}>
                                                                    <warningIcon.ReactComponent/>
                                                                </div>
                                                            </Tooltip>
                                                        </div>

                                                        :
                                                        <div style={{
                                                            width: '215px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}>

                                                        </div>
                                            }


                                        </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <p className={'powered'}>powered by OneClickOnboard</p>
        </>
    );
};

export default MultiStepSignIn;