import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AsyncThunkConfig, RootState} from "./store";
import {
    activitiesApi,
    CreateActivity, LinkActivityReqData,
} from "../api/api";
import {AppStatusType} from "./appStatusReducer";



interface InitialStateType {
    activitiesLinks: ActivityType[]
    isActivityMultiStepLink: boolean
    creditsTableActivitiesLinks:  ActivityType[]
}

export interface ActivityType {
    id: number
    link_id: number
    platform_name: "FACEBOOK" | "GOOGLE" | "TIKTOK",
    user_name: string
    user_email: string
    permission_list: string
    last_activity: string
}

const initialState: InitialStateType = {
    activitiesLinks: [],
    isActivityMultiStepLink: false,
    creditsTableActivitiesLinks: []
}

export const activityTrackerSlice = createSlice({
    name: 'activityTracker',
    initialState,
    reducers: {
        setIsActivityMultiStepLink: (state, action: PayloadAction<boolean>) => {
            state.isActivityMultiStepLink = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetActivitiesLinksThunk.fulfilled, (state, action) => {
                console.log(action.payload[0])
                state.activitiesLinks.push(action.payload[0])
            })
            .addCase(GetCreditsTableActivitiesLinksThunk.fulfilled, (state, action) => {
                state.creditsTableActivitiesLinks = action.payload.filter((l:any) => l.credits_used !== 0)
            })

    }
})
// eslint-disable-next-line
export const {setIsActivityMultiStepLink} = activityTrackerSlice.actions
export const selectActivitiesLinks = (state: RootState): ActivityType[] => state.activityTracker.activitiesLinks
export const selectCreditsTableActivitiesLinks = (state: RootState): ActivityType[] => state.activityTracker.creditsTableActivitiesLinks

export const GetActivitiesLinksThunk = createAsyncThunk<ActivityType[], {link_id: number, platform_name: string}, AsyncThunkConfig>(
    'activityTracker/getActivitiesLinks',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await activitiesApi.getActivitiesLinks(reqData.link_id, reqData.platform_name)
            if (status === 200 && data) {
                console.log(data)
                return thunkAPI.fulfillWithValue(data.link_activities, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const CreateActivityLinkThunk = createAsyncThunk<ActivityType, CreateActivity, AsyncThunkConfig>(
    'activityTracker/getActivitiesLinks',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await activitiesApi.createLinkActivity(reqData)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const UpdateActivityLinkThunk = createAsyncThunk<ActivityType, {link_activity_id: number, linkActivityReqData: LinkActivityReqData}, AsyncThunkConfig>(
    'activityTracker/updateActivitiesLink',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await activitiesApi.updateLinkActivity(reqData.link_activity_id, reqData.linkActivityReqData)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetCreditsTableActivitiesLinksThunk = createAsyncThunk<ActivityType[], void, AsyncThunkConfig>(
    'activityTracker/getCreditsTableActivitiesLinks',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await activitiesApi.getAllLinkActivities()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.link_activities, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export default activityTrackerSlice.reducer