import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {authApi, clientApi, LinkActivityReqData} from "../../api/api";
import {clientLoginDataHelper, userMultiStepHelper} from "../../helpers/localStorageHelper";
import {LoginGoogleClientThunk, selectGoogleClientData} from "../../store/userReducer";
import {Button, Checkbox, Form, Spin} from "antd";
import s from "./Auth.module.scss";
import {
    UpdateActivityLinkThunk
} from "../../store/activityTrackerReducer";


const oneclickLogo = require('../../assets/img/oneclick-logo.svg')
const authPageDecoration = require('../../img/authPageDecoration.svg')

const GoogleSignInClient = () => {
    const dispatch = useAppDispatch()
    const googleClientData = useAppSelector(selectGoogleClientData)
    const [checked, setChecked] = useState<any>([])
    const [checkedT, setCheckedT] = useState<any>([])
    const [checkedTag, setCheckedTag] = useState<any>([])
    const [checkedAds, setCheckedAds] = useState<any>([])
    const [checkedAnalytics, setCheckedAnalytics] = useState<any>([])
    const [checkedA, setCheckedA] = useState<any>([])
    const isMultiStepAuth = userMultiStepHelper.getIsUserMultiStepLogin()
    const [agencyName, setAgencyName] = useState('')
    const [agencyLogo, setAgencyLogo] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    // eslint-disable-next-line
    let code = location.search.split('code=')[1]
    // eslint-disable-next-line
    let linkId = location.search.split('link=')[1]
    code = code?.split('&')[0]
    // eslint-disable-next-line
    let redirect_uri = ''
    const navigate = useNavigate()
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue(googleClientData?.resources)
        if (!!googleClientData?.resources?.google_ads?.length) {
            const checkedArray = googleClientData?.resources?.google_ads.map((r: any) => r.customer_id)
            setChecked(checkedArray)
            setCheckedAds(googleClientData?.resources.google_ads)
        }
        if (!!googleClientData?.resources?.google_tag_manager?.length) {
            const checkedArray = googleClientData?.resources?.google_tag_manager.map((r: any) => r.account_id)
            setCheckedT(checkedArray)
            setCheckedTag(googleClientData?.resources.google_tag_manager);
        }
        if (!!googleClientData?.resources?.google_analytics?.length) {
            const checkedArray = googleClientData?.resources?.google_analytics.map((r: any) => r.id)
            setCheckedA(checkedArray)
            setCheckedAnalytics(googleClientData?.resources.google_analytics);
        }
    }, [form, googleClientData])



    useEffect(() => {
        if (code && !googleClientData && !isMultiStepAuth) {
            const id = clientLoginDataHelper.getConfigId()
            const redirect_uri = clientLoginDataHelper.getRedirectUri()
            // eslint-disable-next-line
            dispatch(LoginGoogleClientThunk({
                authorization_code: code,
                redirect_url: redirect_uri,
                config_id: Number(id)
            })).then((r) => {
                    console.log(r)
                    setAgencyName(r.payload.agency_name)
                    setAgencyLogo(r.payload.agency_logo)
                }
            )
        }
    }, [dispatch, code, redirect_uri, googleClientData, isMultiStepAuth])

    useEffect(() => {

    }, [])


    useEffect(() => {
        if (isMultiStepAuth) {
            const linkActivityReqData: LinkActivityReqData = {
                status: 'OPENED'
            }
            dispatch(UpdateActivityLinkThunk({
                link_activity_id: Number(linkId),
                linkActivityReqData: linkActivityReqData
            }))
            const redirect_uri = userMultiStepHelper.getUserMultiStepLinkGoogleRedirectUri()
            const configId = userMultiStepHelper.getUserMultiStepLinkGoogleConfigId()
            dispatch(LoginGoogleClientThunk({
                authorization_code: code,
                redirect_url: redirect_uri,
                config_id: Number(configId)
            })).then((r) => {
                    console.log(r)
                    setAgencyName(r.payload.agency_name)
                    setAgencyLogo(r.payload.agency_logo)
                }
            )
        }
    }, [dispatch, isMultiStepAuth, code, linkId])


    const handleCheck = (event: any) => {
        const name = event.target.name;
        if (event.target.checked) {
            const checkedArray = [...checked, name]
            const add = googleClientData?.resources.google_ads?.find((a: any) => a.customer_id === Number(name))
            const checkedAdsArray = [...checkedAds, add]
            setChecked(checkedArray);
            setCheckedAds(checkedAdsArray)
        } else {
            const checkedArray = checked.filter((c: any) => c !== name)
            const checkedAdsArray = checkedAds.filter((a: any) => a.customer_id !== Number(name))
            setChecked(checkedArray)
            setCheckedAds(checkedAdsArray)
        }
    };

    const handleTagCheck = (event: any) => {
        const name = event.target.name;
        if (event.target.checked) {
            const checkedArray = [...checkedT, name]
            const tag = googleClientData?.resources.google_tag_manager?.find((t: any) => Number(t.account_id) === Number(name))
            const checkedTagsArray = [...checkedTag, tag]
            setCheckedT(checkedArray)
            setCheckedTag(checkedTagsArray);
        } else {
            const checkedTArray = checkedT.filter((c: any) => c !== name)
            const checkedArray = checkedTag.filter((t: any) => Number(t.account_id) !== Number(name))
            setCheckedTag(checkedArray)
            setCheckedT(checkedTArray)
        }
    };


    const handleAnalyticsCheck = (event: any) => {
        const name = event.target.name;
        if (event.target.checked) {
            const checkedArray = [...checkedA, name]
            const anal = googleClientData?.resources.google_analytics?.find((t: any) => Number(t.id) === Number(name))
            const checkedTagsArray = [...checkedAnalytics, anal]
            setCheckedA(checkedArray)
            setCheckedAnalytics(checkedTagsArray);
        } else {
            const checkedAArray = checkedA.filter((c: any) => c !== name)
            const checkedArray = checkedAnalytics.filter((t: any) => Number(t.id) !== Number(name))
            setCheckedAnalytics(checkedAArray)
            setCheckedA(checkedArray)
        }
    };
    const onFinish = () => {
        setIsLoading(true)
        const id = clientLoginDataHelper.getConfigId()
        const configId = userMultiStepHelper.getUserMultiStepLinkGoogleConfigId()
        const linkActivityId = clientLoginDataHelper.getActivityLinkId()
        const reqBody = {
            refresh_token: googleClientData.refresh_token,
            config_id: !isMultiStepAuth ? Number(id) : Number(configId),
            resources: {
                google_ads: checkedAds.length ? checkedAds : null,
                google_tag_manager: checkedTag.length ? checkedTag : null,
                google_analytics: checkedAnalytics.length ? checkedAnalytics : null
            }
        }

        clientApi.checkIfCreditsAvailable(linkActivityId)
            .then((res) => {
                if (res.data.credit_is_available === true) {
                    authApi.googleClientGrantAccess(reqBody)
                        .then(() => {
                            const linkActivityReqData: LinkActivityReqData = {
                                link_id: id,
                                platform_name: 'GOOGLE',
                                user_name: googleClientData.resources.google_user_name,
                                user_email: googleClientData.resources.google_user_email,
                                status: 'INTEGRATED'
                            }
                            dispatch(UpdateActivityLinkThunk({
                                link_activity_id: linkActivityId,
                                linkActivityReqData: linkActivityReqData
                            }))
                                .then(() => {
                                    setIsLoading(false)
                                })
                            navigate('/client-google-login-success')
                        })
                } else {
                    navigate('/noCreditsLeft')
                }
            })

    }


    return (
        <>
            {
                code
                    ?
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        height: '100%'
                    }}>
                        {googleClientData
                            ?
                            <div className={s.inner} style={{width: '100%'}}>
                                <authPageDecoration.ReactComponent className={s.inner__decoration}/>
                                <div className={s.formBlock}>
                                    {
                                        agencyLogo && agencyLogo !== null
                                            ?
                                            <img src={agencyLogo} alt="logo"/>
                                            :
                                            <oneclickLogo.ReactComponent/>
                                    }

                                    <h1>
                                        {agencyName && agencyName !== null ? agencyName : 'Agency'} asking
                                        permission for your Google add accounts:
                                    </h1>
                                    <Form
                                        name='signInClients'
                                        onFinish={onFinish}
                                        autoComplete='off'
                                        form={form}
                                        validateTrigger='onBlur'
                                        initialValues={googleClientData?.resources}
                                        className={s.formBlock__form}
                                        style={{
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: "center",
                                            flexDirection: 'column'
                                        }}
                                    >
                                        {!!googleClientData?.resources?.google_ads?.length && <div style={{
                                            marginBottom: '10px'
                                        }}>
                                            <p className={s.formBlock__label}>Google ad accounts:</p>
                                            {googleClientData?.resources?.google_ads?.map((p: any) => {
                                                return (
                                                    <div className={s.clientForm__item} key={p.customer_id}>
                                                        <Checkbox
                                                            onChange={handleCheck}
                                                            name={p.customer_id}
                                                            checked={checked.includes(p.customer_id)}
                                                        >
                                                            {`${p.descriptive_name} - (${p.customer_id})`}
                                                        </Checkbox>
                                                        {/*<Tooltip title={p.description}>*/}
                                                        {/*    <QuestionCircleOutlined/>*/}
                                                        {/*</Tooltip>*/}
                                                    </div>
                                                )
                                            })}
                                        </div>}
                                        {!!googleClientData?.resources?.google_tag_manager?.length && <div style={{
                                            marginBottom: '10px'
                                        }}>
                                            <p className={s.formBlock__label}>Google tag accounts:</p>
                                            {googleClientData?.resources?.google_tag_manager?.map((p: any) => {
                                                return (
                                                    <div className={s.clientForm__item} key={p.account_id}>
                                                        <Checkbox
                                                            onChange={handleTagCheck}
                                                            name={p.account_id}
                                                            checked={checkedT.includes(p.account_id)}
                                                        >
                                                            {`${p.name} - (${p.account_id})`}
                                                        </Checkbox>
                                                        {/*<Tooltip title={p.description}>*/}
                                                        {/*    <QuestionCircleOutlined/>*/}
                                                        {/*</Tooltip>*/}
                                                    </div>
                                                )
                                            })}
                                        </div>}
                                        {!!googleClientData?.resources?.google_analytics?.length && <div style={{
                                            marginBottom: '10px'
                                        }}>
                                            <p className={s.formBlock__label}>Google analytics:</p>
                                            {googleClientData?.resources?.google_analytics?.map((p: any) => {
                                                return (
                                                    <div className={s.clientForm__item} key={p.id}>
                                                        <Checkbox
                                                            onChange={handleAnalyticsCheck}
                                                            name={p.id}
                                                            checked={checkedA.includes(p.id)}
                                                        >
                                                            {`${p.name} - (${p.id})`}
                                                        </Checkbox>
                                                        {/*<Tooltip title={p.description}>*/}
                                                        {/*    <QuestionCircleOutlined/>*/}
                                                        {/*</Tooltip>*/}
                                                    </div>
                                                )
                                            })}
                                        </div>}
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                                className={s.formBlock__submitGradient}
                                                loading={isLoading}
                                            >
                                                Grant access
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            :
                            <Spin style={
                                {
                                    margin: '0 auto',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    minHeight: '290px'
                                }
                            }
                            />
                        }
                    </div>
                    :
                    <div className={s.inner}>
                        <authPageDecoration.ReactComponent className={s.inner__decoration}/>
                        <div className={s.formBlock} style={{width: '540px'}}>
                            {
                                agencyLogo && agencyLogo !== null
                                    ?
                                    <div style={{
                                        maxWidth: '300px',
                                        maxHeight: '350px'
                                    }}>
                                        <img src={agencyLogo} alt="logo" style={{
                                            objectFit: 'contain',
                                            width: '100%',
                                            height: '100%'
                                        }
                                        }/>
                                    </div>
                                    :
                                    <oneclickLogo.ReactComponent/>
                            }

                            {/*{!!agencyName ? (*/}
                            {/*    <>*/}
                            {/*        <h1 style={{fontSize: '24px', lineHeight: '28px', marginBottom: '40px'}}>*/}
                            {/*            {agencyName} requests permission to access your <span*/}
                            {/*            style={{fontWeight: 700, color: '#2653ed'}}>Google Business Centers</span>,*/}
                            {/*            please sign*/}
                            {/*            in*/}
                            {/*        </h1>*/}
                            {/*        <AnimatedSignInBtn onLogin={onLogin}/>*/}
                            {/*    </>*/}
                            {/*) : (*/}
                            {/*    <Spin/>*/}
                            {/*)}*/}
                        </div>
                    </div>
            }
            <p className={'powered'}>powered by OneClickOnboard</p>
        </>

    );
};

export default GoogleSignInClient;
