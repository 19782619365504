import { Button, Form, Input } from 'antd'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import s from './Auth.module.scss'
import { useAppDispatch } from '../../hooks'
import { ResetPasswordThunk } from '../../store/userReducer'

const authPageDecoration = require('../../img/authPageDecoration.svg')

const CreateNewPasswordForm = () => {
    const hasToMatchValidator = (valueToCompare: string, errorText: string) => ({
        validator(_: any, value: any) {
            if (!value || valueToCompare === value) {
                return Promise.resolve()
            }
            return Promise.reject(new Error(errorText))
        }
    })

    const dispatch = useAppDispatch()
    const location = useLocation()

    const [isCreated, setIsCreated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const onFinish = (formData: FormValuesType) => {
        // location.search example: ?token=be82790e-06c3-4f06-a016-dc9f12451d0e
        const token = location.search.substr(location.search.indexOf('=') + 1)
        setIsLoading(true)
        dispatch(ResetPasswordThunk({password: formData.password, token}))
            .then((resp:any) => {
                setIsLoading(false)
                !resp.type.includes('rejected') && setIsCreated(true)
            })
    }

    if (isCreated) {
        return (
            <div className={s.inner}>
                <authPageDecoration.ReactComponent className={s.inner__decoration}/>
                <div className={s.formBlock}>
                    <h1>
                        Password reset
                    </h1>
                    <div style={{fontSize: '18px', textAlign: 'center'}}>
                        Your password has been successfully reset
                    </div>
                    <Link to='/sign-in' style={{margin: '20px 0px'}}>
                        <Button type='primary' className={s.formBlock__submit} >
                            To Sign In
                        </Button>
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <div className={s.inner}>
            <authPageDecoration.ReactComponent className={s.inner__decoration}/>
            <div className={s.formBlock}>
                <Form
                    name='newPassword'
                    onFinish={onFinish}
                    autoComplete='off'
                    validateTrigger='onBlur'
                    className={s.formBlock__form}
                >
                    <h1>
                        Set new password
                    </h1>

                    <div className={s.formBlock__label}>
                        New password
                    </div>
                    <Form.Item
                        name='password'
                        rules={[
                            { required: true, message: 'Please enter your password!' },
                            { min: 4, message: 'Password must be at least 4 characters '}
                        ]}
                    >
                        <Input.Password placeholder='Enter new password' className={s.formBlock__input}/>
                    </Form.Item>

                    <div className={s.formBlock__label}>
                        Confirm password
                    </div>
                    <Form.Item
                        name='repeatPassword'
                        dependencies={['password']}
                        rules={[
                            {required: true, message: 'Please repeat your password!'},
                            ({getFieldValue}) => hasToMatchValidator(getFieldValue('password'), 'The two passwords that you entered do not match!'),
                        ]}
                    >
                        <Input.Password placeholder='Confirm password' className={s.formBlock__input}/>
                    </Form.Item>

                    <Form.Item>
                        <Button type='primary' htmlType='submit' className={s.formBlock__submit} loading={isLoading}>
                            Reset password
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

interface FormValuesType {
    password: string
    repeatPassword: string
}

export default CreateNewPasswordForm

