import React from 'react';
import {serviceFilters} from "../Activity";
import s from './ActivityFilters.module.scss'

interface ActivityFiltersProps {
    currentActivityFilter: string
    setCurrentActivityFilter: (filter: serviceFilters) => void
}

const ActivityFilters = ({currentActivityFilter, setCurrentActivityFilter} : ActivityFiltersProps) => {

    const filters: serviceFilters[] = ['View all', 'Google', 'TikTok']

    return (
        <div className={s.inner}>
            {filters.map((f: serviceFilters) => {
                return(
                    <button
                        onClick={() => {setCurrentActivityFilter(f)}}
                        className={currentActivityFilter === f ?`${s.button} ${s.button_active}` : s.button}
                    >
                        {f}
                    </button>
                )
            })}
        </div>
    );
};

export default ActivityFilters;