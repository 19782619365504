import React, {useEffect, useState} from 'react';
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import s from "./ClientsDetails.module.scss";
import PageTitle from "../../PageTitle/PageTitle";
import tableS from "../../FacebookLinksTable/FacebookLinksTable.module.scss";
import {Box} from "@material-ui/core";
import {DataGridPro, useGridApiRef} from "@mui/x-data-grid-pro";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {
    GetClientServicesThunk,
    GetClientThunk, onSetClientServices,
    onSetResetLinkMode, selectClient,
    selectCurrentClientServices,
    setClientForIntegrationLinkCreation,
    setClientServices, UpdateClientLogoThunk,
} from "../../../store/clientReducer";
import {
    selectIsSendLinkModalOpened,
    setCurrentClientModalStep,
    setIsSendLinkModalOpened
} from "../../../store/utilityReducer";
import SendLinkModal from "../../SendLinkModal/SendLinkModal";
import {ClientServiceType} from "../../../types/clientsTypes";
import {GetGoogleTableLinkByIdThunk} from "../../../store/googleReducer";
import {clientLoginDataHelper, userDataHelper} from "../../../helpers/localStorageHelper";
import {Empty, message, Progress, Select} from "antd";
import {ResendLinkViaEmailThunk} from "../../../store/linksReducer";
import "react-image-upload/dist/index.css";
import moment from "moment/moment";
import {GetActivitiesLinksThunk, selectActivitiesLinks} from "../../../store/activityTrackerReducer";
import {setUserData} from "../../../store/userReducer";
import {FacebookCircularProgress} from "../ClientsTable/ClientsTable";


const checkIconLogo = require('../../../img/icons/check_icon.svg')
const googleIcon = require('../../../img/icons/google-logo-small.svg')
const tiktokIcon = require('../../../img/icons/tiktok-logo-small.svg')


const ClientDetails = () => {
    return (
        <PageWithSidebar>
            <ClientDetailsComponent/>
        </PageWithSidebar>
    )
}


const ClientDetailsComponent = () => {
    const apiRef = useGridApiRef();
    const dispatch = useAppDispatch()
    const currentClientServices = useAppSelector(selectCurrentClientServices)
    const isSendLinkModalOpened = useAppSelector(selectIsSendLinkModalOpened)
    const clientId = clientLoginDataHelper.getCurrentClientDataId()
    const currentClientData = useAppSelector(selectClient)
    const services = useAppSelector(selectCurrentClientServices)
    const activitiesLinks = useAppSelector(selectActivitiesLinks)
    const [loading, setIsLoading] = useState(false)
    function convertToTitleCase(inputWord: string) {
        return inputWord?.charAt(0)?.toUpperCase() + inputWord?.slice(1)?.toLowerCase();
    }

    useEffect(() => {
        dispatch(setUserData(userDataHelper.getUserData()))
    }, [dispatch])


    useEffect(() => {
        currentClientServices.forEach((c) => {
            dispatch(GetActivitiesLinksThunk({link_id: c.config_id, platform_name: c.service_type}))
        })

    }, [dispatch, currentClientServices])

    useEffect(() => {
        if (clientId && clientId !== false) {
            dispatch(GetClientThunk(clientId))
        }
    }, [dispatch, clientId])

    const columns = [
        {
            field: 'service_type',
            headerName: 'Services',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            width: 190,
            color: '#6B7280',
            fontSize: '14px',
            lineHeight: '20px',
            filterable: false,
            renderCell: (data: any) => {
                return (
                    <div
                        style={{
                            color: '#101828',
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            padding: '0 10px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                        <span style={{
                            marginRight: '10px',
                            width: '90px',
                            display: "block"
                        }}>{data.row.service_type}</span>
                        <span>{data.row.service_type === 'GOOGLE' ? <googleIcon.ReactComponent/> :
                            <tiktokIcon.ReactComponent/>}</span>
                    </div>)

            }
        },
        {
            field: 'access_request',
            headerName: 'Access request',
            width: 350,
            filterable: false,
            renderCell: () => {
                return (
                    <div
                        style={{
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 400,
                            color: '#667085',
                            width: "fit-content",
                            fontSize: '14px',
                            lineHeight: '20px',
                            padding: '0 10px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                        <checkIconLogo.ReactComponent/>
                        <span style={{
                            marginLeft: '12px'
                        }}>Access to selected functions</span>
                    </div>)

            }
        },
        {
            field: 'permissions',
            headerName: 'Permissions',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            width: 550,
            filterable: false,
            renderCell: (data: any) => {
                const matchingItem = activitiesLinks.find(s => s.link_id === data.row.config_id);
                return (
                    data.row.status === 'SENT' || data.row.status === 'OPENED' || data.row.status === null
                        ?
                        <div
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                color: data.row.status !== 'SENT' && data.row.status !== 'OPENED' && data.row.status !== null ? '#027A48' : '#344054',
                                backgroundColor: data.row.status !== 'SENT' && data.row.status !== 'OPENED' && data.row.status !== null ? '#ECFDF3' : '#F2F4F7',
                                width: 'fit-content',
                                fontSize: '12px',
                                lineHeight: '18px',
                                padding: '0 10px',
                                borderRadius: '16px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: '22px',
                            }}>
                            <div style={{
                                width: '8px',
                                height: '8px',
                                backgroundColor: data.row.status !== 'SENT' && data.row.status !== 'OPENED' && data.row.status !== null ? '#12B76A' : '#667085',
                                borderRadius: '50%',
                                marginRight: '6px'
                            }}></div>
                            Pending...
                        </div>
                        :
                        <div className={s.permissionsList}>{matchingItem?.permission_list}</div>

                )

            }
        },
        {
            field: 'last_activity',
            headerName: 'Last accessed',
            width: 200,
            filterable: false,
            renderCell: (data: any) => {
                const matchingItem = activitiesLinks.find(s => s.link_id === data.row.config_id);
                return (
                    <div
                        style={{
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 400,
                            color: '#667085',
                            width: "fit-content",
                            fontSize: '14px',
                            lineHeight: '20px',
                            padding: '0 10px'
                        }}>
                        <div
                            className={s.permissionsList}>{moment(matchingItem?.last_activity).format('DD MMM YYYY')}</div>
                    </div>)

            }
        },
        {
            field: 'error_message',
            headerName: 'Errors',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            width: 1100,
            color: '#6B7280',
            fontSize: '14px',
            lineHeight: '20px',
            filterable: false,
            renderCell: (data: any) => {
                return (
                    <div
                        style={{
                            color: '#101828',
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            padding: '0 10px'
                        }}>
                        {data.row.error_message}
                    </div>)

            }
        },
    ]

    useEffect(() => {
        setIsLoading(true)
        dispatch(GetClientServicesThunk(currentClientData.id))
            .then(() => {
                setTimeout(() => {
                    setIsLoading(false)
                }, 1000)

            })
    }, [dispatch, currentClientData.id])


    const onSentToArchive = () => {
        const formData = new FormData()
        formData.append('client', new Blob([JSON.stringify({
            name: currentClientData.name,
            email: currentClientData.email,
            status: currentClientData.status === "Active" || currentClientData.status === 'ACTIVE' ? 'Archive' : 'Active'
        })], {type: 'application/json'}))
        dispatch(UpdateClientLogoThunk({client: formData, clientId: currentClientData.id}))
    }


    const onChangeButtonSendOption = (value: string) => {
        if (value === 'Another email') {
            dispatch(onSetResetLinkMode(true))
            dispatch(setClientForIntegrationLinkCreation(currentClientData))
            dispatch(setCurrentClientModalStep('Send link'))
            dispatch(setIsSendLinkModalOpened(true))
        } else {
            dispatch(ResendLinkViaEmailThunk({
                reqData: {
                    email: currentClientData.email,
                    message: '',
                    is_email: true,
                },
                isOurSystem: true,
                client_id: currentClientData.id,

            }))

        }

    }
    const onCopyClientLink = async () => {
        try {
            await navigator.clipboard.writeText(currentClientData.config_link);
            message.success('Link was copied successfully', 3)
        } catch (err) {
            message.error('Failed to copy link');
        }
    }

    const integratedCount = services.filter((s: ClientServiceType) => s.status === 'INTEGRATED')
    const integratedPercentage = (integratedCount.length / services.length) * 100;


    const onOpenConfigsModal = () => {
        dispatch(onSetClientServices([]))
        dispatch(GetClientServicesThunk(currentClientData.id))
            .then((res) => {
                dispatch(setClientForIntegrationLinkCreation(currentClientData))
                dispatch(setCurrentClientModalStep('Configs'))
                dispatch(setIsSendLinkModalOpened(true))
                dispatch(onSetResetLinkMode(true))
                // @ts-ignore
                res.payload.forEach((s: ClientServiceType) => {
                    if (s.service_type === 'GOOGLE' && s.status !== 'INTEGRATED') {
                        dispatch(setClientServices({service: 'Google', isResend: true}))
                        dispatch(GetGoogleTableLinkByIdThunk(s.config_id))
                    } else if (s.service_type === 'TIKTOK' && s.status !== 'INTEGRATED') {
                        dispatch(setClientServices({service: 'Tik Tok', isResend: true}))
                    }

                })
            })

    }


    return (
        <div className={s.inner}>
            <div className={s.inner__header}>


                <PageTitle
                    title={`${currentClientData.name}`}
                    email={`${currentClientData.email}`}
                    isBackLink={true}
                    backLink={'/'}
                    clientLogo={true}
                />


                <button
                    className={s.archiveBtn}
                    onClick={onSentToArchive}
                >
                    {currentClientData.status === 'Active' || currentClientData.status === 'ACTIVE' ? 'Archive' : 'Unarchive'}

                </button>
            </div>
            <div className={s.buttons_mobile}>
                <button
                    className={s.archiveBtn}
                    onClick={onCopyClientLink}
                    style={{
                        width: '136px',
                        height: '44px',
                        marginRight: '12px'
                    }}
                >
                    Copy link
                </button>
                <Select
                    placeholder={'Resend the link'}
                    style={{width: 160, height: 44, fontSize: 14}}
                    options={[{
                        value: `${currentClientData.email}`,
                        label: `${currentClientData.email}`
                    }, {value: `Another email`, label: 'Another email'}]}
                    onChange={onChangeButtonSendOption}
                    className={'resendLinkButton'}
                />
                {/*<button onClick={onResendLink} className={s.resendBtn}>Resend link</button>*/}
                <button onClick={onOpenConfigsModal}
                        className={s.configBtn}
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g id="Filters lines">
                            <path id="Icon" d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054"
                                  strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                    </svg>
                </button>
            </div>
            <div className={tableS.tableInner}>
                <div className={tableS.tableInner__header}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%'
                    }} className={s.itemHeader}>
                        <div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <p className={tableS.tableInner__title}>Personal link</p>
                                {
                                    currentClientData.min_status_value !== null
                                        ?
                                        <div style={{
                                            color: currentClientData.min_status_value === 'SEND' ? '#344054' : currentClientData.min_status_value === 'OPENED' ? '#B54708' : '#027A48',
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 400,
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                            cursor: 'pointer',
                                            borderRadius: '16px',
                                            padding: '2px 8px',
                                            backgroundColor: currentClientData.min_status_value === 'SEND' ? '#F2F4F7' : currentClientData.min_status_value === 'OPENED' ? '#FFFAEB' : '#ECFDF3',
                                            width: 'fit-content',
                                            marginLeft: '8px'
                                        }}>
                                            {convertToTitleCase(currentClientData.min_status_value === 'SEND' ? 'SENT' : currentClientData.min_status_value)}
                                        </div>
                                        :
                                        null
                                }
                                <Progress percent={integratedPercentage} style={{
                                    marginLeft: '8px'
                                }} strokeColor={['#0A5BEB', '#9233F4']}/>
                            </div>
                            <p className={tableS.link}>{currentClientData.config_link}</p>
                        </div>
                        <div className={s.buttons}>
                            <button
                                className={s.archiveBtn}
                                onClick={onCopyClientLink}
                                style={{
                                    width: '136px',
                                    height: '44px',
                                    marginRight: '12px'
                                }}
                            >
                                Copy link
                            </button>
                            <Select
                                placeholder={'Resend the link'}
                                style={{width: 160, height: 44, fontSize: 14}}
                                options={[{
                                    value: `${currentClientData.email}`,
                                    label: `${currentClientData.email}`
                                }, {value: `Another email`, label: 'Another email'}]}
                                onChange={onChangeButtonSendOption}
                                className={'resendLinkButton'}
                            />
                            {/*<button onClick={onResendLink} className={s.resendBtn}>Resend link</button>*/}
                            <button onClick={onOpenConfigsModal}
                                    className={s.configBtn}
                            >
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g id="Filters lines">
                                        <path id="Icon" d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054"
                                              strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                {
                    loading
                        ?
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            height: '305px'
                        }}>
                            <div style={{
                                position: "absolute",
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <FacebookCircularProgress size={100}/>
                            </div>

                        </div>
                        :
                        currentClientServices.length
                            ?

                            <Box>
                                <DataGridPro

                                    sx={{
                                        borderRight: 'none',
                                        borderLeft: 'none',
                                        borderBottom: 'none',
                                        borderRadius: '0px',
                                        borderTop: '1px solid transparent',
                                        "& .MuiDataGrid-columnHeader": {
                                            background: '#F9FAFB',
                                            padding: '0px 20px',
                                        },
                                        "& .MuiDataGrid-columnHeader:nth-child(3)": {
                                            background: '#F9FAFB',
                                            padding: '0px 20px',
                                            // minWidth: "540px !important"
                                        },
                                        "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                            display: "none"
                                        },
                                        "& .MuiDataGrid-cell": {},
                                        "& .MuiDataGrid-cell:nth-child(3)": {
                                            // minWidth: "540px !important"
                                        },
                                        "& .MuiDataGrid-cellContent": {
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#111827',
                                            padding: '0px 10px',
                                            // minWidth: '540px'
                                        },
                                        "& .MuiDataGrid-cellContent:nth-child(3)": {
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#111827',
                                            padding: '0px 10px',
                                            // minWidth: '540px'
                                        },
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 600,
                                            color: '#6B7280',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                        },
                                        "& .MuiDataGrid-row:hover": {
                                            backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                        },
                                        "& .MuiDataGrid-row.Mui-selected": {
                                            backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                        },
                                        "& .MuiDataGrid-row.Mui-selected:hover": {
                                            backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                        },
                                        "& .MuiDataGrid-cell:focus": {
                                            outline: 'none'
                                        },
                                        "& .MuiDataGrid-toolbarContainer": {
                                            color: '#454545',
                                            position: 'absolute',
                                            top: '-62px',
                                            right: '0px'
                                        },
                                        "& .MuiButtonBase-root": {
                                            fontFamily: 'Inter, sans-serif !important',
                                            fontWeight: 600,
                                            color: '#6B7280',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                        },
                                        "& .MuiTablePagination-displayedRows": {
                                            marginBottom: 0
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            overflowY: 'scroll',
                                            overflowX: 'scroll !important',
                                            height: `380px`,
                                        },
                                    }}
                                    apiRef={apiRef}
                                    columns={columns}
                                    rows={currentClientServices}
                                    {...currentClientServices}
                                    rowHeight={58}
                                    getRowId={e => e.config_id}
                                    headerHeight={39}
                                    pagination={true}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    hideFooterSelectedRowCount
                                    autoHeight={true}
                                    autoPageSize={true}
                                    // onRowClick={onRowClick}
                                />
                            </Box>
                            :
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '40px 0'
                            }}>
                                <Empty/>
                            </div>
                }
            </div>
            {
                isSendLinkModalOpened
                &&
                <SendLinkModal/>
            }
        </div>
    );
};

export default ClientDetails;