import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
    GetMultiStepLoginServicesThunk,
    selectMultiStepClientLoginLinks,
} from "../../store/multiStepReducer";
import {userMultiStepHelper} from "../../helpers/localStorageHelper";
import s from "../Auth/Auth.module.scss";
import AnimatedSignInBtn from "../Auth/AnimatedSignInBtn";
import {useNavigate} from "react-router";
import ClientMultiStepIndicator from "../ClientMultiStepIndicator/ClientMultiStepIndicator";
import {GetAgencyDataThunk, selectAgencyData} from "../../store/agencyReducer";

const oneclickLogo = require('../../assets/img/oneclick-logo.svg')
const authPageDecoration = require('../../img/authPageDecoration.svg')

const MultiStepUserSteps = () => {
    const dispatch = useAppDispatch()
    let clientLoginLinks = useAppSelector(selectMultiStepClientLoginLinks)
    const id = userMultiStepHelper.getUserMultiStepConfigId()
    const platformOrder = ['FACEBOOK', 'GOOGLE', 'TIKTOK'];
    const currentPlatformIndex = userMultiStepHelper.getUserMultiStepCurrentIndex()
    const multiStepLinksArrLength = userMultiStepHelper.getUserMultiStepLinksArrLength()
    const currentMultiStepIndex = userMultiStepHelper.getUserMultiStepCurrentIndex()
    const agencyData = useAppSelector(selectAgencyData)
    const navigate = useNavigate()
    useEffect(() => {
        // eslint-disable-next-line
        clientLoginLinks = [...clientLoginLinks].sort((a, b) => platformOrder.indexOf(a.service_type) - platformOrder.indexOf(b.service_type))
        // dispatch(onSetUserCurrentPlatformIndex(0))
        if (!currentPlatformIndex) {
            userMultiStepHelper.setUserMultiStepCurrentIndex(0)
        }
        userMultiStepHelper.setIsUserMultiStepLogin(true)
        userMultiStepHelper.setUserMultiStepLinksArrLength(clientLoginLinks.length)
    }, [clientLoginLinks, currentPlatformIndex, dispatch]);
    useEffect(() => {
        dispatch(GetMultiStepLoginServicesThunk(Number(id)))
    }, [dispatch, id])


    useEffect(() => {
        if (currentMultiStepIndex === multiStepLinksArrLength.length - 1) {
            navigate('/multiServiceLoginSuccess')
        }
    }, [currentMultiStepIndex, multiStepLinksArrLength, navigate])

    const currentPlatform = clientLoginLinks[currentPlatformIndex];
    const onLogin = () => {
        if (currentPlatform.service_type === 'FACEBOOK') {
            // Extract the config_id value
            const configId = currentPlatform?.login_link?.split('config_id=')[1].split("}")[0]
            userMultiStepHelper.setUserMultiStepLinkFacebookConfigId(Number(configId))
            const getParameterValue = (url: string, parameter: any) => {
                // eslint-disable-next-line
                parameter = parameter.replace(/[\[\]]/g, '\\$&');
                const regex = new RegExp(`[?&]${parameter}(=([^&#]*)|&|#|$)`);
                const results = regex.exec(url);

                if (!results) return null;
                if (!results[2]) return '';

                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            };

            // Extracting the redirect_uri value from the URL
            const redirectUri = getParameterValue(currentPlatform.login_link, 'redirect_uri');
            userMultiStepHelper.setUserMultiStepLinkFacebookRedirectUri(redirectUri)
        }
        if (currentPlatform.service_type === 'GOOGLE') {
            const getParameterValue = (url: any, parameter: any) => {
                // eslint-disable-next-line
                parameter = parameter.replace(/[\[\]]/g, '\\$&');
                const regex = new RegExp(`[?&]${parameter}(=([^&#]*)|&|#|$)`);
                const results = regex.exec(url);

                if (!results) return null;
                if (!results[2]) return '';

                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            };
            // @ts-ignore
            const configId = getParameterValue(currentPlatform.login_link, 'state').match(/\{config_id=(\d+)/)[1];
            userMultiStepHelper.setUserMultiStepLinkGoogleConfigId(Number(configId))
            const getRedirectUriValue = (url: string, parameter: string) => {
                // eslint-disable-next-line
                parameter = parameter.replace(/[\[\]]/g, '\\$&');
                const regex = new RegExp(`[?&]${parameter}(=([^&#]*)|&|#|$)`);
                const results = regex.exec(url);

                if (!results) return null;
                if (!results[2]) return '';

                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            };

            // Extracting the redirect_uri value from the URL
            const redirectUri = getRedirectUriValue(currentPlatform.login_link, 'redirect_uri');
            userMultiStepHelper.setUserMultiStepLinkGoogleRedirectUri(redirectUri)
        }
        if (currentPlatform.service_type === 'TIKTOK') {
            // Function to extract the value of a parameter from the URL
            // eslint-disable-next-line
            const getParameterValue = (url: string, parameter: any) => {
                // eslint-disable-next-line
                parameter = parameter.replace(/[\[\]]/g, '\\$&');
                const regex = new RegExp(`[?&]${parameter}(=([^&#]*)|&|#|$)`);
                const results = regex.exec(url);

                if (!results) return null;
                if (!results[2]) return '';

                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            };

            // Extracting the config_id value from the URL
            const stateValue = getParameterValue(currentPlatform.login_link, 'state');
            // @ts-ignore
            const configId = stateValue ? stateValue.match(/config_id=(\d+)/)[1] : null;
            userMultiStepHelper.setUserMultiStepLinkTikTokConfigId(Number(configId))


            const getRedirectUriValue = (url: string, parameter: string) => {
                // eslint-disable-next-line
                parameter = parameter.replace(/[\[\]]/g, '\\$&');
                const regex = new RegExp(`[?&]${parameter}(=([^&#]*)|&|#|$)`);
                const results = regex.exec(url);

                if (!results) return null;
                if (!results[2]) return '';

                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            };

            // Extracting the redirect_uri value from the URL
            const redirectUri = getRedirectUriValue(currentPlatform.login_link, 'redirect_uri');
            userMultiStepHelper.setUserMultiStepLinkTikTokRedirectUri(redirectUri)
        }
        window.location.replace(currentPlatform.login_link)

    }
    useEffect(() => {
        dispatch(GetAgencyDataThunk())
    }, [dispatch])
    return (
        <>
            <div>
                <div className={s.inner}>
                    <authPageDecoration.ReactComponent className={s.inner__decoration}/>
                    <div className={s.inner__multiStepBeginning}>
                        <div className={s.service}>
                            <p className={s.service__name}>{currentPlatform?.service_type?.charAt(0) + currentPlatform?.service_type?.slice(1).toLowerCase()}</p>
                            <p className={s.service__grant}>Grant access</p>
                        </div>
                        {
                            agencyData.logo_url && agencyData.logo_url !== null
                                ?
                                <img src={agencyData.logo_url} alt="logo"/>
                                :
                                <oneclickLogo.ReactComponent/>
                        }
                        <p className={s.multiPermission}>{agencyData.name && agencyData.name !== null ? agencyData.name : 'Agency'} requests
                            permission to open
                            your {currentPlatform?.service_type?.charAt(0) + currentPlatform?.service_type?.slice(1).toLowerCase()} Business
                            Center, please sign in</p>
                        <ClientMultiStepIndicator services={clientLoginLinks}/>
                        <AnimatedSignInBtn onLogin={onLogin}/>
                    </div>
                </div>
            </div>
            <p className={'powered'}>powered by OneClickOnboard</p>
        </>
    );
};

export default MultiStepUserSteps;