import { Button, Form, Input } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import s from './Auth.module.scss'
import { useAppDispatch } from '../../hooks'
import { SendRestorePasswordEmailThunk } from '../../store/userReducer'

const authPageDecoration = require('../../img/authPageDecoration.svg')

const ForgotPasswordFrom = () => {
    const dispatch = useAppDispatch()
    const [isRequestSent, setIsRequestSent] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const onFinish = (formData: {email: string}) => {
        setIsLoading(true)
        dispatch(SendRestorePasswordEmailThunk(formData))
        .then(resp => {
            setIsLoading(false)
            if (!resp.type.includes('rejected')) {
                setIsRequestSent(true)
            }
        })
    }

    if (isRequestSent) {
        return (
        <div className={s.inner}>
            <div className={s.formBlock}>
                <h1>
                    Check your email
                </h1>
                <div style={{fontSize: '18px', textAlign: 'center', marginBottom: '20px'}}>
                    We sent a password reset link to your email!
                </div>
            </div>
        </div>
        )
    }

    return (
        <div className={s.inner}>
            <authPageDecoration.ReactComponent className={s.inner__decoration}/>
            <div className={s.formBlock}>
                <Form
                    name='forgotPassword'
                    onFinish={onFinish}
                    autoComplete='off'
                    validateTrigger='onBlur'
                    className={s.formBlock__form}
                >
                    <h1>
                        Forgot your password?
                    </h1>
                    <div className={s.formBlock__description}>
                        Please enter your email address and we’ll send you a link to reset your password
                    </div>

                    <div className={s.formBlock__label}>
                        Email Address
                    </div>
                    <Form.Item
                        name='email'
                        rules={[
                            { required: true, message: 'Please enter your email!' },
                            { type: 'email', message: 'Invalid e-mail!' },
                        ]}
                    >
                        <Input placeholder='Enter your email' className={s.formBlock__input}/>
                    </Form.Item>

                    <Form.Item>
                    <Button type='primary' htmlType='submit' className={s.formBlock__submit} loading={isLoading}>
                        Reset password
                    </Button>
                    </Form.Item>

                    <Link to='/sign-in' className={s.formBlock__goBack}>
                        Back to Sign in
                    </Link>
                </Form>
            </div>
        </div>
    )
}

export default ForgotPasswordFrom
