import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router";
import s from "../Auth/Auth.module.scss";
import {Button, Checkbox, Spin} from "antd";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
    GetTikTokBusinessCentersAfterEmailThunk,
    selectTikTokBusinessCentersAfterEmail
} from "../../store/tikTokReducer";

const oneclickLogo = require('../../assets/img/oneclick-logo.svg')
const authPageDecoration = require('../../img/authPageDecoration.svg')

const RedirectAfterTikTokEmail = () => {
    const dispatch = useAppDispatch()
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const redirectValue = params.get('tikTokEmailRedirect');
    const {id} = useParams<{ id: string }>()
    console.log(id)
    console.log(redirectValue)
    const tikTokClientConfig = useAppSelector(selectTikTokBusinessCentersAfterEmail)
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(GetTikTokBusinessCentersAfterEmailThunk(id!))
    }, [dispatch, id])
    return (
        tikTokClientConfig
            ?
            <div className={s.inner}>
                <authPageDecoration.ReactComponent className={s.inner__decoration}/>
                <div className={s.formBlock}>
                    <oneclickLogo.ReactComponent />
                    <h1>
                        The client granted permissions for the following items:
                    </h1>
                    
                    <div style={{
                        marginBottom: '10px'
                    }}>
                        {tikTokClientConfig?.map((b: any) => {
                            return (
                                <>
                                    <p className={s.clientForm__title}>Tik Tok business centers:</p>
                                    <div className={s.clientForm__item} style={{
                                        // display: 'flex',
                                        // alignItems: 'center'
                                    }}
                                         key={b.bc_id}
                                    >
                                        <Checkbox
                                            name={b.bc_id}
                                            checked={true}
                                        >
                                            {`${b.name} - (${b.bc_id})`}
                                        </Checkbox>
                                        {/*<Tooltip title={p.description}>*/}
                                        {/*    <QuestionCircleOutlined/>*/}
                                        {/*</Tooltip>*/}
                                        <div>
                                            {b?.resources?.catalogs?.length ?  <p className={s.clientForm__subtitle}>Tik Tok business catalogs:</p> : null}
                                            {b?.resources?.catalogs?.map((c: any) => {
                                                return (
                                                    <>
                                                        <div className={s.clientForm__item} style={{
                                                            display: 'flex',
                                                            alignItems: 'flex-start'
                                                        }}
                                                             key={c.asset_id}
                                                        >
                                                            <Checkbox
                                                                name={c.asset_id}
                                                                checked={true}
                                                            >
                                                                {`${c.asset_name} - (${c.asset_id})`}
                                                            </Checkbox>
                                                            {/*<Tooltip title={p.description}>*/}
                                                            {/*    <QuestionCircleOutlined/>*/}
                                                            {/*</Tooltip>*/}
                                                        </div>
                                                    </>

                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    <Button
                        type='primary'
                        onClick={() => navigate('/')}
                        className={s.formBlock__submit}
                    >
                        Return to main page
                    </Button>
                </div>
            </div>
            :
            <Spin/>

    );
};

export default RedirectAfterTikTokEmail;
