import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AsyncThunkConfig, RootState} from "./store";
import {AdminAcceptInvitationDataType, privatePanelApi, PrivatePanelTableData} from "../api/api";
import {AppStatusType} from "./appStatusReducer";
import {
    tokenDataHelper,
    userDataHelper,
} from "../helpers/localStorageHelper";
import {pickBy} from "lodash";
import {UserDataType} from "../types/userTypes";


interface InitialStateType {
    privatePanelClientData: any
    privatePanelIsLoggedIn: boolean
}

const initialState: InitialStateType = {
    privatePanelClientData: {},
    privatePanelIsLoggedIn: false
}

export const privatePanelSlice = createSlice({
    name: 'privatePanel',
    initialState,
    reducers: {
        setPrivatePanelIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.privatePanelIsLoggedIn = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(PrivatePanelLoginThunk.fulfilled, (state, action) => {
                state.privatePanelIsLoggedIn = true
                state.privatePanelClientData = pickBy(action.payload, (_, key) => key !== 'token') as UserDataType
            })
            .addCase(PrivatePanelGetTableDataThunk.fulfilled, (state, action) => {
                state.privatePanelClientData = action.payload
            })

    }
})

export const {setPrivatePanelIsLoggedIn} = privatePanelSlice.actions

export const selectPrivatePanelClientData = (state: RootState): any => state.privatePanel.privatePanelClientData
export const selectPrivatePanelClientIsLoggedIn = (state: RootState): any => state.privatePanel.privatePanelIsLoggedIn

export const PrivatePanelLoginThunk = createAsyncThunk<UserDataType, {email: string, password: string}, AsyncThunkConfig>(
    'privatePanel/login',
    async (loginData, thunkAPI) => {
        try {
            const {status, data} = await privatePanelApi.privatePanelApiLogin(loginData)
            if (status === 200 && data) {
                userDataHelper.setAdminData(pickBy(data, (_, key) => key !== 'token') as UserDataType)
                tokenDataHelper.setAdminTokenData(data.token!)
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const PrivatePanelGetTableDataThunk = createAsyncThunk<PrivatePanelTableData, void, AsyncThunkConfig>(
    'privatePanel/getTableData',
    async (loginData, thunkAPI) => {
        try {
            const {status, data} = await privatePanelApi.privatePanelTableData()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.admins_invites, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const PrivatePanelSendInviteThunk = createAsyncThunk<void, {email: string}, AsyncThunkConfig>(
    'privatePanel/sendInvite',
    async (inviteData, thunkAPI) => {
        try {
            const {status, data} = await privatePanelApi.sendPrivatePanelInvite(inviteData)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Invitation has been successfully sent'})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        finally {
            thunkAPI.dispatch(PrivatePanelGetTableDataThunk())
        }
    }
)


export const PrivatePanelCheckInvitationTokenThunk = createAsyncThunk<void, string, AsyncThunkConfig>(
    'privatePanel/checkInvitationToken',
    async (token, thunkAPI) => {
        try {
            const {status, data} = await privatePanelApi.checkInvitationToken(token)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            }
            else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.fulfillWithValue({}, {appStatus: AppStatusType.idle})
        }
    }
)

export const PrivatePanelAcceptMemberInvitationThunk = createAsyncThunk<void, AdminAcceptInvitationDataType, AsyncThunkConfig>(
    'privatePanel/inviteMember',
    async (acceptInvitationData, thunkAPI) => {
        try {
            const {status, data} = await privatePanelApi.acceptMemberInvitation(acceptInvitationData)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Successfully created agency, please login to the system using your credentials to manage your agencies'})
            }
            else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.fulfillWithValue({}, {appStatus: AppStatusType.idle})
        }
    }
)


export const PrivatePanelSignOutThunk = createAsyncThunk<void, void, AsyncThunkConfig>(
    'privatePanel/signOut', (_, thunkAPI) => {
        userDataHelper.removeIsAdminUserData()
        tokenDataHelper.removeAdminTokenData()
        userDataHelper.removeAdminData()
        userDataHelper.removeIsAdminUserData()
        window.location.replace('/privatePanelSignIn')
        return thunkAPI.fulfillWithValue(_, {appStatus: AppStatusType.idle})
    }
)

export default privatePanelSlice.reducer