import {Select} from 'antd';
import React, {useEffect, useState} from 'react';
import s from './ClientSelect.module.scss'
import {
    GetClientsThunk, GetClientThunk, selectClient,
    selectClients,
    setCurrentClient,
    setCurrentClientName
} from "../../../store/clientReducer";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {TableClientType} from "../../../types/clientsTypes";
import {LoadingOutlined} from "@ant-design/icons";
import {clientLoginDataHelper} from "../../../helpers/localStorageHelper";

const selectIcon = require('../../../img/icons/handshake.svg')
const searchIcon = require('../../../img/icons/searchTable.svg')

const ClientSelect = () => {
    const dispatch = useAppDispatch()
    const [isClientsLoading, setIsClientsLoading] = useState(false)
    const clients = useAppSelector(selectClients)
    const clientName = useAppSelector(selectClient)
    const clientId = clientLoginDataHelper.getCurrentClientDataId()
    const clientsForSelect = clients.map((c: TableClientType) => ({
        value: `${c.name}`,
        label: `${c.name}`,
        key: c.id,
    }))


    useEffect(() => {
        if(clientId && clientId !== false){
            dispatch(GetClientThunk(clientId))
                .then((res) => {
                    // @ts-ignore
                    dispatch(setCurrentClientName(res.payload.name))
                })
        }

    }, [dispatch, clientId])
    let typingTimeout: any;
    const handleBlur = () => {
        // Do nothing to prevent value loss
    };
    const onSearch = (value: string) => {
        if (value !== '') {
            setIsClientsLoading(true)
            clearTimeout(typingTimeout);
            typingTimeout = setTimeout(() => {
                const formData = new FormData()
                formData.append('filter', new Blob([JSON.stringify({search_value: value})], {type: 'application/json'}))
                dispatch(GetClientsThunk(formData))
                    .then(() => {
                        setIsClientsLoading(false)
                    })
            }, 2000);

        }
    };

    const onChangeSelectValue = (value: any) => {
        dispatch(setCurrentClientName(value))
        console.log(value)
        const client = clients.find((c:any) => c.name === value)
        if (client){
            console.log(client)
            clientLoginDataHelper.setCurrentClientDataId(client.id)
            dispatch(setCurrentClient(client))
            dispatch(GetClientThunk(client.id))
        }
    }


    return (
        <div className={`${s.inner} clientSelect`}>
            <div className={s.inner__item}>
                <selectIcon.ReactComponent/>
                <p>Current Client</p>
            </div>
            <div>
                <Select
                    filterOption={(input, option) =>
                        (option?.label as string)?.toLowerCase().includes(input.toLowerCase())
                    }
                    showSearch
                    placeholder="Search"
                    optionFilterProp="children"
                    onChange={(value, option) => onChangeSelectValue(value)}
                    onSearch={onSearch}
                    options={clientsForSelect}
                    notFoundContent={null}
                    value={clientName?.name}
                    style={{
                        height: '44px',
                        width: '100%'
                    }}
                    onInputKeyDown={(e) => e.stopPropagation()} // Prevent input from being cleared on Enter
                    onBlur={handleBlur}
                    loading={isClientsLoading}
                    // disabled={maxWidthInputs || disabledInputs || currentRowValues.ACT_TYPE === 'Documentation'}
                    suffixIcon={!isClientsLoading ? <searchIcon.ReactComponent/> : <LoadingOutlined/>}
                />
            </div>
        </div>
    );
};

export default ClientSelect;