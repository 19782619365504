import { configureStore } from '@reduxjs/toolkit'
import appStatus, { AppStatusType } from './appStatusReducer'
import user from './userReducer'
import utility from  './utilityReducer'
import businessAsset from './businessAssetReducer'
import permissionConfiguration from './permissionConfigurationReducer'
import google from './googleReducer'
import tiktok from './tikTokReducer'
import links from './linksReducer'
import members from './membersReducer'
import agency from './agencyReducer'
import multiStep from './multiStepReducer'
import privatePanel from './privatePanelReducer'
import activityTracker from './activityTrackerReducer'
import tutorial from './tutorialReducer'
import client from './clientReducer'

export const store = configureStore({
  reducer: {
    appStatus,
    user,
    utility,
    businessAsset,
    permissionConfiguration,
    google,
    tiktok,
    links,
    members,
    agency,
    multiStep,
    privatePanel,
    activityTracker,
    tutorial,
    client
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
  devTools: true,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AsyncThunkConfig = {
  fulfilledMeta: {
    appStatus: AppStatusType.succeeded | AppStatusType.idle | AppStatusType.loading
    appMessage?:  string
  },
  state: RootState
}
export type fulfilledMetaType = {
  arg: unknown;
  requestId: string;
  requestStatus: 'fulfilled',
  appStatus: AppStatusType.succeeded | AppStatusType.idle
  appMessage?:  string
}
