import React from 'react';
import s from './TutorialModal.module.scss'
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
    selectCurrentTutorialStep,
    selectTutorialRole,
    setCurrentTutorialStep,
    setIsTutorialStarted, setTutorialRole
} from "../../store/tutorialReducer";
import TutorialStepButtons from "../TutorialStepButtons/TutorialStepButtons";
import {selectCurrentScreenHeight, selectCurrentScreenWidth, setGoogleTableModalOpen} from "../../store/utilityReducer";
import {useNavigate} from "react-router";
import {tutorialDataHelper, userDataHelper} from "../../helpers/localStorageHelper";
import {selectGoogleAccount} from "../../store/googleReducer";
import {message} from "antd";

const congratulationsGif = require('./../../img/icons/de25c2697485cf1614eebcbb275a589b.gif')
const macbookImg = require('./../../img/icons/macbook.png')


const TutorialModal = () => {
    const dispatch = useAppDispatch()
    const currentTutorialStep = useAppSelector(selectCurrentTutorialStep)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
    const currentScreenHeight = useAppSelector(selectCurrentScreenHeight)
    const navigate = useNavigate()
    const googleAccount = useAppSelector(selectGoogleAccount)
    const tutorialRole = useAppSelector(selectTutorialRole)
    const onSkipTutorial = () => {
        dispatch(setIsTutorialStarted(false))
        tutorialDataHelper.setIsTutorial(false)
        tutorialDataHelper.setCurrentTutorialStep('Finished')
        dispatch(setCurrentTutorialStep('Finished'))
    }

    const onStartTutorial = () => {
        dispatch(setTutorialRole(userDataHelper?.getUserData()?.roles[0]?.name))
        if(userDataHelper?.getUserData()?.roles[0]?.name !== 'Manager'){
            dispatch(setCurrentTutorialStep('First step - Agency Setup'))
            tutorialDataHelper.setCurrentTutorialStep('First step - Agency Setup')
        }
       else{
            dispatch(setCurrentTutorialStep('Fourth step - Link creation / Home'))
            tutorialDataHelper.setCurrentTutorialStep('Fourth step - Link creation / Home')
        }
    }
    const isIntegrated = Object.keys(googleAccount).length !== 0
    const onSetNextStep = () => {
        if (currentTutorialStep === 'First step - Agency Setup') {
            dispatch(setCurrentTutorialStep('Second step - Logo'))
            tutorialDataHelper.setCurrentTutorialStep('Second step - Logo')
            navigate('/settings')
        } else if (currentTutorialStep === 'Second step - Logo') {
            dispatch(setCurrentTutorialStep('Third step - Integrations / Google'))
            tutorialDataHelper.setCurrentTutorialStep('Third step - Integrations / Google')
        } else if (currentTutorialStep === 'Third step - Integrations / Google') {
            if (isIntegrated && googleAccount.google_ads_manager_email !== null && googleAccount.google_ads_manager_id !== null) {
                dispatch(setCurrentTutorialStep('Third step - Integrations / Team members'))
                tutorialDataHelper.setCurrentTutorialStep('Third step - Integrations / Team members')
            } else if (isIntegrated && googleAccount.google_ads_manager_email === null && googleAccount.google_ads_manager_id === null) {
                message.warn("You can't skip this stage, you need to full your configs for Google account to continue tutorial")
            } else if (!isIntegrated) {
                message.warn("You can't skip this stage, you need to integrate your Google account to continue tutorial")
            }
        } else if (currentTutorialStep === 'Third step - Integrations / Team members') {
            navigate('/')
            dispatch(setCurrentTutorialStep('Fourth step - Link creation / Home'))
            tutorialDataHelper.setCurrentTutorialStep('Fourth step - Link creation / Home')
        } else if (currentTutorialStep === 'Fourth step - Link creation / Home') {
            navigate('/googleLinksTable')
            dispatch(setCurrentTutorialStep('Fourth step - Link creation / Table'))
            tutorialDataHelper.setCurrentTutorialStep('Fourth step - Link creation / Table')
        } else if (currentTutorialStep === 'Fourth step - Link creation / Table') {
            dispatch(setGoogleTableModalOpen(true))
            dispatch(setCurrentTutorialStep('Fourth step - Link creation / Modal'))
            tutorialDataHelper.setCurrentTutorialStep('Fourth step - Link creation / Modal')
        } else if (currentTutorialStep === 'Fourth step - Link creation / Modal') {
            dispatch(setCurrentTutorialStep('Final step - Congratulations'))
            tutorialDataHelper.setCurrentTutorialStep('Final step - Congratulations')
            dispatch(setGoogleTableModalOpen(false))
            navigate('/')
        } else if (currentTutorialStep === 'Fourth step - Link creation / Send link') {
            dispatch(setCurrentTutorialStep('Final step - Congratulations'))
            tutorialDataHelper.setCurrentTutorialStep('Final step - Congratulations')
            navigate('/')
        } else if (currentTutorialStep === 'Final step - Congratulations') {
            dispatch(setCurrentTutorialStep('Finished'))
            tutorialDataHelper.setCurrentTutorialStep('Finished')
            dispatch(setIsTutorialStarted(false))
            tutorialDataHelper.setIsTutorial(false)
        }
    }

    return (
        <>
            <div
                className={'tutorialAnimatedArrow'}
                style={{
                    top: currentTutorialStep === 'Pre tutorial' ? '50%'
                        : currentTutorialStep === 'First step - Agency Setup' && currentScreenHeight > 900 ? '20%'
                            : currentTutorialStep === 'First step - Agency Setup' && currentScreenHeight > 870 ? '23%'
                                : currentTutorialStep === 'First step - Agency Setup' && currentScreenHeight > 700 ? '25%'
                                    : currentTutorialStep === 'First step - Agency Setup' && currentScreenHeight > 600 ? '28%'
                                        : currentTutorialStep === 'Second step - Logo' ? '14%'
                                            // : currentTutorialStep === 'Third step - Integrations / Google' && isIntegrated && googleAccount.google_ads_manager_email === null && googleAccount.google_ads_manager_id === null ? '92%'
                                            : currentTutorialStep === 'Third step - Integrations / Team members' ? '30%'
                                                : currentTutorialStep === 'Fourth step - Link creation / Home' ? '14%'
                                                    : currentTutorialStep === 'Fourth step - Link creation / Table' && currentScreenWidth > 1160 ? '16%'
                                                        : currentTutorialStep === 'Fourth step - Link creation / Table' && currentScreenWidth < 1160 ? '23%'
                                                            : currentTutorialStep === 'Fourth step - Link creation / Modal' ? '3%'
                                                                : currentTutorialStep === 'Fourth step - Link creation / Send link' && currentScreenWidth > 1440 ? '41%'
                                                                    : currentTutorialStep === 'Fourth step - Link creation / Send link' && currentScreenWidth < 1440 ? '52%'
                                                                        : '50%',
                    left: currentTutorialStep === 'Pre tutorial' ? '50%'
                        : currentTutorialStep === 'First step - Agency Setup' ? '14%'
                            : currentTutorialStep === 'Second step - Logo' && currentScreenWidth > 1160 ? '49%'
                                : currentTutorialStep === 'Second step - Logo' && currentScreenWidth < 1160 ? '32%'
                                    // : currentTutorialStep === 'Third step - Integrations / Google' && isIntegrated && googleAccount.google_ads_manager_email === null && googleAccount.google_ads_manager_id === null ? '46%'
                                    : currentTutorialStep === 'Third step - Integrations / Team members' ? '30%'
                                        : currentTutorialStep === 'Fourth step - Link creation / Home' ? '13%'
                                            : currentTutorialStep === 'Fourth step - Link creation / Table' ? '94%'
                                                : currentTutorialStep === 'Fourth step - Link creation / Modal' ? '64%'
                                                    : currentTutorialStep === 'Fourth step - Link creation / Send link' ? '87%'
                                                        : '50%',
                    transform: currentTutorialStep === 'First step - Agency Setup'
                        ? 'rotate(45deg)' : currentTutorialStep === 'Second step - Logo'
                            ? 'rotate(-60deg)' : currentTutorialStep === 'Fourth step - Link creation / Table'
                                ? 'rotate(130deg)' : currentTutorialStep === 'Fourth step - Link creation / Send link' ? 'rotate(195deg)'
                                    // : currentTutorialStep === 'Third step - Integrations / Google' && isIntegrated && googleAccount.google_ads_manager_email === null && googleAccount.google_ads_manager_id === null  ? 'rotate(220deg)'
                                    : "inherit",
                    display: currentTutorialStep === 'Pre tutorial'
                    // || currentTutorialStep === 'Third step - Integrations / Google' && isIntegrated && googleAccount.google_ads_manager_email !== null && googleAccount.google_ads_manager_id !== null
                    || currentTutorialStep === 'Third step - Integrations / Google'
                    || currentTutorialStep === 'Third step - Integrations / Team members'
                    || currentTutorialStep === 'Fourth step - Link creation / Home'
                    || currentTutorialStep === 'Fourth step - Link creation / Modal'
                    || currentTutorialStep === 'Final step - Congratulations'
                    || currentTutorialStep === 'Fourth step - Link creation / Send link'
                        ? 'none' : 'block'
                }}
            >
                {
                    currentTutorialStep === 'First step - Agency Setup'
                        ?
                        <div className={'tutorialAnimatedArrow'}>
                            <svg width="160" height="167" viewBox="0 0 160 167" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">


                                <path
                                    d="M20.5221 116.658C18.0511 117.352 15.4871 117.699 12.9821 118.201C11.7941 118.44 10.6051 118.679 9.44308 118.882C8.90108 119.009 5.94108 119.191 5.73608 119.724C5.61508 119.978 7.76208 122.165 8.02508 122.517C8.78708 123.449 9.54808 124.38 10.3111 125.31C11.8261 127.109 13.9561 128.999 14.7511 131.253C15.0551 132.032 14.2161 132.743 13.5071 132.302C12.1641 131.471 11.2571 130.098 10.2701 128.842C9.15908 127.439 7.98208 126.05 6.87008 124.647C5.80608 123.336 2.77808 120.87 3.49208 118.885C4.19008 117.002 7.99008 117.24 9.57608 116.997C13.1251 116.381 16.7311 115.522 20.3091 115.265C21.2511 115.135 21.4411 116.399 20.5221 116.658Z"
                                    fill="#075CEB"/>


                                <path
                                    d="M157.747 42.5176C153.23 60.9566 138.666 77.6886 122.915 87.4406C107.841 96.8246 84.8261 103.774 69.8931 90.9546C64.4411 86.2586 61.7661 78.3256 68.0601 72.9666C73.8481 67.9696 81.9771 69.4636 86.4271 75.4026C98.3391 91.2596 79.4731 111.971 66.2851 119.84C57.9671 124.852 48.5181 127.791 38.8581 128.492C31.1711 129.012 22.2771 128.398 15.1181 125.04"
                                    stroke="#075CEB" strokeWidth="2" strokeMiterlimit="10"/>

                            </svg>
                        </div>
                        :
                        <div className={'tutorialAnimatedArrow'}>
                            <svg width="159" height="166" viewBox="0 0 159 166" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">

                                <path
                                    d="M20.5221 49.3418C18.0511 48.6478 15.4871 48.3008 12.9821 47.7988C11.7941 47.5598 10.6051 47.3208 9.44308 47.1178C8.90108 46.9908 5.94108 46.8088 5.73608 46.2758C5.61508 46.0218 7.76208 43.8348 8.02508 43.4828C8.78708 42.5508 9.54808 41.6198 10.3111 40.6898C11.8261 38.8908 13.9561 37.0008 14.7511 34.7468C15.0551 33.9678 14.2161 33.2568 13.5071 33.6978C12.1641 34.5288 11.2571 35.9018 10.2701 37.1578C9.15908 38.5608 7.98208 39.9498 6.87008 41.3528C5.80608 42.6638 2.77808 45.1298 3.49208 47.1148C4.19008 48.9978 7.99008 48.7598 9.57608 49.0028C13.1251 49.6188 16.7311 50.4778 20.3091 50.7348C21.2511 50.8648 21.4411 49.6008 20.5221 49.3418Z"
                                    fill="#075CEB"/>
                                <path
                                    d="M157.747 123.482C153.23 105.043 138.666 88.3114 122.915 78.5594C107.841 69.1754 84.8261 62.2264 69.8931 75.0454C64.4411 79.7414 61.7661 87.6744 68.0601 93.0334C73.8481 98.0304 81.9771 96.5364 86.4271 90.5974C98.3391 74.7404 79.4731 54.0294 66.2851 46.1604C57.9671 41.1484 48.5181 38.2094 38.8581 37.5084C31.1711 36.9884 22.2771 37.6024 15.1181 40.9604"
                                    stroke="#075CEB" strokeWidth="2" strokeMiterlimit="10"/>


                            </svg>
                        </div>
                }

            </div>

            <div
                className={s.inner}

            >
                <div
                    className={s.inner__modal}
                    style={{
                        top: currentTutorialStep === 'Pre tutorial' ? '50%'
                            : currentTutorialStep === 'First step - Agency Setup' ? '8%'
                                : currentTutorialStep === 'Second step - Logo' && currentScreenWidth > 1160 ? '8%'
                                    : currentTutorialStep === 'Second step - Logo' && currentScreenWidth < 1160 ? '8%'
                                        : currentTutorialStep === 'Third step - Integrations / Google' && currentScreenWidth > 1360 ? '39%'
                                            : currentTutorialStep === 'Third step - Integrations / Google' && currentScreenWidth < 1360 ? '32%'
                                                : currentTutorialStep === 'Third step - Integrations / Team members' ? '30%'
                                                    : currentTutorialStep === 'Fourth step - Link creation / Home' && currentScreenWidth > 1160 ? '14%'
                                                        : currentTutorialStep === 'Fourth step - Link creation / Home' && currentScreenWidth < 1160 ? '53%'
                                                            : currentTutorialStep === 'Fourth step - Link creation / Table' && currentScreenWidth > 1160 ? '16%'
                                                                : currentTutorialStep === 'Fourth step - Link creation / Table' && currentScreenWidth < 1160 ? '24%'
                                                                    : currentTutorialStep === 'Fourth step - Link creation / Modal' && currentScreenWidth > 1160 ? '3%'
                                                                        : currentTutorialStep === 'Fourth step - Link creation / Modal' && currentScreenWidth < 1160 ? '8%'
                                                                            : currentTutorialStep === 'Fourth step - Link creation / Send link' ? '1%'
                                                                                : '50%',
                        left: currentTutorialStep === 'Pre tutorial' ? '50%'
                            : currentTutorialStep === 'First step - Agency Setup' ? '20%'
                                : currentTutorialStep === 'Second step - Logo' && currentScreenWidth > 1160 ? '60%'
                                    : currentTutorialStep === 'Second step - Logo' && currentScreenWidth < 1160 ? '51%'
                                        : currentTutorialStep === 'Third step - Integrations / Google' ? '42%'
                                            : currentTutorialStep === 'Third step - Integrations / Team members' && currentScreenWidth > 1260 ? '30%'
                                                : currentTutorialStep === 'Third step - Integrations / Team members' && currentScreenWidth > 1160 ? '25%'
                                                    : currentTutorialStep === 'Third step - Integrations / Team members' && currentScreenWidth < 1160 ? '10%'
                                                        : currentTutorialStep === 'Fourth step - Link creation / Home' ? '13%'
                                                            : currentTutorialStep === 'Fourth step - Link creation / Table' && currentScreenWidth > 1160 ? '50%'
                                                                : currentTutorialStep === 'Fourth step - Link creation / Table' && currentScreenWidth < 1160 ? '35%'
                                                                    : currentTutorialStep === 'Fourth step - Link creation / Modal' && currentScreenWidth > 1160 ? '64%'
                                                                        : currentTutorialStep === 'Fourth step - Link creation / Modal' && currentScreenWidth < 1160 ? '55%'
                                                                            : currentTutorialStep === 'Fourth step - Link creation / Send link' && currentScreenWidth > 1440 ? '58%'
                                                                                : currentTutorialStep === 'Fourth step - Link creation / Send link' && currentScreenWidth < 1160 ? '32%'
                                                                                    : '50%',
                        transform: currentTutorialStep === 'Pre tutorial' || currentTutorialStep === 'Final step - Congratulations' ? 'translate(-50%, -50%)' : "inherit",
                        position: "relative",
                        transition: 'all 0.7s ease-in-out',
                        zIndex: '1001',
                        padding: currentTutorialStep === 'Pre tutorial' ? '24px 24px' : '18px 24px',
                        maxWidth: currentTutorialStep === 'Fourth step - Link creation / Send link' ? '540px' : '400px'
                        // transition: 'max-height  1s ease-in-out',
                    }}
                >
                    {
                        currentTutorialStep === 'Pre tutorial'
                            ?
                            <>
                                <img src={macbookImg} alt="macbookImg"/>
                                <h1>Welcome to One Click Onboard</h1>
                                <p>We are delighted to welcome you to our application, where you can efficiently create
                                    links for your
                                    clients, invite members to the agency to achieve common goals.</p>
                                <div className={s.inner__modalBtns}>
                                    <button
                                        className={'secondaryButton'}
                                        style={{
                                            width: '170px',
                                            height: '44px'
                                        }}
                                        onClick={onSkipTutorial}
                                    >
                                        Skip
                                    </button>
                                    <button
                                        className={'primaryButton'}
                                        style={{
                                            width: '170px',
                                            height: '44px'
                                        }}
                                        onClick={onStartTutorial}
                                    >
                                        Next
                                    </button>
                                </div>
                            </>
                            :
                            <>
                                {/*<div className="animation-container">*/}
                                {/*    <div className="line"></div>*/}
                                {/*</div>*/}
                                {
                                    currentTutorialStep === 'Final step - Congratulations'
                                    &&
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <img src={congratulationsGif} alt="congratulations_gif"/>
                                    </div>
                                }
                                {
                                    currentTutorialStep === 'First step - Agency Setup'
                                        ?
                                        <h1 style={{
                                            marginTop: 0
                                        }}>First step - Agency setup.</h1>
                                        :
                                        currentTutorialStep === 'Second step - Logo'
                                            ?
                                            <h1 style={{
                                                marginTop: 0
                                            }}>Second step - Logo.</h1>
                                            :
                                            currentTutorialStep === 'Third step - Integrations / Google'
                                                ?
                                                <h1 style={{
                                                    marginTop: 0
                                                }}>Third step - Integrations.</h1>
                                                :
                                                currentTutorialStep === 'Third step - Integrations / Team members'
                                                    ?
                                                    <h1 style={{
                                                        marginTop: 0
                                                    }}>Team members</h1>
                                                    :
                                                    currentTutorialStep === 'Fourth step - Link creation / Home'
                                                        ?
                                                        <h1 style={{
                                                            marginTop: 0
                                                        }}>{tutorialRole !== 'Manager' ? 'Final step - Link creation.' : 'Next step - Link creation.'}</h1>
                                                        :
                                                        currentTutorialStep === 'Fourth step - Link creation / Table'
                                                            ?
                                                            <h1 style={{
                                                                marginTop: 0
                                                            }}>{tutorialRole !== 'Manager' ? 'Final step - Link creation.' : 'Next step - Link creation.'}</h1>
                                                            :
                                                            currentTutorialStep === 'Fourth step - Link creation / Modal'
                                                                ?
                                                                <h1 style={{
                                                                    marginTop: 0
                                                                }}>{tutorialRole !== 'Manager' ? 'Final step - Link creation.' : 'Next step - Link creation.'}</h1>
                                                                :
                                                                currentTutorialStep === 'Fourth step - Link creation / Send link'
                                                                    ?
                                                                    <h1 style={{
                                                                        marginTop: 0
                                                                    }}>{tutorialRole !== 'Manager' ? 'Final step - Link creation.' : 'Next step - Link creation.'}</h1>
                                                                    :
                                                                    currentTutorialStep === 'Final step - Congratulations'
                                                                        ?
                                                                        <h1 style={{
                                                                            marginTop: 0
                                                                        }}>Congratulations!</h1>
                                                                        :
                                                                        <></>
                                }
                                {
                                    currentTutorialStep === 'First step - Agency Setup'
                                        ?
                                        <p style={{
                                            marginBottom: '20px'
                                        }}>To begin, you need to upload your agency's logo so that your customers can
                                            recognize
                                            you. To do this, please go to the "Settings" menu item.</p>
                                        :
                                        currentTutorialStep === 'Second step - Logo'
                                            ?
                                            <p style={{
                                                marginBottom: '20px'
                                            }}>Upload your agency's logo.</p>
                                            :
                                            currentTutorialStep === 'Third step - Integrations / Google'
                                                ?
                                                <p style={{
                                                    marginBottom: '20px'
                                                }}>Perform integrations to create links for your customers. Only the
                                                    agency administrator performs the integration.</p>
                                                :
                                                currentTutorialStep === 'Third step - Integrations / Team members'
                                                    ?
                                                    <p style={{
                                                        marginBottom: '20px'
                                                    }}>You can invite team members to the agency to collaborate on
                                                        common goals.</p>
                                                    :
                                                    currentTutorialStep === 'Fourth step - Link creation / Home'
                                                        ?
                                                        <p style={{
                                                            marginBottom: '20px'
                                                        }}>Alright, let's create a link together, and then you'll become
                                                            a guru of our application. Let's create a link for Google.
                                                            You need to click on the Google card.</p>
                                                        :
                                                        currentTutorialStep === 'Fourth step - Link creation / Table'
                                                            ?
                                                            <p style={{
                                                                marginBottom: '20px'
                                                            }}>To create a link, press the button “Add link” .</p>

                                                            :
                                                            currentTutorialStep === 'Fourth step - Link creation / Modal'
                                                                ?
                                                                <p style={{
                                                                    marginBottom: '20px'
                                                                }}>Fill in all the required fields and press the
                                                                    confirmation button to create the link.</p>
                                                                :
                                                                currentTutorialStep === 'Fourth step - Link creation / Send link'
                                                                    ?
                                                                    <p style={{
                                                                        marginBottom: '20px',
                                                                        maxWidth: '470px'
                                                                    }}>To send it, click the "Send" button. You can send the link in two ways, to email or to another messenger. In the "Activity" menu you can track the status of the link and the details of the customer who went through the integration.</p>
                                                                    :
                                                                    currentTutorialStep === 'Final step - Congratulations'
                                                                        ?
                                                                        <p style={{
                                                                            marginBottom: '20px'
                                                                        }}> You have successfully completed the
                                                                            introductory stages of our system. We wish
                                                                            you success in your future endeavors. If you
                                                                            have any questions or need assistance during
                                                                            the setup process, don't hesitate to contact
                                                                            our support. We are ready to help you
                                                                            achieve outstanding results in the world of
                                                                            promotion.</p>
                                                                        :
                                                                        <></>
                                }

                                {
                                    currentTutorialStep !== 'Final step - Congratulations'
                                    &&
                                    <TutorialStepButtons/>
                                }

                                {
                                    currentTutorialStep !== 'Final step - Congratulations'
                                        ?
                                        <div className={currentTutorialStep !== 'Fourth step - Link creation / Send link' ? s.inner__modalBtns : s.inner__modalBtns_fourthStep }>
                                            <button
                                                className={'secondaryButton'}
                                                style={{
                                                    width: currentTutorialStep === 'Fourth step - Link creation / Send link' ? '185px' : '170px',
                                                    height: '44px'
                                                }}
                                                onClick={onSkipTutorial}
                                            >
                                                Skip
                                            </button>
                                            <button
                                                className={'primaryButton'}
                                                style={{
                                                    width: currentTutorialStep === 'Fourth step - Link creation / Send link' ? '185px' : '170px',
                                                    height: '44px'
                                                }}
                                                onClick={onSetNextStep}
                                            >
                                                Next
                                            </button>
                                        </div>
                                        :

                                        <button className={'primaryButton'} style={{
                                            width: '100%',
                                            height: '44px'
                                        }}
                                                onClick={onSetNextStep}
                                        >Continue work</button>

                                }

                            </>
                    }


                </div>
            </div>


        </>

    );
};

export default TutorialModal;