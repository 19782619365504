import React, {useEffect, useState} from 'react';
import Avatar from "react-avatar";
import {selectClient, UpdateClientLogoThunk} from "../../store/clientReducer";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {beforeUpload} from "../../helpers/fileHelper";
const editIcon = require('../../img/icons/edit.svg')

const CustomImageUpload = () => {
    const [image, setImage] = useState(null);
    const [showButtons, setShowButtons] = useState(false);
    const dispatch = useAppDispatch()
    const currentClientData = useAppSelector(selectClient)

    useEffect(() => {
        if(currentClientData.logo_url !== null){
            // @ts-ignore
            setImage(currentClientData.logo_url)
        }
        else {
            setImage(null)
        }

    }, [currentClientData.logo_url])

    const handleUpload = (event: any) => {

        const file = event.target.files[0];

        const isFileCorrect = beforeUpload(file)
        if(isFileCorrect === true){
            const reader = new FileReader();

            reader.onload = (e: any) => {
                setImage(e.target.result);
            };

            reader.readAsDataURL(file);
            if (!!file) {
                const formData = new FormData()
                formData.append('logo', file)
                formData.append('client', new Blob([JSON.stringify({
                    name: currentClientData.name,
                    email: currentClientData.email,
                    status: currentClientData.status
                })], {type: 'application/json'}))
                dispatch(UpdateClientLogoThunk({client: formData, clientId: currentClientData.id}))
            }
        }


    };




    let firstLetter = currentClientData.name.split(' ')[0].charAt(0)



    return (
        <div
            className="custom-image-upload-container"
            onMouseEnter={() => setShowButtons(true)}
            onMouseLeave={() => setShowButtons(false)}
        >
            {image ? (
                <img src={image} alt="Uploaded" className="uploaded-image" />
            ) : (
                <label htmlFor="file-upload" className="upload-label">
                    <input
                        type="file"
                        id="file-upload"
                        accept="image/*"
                        onChange={handleUpload}
                        className="file-input"
                    />
                    <Avatar name={firstLetter} size="80" round={'80px'} color={'#F9F5FF'} fgColor={'#0A5BEB'}/>
                </label>
            )}
            {/*{showButtons && image && (*/}
            {/*    <div className="action-buttons">*/}
            {/*        <button className="delete-button" onClick={handleDelete}>*/}
            {/*            <deleteIcon.ReactComponent*/}
            {/*                style={*/}
            {/*                    {*/}
            {/*                        marginRight: '5px',*/}
            {/*                        cursor: 'pointer',*/}
            {/*                        fontSize: '18px'*/}
            {/*                    }*/}
            {/*                }*/}
            {/*                color="#fff"*/}
            {/*            />*/}
            {/*        </button>*/}
            {/*        <label htmlFor="file-upload" className="upload-button">*/}
            {/*            <editIcon.ReactComponent*/}
            {/*                color="#fff"*/}
            {/*                style={*/}
            {/*                    {*/}
            {/*                        cursor: 'pointer',*/}
            {/*                        fontSize: '18px'*/}
            {/*                    }*/}
            {/*                }*/}

            {/*               />*/}
            {/*            <input*/}
            {/*                type="file"*/}
            {/*                id="file-upload"*/}
            {/*                accept="image/*"*/}
            {/*                onChange={handleUpload}*/}
            {/*                className="file-input"*/}
            {/*            />*/}
            {/*        </label>*/}
            {/*    </div>*/}
            {/*)}*/}
            {showButtons && (
                <div className="action-buttons">
                    <label htmlFor="file-upload" className="upload-button">
                        <editIcon.ReactComponent
                            color="#fff"
                            style={
                                {
                                    cursor: 'pointer',
                                    fontSize: '18px'
                                }
                            }

                        />
                        <input
                            type="file"
                            id="file-upload"
                            accept="image/*"
                            onChange={handleUpload}
                            className="file-input"
                        />
                    </label>
                </div>
            )}
        </div>
    );
};

export default CustomImageUpload;
