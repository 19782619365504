
import SignIn from "./components/Auth/SignIn";
import FacebookLinksTable from "./components/FacebookLinksTable/FacebookLinksTable";
import SignInClient from "./components/Auth/SignInClient";
import ClientSuccessfulLogin from "./components/ClientSuccesfulLogin/ClientSuccessfulLogin";
import GoogleLinksTable from "./components/GoogleLinksTable/GoogleLinksTable";
import GoogleSignInClient from "./components/Auth/GoogleSignInClient";
import ClientGoogleSuccessfullLogin from "./components/ClientSuccesfulLogin/ClientGoogleSuccessfullLogin";
import TikTokLinksTable from "./components/TikTokLinksTable/TikTokLinksTable";
import TikTokAccountAssets from "./components/TikTokAccountAssets/TikTokAccountAssets";
import RedirectAfterTikTokEmail from "./components/Redirect/RedirectAfterTikTokEmail";
import TikTokSignInClient from "./components/Auth/TikTokSignInClient";
import TikTokClientConfig from "./components/TikTokClientConfig/TikTokClientConfig";
import ClientTikTokSuccessfulLogin from "./components/ClientSuccesfulLogin/ClientTikTokSuccessfulLogin";
import AllLinksTable from "./components/AllLinksTable/AllLinksTable";
import Settings from "./components/Settings/Settings";
import ForgotPasswordFrom from "./components/Auth/ForgotPasswordForm";
import CreateNewPasswordForm from "./components/Auth/CreateNewPasswordForm";
import AcceptInvitationForm from "./components/Auth/AcceptInvitationForm";
import TableForCommonLinks from "./components/TableForCommonLinks/TableForCommonLinks";
import MultiStepSignIn from "./components/Auth/MutliStepSignIn";
import MultiStepUserSteps from "./components/MultiStepUserSteps/MultiStepUserSteps";
import MultiStepLoginSuccess from "./components/MultiStepLoginSuccess/MultiStepLoginSuccess";
import MobileLink from "./components/MobileLinkPage/MobileLink";
import PrivatePanelSignIn from "./components/PrivatePanel/PrivatePanelSignIn";
import PrivatePanelTable from "./components/PrivatePanel/PrivatePanelTable/PrivatePanelTable";
import AcceptInvitationFormAdmin from "./components/Auth/AcceprInvitationFormAdmin";
import Activity from "./components/Activity/Activity";
import ActivityCurrentLink from "./components/Activity/ActivityCurrentLink/ActivityCurrentLink";
import ActivityCurrentLinkMulti from "./components/Activity/ActivityCurrentLinkMulti/ActivityCurrentLinkMulti";
import ActivityMulti from "./components/Activity/ActivityMulti";
import TableOfCredits from "./components/TableOfCredits/TableOfCredits";
import NoCreditsLeft from "./components/NoCreditsLeft/NoCreditsLeft";
import Integrations from "./components/Integrations/Integrations";
import ClientsTable from "./components/Client/ClientsTable/ClientsTable";
import ClientDetails from "./components/Client/ClientDetails/ClientDetails";





const routes: RouteType[] = [
  {path: '/', component: ClientsTable, auth: true, breadcrumb: 'Most Popular'},
  {path: '/facebookLinksTable', component: FacebookLinksTable, auth: true, breadcrumb: 'Facebook table'},
  {path: '/googleLinksTable', component: GoogleLinksTable, auth: true, breadcrumb: 'Google table'},
  {path: '/facebookLinksTableRedirect', component: FacebookLinksTable, auth: true},
  {path: '/tiktokLinksTable', component: TikTokLinksTable, auth: true, breadcrumb: 'TikTok table'},
  {path: '/tiktokAdminAccountAssets', component: TikTokAccountAssets, auth: false},
  {path: '/allLinksTable', component: AllLinksTable, auth: true, breadcrumb: 'All links table'},
  {path: '/commonLinksTable', component: TableForCommonLinks, auth: true, breadcrumb: 'Table for common links'},
  {path: '/settings', component: Settings, auth: true},
  {path: '/activity', component: Activity, auth: true},
  {path: '/activity/activityCurrentLink', component: ActivityCurrentLink, auth: true, breadcrumb: 'Activity tracker'},
  {path: '/activityMulti', component: ActivityMulti, auth: true},
  {path: '/activity/activityCurrentLinkMulti', component: ActivityCurrentLinkMulti, auth: true, breadcrumb: 'Activity tracker'},
  {path: '/sign-in/forgot-password', component: ForgotPasswordFrom, auth: false},
  {path: '/login/create-password/:token?', component: CreateNewPasswordForm, auth: false},
  {path: '/users/invite/:token?', component: AcceptInvitationForm, auth: false},
  {path: '/sign-in', component: SignIn, auth: false},
  {path: '/sign-in-user-redirect/:id?', component: SignInClient, auth: false},
  {path: '/google/sign-in-user-redirect/:id?', component: GoogleSignInClient, auth: false},
  {path: '/tiktokAdminAccountAssets/:id?', component: TikTokSignInClient, auth: false},
  {path: '/tiktokClientConfig', component: TikTokClientConfig, auth: false},
  {path: '/client-login-success', component: ClientSuccessfulLogin, auth: false},
  {path: '/client-tiktok-login-success', component: ClientTikTokSuccessfulLogin, auth: false},
  {path: '/client-google-login-success', component: ClientGoogleSuccessfullLogin, auth: false},
  {path: '/tiktokAdminAccountAssets/tikTokEmailRedirect/:id?', component: RedirectAfterTikTokEmail, auth: false},
  {path: '/client/:id?/service-login', component: MultiStepSignIn, auth: false},
  {path: '/multiServiceRedirectSteps', component: MultiStepUserSteps, auth: false},
  {path: '/multiServiceLoginSuccess', component: MultiStepLoginSuccess, auth: false},
  {path: '/mobileLink', breadcrumb: 'table', component: MobileLink, auth: true},
  {path: '/privatePanelSignIn', component: PrivatePanelSignIn, auth: false},
  {path: '/privatePanelTable', component: PrivatePanelTable, auth: true},
  {path: '/admins/invite/:token?', component: AcceptInvitationFormAdmin, auth: false},
  {path: '/tableOfCredits', component: TableOfCredits, auth: true},
  {path: '/noCreditsLeft', component: NoCreditsLeft, auth: false},
  {path: '/integrations', component: Integrations, auth: true},
  {path: '/clients', component: ClientsTable, auth: true},
  {path: '/clientDetails', component: ClientDetails, auth: true},
]


interface RouteType {
  path: string
  component: React.FC<any>
  auth: boolean
  props?: object
  breadcrumb?: string | null
}

export default routes
