import axios from '../helpers/axiosHelper'
import { AcceptInvitationDataType } from '../types/userTypes'

export const authApi = {
    login(requestBody: any){
        return axios.post(`/facebook/authorization`, requestBody)
    },
    emailLogin(requestBody: {email: string, password: string}){
        return axios.post(`/login`, requestBody)
    },
    sendRestorePasswordEmail(requestBody: {email: string}){
        return axios.post(`/login/password`, requestBody)
    },
    resetPassword(requestBody: {password: string, token: string}){
        return axios.post(`/login/reset-password`, requestBody)
    },
    getPermissionConfigurationLinkForLogin(config_id: string){
        return axios.get(`/facebook/permission-configurations/${config_id}/login-link`)
    },
    facebookClientLogin(requestBody: {authorization_code: string, redirect_url: string, config_id: number}){
        return axios.post(`/facebook/client`, requestBody)
    },
    facebookClientLoginGrantAccess(requestBody: any){
        return axios.post(`/facebook/client/grant-access`, requestBody)
    },
    googleAuthorizationAdminFirstStage(){
        return axios.get(`/google/authorization/url`)
    },
    googleAuthorizationAdminSecondStage(code: string){
        return axios.get(`/google/authorization?code=${code}`)
    },
    googleLogout(){
        return axios.delete(`/google/logout`)
    },
    googleClientLogin(requestBody: {authorization_code: string, redirect_url: string, config_id: number}){
        return axios.post(`/google/client`, requestBody)
    },
    googleClientGrantAccess(reqBody: any){
        return axios.post(`/google/client/grant-access`, reqBody)
    },
    tiktokAuthorizationAdminFirstStage(){
        return axios.get(`/tiktok/authorization/url`)
    },
    tiktokAuthorizationAdminSecondStage(auth_code : string){
        return axios.get(`/tiktok/authorization?auth_code=${auth_code}`)
    },
    tikTokLogout(){
        return axios.delete(`/tiktok/logout`)
    },
    tikTokClientLogin(authorization_code: string, config_id: number){
        return axios.post(`/tiktok/client`, {authorization_code, config_id})
    },
    tikTokClientGrantAccess(reqBody: any){
        return axios.post(`/tiktok/client/grant-access`, reqBody)
    },
    tikTokClientAssetsAfterEmailMessage(uniqueId: string){
        return axios.get(`/tiktok/assets-access/${uniqueId}`)
    }
}

export const linksApi = {
    getAllServicesLinks(){
        return axios.post(`/permission-configurations`)
    },
    getActivityMultiLinkLinksDivided(linkId: number){
        return axios.post(`/permission-configurations/multi-service`, {multi_service_id: linkId})
    }
}

export const googleIntegration = {
    getGoogleAccountMe(){
        return axios.get(`/google/me/info`)
    },
    getGoogleAccount(){
        return axios.get(`/google-account`)
    },
    updateGoogleAccount(requestBody: {google_ads_manager_id: number | null, google_ads_manager_email: string | null}){
        return axios.put(`/google-account`, requestBody)
    },
    getGoogleTableLinks(){
        return axios.get(`/google/permission-configurations`)
    },
    getGooglePermissions(){
        return axios.get(`/google/permissions`)
    },
    createGoogleTableLink(req: CreateGoogleAndTikTokLink){
        return axios.post(`/google/permission-configurations`, req)
    },
    updateGoogleTableLink(req: CreateGoogleAndTikTokLink, config_id: number | null){
        return axios.put(`/google/permission-configurations/${config_id}`, req)
    },
    deleteGoogleTableLink(config_id: number){
        return axios.delete(`/google/permission-configurations/${config_id}`)
    },
    getGoogleLinkByConfigId(config_id: number){
        return axios.get(`/google/permission-configurations/${config_id}`)
    },
    getGoogleAccountClientRedirectLink(config_id: number){
        return axios.get(`/google/permission-configurations/${config_id}/login-link`)
    }
}

export const tikTokIntegration = {
    getTikTokAccount(){
        return axios.get(`/tiktok-account`)
    },
    updateTikTokAccount(requestBody: UpdateTikTokAccount){
        return axios.put(`/tiktok-account`, requestBody)
    },
    getTikTokAccountTableLinks(){
        return axios.get(`/tiktok/permission-configurations`)
    },
    getTikTokPermissions(){
        return axios.get(`/tiktok/permissions`)
    },
    createTikTokTableLink(req: CreateGoogleAndTikTokLink){
        return axios.post(`/tiktok/permission-configurations`, req)
    },
    updateTikTokTableLink(req: CreateGoogleAndTikTokLink, config_id: number | null){
        return axios.put(`/tiktok/permission-configurations/${config_id}`, req)
    },
    deleteTikTokTableLink(config_id: number){
        return axios.delete(`/tiktok/permission-configurations/${config_id}`)
    },
    getTikTokLinkByConfigId(config_id: number){
        return axios.get(`/tiktok/permission-configurations/${config_id}`)
    },
    getTikTokBusinessCenters(){
        return axios.get(`/tiktok/business-center`)
    },
    getTikTokAccountClientRedirectLink(config_id: number){
        return axios.get(`/tiktok/permission-configurations/${config_id}/login-link`)
    }
}

export const businessAssetPermission = {
    getBusinessAssetPermission(){
        return axios.get(`/facebook/business-asset-permissions`)
    }
}

export const permissionConfigurationController = {
    getPermissionConfiguration(){
        return axios.get(`/facebook/permission-configurations`)
    },
    addPermissionConfiguration(requestBody: any){
        return axios.post(`/facebook/permission-configurations`, requestBody)
    },
    deletePermissionConfiguration(config_id: number) {
        return axios.delete(`/facebook/permission-configurations/${config_id}`)
    },
    getPermissionConfigurationById(config_id: number) {
        return axios.get(`/facebook/permission-configurations/${config_id}`)
    },
    updatePermissionConfigurationById(config_id: number, requestBody: any) {
        return axios.put(`/facebook/permission-configurations/${config_id}`, requestBody)
    },
    logoutFacebook() {
        return axios.delete('/facebook/logout')
    }
}

export const businessesController = {
    getUserBusinesses(user_id: number){
        return axios.get(`/facebook/businesses/users/${user_id}`)
    },
    uploadAgentLogo(business_id: number, logo: any){
        console.log(logo)
        return axios.post(`/facebook/businesses/${business_id}/upload-logo`, logo ,{
            headers: {
                "Content-Type": "image/png",
            },
        })
    }
}

export const membersApi = {
    getAllMembers(){
        return axios.get(`/users-invites`)
    },
    getAllAvailableRoles(){
        return axios.get(`/roles`)
    }, 
    inviteMember(email: string){
        return axios.post(`/users/invite/send`, {email})
    },
    changeMemberRole(userId: number, roles: number[]){
        return axios.put(`/users/${userId}`, {roles})
    },
    checkInvitationToken(token: string){
        return axios.get(`/users/invite/check-active-status?token=${token}`)
    },
    acceptMemberInvitation(data: AcceptInvitationDataType){
        return axios.post(`/users/invite/accept`, data)
    },
    cancelMemberInvitation(inviteId: number){
        return axios.delete(`/users/invite/${inviteId}/cancel`)
    },
    deleteMember(userId: number){
        return axios.delete(`/users/${userId}`)
    },
    getAgencyNameForUser(token: string){
        return axios.post(`/login/get-agency`, token)
    }
}

export const agencyApi = {
    getAgencyData(){
        return axios.get(`/agency`)
    },
    editAgency(data: any){
        return axios.put(`/agency`, data)
    },
}

export const multiStepApi = {
    getMultiStepLinks(){
      return axios.get(`/multi-service/permission-configurations`)
    },
    createMultiStepLink(data: CreateMultiStepLink){
        return axios.post(`/multi-service/permission-configurations`, data)
    },
    updateMultiStepLink(data: CreateMultiStepLink){
        return axios.put(`/multi-service/permission-configurations`, data)
    },
    deleteMultiStepLink(config_id: number | null){
        return axios.delete(`/multi-service/permission-configurations/${config_id}`)
    },
    getMultiStepLinkById(config_id: number){
        return axios.get(`/multi-service/permission-configurations/${config_id}`)
    },
    getMultiStepLoginServices(config_id: number){
        return axios.get(`/multi-service/permission-configurations/${config_id}/login-link`)
    }
}


export const privatePanelApi = {
    privatePanelApiLogin(requestBody: any){
        return axios.post(`/login/admin`, requestBody)
    },
    privatePanelTableData(){
        return axios.get('/admin-invites')
    },
    sendPrivatePanelInvite(requestData: {email: string}){
        return axios.post(`/customers/invite/send`, requestData)
    },
    checkInvitationToken(token: string){
        return axios.get(`/customers/invite/check-active-status?token=${token}`)
    },
    acceptMemberInvitation(data: AdminAcceptInvitationDataType){
        return axios.post(`/customers/invite/accept`, data)
    },
}

export const activitiesApi = {
    getActivitiesLinks(link_id: number, platform_name: string){
        return axios.get(`/link-activities?link_id=${link_id}&platform_name=${platform_name}`)
    },
    createLinkActivity(reqBody: CreateActivity){
        return axios.post(`/link-activities`, reqBody)
    },
    updateLinkActivity(link_activity_id: number, linkActivityReqData: LinkActivityReqData){
        return axios.put(`/link-activities/${link_activity_id}`, linkActivityReqData)
    },
    getAllLinkActivities(){
        return axios.get(`/link-activities`)
    }

}


export const emailApi = {
    sendLinkWithEmail(req: LinkSentViaEmailType, client_id: number){
        return axios.post(`/email/client/${client_id}/config-link`,req)
    },
    resendLinkWithEmail(req: LinkSentViaEmailType, client_id: number){
        return axios.post(`/email/client/${client_id}/config-link/resend`,req)
    }
}


export const clientApi = {
    createClient(req: CreateAndUpdateClientType){
        return axios.post(`/client`, req)
    },
    getClients(req: FormData){
        return axios.post(`/client/search`, req)
    },
    updateClient(req: FormData, client_id: number){
        return axios.put(`/client/${client_id}`,req)
    },
    getClientConfigs(client_id: number){
        return axios.get(`/client/${client_id}/configs`)
    },
    checkIfCreditsAvailable(link_activity_id: number){
        return axios.get(`/link-activities/${link_activity_id}/credit-is-available`)
    },
    getClient(client_id: number){
        return axios.get(`/client/${client_id}`)
    },
}


export const feedbackApi = {
    sentFeedback(feedBack: any){
        return axios.post(`/feedback`, feedBack)
    },
}

export interface PrivatePanelTableData {
    admins_invites: PrivatePanelDataItem[]
}

export interface CreateActivity {
    link_id: number
    platform_name: string
    user_name: string
    user_email: string
}

export interface AdminAcceptInvitationDataType {
    token: string
    user_name: string
    agency_name: string
    password: string
}

export interface PrivatePanelDataItem {
    cust_id: number
    agency_name: string
    email: string
    status: string
}

export interface CreateGoogleAndTikTokLink {
    name: string
    description: string
    permission_id_list?: number[],
    is_multi_service_configuration: boolean
    client_id: number
}

export interface UpdateTikTokAccount {
    email: string,
    business_center_id: string,
    business_center_name: string,
    business_center_company_name: string
}

export interface CreateMultiStepLink {
    name: string
    description: string
    facebook_configuration_id: number | null
    google_configuration_id: number | null
    tiktok_configuration_id: number | null
}

export interface LinkSentViaEmailType {
    email: string
    message: string
    link_id?: number
    platform_name?: string
    is_email?: boolean
}

export interface LinkActivityReqData {
    link_id?: number
    platform_name?: "FACEBOOK" | "GOOGLE" | "TIKTOK",
    user_name?: string,
    user_email?: string,
    permission_list?: number,
    status: "SENT" | "OPENED" | "INTEGRATED"

}

export interface CreateAndUpdateClientType {
    client: {
        name: string
        email: string
        status?: string
    },
    logo?: any
}

export interface GetClientsType {
    filter?: {
        search_value: string
        status: string
    },
    pagination?: {
        page: number
        size: number
        sort?: [
            {
                field: string
                direction: "ASC" | "DESC"
            }
        ]
    }
}