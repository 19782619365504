import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
    selectCurrentScreenHeight,
    selectCurrentScreenWidth,
    selectIsGoogleTableModalOpen,
    selectIsMultiStepLinkFinished,
    selectIsSendLinkModalOpened,
    setBeforeAddingLinksModalOpen,
    setGoogleModalOpen,
    setGoogleTableModalOpen,
    setIsMultiStepLinkFinished, setIsSendLinkModalOpened,
    setMobileIsMultiLinkOpened, setSendLinkModalCurrentLink,
    setTikTokTableModalOpen
} from "../../store/utilityReducer";
import {Checkbox, Form, Input, Popconfirm, Tooltip} from "antd";
import {DataGridPro, useGridApiRef} from "@mui/x-data-grid-pro";
import s from "../FacebookLinksTable/FacebookLinksTable.module.scss";
import PageTitle from "../PageTitle/PageTitle";
import RequirementSearchInput from "../RequirementSearchInput/RequirementSearchInput";
import {Box} from "@material-ui/core";
import CommonModal from "../CommonModal/CommonModal";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {
    DeleteGoogleTableLinkThunk,
    GetGoogleTableLinkByIdThunk,
    GetGoogleTableLinksThunk,
    GetGoogleTablePermissionsThunk,
    selectCurrentGoogleModalLink,
    selectCurrentGoogleModalLinkPermissions,
    selectGoogleAccount,
    selectGoogleTableLinks,
    selectGoogleTablePermissions,
    setCurrentGoogleModalLink,
    setCurrentGoogleModalLinkPermissions,
} from "../../store/googleReducer";
import PageWithSidebar from '../PageWithSidebar/PageWithSidebar';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import {useNavigate} from 'react-router';
import GoogleAccountConfigsModal from '../GoogleAccountConfigsModal/GoogleAccountConfigsModal';
import MultiStepIndicator from "../MultiStepIndicator/MultiStepIndicator";
import {
    onSetCurrentMultiStepLink, onSetCurrentPlatformIndex,
    selectCurrentPlatformIndex,
    selectMultiStepCurrentLinkData
} from "../../store/multiStepReducer";
import {selectMultiStageServices, setMultiStageServices} from "../../store/linksReducer";
import {
    resetFacebookModalConfigurations,
    setAddAccountConfigurations,
    setCatalogAccountConfigurations,
    setInstagramAccountConfigurations,
    setPageAccountConfigurations,
    setPixelAccountConfigurations,
    setSelectedConfigurations,
} from "../../store/permissionConfigurationReducer";
import {selectPermissionCatalog} from "../../store/businessAssetReducer";
import {tutorialDataHelper, userDataHelper, userMultiStepHelper} from "../../helpers/localStorageHelper";
import {setCurrentTikTokModalLinkPermissions} from "../../store/tikTokReducer";
import {selectCurrentTutorialStep, selectIsTutorial, setCurrentTutorialStep} from "../../store/tutorialReducer";
import TutorialModal from "../TutorialModal/TutorialModal";
import SendLinkModal from "../SendLinkModal/SendLinkModal";
import moment from "moment/moment";


const deleteIcon = require('../../img/icons/delete.svg')
const editIcon = require('../../img/icons/edit.svg')
const closeIcon = require('../../img/icons/close.svg')
const googleIcon = require('../../img/icons/google-logo-small.svg')


const GoogleLinksTable = () => {
    return (
        <PageWithSidebar>
            <GoogleLinksTableComponent/>
        </PageWithSidebar>
    )
}

const GoogleLinksTableComponent = () => {
    const [isColumnVisible, setIsColumnVisible] = useState(true);
    const [screenWidth, setScreenWidth] = useState<number>(0)
    const currentTutorialStep = useAppSelector(selectCurrentTutorialStep)
    const isTutorial = useAppSelector(selectIsTutorial)
    const isSendLinkModalOpened = useAppSelector(selectIsSendLinkModalOpened)
    const currentScreenHeight = useAppSelector(selectCurrentScreenHeight)
    const navigate = useNavigate()
    useEffect(() => {
        const handleResize = () => {
            setIsColumnVisible(window.innerWidth > 1160); // Adjust the breakpoint as needed
            setScreenWidth(window.innerWidth)
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const googleAccount = useAppSelector(selectGoogleAccount)

    const [editMode, setEditMode] = useState(false)
    const [visibleRowsCount, setVisibleRowsCount] = useState(0)
    const [visibleRows, setVisibleRows] = useState<any[]>([])

    const tableLinks = useAppSelector(selectGoogleTableLinks)
    const dispatch = useAppDispatch()

    useEffect(() => {
        setVisibleRowsCount(tableLinks.length)
        setVisibleRows(tableLinks)
    }, [tableLinks])

    useEffect(() => {
        dispatch(GetGoogleTableLinksThunk())
        dispatch(GetGoogleTablePermissionsThunk())
    }, [dispatch])

    useEffect(() => {
        if (googleAccount.google_ads_manager_email === null && googleAccount.google_ads_manager_id === null) {
            dispatch(setGoogleModalOpen(true))
        }
    }, [dispatch, googleAccount])

    const onDeleteGoogleLink = (configId: number) => {
        dispatch(DeleteGoogleTableLinkThunk(configId))
    }

    const onGetGoogleLinkConfigById = (configId: number) => {
        dispatch(GetGoogleTableLinkByIdThunk(configId))
        dispatch(setGoogleTableModalOpen(true))
        setEditMode(true)
    }




    const onOpenSendModalLink = (linkData: any) => {
        dispatch(setIsSendLinkModalOpened(true))
        dispatch(setSendLinkModalCurrentLink({...linkData, platform: 'Google'}))
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
        },
        {
            field: 'serviceType',
            headerName: 'Service',
            flex: 0.45,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            renderCell: () => <googleIcon.ReactComponent style={{marginLeft: '10px'}}/>,
            hide: !isColumnVisible,
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 0.8,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
        },
        // {
        //     field: 'url',
        //     headerName: 'Link URL',
        //     flex: screenWidth > 1400 ? 2 : 1,
        //     fontFamily: 'Inter, sans-serif',
        //     fontWeight: 600,
        //     color: '#6B7280',
        //     fontSize: '12px',
        //     lineHeight: '20px',
        //     renderCell: (data: any) => (
        //         <div style={{color: '#667085', marginLeft: '10px'}}>
        //             <span
        //                 style={{display: screenWidth < 768 ? 'none' : 'block', minWidth: '181px'}}>{data.row.url}</span>
        //             <span style={{display: screenWidth >= 768 ? 'none' : 'block', width: '50px'}}>Link</span>
        //         </div>
        //     )
        // },
        {
            field: 'date_created',
            headerName: 'Date Created',
            flex: screenWidth > 768 ? 1 : 0.5,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            renderCell: (data: any) => (
                <div style={{color: '#667085', marginLeft: '10px'}}>
                    {
                        moment(data.row.date_created, "DD-MM-YYYY HH:mm:ss").format('DD MMM YYYY (hh:mm A)')
                    }
                </div>
            )
        },
        {
            field: '',
            headerName: '',
            flex: 0.5,
            renderCell: (data: any) => {
                return (
                    <button
                        onClick={() => onOpenSendModalLink(data.row)}
                        className={s.sendButton}
                        style={{
                            color: '#075CEB',
                            marginLeft: '10px',
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: '20px',
                            backgroundColor: 'transparent',
                            border: 'none',
                            outline: 'none',
                            cursor: 'pointer'
                        }}>
                        Send
                    </button>
                )
            }
        },
        {
            field: 'Action',
            headerName: '',
            flex: 0.6,
            disableExport: true,
            hide: screenWidth < 768,
            renderCell: (data: any) => {
                return (
                    <div>
                        {/*<copyIcon.ReactComponent*/}
                        {/*    style={*/}
                        {/*        {*/}
                        {/*            marginRight: '15px',*/}
                        {/*            cursor: 'pointer',*/}
                        {/*            fontSize: '18px'*/}
                        {/*        }*/}
                        {/*    }*/}
                        {/*    color="action"*/}
                        {/*    onClick={() => {*/}
                        {/*        onCopyGoogleLink(data.row.url)*/}
                        {/*    }}*/}
                        {/*/>*/}
                        {
                            !isTutorial
                            &&
                            <>
                                <Popconfirm
                                    title="Are you sure to delete this link?"
                                    onConfirm={() => onDeleteGoogleLink(data.row.id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <deleteIcon.ReactComponent
                                        style={
                                            {
                                                marginRight: '15px',
                                                cursor: 'pointer',
                                                fontSize: '18px'
                                            }
                                        }
                                        color="#fff"
                                    />
                                </Popconfirm>
                                <editIcon.ReactComponent
                                    color="action"
                                    style={
                                        {
                                            marginRight: '15px',
                                            cursor: 'pointer',
                                            fontSize: '18px'
                                        }
                                    }
                                    onClick={() => {
                                        onGetGoogleLinkConfigById(data.row.id)
                                    }}
                                />
                            </>
                        }

                    </div>

                )
            }
        }
    ]
    const apiRef = useGridApiRef();

    // if (Object.keys(googleAccount).length === 0) {
    //
    //     return <Navigate to='/'/>
    // }


    const onMobileRowClick = (value: any) => {
        if (screenWidth < 768) {
            dispatch(GetGoogleTableLinkByIdThunk(value.row.id))
            userDataHelper.setMobileLinkServiceType('Google')
            navigate('/mobileLink')
        }
    }


    const onGoogleAddBtnClick = () => {
        dispatch(setGoogleTableModalOpen(true))
        if (currentTutorialStep === 'Fourth step - Link creation / Table' && isTutorial) {
            dispatch(setCurrentTutorialStep('Fourth step - Link creation / Modal'))
            tutorialDataHelper.setCurrentTutorialStep('Fourth step - Link creation / Modal')
        }
    }

    return (
        <div
            style={{
                padding: screenWidth > 1160 ? '32px' : screenWidth > 480 ? '10px 20px' : '10px 8px'
            }}>
            <Breadcrumbs/>
            <div className={s.inner__header}>
                <PageTitle
                    title='Google table'
                    description='Create links for your customers'
                    onAddLink={onGoogleAddBtnClick}
                />
            </div>
            <RequirementSearchInput
                apiRef={apiRef}
                setVisibleRowsCount={(count: number) => setVisibleRowsCount(count)}
                setVisibleRows={(rows: any[]) => setVisibleRows(rows)}
                allRows={tableLinks}
            />
            <div
                className={currentTutorialStep === 'Fourth step - Link creation / Send link' && isTutorial ? `${s.tableInner} ${s.tableInner_tutorial}` : s.tableInner}>
                <h2>
                    All links <span>{visibleRowsCount} links</span>
                </h2>
                <Box>
                    <DataGridPro
                        sx={{
                            borderRight: 'none',
                            borderLeft: 'none',
                            borderBottom: 'none',
                            borderRadius: '0px',
                            borderTop: '1px solid transparent',
                            "& .MuiDataGrid-columnHeader": {
                                background: '#F9FAFB',
                                padding: '0px 20px',
                                // minWidth: "121px !important",
                                minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                            },
                            "& .MuiDataGrid-columnHeader:last-child": {
                                background: '#F9FAFB',
                                padding: '0px 20px',
                                // minWidth: "540px !important"
                            },
                            "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                display: "none"
                            },
                            "& .MuiDataGrid-cell": {
                                // minWidth: "121px !important",
                                minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                                outline: 'none'
                            },
                            "& .MuiDataGrid-cell:last-child": {
                                // minWidth: "540px !important"
                            },
                            "& .MuiDataGrid-cellContent": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#111827',
                                padding: '0px 10px',
                                // minWidth: '540px'
                            },
                            "& .MuiDataGrid-cellContent:last-child": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#111827',
                                padding: '0px 10px',
                                // minWidth: '540px'
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 600,
                                color: '#6B7280',
                                fontSize: '12px',
                                lineHeight: '20px',
                            },
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-row.Mui-selected": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-row.Mui-selected:hover": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-cell:focus": {
                                outline: 'none'
                            },
                            "& .MuiDataGrid-toolbarContainer": {
                                color: '#454545',
                                position: 'absolute',
                                top: '-62px',
                                right: '0px'
                            },
                            "& .MuiButtonBase-root": {
                                fontFamily: 'Inter, sans-serif !important',
                                fontWeight: 600,
                                color: '#6B7280',
                                fontSize: '12px',
                                lineHeight: '20px',
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginBottom: 0
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                overflowY: 'scroll !important',
                                overflowX: 'scroll !important',
                                height: `${currentScreenHeight - 420}px !important`,
                            },
                        }}
                        apiRef={apiRef}
                        columns={columns}
                        rows={visibleRows}
                        {...visibleRows}
                        rowHeight={58}
                        headerHeight={39}
                        pagination={true}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        hideFooterSelectedRowCount
                        autoHeight={true}
                        autoPageSize={true}
                        onRowClick={onMobileRowClick}
                    />
                </Box>
            </div>
            <GoogleAccountConfigsModal/>
            <GoogleModal editMode={editMode} setEditMode={(mode: boolean) => setEditMode(mode)}/>
            {
                isTutorial && currentTutorialStep === 'Fourth step - Link creation / Table'
                &&
                <TutorialModal/>
            }
            {
                isTutorial && currentTutorialStep === 'Fourth step - Link creation / Send link'
                &&
                <TutorialModal/>
            }
            {
                isSendLinkModalOpened
                &&
                <SendLinkModal/>
            }
        </div>
    );
};

export const GoogleModal: React.FC<{ editMode?: boolean, setEditMode?: (editMode: boolean) => void, isMultiStep?: boolean, onStepChange?: () => void, isMobileLink?: boolean }> = ({
                                                                                                                                                                                       editMode,
                                                                                                                                                                                       setEditMode,
                                                                                                                                                                                       isMultiStep,
                                                                                                                                                                                       onStepChange,
                                                                                                                                                                                       isMobileLink
                                                                                                                                                                                   }) => {
    const dispatch = useAppDispatch()
    const isModalOpen = useAppSelector(selectIsGoogleTableModalOpen)
    const tablePermissions = useAppSelector(selectGoogleTablePermissions)
    const currentGoogleLink = useAppSelector(selectCurrentGoogleModalLink)
    const currentGoogleLinkPermissions = useAppSelector(selectCurrentGoogleModalLinkPermissions)
    const currentPlatformIndex = useAppSelector(selectCurrentPlatformIndex)
    const businessPermissions = useAppSelector(selectPermissionCatalog)
    const [checked, setChecked] = useState<any>([]);
    const [form] = Form.useForm()
    const multiStageServices = useAppSelector(selectMultiStageServices)
    const currentMultiStepLink = useAppSelector(selectMultiStepCurrentLinkData)
    const isMultiStepLinkFinished = useAppSelector(selectIsMultiStepLinkFinished)
    const isTutorial = useAppSelector(selectIsTutorial)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
    useEffect(() => {
        if (isMultiStep && currentMultiStepLink) {
            form.setFieldsValue(currentMultiStepLink && currentMultiStepLink.google_configuration)
        } else if (editMode) {
            form.setFieldsValue(currentGoogleLink)
        } else if (isMultiStep && !editMode && !isMultiStepLinkFinished) {
            form.setFieldsValue({name: ''})
            form.setFieldsValue({business: ''})
            form.setFieldsValue({description: ''})
        } else {
            form.setFieldsValue({name: ''})
            form.setFieldsValue({business: ''})
            form.setFieldsValue({description: ''})
            userMultiStepHelper.setIsUserMultiStepLogin(false)
        }
    }, [form, currentGoogleLink, currentMultiStepLink, isMultiStep, editMode, isMultiStepLinkFinished])
    useEffect(() => {
        setChecked(currentGoogleLinkPermissions)
    }, [currentGoogleLinkPermissions])

    const handleCheck = (event: any) => {
        const name = event.target.name;
        if (event.target.checked) {
            const checkedArray = [...checked, name]
            setChecked(checkedArray);
        } else {
            const checkedArray = checked.filter((c: any) => c !== name)
            setChecked(checkedArray)
        }
    };

    const onCloseModal = () => {
        if (!isMobileLink) {
            // form.setFieldsValue({name: ''})
            form.setFieldsValue({description: ''})
            if (setEditMode) {
                setEditMode(false)
            }
            setChecked([])
            dispatch(setGoogleTableModalOpen(false))
            dispatch(setCurrentGoogleModalLink({}))
            dispatch(setCurrentGoogleModalLinkPermissions({permissions: []}))
            dispatch(setMultiStageServices([]))
            dispatch(setCurrentTikTokModalLinkPermissions({permissions: []}))
            dispatch(setBeforeAddingLinksModalOpen(false))
            dispatch(onSetCurrentMultiStepLink(null))
            dispatch(setAddAccountConfigurations(businessPermissions.AD_ACCOUNT))
            dispatch(setCatalogAccountConfigurations(businessPermissions.CATALOG))
            dispatch(setInstagramAccountConfigurations(businessPermissions.INSTAGRAM))
            dispatch(setPageAccountConfigurations(businessPermissions.PAGE))
            dispatch(setPixelAccountConfigurations(businessPermissions.PIXEL))
            dispatch(setSelectedConfigurations({isEdit: false, emptyData: []}))
            dispatch(onSetCurrentPlatformIndex(0))
        }
        if (isMultiStep) {
            window.location.reload()
        } else {
            form.resetFields();
            dispatch(resetFacebookModalConfigurations())
            dispatch(setCurrentGoogleModalLinkPermissions({permissions: []}))
            dispatch(setCurrentTikTokModalLinkPermissions({permissions: []}))
            dispatch(setMultiStageServices([]))
            dispatch(onSetCurrentMultiStepLink(null))
            dispatch(setGoogleTableModalOpen(false))
            dispatch(setMobileIsMultiLinkOpened(false))
            //
            form.resetFields();
            dispatch(setIsMultiStepLinkFinished(false))
            dispatch(resetFacebookModalConfigurations())
            dispatch(setMultiStageServices([]))
            dispatch(setCurrentGoogleModalLinkPermissions({permissions: []}))
            dispatch(setCurrentTikTokModalLinkPermissions({permissions: []}))
            dispatch(onSetCurrentMultiStepLink(null))
            dispatch(setTikTokTableModalOpen(false))
        }


    }
    // const onFinish = (formData: any, button?: string) => {
    //     let values = form.getFieldsValue();
    //     if (!isMultiStep) {
    //         if (!checked.length) {
    //             message.error('Please, select at least one google account configuration!')
    //         } else {
    //             const requestBody: CreateGoogleAndTikTokLink = {
    //                 name: formData.name,
    //                 description: formData.description,
    //                 permission_id_list: checked,
    //                 is_multi_service_configuration: false
    //             }
    //             if (!editMode) {
    //                 dispatch(CreateGoogleAccountLinkThunk({request: requestBody}))
    //             } else {
    //                 console.log(requestBody)
    //                 dispatch(UpdateGoogleAccountLinkThunk({req: requestBody, config_id: currentGoogleLink.id}))
    //             }
    //             setChecked([])
    //             form.setFieldsValue({name: ''})
    //             form.setFieldsValue({description: ''})
    //             dispatch(setIsMultiStepLinkFinished(true))
    //         }
    //         if (isTutorial) {
    //             dispatch(setCurrentTutorialStep('Fourth step - Link creation / Send link'))
    //         }
    //     } else {
    //         if (button === 'Next') {
    //             const requestBody: CreateGoogleAndTikTokLink = {
    //                 name: values.name,
    //                 description: values.description,
    //                 permission_id_list: checked,
    //                 is_multi_service_configuration: true
    //             }
    //             if (onStepChange) {
    //                 dispatch(setGoogleStepData({
    //                     name: values.name,
    //                     description: values.description,
    //                     permission_id_list: checked,
    //                 }))
    //                 if (!editMode) {
    //                     dispatch(CreateGoogleAccountLinkThunk({request: requestBody, isMultiStep: true}))
    //                     onStepChange()
    //                 } else if (editMode) {
    //                     dispatch(UpdateGoogleAccountLinkThunk({
    //                         req: requestBody,
    //                         config_id: currentMultiStepLink && currentMultiStepLink.google_configuration.id,
    //                         isMultiStep: true
    //                     }))
    //                 }
    //                 onStepChange()
    //             }
    //         } else {
    //             if (onStepChange)
    //                 onStepChange()
    //         }
    //     }
    //     if (isMobileLink && !isMultiStep) {
    //         navigate('/googleLinksTable')
    //     }
    // }

    // const onSendAllLinksData = () => {
    //     dispatch(setMobileIsMultiLinkOpened(false))
    //     let values = form.getFieldsValue();
    //     const requestBody: CreateGoogleAndTikTokLink = {
    //         name: values.name,
    //         description: values.description,
    //         permission_id_list: checked,
    //         is_multi_service_configuration: true
    //     }
    //
    //     if (!editMode) {
    //         dispatch(CreateGoogleAccountLinkThunk({
    //             request: requestBody,
    //             isMultiStep: true,
    //             multiStepReqData: multiStepReqData
    //         }))
    //     } else if (editMode) {
    //         dispatch(UpdateGoogleAccountLinkThunk({
    //             req: requestBody,
    //             config_id: currentMultiStepLink && currentMultiStepLink.google_configuration.id,
    //             isMultiStep: true,
    //             multiStepReqData: multiStepReqData
    //         }))
    //     }
    //     dispatch(setBeforeAddingLinksModalOpen(null))
    //     dispatch(setMultiStageServices([]))
    //     dispatch(setSelectedConfigurations({isEdit: false, emptyData: []}))
    //     dispatch(setCurrentGoogleModalLinkPermissions({permissions: []}))
    //     dispatch(setCurrentTikTokModalLinkPermissions({permissions: []}))
    //     dispatch(switchAllConfigurationsCheckboxes(false))
    //     dispatch(setFacebookStepData({}))
    //     dispatch(setGoogleStepData({}))
    //     dispatch(setTikTokStepData({}))
    //     setTimeout(() => {
    //         window.location.reload()
    //     }, 500)
    //     if (isMobileLink && !isMultiStep) {
    //         navigate('/googleLinksTable')
    //     } else if (isMobileLink && isMultiStep && currentServiceType === 'All') {
    //         dispatch(setMobileIsMultiLinkOpened(false))
    //         navigate('/allLinksTable')
    //     } else if (isMobileLink && isMultiStep && currentServiceType === 'Common') {
    //         dispatch(setMobileIsMultiLinkOpened(false))
    //         navigate('/commonLinksTable')
    //     }
    // }

    return (
        <CommonModal active={isModalOpen} setEditMode={(editMode) => setEditMode && setEditMode(editMode)}
                     height={'400px'} additionalTopMargin={currentScreenWidth > 1440 ? '8%' : '11%'}>
            <div className={`${s.modal} facebookModal`}>
                <button className={s.modal__closeBtn} onClick={onCloseModal} disabled={isTutorial}>
                    <closeIcon.ReactComponent/>
                </button>
                <h2>{editMode ? 'Edit Link' : 'Add Link'}</h2>
                <MultiStepIndicator currentStage={'Google'} editMode={editMode && editMode}/>
                <Form
                    name='googleLinksTable'
                    // onFinish={(formData) => onFinish(formData)}
                    form={form}
                    autoComplete='off'
                    validateTrigger='onBlur'
                    initialValues={currentGoogleLink}
                >
                    <div>
                        <p className={s.modal__subtitle}>Name:</p>

                        <Form.Item
                            name='name'
                            rules={[
                                {required: true, message: 'Enter configuration name!'},
                            ]}
                        >

                            <Input
                                placeholder={'Enter name please'}
                                className={s.modal__input}


                            />

                        </Form.Item>

                    </div>
                    <div>
                        <p className={s.modal__subtitle}>Configuration description:</p>

                        <Form.Item
                            name='description'
                            rules={[
                                {required: true, message: 'Enter configuration description!'},
                            ]}
                        >

                            <Input
                                placeholder={'Enter configuration description please'}
                                className={s.modal__input}

                            />

                        </Form.Item>


                    </div>
                    {tablePermissions?.map((p: any) => {
                        return (
                            <div style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                                 key={p.id}
                            >
                                <Checkbox
                                    onChange={handleCheck}
                                    name={p.id}
                                    checked={checked.includes(p.id)}
                                    className={s.modal__checkbox}
                                    disabled={!p.is_active}
                                >
                                    {p.name}
                                </Checkbox>
                                <div style={{
                                    opacity: !p.is_active ? '0.5' : 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Tooltip title={p.description}>
                                        <QuestionCircleOutlined/>
                                    </Tooltip>
                                </div>

                            </div>
                        )
                    })}
                    {!isMultiStep
                        ?
                        <Form.Item>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <button
                                    className={s.modal__btn}
                                    type={"submit"}
                                >Confirm
                                </button>
                            </div>
                        </Form.Item>
                        :
                        <div>
                            {/*<Form.Item>*/}
                            {/*    <div style={{*/}
                            {/*        width: '100%',*/}
                            {/*        display: 'flex',*/}
                            {/*        justifyContent: 'center',*/}
                            {/*        alignItems: 'center'*/}
                            {/*    }}>*/}
                            {/*        <button*/}
                            {/*            className={s.modal__btn}*/}
                            {/*            type={"button"}*/}
                            {/*            onClick={(formData: any) => onFinish(formData, 'Skip')}*/}
                            {/*        >Skip*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*</Form.Item>*/}
                            {
                                currentPlatformIndex !== multiStageServices.length - 1
                                    ?
                                    <Form.Item>
                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <button
                                                className={s.modal__btn}
                                                type={"button"}
                                                // onClick={(formData: any) => onFinish(formData, 'Next')}
                                            >Next
                                            </button>
                                        </div>
                                    </Form.Item>
                                    :
                                    <Form.Item>
                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <button
                                                className={s.modal__btn}
                                                type={"button"}
                                                // onClick={onSendAllLinksData}
                                            >Add link
                                            </button>
                                        </div>
                                    </Form.Item>
                            }
                        </div>
                    }

                </Form>
            </div>
        </CommonModal>
    )
}

export default GoogleLinksTable;
