import { Lottie } from "@crello/react-lottie"
import signInAnimatedBtnLottie from './../../assets/signInAnimatedBtnLottie.json'
import s from './AnimatedBtn.module.scss'
const signInIcon = require('../../img/icons/signIn.svg')

;

const AnimatedSignInBtn = ({onLogin, isVisible}: {onLogin?: () => void, isVisible?: boolean}) => {
    return (
        <div
            className={s.animatedBtn}
            style={{position:'relative',  visibility: isVisible ? 'initial' : 'hidden'}}
            onClick={onLogin}
        >
            <Lottie
            // @ts-ignore
                config={{animationData: signInAnimatedBtnLottie, loop: true}}
                height={'40px'}
                className={s.animatedBtn__lottie}
            />
            <div
                style={{
                    position:'absolute',
                    top: '8px',
                    color: 'white',
                    left: '57px',
                    cursor: 'pointer',
                    fontWeight: 700,
                    fontSize: '16px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <signInIcon.ReactComponent style={{marginRight: '20px'}}/>
                Sign in 
            </div>
        </div>
    )
}

export default AnimatedSignInBtn
