import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AsyncThunkConfig, RootState} from "./store";
import {CreateGoogleAndTikTokLink, googleIntegration} from "../api/api";
import {AppStatusType} from "./appStatusReducer";
import {setGoogleTableModalOpen} from "./utilityReducer";
import {
    CreateMultiStepLinksThunk, GetMultiStepLinksThunk,
    MultiStepRequestData,
    onSetRequestDataGoogleId, UpdateMultiStepLinksThunk
} from "./multiStepReducer";
import {GetAllServicesLinksThunk} from "./linksReducer";
import {onSetGoogleServiceIds} from "./clientReducer";

interface GoogleAccount {
    id: number
    google_ads_manager_id: null | number
    google_ads_manager_email: null | string
}

interface GoogleTableLinksConfigurations {
    id: number
    name: string
    description: string
    url: string
    permissions: [
        {
            id: number
            name: string
            description: string
            service_type: string
        }
    ]
    is_multi_service_configuration: boolean
    date_created: string
    client_id: number
}

interface GoogleTablePermission {
    id: number
    name: string
    description: string
    service_type: string
}

export interface GoogleTableLinks {
    configurations: GoogleTableLinksConfigurations[] | []
}

export interface GoogleTablePermissions {
    google_permissions: GoogleTablePermission[] | []
}

interface GoogleAccountMe {
    email_addresses: string[]
    google_ads: [
        {
            customer_id: number
            descriptive_name: string
            currency_code: string
            time_zone: string
            tracking_url_template: string
            auto_tagging_enabled: boolean
        }
    ]
}

interface InitialStateType {
    googleAccount: GoogleAccount | {}
    googleAccountMe: GoogleAccountMe | {}
    googleTableLinks: {
        configurations: GoogleTableLinks[] | []
    },
    googleTablePermissions: {
        google_permissions: GoogleTablePermissions[] | []
    },
    googleSelectedConfigurations: number[]
    currentGoogleModalLink: GoogleTableLinksConfigurations | {}
    currentGoogleModalLinkPermissions: number[]
}

const initialState: InitialStateType = {
    googleAccount: {},
    googleAccountMe: {},
    googleTableLinks: {
        configurations: []
    },
    googleTablePermissions : {
        google_permissions: []
    },
    googleSelectedConfigurations: [],
    currentGoogleModalLink: {},
    currentGoogleModalLinkPermissions: []
}

export const googleSlice = createSlice({
    name: 'google',
    initialState,
    reducers: {
        addGoogleSelectedConfigurations: (state, action: PayloadAction<number>) => {
            state.googleSelectedConfigurations.push(action.payload)
        },
        deleteGoogleSelectedConfigurations: (state, action: PayloadAction<number>) => {
            state.googleSelectedConfigurations = state.googleSelectedConfigurations.filter((c: any) => c !== action.payload)
        },
        setCurrentGoogleModalLink: (state, action: PayloadAction<number | {}>) => {
            state.currentGoogleModalLink = action.payload
        },
        setCurrentGoogleModalLinkPermissions: (state, action: PayloadAction<any>) => {
            state.currentGoogleModalLinkPermissions = action.payload.permissions.map((l:any) => l.id)
        },
        onClearGoogleModalLinkPermissions: (state) => {
            state.currentGoogleModalLinkPermissions = []
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetGoogleAccountThunk.fulfilled, (state, action) => {
                state.googleAccount = action.payload
            })
            .addCase(GetGoogleAccountMeThunk.fulfilled, (state, action) => {
                state.googleAccountMe = action.payload
            })
            .addCase(GetGoogleTableLinksThunk.fulfilled, (state, action) => {
                state.googleTableLinks.configurations = action.payload
            })
            .addCase(GetGoogleTablePermissionsThunk.fulfilled, (state, action) => {
                state.googleTablePermissions.google_permissions = action.payload
            })
            .addCase(CreateGoogleAccountLinkThunk.fulfilled, (state, action) => {
                state.currentGoogleModalLink = action.payload
            })

    }
})

export const {
    setCurrentGoogleModalLink,
    setCurrentGoogleModalLinkPermissions,
    onClearGoogleModalLinkPermissions
} = googleSlice.actions


export const selectGoogleAccount = (state: RootState): any => state.google.googleAccount
export const selectGoogleAccountMe = (state: RootState): any => state.google.googleAccountMe
export const selectGoogleTableLinks = (state: RootState): any => state.google.googleTableLinks.configurations
export const selectGoogleTablePermissions = (state: RootState): any => state.google.googleTablePermissions.google_permissions
export const selectCurrentGoogleModalLink = (state: RootState): any => state.google.currentGoogleModalLink
export const selectCurrentGoogleModalLinkPermissions = (state: RootState): any => state.google.currentGoogleModalLinkPermissions

export const GetGoogleAccountThunk = createAsyncThunk<GoogleAccount, void, AsyncThunkConfig>(
    'google/getGoogleAccount',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await googleIntegration.getGoogleAccount()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(null)
            }
        } catch (error: any) {
            return thunkAPI.fulfillWithValue({}, {appStatus: AppStatusType.idle})
        }
    }
)

export const GetGoogleAccountMeThunk = createAsyncThunk<GoogleAccountMe, void, AsyncThunkConfig>(
    'google/getGoogleAccountMe',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await googleIntegration.getGoogleAccountMe()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue('')
        }
    }
)

export const UpdateGoogleAccountThunk = createAsyncThunk<any, { google_ads_manager_id: null | number, google_ads_manager_email: null | string }, AsyncThunkConfig>(
    'google/updateGoogleAccount',
    async (request, thunkAPI) => {
        try {
            const {status, data} = await googleIntegration.updateGoogleAccount(
                {
                    google_ads_manager_id: request.google_ads_manager_id,
                    google_ads_manager_email: request.google_ads_manager_email
                }
            )
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            window.location.reload()
        }
    }
)

export const GetGoogleTableLinksThunk = createAsyncThunk<[], void, AsyncThunkConfig>(
    'google/getGoogleTableLinks',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await googleIntegration.getGoogleTableLinks()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.configurations, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data.configurations)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetGoogleTablePermissionsThunk = createAsyncThunk<[], void, AsyncThunkConfig>(
    'google/getGoogleTablePermissions',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await googleIntegration.getGooglePermissions()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.google_permissions, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data.google_permissions)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const CreateGoogleAccountLinkThunk = createAsyncThunk<GoogleTableLinksConfigurations, {request: CreateGoogleAndTikTokLink, isMultiStep?: boolean , multiStepReqData?: MultiStepRequestData | {}}, AsyncThunkConfig>(
    'google/createGoogleLinkAccount',
    async (request, thunkAPI) => {
        try {
            const {status, data} = await googleIntegration.createGoogleTableLink(request.request)
            if (status === 200 && data) {
                if(request.isMultiStep && request.multiStepReqData){
                    const req: any = {
                        ...request.multiStepReqData,
                        google_configuration_id: data.id
                    }
                    thunkAPI.dispatch(CreateMultiStepLinksThunk(req))
                }
                thunkAPI.dispatch(onSetRequestDataGoogleId(data.id))
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(setGoogleTableModalOpen(false))
            thunkAPI.dispatch(GetGoogleTableLinksThunk())
            if(request.isMultiStep){
                thunkAPI.dispatch(GetMultiStepLinksThunk())
            }
        }
    }
)

export const UpdateGoogleAccountLinkThunk = createAsyncThunk<GoogleTableLinksConfigurations, {req: CreateGoogleAndTikTokLink, config_id: number | null, isMultiStep?: boolean , multiStepReqData?: MultiStepRequestData | {}}, AsyncThunkConfig>(
    'google/updateGoogleLinkAccount',
    async (request, thunkAPI) => {
        try {
            const {status, data} = await googleIntegration.updateGoogleTableLink(request.req, request.config_id)
            if (status === 200 && data) {
                if(request.isMultiStep && request.multiStepReqData){
                    const req: any = {
                        ...request.multiStepReqData,
                        google_configuration_id: data.id
                    }
                    thunkAPI.dispatch(UpdateMultiStepLinksThunk(req))
                }
                thunkAPI.dispatch(onSetRequestDataGoogleId(data.id))
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(setGoogleTableModalOpen(false))
            thunkAPI.dispatch(GetGoogleTableLinksThunk())
        }
    }
)

export const DeleteGoogleTableLinkThunk = createAsyncThunk<null, number, AsyncThunkConfig>(
    'google/deleteGoogleTableLink',
    async (configId, thunkAPI) => {
        try {
            const {status} = await googleIntegration.deleteGoogleTableLink(configId)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(null, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: 'Deleted successfully'
                })
            } else {
                return thunkAPI.rejectWithValue(null)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(GetGoogleTableLinksThunk())
            thunkAPI.dispatch(GetAllServicesLinksThunk())
        }
    }
)

export const GetGoogleTableLinkByIdThunk = createAsyncThunk<null, number, AsyncThunkConfig>(
    'google/getGoogleTableLinkById',
    async (configId, thunkAPI) => {
        try {
            const {status, data} = await googleIntegration.getGoogleLinkByConfigId(configId)
            if (status === 200 && data) {
                thunkAPI.dispatch(setCurrentGoogleModalLink(data))
                thunkAPI.dispatch(setCurrentGoogleModalLinkPermissions(data))
                thunkAPI.dispatch(onSetGoogleServiceIds(data.permissions.map((l:any) => l.id)))
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetGoogleAccountClientRedirectLinkThunk = createAsyncThunk<null, number, AsyncThunkConfig>(
    'google/getGoogleAccountClientRedirectLink',
    async (configId, thunkAPI) => {
        try {
            const {status, data} = await googleIntegration.getGoogleAccountClientRedirectLink(configId)
            if (status === 200 && data) {
                // window.location.replace(data.message)
                console.log(data.message)
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export default googleSlice.reducer
