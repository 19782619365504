import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {activitiesApi, clientApi} from "../api/api";
import {AsyncThunkConfig, RootState} from "./store";
import {AppStatusType} from "./appStatusReducer";
import {ClientServiceType, TableClientsType, TableClientType} from "../types/clientsTypes";
import {ActivityType} from "./activityTrackerReducer";


interface InitialStateType {
    clients: TableClientsType
    currentPage: number
    clientForIntegrationLinkCreation: TableClientType
    clientServices: string[]
    currentClient: TableClientType
    currentClientServices: ClientServiceType[]
    currentClientName: string
    isClientLinkResending: boolean
    clientsLinkActivities: ActivityType[]
    checkedGoogleServiceIds: number[]
    resendLinkMode: boolean
    clientSearchValue: string
    isGoogleCheckboxTouched: boolean
    client: TableClientType
    clientSelectButtonValue: string | undefined
    integratedClientServices: string[]
    gridRowsCount: number
}

const initialState: InitialStateType = {
    clients: {
        clients: [],
        total_count: 0
    },
    currentPage: 1,
    clientForIntegrationLinkCreation: {
        id: 0,
        name: '',
        email: '',
        status: '',
        config_link: '',
        date_created: '',
        min_status_value: ''

    },
    clientServices: [], // services for link creation, checkboxes with service names
    currentClient: {
        id: 0,
        name: '',
        email: '',
        status: '',
        config_link: '',
        date_created: '',
        min_status_value: ''

    },
    currentClientServices: [], // services on the current client page, when we open client by clicking on row on the client table
    currentClientName: '',
    isClientLinkResending: false,
    clientsLinkActivities: [],
    checkedGoogleServiceIds: [1, 2, 3],
    resendLinkMode: false,
    clientSearchValue: '',
    isGoogleCheckboxTouched: false,
    client: {
        id: 0,
        name: '',
        email: '',
        status: '',
        config_link: '',
        date_created: '',
        min_status_value: '',
        credits: 0
    },
    clientSelectButtonValue: '',
    integratedClientServices: [],
    gridRowsCount: 0
}

export const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },
        setClientServices: (state, action: PayloadAction<{ service: string, serverGooglePermissions?: number[], isResend: boolean }>) => {
            if (!state.clientServices.includes(action.payload.service)) {
                if (action.payload.service === 'Google') {
                    state.clientServices.push(action.payload.service)
                    state.checkedGoogleServiceIds = [1, 2, 3]
                } else {
                    state.clientServices.push(action.payload.service)
                }

            } else {
                if (action.payload.service === 'Google') {
                    state.clientServices = state.clientServices.filter((s: string) => s !== action.payload.service)
                    if (action.payload.isResend && action.payload.serverGooglePermissions) {
                        state.checkedGoogleServiceIds = action.payload.serverGooglePermissions
                    } else {
                        state.checkedGoogleServiceIds = []
                    }
                } else {
                    state.clientServices = state.clientServices.filter((s: string) => s !== action.payload.service)
                }

            }
        },
        setCurrentClient: (state, action: PayloadAction<TableClientType>) => {
            state.currentClient = action.payload
        },
        setClientForIntegrationLinkCreation: (state, action: PayloadAction<TableClientType>) => {
            state.clientForIntegrationLinkCreation = action.payload
        },
        setCurrentClientName: (state, action: PayloadAction<any>) => {
            state.currentClientName = action.payload
        },
        setIsClientLinkResending: (state, action: PayloadAction<boolean>) => {
            state.isClientLinkResending = action.payload
        },
        onSetClientServices: (state, action: PayloadAction<string[]>) => {
            state.clientServices = action.payload
        },
        onAddClientService: (state, action: PayloadAction<string>) => {
            state.clientServices.push(action.payload)
        },
        onCheckGoogleServiceIds: (state, action: PayloadAction<number>) => {
            if (!state.clientServices.includes('Google')) {
                state.clientServices.push('Google')
            }
            if (!state.checkedGoogleServiceIds.includes(action.payload)) {
                state.checkedGoogleServiceIds.push(action.payload)
            } else {
                state.checkedGoogleServiceIds = state.checkedGoogleServiceIds.filter((s: number) => s !== action.payload)
            }
        },
        onSetGoogleServiceIds: (state, action: PayloadAction<number[]>) => {
            state.checkedGoogleServiceIds = action.payload
        },
        onSetResetLinkMode: (state, action: PayloadAction<boolean>) => {
            state.resendLinkMode = action.payload
        },
        onChangeSearchValue: (state, action: PayloadAction<string>) => {
            state.clientSearchValue = action.payload
        },
        onClearCurrentClientServices: (state) => {
            state.currentClientServices = []
        },
        onSetIsGoogleCheckboxTouched: (state, action: PayloadAction<boolean>) => {
            state.isGoogleCheckboxTouched = action.payload
        },
        onClearSearchValue: (state) => {
            state.clientSearchValue = ''
        },
        onSetClientSelectButtonValue: (state,  action: PayloadAction<string | undefined>) => {
            state.clientSelectButtonValue = action.payload
        },
        onAddIntegratedClientService: (state, action: PayloadAction<string>) => {
            state.integratedClientServices.push(action.payload)
        },
        onSetClientsTotalCount: (state, action: PayloadAction<number>) => {
            state.clients.total_count = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetClientsThunk.fulfilled, (state, action) => {
                state.clients.clients = action.payload.clients
                state.clients.total_count = action.payload.total_count
            })
            .addCase(CreateNewClientThunk.fulfilled, (state, action) => {
                state.clientForIntegrationLinkCreation = action.payload
            })
            .addCase(GetClientServicesThunk.fulfilled, (state, action) => {
                state.currentClientServices = action.payload
            })
            .addCase(GetClientsTableActivitiesLinksThunk.fulfilled, (state, action) => {
                state.clientsLinkActivities = action.payload
            })
            .addCase(GetClientThunk.fulfilled, (state, action) => {
                state.client = action.payload
            })

    }
})

export const {
    setCurrentPage,
    setClientServices,
    setCurrentClient,
    setClientForIntegrationLinkCreation,
    setCurrentClientName,
    setIsClientLinkResending,
    onSetClientServices,
    onCheckGoogleServiceIds,
    onSetGoogleServiceIds,
    onSetResetLinkMode,
    onChangeSearchValue,
    onClearCurrentClientServices,
    onSetIsGoogleCheckboxTouched,
    onClearSearchValue,
    onAddClientService,
    onSetClientSelectButtonValue,
    onAddIntegratedClientService,
    onSetClientsTotalCount
} = clientSlice.actions

export const selectClients = (state: RootState): TableClientType[] | [] => state.client.clients.clients
export const selectClientsTotalCount = (state: RootState): number => state.client.clients.total_count
export const selectCurrentPage = (state: RootState): number => state.client.currentPage
export const selectClientForIntegrationLinkCreation = (state: RootState): TableClientType => state.client.clientForIntegrationLinkCreation
export const selectClientServices = (state: RootState): string[] => state.client.clientServices
export const selectCurrentClient = (state: RootState): TableClientType => state.client.currentClient
export const selectCurrentClientServices = (state: RootState): ClientServiceType[] => state.client.currentClientServices
export const selectCurrentClientName = (state: RootState): string => state.client.currentClientName
export const selectIsClientLinkResending = (state: RootState): boolean => state.client.isClientLinkResending
export const selectClientsTableActivitiesLinks = (state: RootState): ActivityType[] => state.client.clientsLinkActivities
export const selectCheckedGoogleServiceIds = (state: RootState): number[] => state.client.checkedGoogleServiceIds
export const selectResendLinkMode = (state: RootState): boolean => state.client.resendLinkMode
export const selectSearchValue = (state: RootState): string => state.client.clientSearchValue
export const selectIsGoogleCheckboxTouched = (state: RootState): boolean => state.client.isGoogleCheckboxTouched
export const selectClient = (state: RootState): TableClientType => state.client.client
export const selectClientSelectButtonValue = (state: RootState): string | undefined => state.client.clientSelectButtonValue
export const selectGridRowsCount = (state: RootState): number => state.client.gridRowsCount

export const GetClientsThunk = createAsyncThunk<TableClientsType, FormData, AsyncThunkConfig>(
    'clients/getClientsThunk',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await clientApi.getClients(requestData)
            if (status === 200 && data) {
                console.log(data)
                thunkAPI.dispatch(onSetClientsTotalCount(data.total_count))
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const CreateNewClientThunk = createAsyncThunk<TableClientType, any, AsyncThunkConfig>(
    'clients/createNewClientThunk',
    async (requestData, thunkAPI) => {
        try {

            const {status, data} = await clientApi.createClient(requestData)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        // finally {
        //     thunkAPI.dispatch(GetClientsThunk(requestData.tableData))
        // }
    }
)

export const UpdateClientThunk = createAsyncThunk<TableClientType, { client: FormData, tableData?: FormData, clientId: number }, AsyncThunkConfig>(
    'clients/updateClientThunk',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await clientApi.updateClient(requestData.client, requestData.clientId)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {


            thunkAPI.dispatch(GetClientThunk(requestData.clientId))
        }
    }
)

export const UpdateClientLogoThunk = createAsyncThunk<TableClientType, { client: any, clientId: number }, AsyncThunkConfig>(
    'clients/updateClientLogoThunk',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await clientApi.updateClient(requestData.client, requestData.clientId)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {


            thunkAPI.dispatch(GetClientThunk(requestData.clientId))
        }
    }
)

export const GetClientServicesThunk = createAsyncThunk<ClientServiceType[], number, AsyncThunkConfig>(
    'clients/getClientServicesThunk',
    async (client_id, thunkAPI) => {
        try {
            const {status, data} = await clientApi.getClientConfigs(client_id)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.services, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const GetClientsTableActivitiesLinksThunk = createAsyncThunk<ActivityType[], void, AsyncThunkConfig>(
    'clients/getClientsTableActivitiesLinks',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await activitiesApi.getAllLinkActivities()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.link_activities, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const GetClientThunk = createAsyncThunk<TableClientType, number, AsyncThunkConfig>(
    'clients/getClientThunk',
    async (client_id, thunkAPI) => {
        try {
            const {status, data} = await clientApi.getClient(client_id)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export default clientSlice.reducer