import React, {useEffect} from 'react';
import s from "./PrivatePanel.module.scss";
import {Button, Form, Input} from "antd";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useNavigate} from "react-router";
import {
    PrivatePanelLoginThunk,
    selectPrivatePanelClientIsLoggedIn,
    setPrivatePanelIsLoggedIn
} from "../../store/privatePanelReducer";
import {userDataHelper} from "../../helpers/localStorageHelper";
const privatePanelHeaderLogo = require('../../img/icons/privatePanelLogo.svg')
const privatePanelMockUp = require('../../img/icons/privatePanelMockUp.png')
const PrivatePanelSignIn = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const isLoggedIn = useAppSelector(selectPrivatePanelClientIsLoggedIn)
    const userData = userDataHelper.getAdminData()
    useEffect(() => {
        if(userData){
            dispatch(setPrivatePanelIsLoggedIn(true))
        }
    }, [dispatch, userData])

    const onFinish = (loginData: {email: string, password: string}) => {
        dispatch(PrivatePanelLoginThunk(loginData))
        userDataHelper.setIsAdminUserData(true)
    }

    if (isLoggedIn) {
        navigate('/privatePanelTable')
    }

    return (
        <div className={s.inner}>
            <div className={s.inner__left}>
                <div className={s.logo}>
                    <privatePanelHeaderLogo.ReactComponent />
                </div>
                <div className={s.form}>
                    <div className={s.form__content}>
                        <h1>Login</h1>
                        <p>Welcome back! Please enter your details.</p>
                        <Form
                            name='privatePanelSignIn'
                            onFinish={onFinish}
                            autoComplete='off'
                            validateTrigger='onBlur'
                            className={'privatePanelSignIn'}
                        >
                            <div className={s.form__label}>
                                Email
                            </div>
                            <Form.Item
                                name='email'
                                rules={[
                                    { required: true, message: 'Please enter your email!' },
                                    { type: 'email', message: 'Invalid e-mail!' },
                                ]}
                            >
                                <Input
                                    placeholder='Enter your email'
                                    className={s.form__input}
                                />
                            </Form.Item>

                            <div className={s.form__label}>
                                Password
                            </div>
                            <Form.Item
                                name='password'
                                rules={[ { required: true, message: 'Please enter your password!' } ]}
                            >
                                <Input.Password
                                    placeholder='Enter your password'
                                    className={s.form__input}
                                />
                            </Form.Item>
                            <Form.Item style={{
                                marginBottom: '0px'
                            }}>
                                <Button
                                    type='primary'
                                    htmlType='submit'
                                    className={s.form__submit}
                                >
                                    Sign in
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
            <div className={s.inner__right}>
                <div>
                    <img src={privatePanelMockUp} alt="mockup_img"/>
                </div>
            </div>
        </div>
    );
};

export default PrivatePanelSignIn;