import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AsyncThunkConfig, RootState} from './store';
import {agencyApi} from '../api/api';
import {AppStatusType} from './appStatusReducer';

interface AgencyType {
    name: string,
    logo_url: string,
    credits: number
}

interface InitialStateType {
    agencyData: AgencyType
}

const initialState:InitialStateType = {
    agencyData: {} as AgencyType,
}

export const agencySlice = createSlice({
    name: 'agency',
    initialState,
    reducers: {
        setAgencyData: (state, action: PayloadAction<AgencyType>) => {
            state.agencyData = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAgencyDataThunk.fulfilled, (state, action) => {
                state.agencyData = action.payload
            })
            .addCase(EditAgencyThunk.fulfilled, (state, action) => {
                state.agencyData = action.payload
            })
            
    }
})

export const { setAgencyData } = agencySlice.actions

export const selectAgencyData = (state: RootState): AgencyType => state.agency.agencyData

export const GetAgencyDataThunk = createAsyncThunk<AgencyType, void, AsyncThunkConfig>(
    'agency/getAgencyData',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await agencyApi.getAgencyData()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            }
            else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            console.log(error)
            return thunkAPI.fulfillWithValue({}, {appStatus: AppStatusType.idle})
        }
    }
)

export const EditAgencyThunk = createAsyncThunk<AgencyType, any, AsyncThunkConfig>(
    'agency/editAgency',
    async (agencyData, thunkAPI) => {
        try {
            const {status, data} = await agencyApi.editAgency(agencyData)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'All changes has been successfully saved'})
            }
            else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            console.log(error)
            return thunkAPI.fulfillWithValue({}, {appStatus: AppStatusType.idle})
        }
    }
)

export default agencySlice.reducer
