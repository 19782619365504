import React, {useEffect, useState} from 'react';
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import s from "../FacebookLinksTable/FacebookLinksTable.module.scss";
import PageTitle from "../PageTitle/PageTitle";
import RequirementSearchInput from "../RequirementSearchInput/RequirementSearchInput";
import {Box} from "@material-ui/core";
import {DataGridPro, useGridApiRef} from "@mui/x-data-grid-pro";
import {useNavigate} from "react-router";
import ActivityFilters from "./ActivityFilters/ActivityFilters";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
    GetActivitiesLinksThunk,
} from "../../store/activityTrackerReducer";
import {
    GetAllServicesLinksMultiThunk,
    selectAllServicesLinks
} from "../../store/linksReducer";
import {activityDataHelper} from "../../helpers/localStorageHelper";
import moment from "moment/moment";

const facebookIcon = require('../../img/icons/facebook-logo-small.svg')
const googleIcon = require('../../img/icons/google-logo-small.svg')
const tiktokIcon = require('../../img/icons/tiktok-logo-small.svg')

const ActivityMulti = () => {
    return (
        <PageWithSidebar>
            <ActivityComponentMulti/>
        </PageWithSidebar>
    )
};

// types of services for switching current service view in table
export type serviceFilters = 'View all' | 'Facebook' | 'Google' | 'TikTok'
const ActivityComponentMulti = () => {
    const dispatch = useAppDispatch()
    const [currentServiceFilter, setCurrentServiceFilter] = useState<serviceFilters>('View all')
    const [screenWidth, setScreenWidth] = useState<number>(0)
    const navigate = useNavigate()
    const [visibleRowsCount, setVisibleRowsCount] = useState(0)
    let tableLinks = useAppSelector(selectAllServicesLinks)
    // eslint-disable-next-line
    const [visibleRows, setVisibleRows] = useState<any[]>([])
    useEffect(() => {
        const linkId = activityDataHelper.getActivityCurrentLinkId()
        dispatch(GetAllServicesLinksMultiThunk(linkId))
    }, [dispatch])

    useEffect(() => {
        if (currentServiceFilter !== 'View all') {
            // eslint-disable-next-line
            tableLinks = tableLinks.filter((l: any) => l.service_type.toLowerCase() === currentServiceFilter.toLowerCase())
            setVisibleRowsCount(tableLinks.length)
            setVisibleRows(tableLinks)
        } else {
            tableLinks = tableLinks?.filter((l: any) => l.service_type.toLowerCase())
            setVisibleRowsCount(tableLinks?.length)
            setVisibleRows(tableLinks)
        }
        // eslint-disable-next-line
    }, [tableLinks, currentServiceFilter])


    //handle current width screen for media queries
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const apiRef = useGridApiRef();


    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: screenWidth > 768 ? 1 : 0.6,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
        },
        {
            field: 'service_type',
            headerName: 'Service',
            flex: 0.45,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            renderCell: (data: any) => {
                if (data.row.service_type.toLowerCase() === 'facebook') {
                    return <facebookIcon.ReactComponent style={{marginLeft: '10px'}}/>
                } else if (data.row.service_type.toLowerCase() === 'google') {
                    return <googleIcon.ReactComponent style={{marginLeft: '10px'}}/>
                } else if (data.row.service_type.toLowerCase() === 'tiktok') {
                    return <tiktokIcon.ReactComponent style={{marginLeft: '10px'}}/>
                } else {
                    return (
                        <div className={s.servicesIcons}>
                            <div className={s.servicesIcons__icon}>
                                <facebookIcon.ReactComponent/>
                            </div>
                            <div className={s.servicesIcons__icon} style={{marginLeft: '-5px'}}>
                                <googleIcon.ReactComponent/>
                            </div>
                            <div className={s.servicesIcons__icon} style={{marginLeft: '-5px'}}>
                                <tiktokIcon.ReactComponent/>
                            </div>
                        </div>
                    )
                }
            }
        },
        {
            field: 'url',
            headerName: 'Link URL',
            flex: screenWidth > 1160 ? 2 : 1,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            renderCell: (data: any) => (
                <div style={{color: '#667085', marginLeft: '10px'}}>
                    <span style={{display: screenWidth < 768 ? 'none' : 'block'}}>{data.row.url}</span>
                    <span style={{display: screenWidth >= 768 ? 'none' : 'block'}}>Link</span>
                </div>
            )
        },
        {
            field: 'last_access',
            headerName: 'Last accessed',
            flex: 0.6,
            disableExport: true,
            renderCell: (data: any) => (<div
                style={{
                    color: '#667085',
                    marginLeft: '10px',
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '22px',
                    cursor: 'pointer'
                }}>
                {data.row.last_access ? moment(data.row.last_access, "DD-MM-YYYY HH:mm:ss").format('DD MMM YYYY') : ''}
            </div>)
        },
    ]


    const onRowClick = (data: any) => {
        dispatch(GetActivitiesLinksThunk({link_id: data.row.id, platform_name: data.row.service_type}))
        activityDataHelper.setActivityCurrentServiceUrl(data.row.url)
        activityDataHelper.setActivityCurrentServiceNameAndAgentName(`${data.row.name} - ${data.row.service_type}`)
        navigate('/activity/activityCurrentLinkMulti')
    }


    return (
        <div
            style={{
                padding: screenWidth > 1160 ? '32px' : screenWidth > 480 ? '10px 20px' : '10px 8px'
            }}>
            <Breadcrumbs/>
            <div className={s.inner__header}>
                <PageTitle
                    title='Table of activity tracker'
                    description='Check link activity'
                    backLink={'/activity'}
                />
            </div>
            <div style={{
                display: 'flex',
                justifyContent: "space-between",
                flexDirection: screenWidth > 660 ? 'row' : 'column'
            }}>
                <ActivityFilters
                    currentActivityFilter={currentServiceFilter}
                    setCurrentActivityFilter={(filter) => setCurrentServiceFilter(filter)}
                />
                <RequirementSearchInput
                    apiRef={apiRef}
                    setVisibleRowsCount={(count: number) => setVisibleRowsCount(count)}
                    setVisibleRows={(rows: any[]) => setVisibleRows(rows)}
                    allRows={tableLinks}
                />
            </div>

            <div className={s.tableInner}>
                <h2>
                    All links <span>{visibleRowsCount} links</span>
                </h2>
                <Box>
                    <DataGridPro
                        sx={{
                            borderRight: 'none',
                            borderLeft: 'none',
                            borderBottom: 'none',
                            borderRadius: '0px',
                            borderTop: '1px solid transparent',
                            "& .MuiDataGrid-columnHeader": {
                                background: '#F9FAFB',
                                padding: '0px 20px',
                                minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                            },
                            "& .MuiDataGrid-columnHeader:nth-child(3)": {
                                background: '#F9FAFB',
                                padding: '0px 20px',
                                // minWidth: "540px !important"
                            },
                            "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                display: "none"
                            },
                            "& .MuiDataGrid-cell":{
                                minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                            },
                            "& .MuiDataGrid-cell:nth-child(3)":{
                                // minWidth: "540px !important"
                            },
                            "& .MuiDataGrid-cellContent": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#111827',
                                padding: '0px 10px',
                                // minWidth: '540px'
                            },
                            "& .MuiDataGrid-cellContent:nth-child(3)": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#111827',
                                padding: '0px 10px',
                                // minWidth: '540px'
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 600,
                                color: '#6B7280',
                                fontSize: '12px',
                                lineHeight: '20px',
                            },
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-row.Mui-selected": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-row.Mui-selected:hover": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-cell:focus": {
                                outline: 'none'
                            },
                            "& .MuiDataGrid-toolbarContainer": {
                                color: '#454545',
                                position: 'absolute',
                                top: '-62px',
                                right: '0px'
                            },
                            "& .MuiButtonBase-root": {
                                fontFamily: 'Inter, sans-serif !important',
                                fontWeight: 600,
                                color: '#6B7280',
                                fontSize: '12px',
                                lineHeight: '20px',
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginBottom: 0
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                overflowY: 'scroll',
                                overflowX: 'scroll !important',
                            },
                        }}
                        apiRef={apiRef}
                        columns={columns}
                        rows={visibleRows}
                        {...visibleRows}
                        rowHeight={58}
                        getRowId={e => e.url}
                        headerHeight={39}
                        pagination={true}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        hideFooterSelectedRowCount
                        autoHeight={true}
                        autoPageSize={true}
                        onRowClick={onRowClick}
                    />
                </Box>
            </div>
        </div>
    )
}

export default ActivityMulti;
