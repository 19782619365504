import {Button, Checkbox, Form, message, Spin, Tooltip} from 'antd';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router";
import {authApi} from "../../api/api";
import {clientLoginDataHelper, userMultiStepHelper} from "../../helpers/localStorageHelper";
import {useAppDispatch, useAppSelector} from "../../hooks";
import s from './Auth.module.scss'
import {
    LoginClientThunk,
    onChangeAddResourcesCheckbox,
    onChangeBusinessResourcesCheckbox,
    onChangeBusinessResourcesInstagramCheckbox,
    onChangeBusinessResourcesPixelsCheckbox, onChangeCatalogResourcesCheckbox,
    onChangePagesResourcesCheckbox,
    selectClientAddAccounts,
    selectClientBusinessAccounts,
    selectClientData,
    selectClientPages,
    selectClientPagesCatalogs,
} from "../../store/userReducer";

import {QuestionCircleOutlined} from "@ant-design/icons";
import {
    GetPermissionConfigurationConfigByIdThunk,
    selectCurrentConfiguration
} from "../../store/permissionConfigurationReducer";
import AnimatedSignInBtn from './AnimatedSignInBtn';
import {CreateActivityLinkThunk} from "../../store/activityTrackerReducer";
import {GetAgencyDataThunk, selectAgencyData} from "../../store/agencyReducer";

const oneclickLogo = require('../../assets/img/oneclick-logo.svg')
const authPageDecoration = require('../../img/authPageDecoration.svg')

const SignInClient = () => {
    const dispatch = useAppDispatch()
    const clientData = useAppSelector(selectClientData)
    let clientAddAccounts = useAppSelector(selectClientAddAccounts)
    let clientBusinessAccounts = useAppSelector(selectClientBusinessAccounts)
    let clientAccountPages = useAppSelector(selectClientPages)
    let clientPagesCatalogs = useAppSelector(selectClientPagesCatalogs)
    const currentConfiguration = useAppSelector(selectCurrentConfiguration)
    const [form] = Form.useForm()
    const isMultiStepAuth = userMultiStepHelper.getIsUserMultiStepLogin()
    const multiStepLinksArrLength = userMultiStepHelper.getUserMultiStepLinksArrLength()
    const currentMultiStepIndex = userMultiStepHelper.getUserMultiStepCurrentIndex()
    const [agencyName, setAgencyName] = useState('')
    const [redirectUrl, setRedirectUrl] = useState('')
    const agencyData = useAppSelector(selectAgencyData)
    useEffect(() => {
        form.setFieldsValue(clientData?.resources)
    }, [form, clientData])

    const {id} = useParams<{ id: string }>()
    const navigate = useNavigate()
    // eslint-disable-next-line
    let code = location.search.split('code=')[1]
    code = code?.split('&')[0]
    // eslint-disable-next-line
    let redirect_uri = ''
    // eslint-disable-next-line
    // let configId = ''
    useEffect(() => {
        if(id && id !== 'login'){
            clientLoginDataHelper.setConfigId(id)
        }
    },[id])

    useEffect(() => {
        dispatch(GetAgencyDataThunk())
    }, [dispatch])

    useEffect(() => {
        if (id && !isMultiStepAuth) {
            const configId = clientLoginDataHelper.getConfigId()
            authApi.getPermissionConfigurationLinkForLogin(configId)
                .then((res) => {
                    // eslint-disable-next-line
                    redirect_uri = res.data.login_link.split('redirect_uri=')[1]
                    // eslint-disable-next-line
                    // configId = res.data.login_link.split('config_id=')[1]
                    // configId = configId.split('}')[0]
                    redirect_uri = redirect_uri.split('&')[0]
                    clientLoginDataHelper.setRedirectUri(redirect_uri)
                    setRedirectUrl(res.data.login_link)
                    setAgencyName(res.data.agency_name)
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }, [id])

    useEffect(() => {
        if (code && !clientData && !isMultiStepAuth) {
            const redirect_uri = clientLoginDataHelper.getRedirectUri()
            const configId = clientLoginDataHelper.getConfigId()
            // eslint-disable-next-line
            dispatch(LoginClientThunk({
                authorization_code: `${code}#_=_`, // ok
                redirect_url: redirect_uri,
                config_id: Number(configId) // not ok
            }))
            dispatch(GetPermissionConfigurationConfigByIdThunk(configId))
        }
    }, [dispatch, code, clientData, redirect_uri, isMultiStepAuth])

    useEffect(() => {
        if (isMultiStepAuth) {
            const redirect_uri = userMultiStepHelper.getUserMultiStepLinkFacebookRedirectUri()
            const configId = userMultiStepHelper.getUserMultiStepLinkFacebookConfigId()
            dispatch(LoginClientThunk({
                authorization_code: `${code}#_=_`,
                redirect_url: redirect_uri,
                config_id: Number(configId)
            }))
            dispatch(GetPermissionConfigurationConfigByIdThunk(configId))
        }
    }, [multiStepLinksArrLength, code, dispatch, isMultiStepAuth])

    const onLogin = () => {
        window.location.replace(redirectUrl)
    }
    const onFinish = () => {
        clientAddAccounts = clientAddAccounts?.filter((a: any) => a.checked === true)
        clientAddAccounts = clientAddAccounts?.map((obj: any) => {
            const {checked, ...rest} = obj; // using object destructuring to remove the checked property
            return rest;
        });
        clientBusinessAccounts = clientBusinessAccounts?.filter((a: any) => a.checked === true)
        clientBusinessAccounts = clientBusinessAccounts?.map((obj: any) => {
            const {checked, ...rest} = obj; // using object destructuring to remove the checked property
            return rest;
        });
        clientAccountPages = clientAccountPages?.filter((a: any) => a.checked === true)
        clientAccountPages = clientAccountPages?.map((obj: any) => {
            const {checked, ...rest} = obj; // using object destructuring to remove the checked property
            return rest;
        });
        let pixels = clientBusinessAccounts?.map((m: any) => m.pixels).flat(1)
        pixels = pixels[0] !== null && pixels !== null ? pixels.filter((a: any) => a.checked === true) : []
        pixels = pixels?.map((obj: any) => {
            const {checked, ...rest} = obj; // using object destructuring to remove the checked property
            return rest;
        });
        let instagrams = clientBusinessAccounts?.map((m: any) => m.instagrams ? m.instagrams : null).flat(1)
        instagrams = instagrams[0] !== null && instagrams !== null ? instagrams.filter((a: any) => a.checked === true) : []
        instagrams = instagrams?.map((obj: any) => {
            const {checked, ...rest} = obj; // using object destructuring to remove the checked property
            return rest;
        });
        let catalogs = clientBusinessAccounts?.map((m: any) => m.catalogs ? m.catalogs : null).flat(1)
        catalogs = catalogs[0] !== null && catalogs !== null ? catalogs.filter((a: any) => a.checked === true) : []
        catalogs = catalogs?.map((obj: any) => {
            const {checked, ...rest} = obj; // using object destructuring to remove the checked property
            return rest;
        });
        const config_id = clientLoginDataHelper.getConfigId()
        const configId = userMultiStepHelper.getUserMultiStepLinkFacebookConfigId()
        const requestBody = {
            user_id: clientData.user_id,
            access_token: clientData.access_token,
            config_id: !isMultiStepAuth ? config_id : configId,
            resources: {
                ad_accounts: clientAddAccounts.length ? clientAddAccounts : null,
                pixels: pixels.length && pixels[0] !== null ? pixels : null,
                instagrams: instagrams.length && instagrams[0] !== null ? instagrams : null,
                catalogs: catalogs.length && catalogs[0] !== null ? catalogs : null,
                pages: clientAccountPages.length ? clientAccountPages.flat(1) : null

            }
        }

        authApi.facebookClientLoginGrantAccess(requestBody)
            .then((res) => {
                console.log(res)
            })
            .then(() => {
                if (isMultiStepAuth) {
                    userMultiStepHelper.setUserMultiStepIsFacebookItem(true)
                    if (Number(currentMultiStepIndex) !== Number(multiStepLinksArrLength - 1)) {
                        userMultiStepHelper.setUserMultiStepCurrentIndex(currentMultiStepIndex + 1)
                        navigate('/multiServiceRedirectSteps')
                    } else {
                        navigate('/multiServiceLoginSuccess')
                    }
                } else {
                    navigate('/client-login-success')
                }
                const req = {
                    link_id: !isMultiStepAuth ? config_id : configId,
                    platform_name: 'FACEBOOK',
                    user_name: clientData.user_name,
                    user_email: clientData.email,
                }
                dispatch(CreateActivityLinkThunk(req))
            })
            .catch((e) => {
                console.log(e)
                message.error(e)
            })
    }


    const onChange = (e: any, id: number, business_acc_id?: number, pixel?: any, instagram?: any, catalog?: any) => {
        dispatch(onChangeAddResourcesCheckbox({checked: e.target.checked, id}))
        dispatch(onChangeBusinessResourcesCheckbox({checked: e.target.checked, id}))
        dispatch(onChangePagesResourcesCheckbox({checked: e.target.checked, id}))
        if (business_acc_id && instagram !== null) {
            dispatch(onChangeBusinessResourcesInstagramCheckbox({checked: e.target.checked, id, business_acc_id}))
        }
        if (business_acc_id && pixel !== null) {
            dispatch(onChangeBusinessResourcesPixelsCheckbox({checked: e.target.checked, id, business_acc_id}))
        }
        if (business_acc_id && catalog !== null) {
            dispatch(onChangeCatalogResourcesCheckbox({checked: e.target.checked, id, business_acc_id}))
        }
    }

    return (
        <>
            {
                code
                    ?
                    <div className={s.inner}>
                        <authPageDecoration.ReactComponent className={s.inner__decoration}/>
                        <Form
                            name='signInClients'
                            onFinish={onFinish}
                            autoComplete='off'
                            form={form}
                            validateTrigger='onBlur'
                            initialValues={clientData?.resources}
                            className={s.formBlock}
                            style={{
                                height: '100%',
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            {
                                agencyData.logo_url && agencyData.logo_url !== null
                                    ?
                                    <img src={agencyData.logo_url} alt="logo"/>
                                    :
                                    <oneclickLogo.ReactComponent/>
                            }
                            {
                                clientData
                                    ?
                                    <>
                                        {currentConfiguration
                                            &&
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                                {
                                                    clientData?.logo_url
                                                        ?
                                                        <div style={{
                                                            height: '80px',
                                                            width: '80px'
                                                        }}>
                                                            <img
                                                                src={clientData?.logo_url}
                                                                alt="agency-logo"
                                                                style={
                                                                    {
                                                                        objectFit: "contain",
                                                                        height: '100%',
                                                                        width: '100%'
                                                                    }
                                                                }/>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <h1 style={{fontSize: '24px', lineHeight: '28px', marginBottom: '40px'}}>
                                                    {agencyData.name && agencyData.name !== null ? agencyData.name : 'Agency'}
                                                    asking your Facebook account
                                                    permissions:
                                                </h1>
                                            </div>
                                        }
                                        {clientPagesCatalogs.length
                                            ?
                                            <div className={s.clientForm__item}>
                                                <div className={s.clientForm__tooltip}>
                                                    <p className={s.clientForm__title}>Catalogs:</p>
                                                    <Tooltip
                                                        title={'Granting access to your facebook catalogs'}
                                                    >
                                                        <QuestionCircleOutlined/>
                                                    </Tooltip>
                                                </div>
                                                <div className={s.clientForm__items}>
                                                    {clientPagesCatalogs?.map((i: any) => {
                                                        return (
                                                            <Checkbox
                                                                // onChange={(e) => onChange(e, i.id)}
                                                                value={i.id}
                                                                checked={true}
                                                                defaultChecked={true}
                                                            >
                                                                {i.name}
                                                            </Checkbox>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                        {clientAddAccounts.length
                                            ?
                                            <div className={s.clientForm__item}>
                                                <div className={s.clientForm__tooltip}>
                                                    <p className={s.clientForm__title}>Ad accounts:</p>
                                                    <Tooltip
                                                        title={'Granting access to your facebook ad accounts'}
                                                    >
                                                        <QuestionCircleOutlined/>
                                                    </Tooltip>
                                                </div>
                                                <div className={s.clientForm__items}>
                                                    {clientAddAccounts?.map((i: any) => {
                                                        return (
                                                            <Checkbox
                                                                onChange={(e) => onChange(e, i.id)}
                                                                value={i.id}
                                                                checked={i.checked === true}
                                                                defaultChecked={true}
                                                            >
                                                                {i.name}
                                                            </Checkbox>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                        {clientBusinessAccounts.length
                                            ?
                                            <div className={s.clientForm__item}>
                                                <div className={s.clientForm__tooltip}>
                                                    <p
                                                        className={s.clientForm__title}
                                                        style={{
                                                            marginBottom: '0px'
                                                        }}
                                                    >
                                                        Businesses:
                                                    </p>
                                                    <Tooltip
                                                        title={'Granting access to your facebook business pages (including instagram pages and pixels)'}
                                                    >
                                                        <QuestionCircleOutlined/>
                                                    </Tooltip>
                                                </div>
                                                <div className={s.clientForm__items}>
                                                    {clientBusinessAccounts?.map((i: any) => {
                                                        return (
                                                            <>
                                                                <p>Business page name:</p>
                                                                <Checkbox
                                                                    onChange={(e) => onChange(e, i.id)}
                                                                    value={i.id}
                                                                    checked={i.checked === true}
                                                                    defaultChecked={true}
                                                                >
                                                                    {i.name}
                                                                </Checkbox>
                                                                {
                                                                    i.instagrams !== null && i.instagrams[0] !== null
                                                                    &&
                                                                    <div className={s.clientForm__items}>
                                                                        <p className={s.clientForm__subtitle}>Instagram
                                                                            accounts:</p>
                                                                        {i?.instagrams?.map((m: any) => {
                                                                            return (
                                                                                <Checkbox
                                                                                    onChange={(e) => onChange(e, m.id, i.id, i.pixels, i.instagrams, i.catalogs)}
                                                                                    value={m?.id}
                                                                                    checked={m.checked === true}
                                                                                    defaultChecked={true}
                                                                                    disabled={i.checked === false}
                                                                                >
                                                                                    {m.name}
                                                                                </Checkbox>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                }
                                                                {
                                                                    i.pixels !== null && i.pixels[0] !== null
                                                                    &&
                                                                    <div className={s.clientForm__items}>
                                                                        <p className={s.clientForm__subtitle}
                                                                           style={{
                                                                               marginTop: '0px'
                                                                           }}>Pixels:</p>
                                                                        {i.pixels.map((p: any) => {
                                                                            return (
                                                                                <Checkbox
                                                                                    onChange={(e) => onChange(e, p.id, i.id, i.pixels, i.instagrams, i.catalogs)}
                                                                                    value={p.id}
                                                                                    checked={p.checked === true}
                                                                                    defaultChecked={true}
                                                                                    disabled={i.checked === false}
                                                                                >
                                                                                    {p.name}
                                                                                </Checkbox>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                }
                                                                {
                                                                    i.catalogs !== null && i.catalogs[0] !== null
                                                                    &&
                                                                    <div className={s.clientForm__items}>
                                                                        <p className={s.clientForm__subtitle}>Catalogs:</p>
                                                                        {i.catalogs.map((p: any) => {
                                                                            return (
                                                                                <Checkbox
                                                                                    onChange={(e) => onChange(e, p.id, i.id, i.pixels, i.instagrams, i.catalogs)}
                                                                                    value={p.id}
                                                                                    checked={p.checked === true}
                                                                                    defaultChecked={true}
                                                                                    disabled={i.checked === false}
                                                                                >
                                                                                    {p.name}
                                                                                </Checkbox>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                }
                                                            </>

                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                        {clientAccountPages.length
                                            ?
                                            <div className={s.clientForm__item}>
                                                <div className={s.clientForm__tooltip}>
                                                    <p className={s.clientForm__title}>Pages:</p>
                                                    <Tooltip
                                                        title={'Granting access to your facebook pages'}
                                                    >
                                                        <QuestionCircleOutlined/>
                                                    </Tooltip>
                                                </div>
                                                <div className={s.clientForm__items}>
                                                    {clientAccountPages?.map((i: any) => {
                                                        return (
                                                            <Checkbox
                                                                onChange={(e) => onChange(e, i.id)}
                                                                value={i.id}
                                                                checked={i.checked === true}
                                                                defaultChecked={true}
                                                            >
                                                                {i.name}
                                                            </Checkbox>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            :
                                            null
                                        }

                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                                className={s.formBlock__submit}
                                                // onClick={onLogin}
                                            >
                                                Grant access
                                            </Button>
                                        </div>
                                    </>
                                    :
                                    <Spin style={
                                        {
                                            margin: '0 auto',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            minHeight: '290px'
                                        }
                                    }
                                    />
                            }
                        </Form>
                    </div>
                    :
                    <div className={s.inner}>
                        <authPageDecoration.ReactComponent className={s.inner__decoration}/>
                        <div className={s.formBlock} style={{width: '540px'}}>
                            {
                                agencyData.logo_url && agencyData.logo_url !== null
                                    ?
                                    <img src={agencyData.logo_url} alt="logo"/>
                                    :
                                    <oneclickLogo.ReactComponent/>
                            }
                            {!!agencyName.length ? (
                                <>
                                    <h1 style={{fontSize: '24px', lineHeight: '28px', marginBottom: '40px'}}>
                                        {agencyName} requests permission to access your <span
                                        style={{fontWeight: 700, color: '#2653ed'}}>Facebook Business Centers</span>, please
                                        sign in
                                    </h1>
                                    <AnimatedSignInBtn onLogin={onLogin}/>
                                </>
                            ) : (
                                <Spin/>
                            )}
                        </div>
                    </div>
            }
            <p className={'powered'}>powered by OneClickOnboard</p>
        </>

    );
};

export default SignInClient;
