import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {LinkActivityReqData, tikTokIntegration} from "../../api/api";
import {clientLoginDataHelper, userMultiStepHelper} from "../../helpers/localStorageHelper";
import {
    LoginTikTokClientThunk,
    selectTikTokClientData
} from "../../store/userReducer";
import {Form, Spin} from "antd";
import s from "./Auth.module.scss";
import AnimatedSignInBtn from './AnimatedSignInBtn';
import { UpdateActivityLinkThunk} from "../../store/activityTrackerReducer";

const oneclickLogo = require('../../assets/img/oneclick-logo.svg')
const authPageDecoration = require('../../img/authPageDecoration.svg')

const TikTokSignInClient = () => {
    const dispatch = useAppDispatch()
    const tikTokClientData = useAppSelector(selectTikTokClientData)
    const {id} = useParams<{ id: string }>()
    // eslint-disable-next-line
    let code = location.search.split('code=')[1]
    code = code?.split('&')[0]
    // eslint-disable-next-line
    let redirect_uri = ''
    // const navigate = useNavigate()
    // eslint-disable-next-line
    let linkId = location.search.split('link=')[1]
    const [form] = Form.useForm()
    useEffect(() => {
        form.setFieldsValue(tikTokClientData?.resources)
    }, [form, tikTokClientData])
    const isMultiStepAuth = userMultiStepHelper.getIsUserMultiStepLogin()
    const [agencyName, setAgencyName] = useState('')
    const [redirectUrl, setRedirectUrl] = useState('')
    const [agencyLogo, setAgencyLogo] = useState('')

    useEffect(() => {
        if (id) {
            if(linkId !== undefined){
                clientLoginDataHelper.setActivityLinkId(linkId)
            }
            else {
                clientLoginDataHelper.setActivityLinkId(false)
            }
            const linkActivityReqData: LinkActivityReqData = {
                status: 'OPENED'
            }
            if(!isNaN(Number(linkId))){
                dispatch(UpdateActivityLinkThunk({
                    link_activity_id: Number(linkId),
                    linkActivityReqData: linkActivityReqData
                }))
            }
            clientLoginDataHelper.setConfigId(id)
            tikTokIntegration.getTikTokAccountClientRedirectLink(Number(id))
                .then((res) => {
                    // eslint-disable-next-line
                    redirect_uri = res.data.login_link.split('redirect_uri=')[1]
                    redirect_uri = redirect_uri.split('&')[0]
                    clientLoginDataHelper.setRedirectUri(redirect_uri)
                    setRedirectUrl(res.data.login_link)
                    setAgencyName(res.data.agency_name)
                    setAgencyLogo(res.data.agency_logo)
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }, [id])

    useEffect(() => {
        if (code && !tikTokClientData && !isMultiStepAuth) {
            const id = clientLoginDataHelper.getConfigId()
            // eslint-disable-next-line
            dispatch(LoginTikTokClientThunk({
                authorization_code: code,
                config_id: Number(id)
            }))
        }
    }, [dispatch, code, tikTokClientData, isMultiStepAuth, linkId])

    useEffect(() => {
        if (isMultiStepAuth) {
            const configId = userMultiStepHelper.getUserMultiStepLinkTikTokConfigId()
            dispatch(LoginTikTokClientThunk({
                authorization_code: code,
                config_id: Number(configId)
            }))
        }
    }, [dispatch, isMultiStepAuth, code])

    const onLogin = () => {
        window.location.replace(redirectUrl)
    }

    return (
        <>
            <div className={s.inner}>
                <authPageDecoration.ReactComponent className={s.inner__decoration}/>
                <div className={s.formBlock} style={{width: '540px'}}>
                    {
                        agencyLogo && agencyLogo !== null
                            ?
                            <div style={{
                                maxWidth: '300px',
                                maxHeight: '350px'
                            }}>
                                <img src={agencyLogo} alt="logo" style={{
                                    objectFit: 'contain',
                                    width: '100%',
                                    height: '100%'}
                                }/>
                            </div>
                            :
                            <oneclickLogo.ReactComponent/>
                    }

                    {!!agencyName ? (
                        <>
                            <h1 style={{fontSize: '24px', lineHeight: '28px', marginBottom: '40px'}}>
                                {agencyName} requests permission to access your <span
                                style={{fontWeight: 700, color: '#2653ed'}}>Tik Tok Business Center</span>, please sign
                                in
                            </h1>
                            <AnimatedSignInBtn onLogin={onLogin}/>
                        </>
                    ) : (
                        <Spin/>
                    )}

                </div>
            </div>
            <p className={'powered'}>powered by OneClickOnboard</p>
        </>

    );
};

export default TikTokSignInClient;
