import React from "react";
import s from './CommonModal.module.scss'
import {useAppSelector} from "../../hooks";
import {selectCurrentTutorialStep, selectIsTutorial} from "../../store/tutorialReducer";
import TutorialModal from "../TutorialModal/TutorialModal";


type CommonModalPropsType = {
    active: any
    setActive?: Function
    children: any
    setEditMode?: (editMode: boolean) => void
    height?: string
    width?: string
    additionalTopMargin?: string
    padding?: string
    backgroundColor?: string
    borderRadius?: string
}


const CommonModal = React.memo(({active, children, height, width, additionalTopMargin, padding, backgroundColor, borderRadius}: CommonModalPropsType) => {
    const currentTutorialStep = useAppSelector(selectCurrentTutorialStep)
    const isTutorial = useAppSelector(selectIsTutorial)
    return(
        <div className={active ? `${s.modal} ${s.active}` : s.modal}>
            <div
                // className={active ? s.modalContent + ' ' + s.active : s.modalContent}
                className={active && currentTutorialStep === 'Fourth step - Link creation / Modal' && isTutorial ? `${s.modalContent + ' ' + s.active} ${s.modalContent_tutorial}` : s.modalContent}
                onClick={e => e.stopPropagation()}
                style={{
                    minHeight: height,
                    maxWidth: width,
                    top: additionalTopMargin ? additionalTopMargin : '0',
                    padding: padding ? padding : 'initial',
                    backgroundColor: backgroundColor,
                    borderRadius: borderRadius ? borderRadius : '4px'
                }}
            >
                {children}
            </div>
            {
                isTutorial && currentTutorialStep === 'Fourth step - Link creation / Modal'
                &&
                <TutorialModal/>
            }
        </div>
    )
})

export default CommonModal