import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {tutorialDataHelper, userDataHelper} from "../helpers/localStorageHelper";


interface InitialStateType {
    isTutorial: boolean
    currentTutorialStep:
        'Pre tutorial'
        | 'First step - Agency Setup'
        | 'Second step - Logo'
        | 'Third step - Integrations / Facebook'
        | 'Third step - Integrations / Google'
        | 'Third step - Integrations / TikTok'
        | 'Third step - Integrations / Team members'
        | 'Fourth step - Link creation / Home'
        | 'Fourth step - Link creation / Table'
        | 'Fourth step - Link creation / Modal'
        | 'Fourth step - Link creation / Send link'
        | 'Final step - Congratulations'
    | 'Finished'
    tutorialRole: 'Admin' | 'Manager' | 'SuperAdmin' | string
}

const initialState: InitialStateType = {
    isTutorial: tutorialDataHelper.getIsTutorial(),
    currentTutorialStep: tutorialDataHelper.getCurrentTutorialStep(),
    tutorialRole: userDataHelper && userDataHelper?.getUserData() && userDataHelper?.getUserData()?.roles && userDataHelper?.getUserData()?.roles[0] && userDataHelper?.getUserData()?.roles[0]?.name
}

export const tutorialSlice = createSlice({
    name: 'tutorial',
    initialState,
    reducers: {
        setIsTutorialStarted: (state, action: PayloadAction<boolean>) => {
            state.isTutorial = action.payload
        },
        setCurrentTutorialStep: (state, action: PayloadAction<'Pre tutorial'
            | 'First step - Agency Setup'
            | 'Second step - Logo'
            | 'Third step - Integrations / Facebook'
            | 'Third step - Integrations / Google'
            | 'Third step - Integrations / TikTok'
            | 'Third step - Integrations / Team members'
            | 'Fourth step - Link creation / Home'
            | 'Fourth step - Link creation / Table'
            | 'Fourth step - Link creation / Modal'
            | 'Fourth step - Link creation / Send link'
            | 'Final step - Congratulations'
            | 'Finished'
            >) => {
            state.currentTutorialStep = action.payload
        },
        setTutorialRole: (state, action: PayloadAction<'Admin' | 'Manager' | 'SuperAdmin' | string>) => {
            state.tutorialRole = action.payload
        },
    },
})

export const {
    setIsTutorialStarted,
    setCurrentTutorialStep,
    setTutorialRole

} = tutorialSlice.actions

export const selectIsTutorial = (state: RootState): boolean => state.tutorial.isTutorial
export const selectCurrentTutorialStep = (state: RootState): 'Pre tutorial'
    | 'First step - Agency Setup'
    | 'Second step - Logo'
    | 'Third step - Integrations / Facebook'
    | 'Third step - Integrations / Google'
    | 'Third step - Integrations / TikTok'
    | 'Third step - Integrations / Team members'
    | 'Fourth step - Link creation / Home'
    | 'Fourth step - Link creation / Table'
    | 'Fourth step - Link creation / Modal'
    | 'Fourth step - Link creation / Send link'
    | 'Final step - Congratulations'
    | 'Finished' => state.tutorial.currentTutorialStep
export const selectTutorialRole = (state: RootState): 'Admin' | 'Manager' | 'SuperAdmin' | string => state.tutorial.tutorialRole


export default tutorialSlice.reducer