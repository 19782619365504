import { createSlice, PayloadAction, isRejected, isPending, isFulfilled } from '@reduxjs/toolkit'
import { fulfilledMetaType, RootState } from './store'

export enum AppStatusType {'idle', 'loading', 'succeeded', 'failed'}

interface InitialStateType {
  appStatus: AppStatusType,
  errorMessage: string | null,
  successMessage: string | null,
}

const initialState: InitialStateType = {
  appStatus: AppStatusType.idle,
  errorMessage: null,
  successMessage: null,
}

export const appStatusSlice = createSlice({
  name: 'appStatus',
  initialState,
  reducers: {
    setAppStatus: (state, action: PayloadAction<AppStatusType>) => {
      state.appStatus = action.payload
    },
    setErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.errorMessage = action.payload
    },
    setSuccessMessage: (state, action: PayloadAction<string | null>) => {
      state.successMessage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending, (state) => {
        state.appStatus = AppStatusType.loading
      })
      .addMatcher(isRejected, (state, action) => {
        state.appStatus = AppStatusType.failed
        state.errorMessage = action.payload as string || ''
        //  || 'Something went wrong'
      })
      .addMatcher(isFulfilled, (state, action) => {
        const meta = action.meta as fulfilledMetaType
        meta.appStatus === AppStatusType.idle && (state.appStatus = AppStatusType.idle)
        if (meta.appStatus === AppStatusType.succeeded) {
          state.appStatus = AppStatusType.succeeded
          state.successMessage = meta?.appMessage || null
        }
      })
  },
})

export const { setAppStatus, setErrorMessage, setSuccessMessage } = appStatusSlice.actions

export const selectAppStatus = (state: RootState) => state.appStatus.appStatus
export const selectErrorMessage = (state: RootState) => state.appStatus.errorMessage
export const selectSuccessMessage = (state: RootState) => state.appStatus.successMessage

export default appStatusSlice.reducer
