import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AsyncThunkConfig, RootState} from "./store";
import {permissionConfigurationController} from "../api/api";
import {AppStatusType} from "./appStatusReducer";
import {
    CreateMultiStepLinksThunk,
    MultiStepRequestData,
    onSetRequestDataFacebookId, UpdateMultiStepLinksThunk
} from "./multiStepReducer";
import {GetAllServicesLinksThunk} from "./linksReducer";

export interface ConfigType {
    id: number
    name: string
    description: string
    url: string
    business: {
        id: number
        business_id: string
        name: string
        logo_url: string
    },
    permissions: [
        {
            id: number
            name: string
            description: string
            role_name: string
        }
    ]
}

interface InitialStateType {
    configurations: any,
    selectedConfigurations: any
    currentConfig: ConfigType | {}
    addAccountConfigurations: ConfigType[]
    catalogConfigurations: ConfigType[]
    instagramAccountConfigurations: ConfigType[]
    pageAccountConfigurations: ConfigType[]
    pagePixelConfigurations: ConfigType[]

}

const initialState: InitialStateType = {
    configurations: {},
    selectedConfigurations: [],
    currentConfig: {},
    addAccountConfigurations: [],
    catalogConfigurations: [],
    instagramAccountConfigurations: [],
    pageAccountConfigurations: [],
    pagePixelConfigurations: []

}

export const permissionConfigurationSlice = createSlice({
    name: 'permissionConfiguration',
    initialState,
    reducers: {
        setPermissionConfigurations: (state, action: PayloadAction<boolean>) => {
            state.configurations = action.payload
        },
        addSelectedConfigurations: (state, action: PayloadAction<boolean>) => {
            state.selectedConfigurations.push(action.payload)
        },
        deleteSelectedConfigurations: (state, action: PayloadAction<boolean>) => {
            state.selectedConfigurations = state.selectedConfigurations.filter((c: any) => c !== action.payload)
        },
        setCurrentConfig: (state, action: PayloadAction<ConfigType | {}>) => {
            state.currentConfig = action.payload
        },
        setCurrentConfigIntoAllConfigurations: (state, action: PayloadAction<any>) => {
            state.configurations.map((c: ConfigType) => c.id === action.payload.id ? {...c, checked: true} : c)
        },
        setAddAccountConfigurations: (state, action: PayloadAction<any>) => {
            state.addAccountConfigurations = action.payload
        },
        setCatalogAccountConfigurations: (state, action: PayloadAction<any>) => {
            state.catalogConfigurations = action.payload
        },
        changeAddAccountConfigurations: (state, action: PayloadAction<any>) => {
            state.addAccountConfigurations = state.addAccountConfigurations.map((c: any) => c.id === action.payload.id ? {
                ...c,
                checked: true
            } : c)
        },
        changeAddAccountConfigurationCheckbox: (state, action: PayloadAction<{ checked: boolean, id: number }>) => {
            state.addAccountConfigurations = state.addAccountConfigurations.map((c: any) => c.id === action.payload.id ? {
                ...c,
                checked: action.payload.checked
            } : c)
        },
        changeCatalogAccountConfigurations: (state, action: PayloadAction<any>) => {
            state.catalogConfigurations = state.catalogConfigurations.map((c: any) => c.id === action.payload.id ? {
                ...c,
                checked: true
            } : c)
        },
        changeCatalogAccountConfigurationCheckbox: (state, action: PayloadAction<{ checked: boolean, id: number }>) => {
            state.catalogConfigurations = state.catalogConfigurations.map((c: any) => c.id === action.payload.id ? {
                ...c,
                checked: action.payload.checked
            } : c)
        },
        //
        resetFacebookModalConfigurations: (state) => {
            state.addAccountConfigurations = state.addAccountConfigurations.map((c: any) => c && {
                ...c,
                checked: false
            })
            state.catalogConfigurations = state.catalogConfigurations.map((c: any) => c && {
                ...c,
                checked: false
            })
            state.instagramAccountConfigurations = state.instagramAccountConfigurations.map((c: any) => c && {
                ...c,
                checked: false
            })
            state.pageAccountConfigurations = state.pageAccountConfigurations.map((c: any) => c && {
                ...c,
                checked: false
            })
            state.pagePixelConfigurations = state.pagePixelConfigurations.map((c: any) => c && {
                ...c,
                checked: false
            })
        },
        setInstagramAccountConfigurations: (state, action: PayloadAction<any>) => {
            state.instagramAccountConfigurations = action.payload
        },
        changeInstagramAccountConfigurations: (state, action: PayloadAction<any>) => {
            state.instagramAccountConfigurations = state.instagramAccountConfigurations.map((c: any) => c.id === action.payload.id ? {
                ...c,
                checked: true
            } : c)
        },
        changeInstagramAccountConfigurationCheckbox: (state, action: PayloadAction<{ checked: boolean, id: number }>) => {
            state.instagramAccountConfigurations = state.instagramAccountConfigurations.map((c: any) => c.id === action.payload.id ? {
                ...c,
                checked: action.payload.checked
            } : c)
        },
        //
        //
        setPageAccountConfigurations: (state, action: PayloadAction<any>) => {
            state.pageAccountConfigurations = action.payload
        },
        changePageAccountConfigurations: (state, action: PayloadAction<any>) => {
            state.pageAccountConfigurations = state.pageAccountConfigurations.map((c: any) => c.id === action.payload.id ? {
                ...c,
                checked: true
            } : c)
        },
        changePageAccountConfigurationCheckbox: (state, action: PayloadAction<{ checked: boolean, id: number }>) => {
            state.pageAccountConfigurations = state.pageAccountConfigurations.map((c: any) => c.id === action.payload.id ? {
                ...c,
                checked: action.payload.checked
            } : c)
        },
        //
        //
        setPixelAccountConfigurations: (state, action: PayloadAction<any>) => {
            state.pagePixelConfigurations = action.payload
        },
        changePixelAccountConfigurations: (state, action: PayloadAction<any>) => {
            state.pagePixelConfigurations = state.pagePixelConfigurations.map((c: any) => c.id === action.payload.id ? {
                ...c,
                checked: true
            } : c)
        },
        changePixelAccountConfigurationCheckbox: (state, action: PayloadAction<{ checked: boolean, id: number }>) => {
            state.pagePixelConfigurations = state.pagePixelConfigurations.map((c: any) => c.id === action.payload.id ? {
                ...c,
                checked: action.payload.checked
            } : c)
        },
        //
        setSelectedConfigurations: (state, action: PayloadAction<{ isEdit: boolean, id?: number, emptyData?: [] }>) => {
            action.payload.isEdit ?
                state.selectedConfigurations.push(action.payload.id)
                :
                state.selectedConfigurations = action.payload.emptyData
        },
        switchAllConfigurationsCheckboxes: (state, action: PayloadAction<boolean>) => {
            state.addAccountConfigurations = state.addAccountConfigurations.map((m: any) => m && {...m, checked: m?.is_active ? action.payload : false})
            state.catalogConfigurations = state.catalogConfigurations.map((m: any) => m && {...m, checked: m?.is_active ? action.payload : false})
            state.instagramAccountConfigurations = state.instagramAccountConfigurations.map((m: any) => m && {...m, checked: m?.is_active ? action.payload : false})
            state.pageAccountConfigurations = state.pageAccountConfigurations.map((m: any) => m && {...m, checked: m?.is_active ? action.payload : false})
            state.pagePixelConfigurations = state.pagePixelConfigurations.map((m: any) => m && {...m, checked: m?.is_active ? action.payload : false})
            const selectedConfigs =  state.addAccountConfigurations.concat(state.catalogConfigurations, state.instagramAccountConfigurations, state.pageAccountConfigurations, state.pagePixelConfigurations).flat(1)
            if (action.payload){
                state.selectedConfigurations = selectedConfigs.filter((c: any) => !!c.checked)?.map((c: any) => c.id)
            }
            else if(!action.payload) {
                state.selectedConfigurations = []
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetPermissionConfigurationsThunk.fulfilled, (state, action) => {
                state.configurations = action.payload
            })
            .addCase(GetPermissionConfigurationConfigByIdThunk.fulfilled, (state, action) => {
                state.currentConfig = action.payload
            })
    }
})

export const {
    setSelectedConfigurations,
    changeAddAccountConfigurations,
    addSelectedConfigurations,
    deleteSelectedConfigurations,
    setCurrentConfig,
    changeAddAccountConfigurationCheckbox,
    setAddAccountConfigurations,
    setCatalogAccountConfigurations,
    changeCatalogAccountConfigurations,
    changeCatalogAccountConfigurationCheckbox,
    setInstagramAccountConfigurations,
    changeInstagramAccountConfigurations,
    changeInstagramAccountConfigurationCheckbox,
    setPageAccountConfigurations,
    changePageAccountConfigurations,
    changePageAccountConfigurationCheckbox,
    setPixelAccountConfigurations,
    changePixelAccountConfigurations,
    changePixelAccountConfigurationCheckbox,
    switchAllConfigurationsCheckboxes,
    resetFacebookModalConfigurations
} = permissionConfigurationSlice.actions


export const selectPermissionConfigurations = (state: RootState): any => state.permissionConfiguration.configurations
export const selectSelectedConfigurations = (state: RootState): any => state.permissionConfiguration.selectedConfigurations
export const selectCurrentConfiguration = (state: RootState): any => state.permissionConfiguration.currentConfig
export const selectAddAccountConfigurations = (state: RootState): any => state.permissionConfiguration.addAccountConfigurations
export const selectCatalogAccountConfigurations = (state: RootState): any => state.permissionConfiguration.catalogConfigurations
export const selectInstagramAccountConfigurations = (state: RootState): any => state.permissionConfiguration.instagramAccountConfigurations
export const selectPageAccountConfigurations = (state: RootState): any => state.permissionConfiguration.pageAccountConfigurations
export const selectPixelAccountConfigurations = (state: RootState): any => state.permissionConfiguration.pagePixelConfigurations

export const GetPermissionConfigurationsThunk = createAsyncThunk<any, void, AsyncThunkConfig>(
    'permissionConfiguration/getPermissionConfigurations',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await permissionConfigurationController.getPermissionConfiguration()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.configurations, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const CreatePermissionConfigurationConfigThunk = createAsyncThunk<any, {request: any, isMultiStep?: boolean , multiStepReqData?: MultiStepRequestData | {}}, AsyncThunkConfig>(
    'permissionConfiguration/createPermissionConfigurations',
    async (configData, thunkAPI) => {
        try {
            const {status, data} = await permissionConfigurationController.addPermissionConfiguration(configData.request)
            if (status === 200 && data) {
                if(configData.isMultiStep && configData.multiStepReqData){
                    const req: any = {
                        ...configData.multiStepReqData,

                        facebook_configuration_id: data.id
                    }
                    console.log(req)
                    thunkAPI.dispatch(CreateMultiStepLinksThunk(req))
                }
                thunkAPI.dispatch(onSetRequestDataFacebookId(data.id))
                return thunkAPI.fulfillWithValue(data.configurations, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(GetPermissionConfigurationsThunk())
            // if(configData.isMultiStep){
            //     thunkAPI.dispatch(GetMultiStepLinksThunk())
            // }
        }
    }
)

export const UpdatePermissionConfigurationConfigThunk = createAsyncThunk<any, {request: any, isMultiStep?: boolean , multiStepReqData?: MultiStepRequestData | {}}, AsyncThunkConfig>(
    'permissionConfiguration/updatePermissionConfigurations',
    async (configData, thunkAPI) => {
        try {
            const {status, data} = await permissionConfigurationController.updatePermissionConfigurationById(configData.request.configId, configData.request.configData)
            if (status === 200 && data) {
                console.log(data)
                if(configData.isMultiStep && configData.multiStepReqData){
                    const req: any = {
                        ...configData.multiStepReqData,
                        facebook_configuration_id: data.id
                    }
                    thunkAPI.dispatch(UpdateMultiStepLinksThunk(req))
                }
                thunkAPI.dispatch(onSetRequestDataFacebookId(data.id))
                return thunkAPI.fulfillWithValue(data.configurations, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(GetPermissionConfigurationsThunk())
        }
    }
)

export const DeletePermissionConfigurationConfigThunk = createAsyncThunk<null, number, AsyncThunkConfig>(
    'permissionConfiguration/deletePermissionConfigurations',
    async (configId, thunkAPI) => {
        try {
            const {status} = await permissionConfigurationController.deletePermissionConfiguration(configId)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(null, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: 'Deleted successfully'
                })
            } else {
                return thunkAPI.rejectWithValue(null)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(GetPermissionConfigurationsThunk())
            thunkAPI.dispatch(GetAllServicesLinksThunk())
        }
    }
)

export const GetPermissionConfigurationConfigByIdThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'permissionConfiguration/createPermissionConfigurations',
    async (configId, thunkAPI) => {
        try {
            const {status, data} = await permissionConfigurationController.getPermissionConfigurationById(configId)
            if (status === 200 && data) {
                data.permissions.forEach((a: any) => {
                    thunkAPI.dispatch(changeAddAccountConfigurations(a))
                    thunkAPI.dispatch(changeCatalogAccountConfigurations(a))
                    thunkAPI.dispatch(changeInstagramAccountConfigurations(a))
                    thunkAPI.dispatch(changePageAccountConfigurations(a))
                    thunkAPI.dispatch(changePixelAccountConfigurations(a))
                    thunkAPI.dispatch(setSelectedConfigurations({isEdit: true, id: a.id}))
                })
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(GetPermissionConfigurationsThunk())
        }
    }
)

export const LogoutFacebookThunk = createAsyncThunk<void, void, AsyncThunkConfig>(
    'permissionConfiguration/logoutFacebook',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await permissionConfigurationController.logoutFacebook()
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(GetPermissionConfigurationsThunk())
        }
    }
)

export default permissionConfigurationSlice.reducer
