import {Link} from 'react-router-dom'
import classes from './PageTitle.module.scss'
import React, {ReactNode} from 'react'
import {useAppSelector} from "../../hooks";
import {selectCurrentTutorialStep, selectIsTutorial} from "../../store/tutorialReducer";
import CustomImageUpload from "../CustomImageUpload/CustomImageUpload";

const leftArrowLight = require('../../img/icons/leftArrowLight.svg')


const PageTitle: React.FC<PageTitlePropTypes> = ({
                                                     title,
                                                     backLink,
                                                     description,
                                                     onAddLink,
                                                     isMobile,
                                                     agencyName,
                                                     isBackLink, email, clientLogo
                                                 }) => {
    const isTutorial = useAppSelector(selectIsTutorial)


    return (

        <div className={classes.wrapper}>
            <div className={classes.titleBlock}>
                {
                    isBackLink
                        ?
                        <>
                            <Link to={backLink || '/'} className={classes.backLink} style={{
                                opacity: isTutorial ? '0.5' : 1
                            }}>
                                <leftArrowLight.ReactComponent/>
                            </Link>
                                {
                                    clientLogo
                                    &&
                                    <CustomImageUpload />



                                }
                            <Link to={backLink || '/'} className={classes.backLink} style={{
                                opacity: isTutorial ? '0.5' : 1
                            }}>
                                {title && !isMobile &&
                                    <h1 className={classes.titleWrapper} style={{marginBottom: '0px'}}>
                                        {title}
                                    </h1>
                                }
                                {
                                    isMobile
                                    &&
                                    <h1 className={classes.titleWrapper} style={{marginBottom: '0px'}}>
                                        {agencyName}
                                    </h1>
                                }
                            </Link>

                        </>

                        :
                        <h1 className={classes.titleWrapper} style={{marginBottom: '0px'}}>
                            {title}
                        </h1>
                }

                {!!onAddLink && <AddLinkBtn onClick={onAddLink}/>}
            </div>

            {description && !isMobile &&
                <div className={classes.description}>
                    {description}
                </div>
            }
            {email &&
                <div className={classes.email}>
                    {email}
                </div>
            }
        </div>
    )
}

const AddLinkBtn: React.FC<{ onClick: () => void }> = ({onClick}) => {
    const currentTutorialStep = useAppSelector(selectCurrentTutorialStep)
    const isTutorial = useAppSelector(selectIsTutorial)
    return (
        <button
            className={currentTutorialStep === 'Fourth step - Link creation / Table' && isTutorial ? `${classes.button} ${classes.button_tutorial}` : classes.button}
            onClick={onClick}
        >
            + Add Link
        </button>
    )
}


interface PageTitlePropTypes {
    title?: string | ReactNode
    backLink?: string
    description?: string
    onAddLink?: () => void
    isMobile?: boolean
    agencyName?: string
    isBackLink?: boolean
    email?: string
    clientLogo?: boolean
}

export default PageTitle
