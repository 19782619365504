//@ts-nocheck
import React, {useEffect, useState} from 'react';
import s from './PrivatePanelTable.module.scss'
import {Box} from "@material-ui/core";
import {DataGridPro, useGridApiRef} from "@mui/x-data-grid-pro";
import RequirementSearchInput from "../../RequirementSearchInput/RequirementSearchInput";
import CommonModal from "../../CommonModal/CommonModal";
import {Button, Form, Input} from "antd";
import {Navigate} from "react-router";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {
    PrivatePanelGetTableDataThunk, PrivatePanelSendInviteThunk, PrivatePanelSignOutThunk,
    selectPrivatePanelClientData,
    selectPrivatePanelClientIsLoggedIn
} from "../../../store/privatePanelReducer";
import moment from "moment/moment";
import {userDataHelper} from "../../../helpers/localStorageHelper";


const privatePanelHeaderLogo = require('../../../img/icons/privatePanelLogo.svg')
const avatar = require('../../../img/avatar.svg')
const more = require('../../../img/icons/more-vertical.svg')

type TabTypes = 'Connected Agencies' | 'Payment history'

const PrivatePanelTable = () => {
    const dispatch = useAppDispatch()
    const [currentTab, setCurrentTab] = useState<TabTypes>('Connected Agencies')
    const apiRef = useGridApiRef();
    const [visibleRows, setVisibleRows] = useState<any[]>([])
    const [visibleRowsCount, setVisibleRowsCount] = useState(0)
    const [openedAddModal, setOpenedAddModal] = useState(false)
    const isLoggedIn = useAppSelector(selectPrivatePanelClientIsLoggedIn)
    const tableData = useAppSelector(selectPrivatePanelClientData)
    const [screenWidth, setScreenWidth] = useState<number>(0)
    const [menuOpened, setMenuOpened] = useState(false)
    const userData = userDataHelper.getAdminData()
    useEffect(() => {
        dispatch(PrivatePanelGetTableDataThunk())
        userDataHelper.setIsAdminUserData(true)
    }, [dispatch])

    useEffect(() => {
        setVisibleRowsCount(tableData.length)
        setVisibleRows(tableData)
    }, [tableData])

    const onResendLink = (data: any) => {
        dispatch(PrivatePanelSendInviteThunk({email: data}))
    }


    //handle current width screen for media queries
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: screenWidth > 600 ? 1 : 'auto',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: screenWidth > 600 ? 1 : 'auto',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            renderCell: (data: any) => (<div>{data.row.email || ''}</div>)
        },
        {
            field: 'date_created',
            headerName: 'Date',
            flex: screenWidth > 600 ? 1 : 'auto',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#131313',
            fontSize: '12px',
            lineHeight: '20px',
            renderCell: (data: any) => (<div
                style={{
                    color: '#131313',
                    marginLeft: '10px',
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '22px',
                    cursor: 'pointer'
                }}>
                {data.row.date_created ? moment(data.row.date_created).format('DD.MM.YYYY (hh:mm A)') : ''}
            </div>)
        },
        {
            field: '',
            headerName: '',
            flex: screenWidth > 1660 ? 0.2 : screenWidth > 1024 ? 0.4 : screenWidth > 600 ? 0.6 : 'auto',
            renderCell: (data: any) => {
                return (
                    <button
                        onClick={() => onResendLink(data.row.email)}
                        disabled={!data.row.status}
                        style={{
                            color: !data.row.status ? '#6B7280' : '#075CEB',
                            marginLeft: '10px',
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 600,
                            fontSize: '12px',
                            lineHeight: '20px',
                            cursor: !data.row.status ? 'not-allowed' : 'pointer',
                            backgroundColor: 'transparent',
                            border: 'none',
                            outline: 'none',
                        }}>
                        Resend
                    </button>
                )
            }
        },
    ]
    const onFinish = (loginData: { email: string }) => {
        dispatch(PrivatePanelSendInviteThunk(loginData))
        setOpenedAddModal(false)
    }
    return (
        isLoggedIn
            ?
            <div>
                <div className={s.header}>
                    <div className={s.logo}>
                        <privatePanelHeaderLogo.ReactComponent/>
                    </div>
                    <div className={s.menu}>
                        <div>
                            <avatar.ReactComponent/>
                        </div>
                        <div
                            className={s.menu__item}
                            onClick={() => {
                                setMenuOpened(!menuOpened)
                            }}
                        >
                            <more.ReactComponent/>
                        </div>
                    </div>
                </div>
                {menuOpened
                    &&
                    <div className={s.mobile}>
                        <div className={s.mobile__menu}>

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                                className={s.mobile__menu_avatar}
                            >
                                <avatar.ReactComponent/>
                                <p>{userData.user_name}</p>
                            </div>
                            <div>
                                <div
                                    className={`${s.mobile__menu_item} ${s.mobile__menu_itemFooter}`}
                                    onClick={() => dispatch(PrivatePanelSignOutThunk())}
                                >
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H6M10.6667 11.3333L14 8M14 8L10.6667 4.66667M14 8H6"
                                            stroke="#344054" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>
                                    <p>Log out</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className={s.tabButtons}>
                    <button
                        onClick={() => setCurrentTab('Connected Agencies')}
                        className={currentTab === 'Connected Agencies' ? `${s.tabButtons__btn} ${s.tabButtons__btn_active}` : s.tabButtons__btn}
                    >
                        Connected Agencies
                    </button>
                    <button
                        onClick={() => setCurrentTab('Payment history')}
                        className={currentTab === 'Payment history' ? `${s.tabButtons__btn} ${s.tabButtons__btn_active}` : s.tabButtons__btn}
                        disabled={true}
                    >
                        Payment history
                    </button>
                </div>
                <div>
                    {
                        currentTab === 'Connected Agencies'
                            ?
                            <div className={s.table}>
                                <div className={s.table__header}>
                                    <div>
                                        <h1>Table of connected agencies</h1>
                                        <p>Invite more agencies to join</p>
                                    </div>
                                    <button
                                        className={s.table__header_invite}
                                        onClick={() => setOpenedAddModal(true)}
                                    >
                                        + Invite
                                    </button>
                                </div>
                                <RequirementSearchInput
                                    apiRef={apiRef}
                                    setVisibleRowsCount={(count: number) => setVisibleRowsCount(count)}
                                    setVisibleRows={(rows: any[]) => setVisibleRows(rows)}
                                    allRows={Array.isArray(tableData) ? tableData : []}
                                />
                                <div className={s.table__content}>
                                    <h2>
                                        List of agencies <span>{visibleRowsCount} links</span>
                                    </h2>
                                    <Box>
                                        <DataGridPro
                                            sx={{
                                                borderRight: 'none',
                                                borderLeft: 'none',
                                                borderBottom: 'none',
                                                borderRadius: '0px',
                                                borderTop: '1px solid transparent',
                                                "& .MuiDataGrid-columnHeader": {
                                                    background: '#F9FAFB',
                                                    padding: '0px 20px',
                                                    minWidth: "201px !important",
                                                },
                                                "& .MuiDataGrid-columnHeader:last-child": {
                                                    background: '#F9FAFB',
                                                    padding: '0px 20px',
                                                    minWidth: "90px !important"
                                                },
                                                "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                                    display: "none"
                                                },
                                                "& .MuiDataGrid-cell":{
                                                    minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                                                },
                                                "& .MuiDataGrid-cell:last-child":{
                                                    minWidth: screenWidth < 650 ? "90px !important" : 'auto',
                                                },
                                                "& .MuiDataGrid-cellContent": {
                                                    fontFamily: 'Inter, sans-serif',
                                                    fontWeight: 500,
                                                    fontSize: '14px',
                                                    lineHeight: '20px',
                                                    color: '#111827',
                                                    padding: '0px 10px',
                                                    minWidth: '201px'
                                                },
                                                "& .MuiDataGrid-cellContent:last-child": {
                                                    fontFamily: 'Inter, sans-serif',
                                                    fontWeight: 500,
                                                    fontSize: '14px',
                                                    lineHeight: '20px',
                                                    color: '#111827',
                                                    padding: '0px 10px',
                                                    minWidth: '90px'
                                                },
                                                "& .MuiDataGrid-columnHeaderTitle": {
                                                    fontFamily: 'Inter, sans-serif',
                                                    fontWeight: 600,
                                                    color: '#6B7280',
                                                    fontSize: '12px',
                                                    lineHeight: '20px',
                                                },
                                                "& .MuiDataGrid-row:hover": {
                                                    backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                                },
                                                "& .MuiDataGrid-row.Mui-selected": {
                                                    backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                                },
                                                "& .MuiDataGrid-row.Mui-selected:hover": {
                                                    backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                                },
                                                "& .MuiDataGrid-cell:focus": {
                                                    outline: 'none'
                                                },
                                                "& .MuiDataGrid-toolbarContainer": {
                                                    color: '#454545',
                                                    position: 'absolute',
                                                    top: '-62px',
                                                    right: '0px'
                                                },
                                                "& .MuiButtonBase-root": {
                                                    fontFamily: 'Inter, sans-serif !important',
                                                    fontWeight: 600,
                                                    color: '#6B7280',
                                                    fontSize: '12px',
                                                    lineHeight: '20px',
                                                },
                                                "& .MuiTablePagination-displayedRows": {
                                                    marginBottom: 0
                                                },
                                                "& .MuiDataGrid-virtualScroller": {
                                                    overflowY: 'scroll',
                                                    overflowX: 'scroll !important',
                                                },
                                            }}
                                            apiRef={apiRef}
                                            getRowId={e => e.email}
                                            columns={columns}
                                            rows={visibleRows}
                                            {...visibleRows}
                                            rowHeight={58}
                                            headerHeight={39}
                                            pagination={true}
                                            pageSize={10}
                                            rowsPerPageOptions={[10]}
                                            hideFooterSelectedRowCount
                                            autoHeight={true}
                                            // onRowClick={onMobileRowClick}
                                        />
                                    </Box>
                                    <CommonModal active={openedAddModal} height={'270px'} width={'400px'}>
                                        <div className={s.table__invite}>
                                            <h3>Invite agency</h3>
                                            <p>Create an invitation for an agency </p>
                                            <Form
                                                name='inviteAgency'
                                                onFinish={onFinish}
                                                autoComplete='off'
                                                validateTrigger='onBlur'
                                                className={'privatePanelSignIn'}
                                            >
                                                <div className={s.formLabel}>
                                                    Email address
                                                </div>
                                                <Form.Item
                                                    name='email'
                                                    rules={[
                                                        {required: true, message: 'Please enter your email!'},
                                                        {type: 'email', message: 'Invalid e-mail!'},
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder='Enter your email'
                                                        className={s.formInput}
                                                    />
                                                </Form.Item>
                                                <div className={s.formButtons}>
                                                    <Form.Item style={{
                                                        marginBottom: '0px'
                                                    }}>
                                                        <Button
                                                            type='primary'
                                                            htmlType='submit'
                                                            className={s.formButtons__submit}
                                                        >
                                                            Send invite
                                                        </Button>
                                                    </Form.Item>
                                                    <Form.Item style={{
                                                        marginBottom: '0px'
                                                    }}>
                                                        <button
                                                            type='reset'
                                                            className={s.formButtons__cancel}
                                                            onClick={() => setOpenedAddModal(false)}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </Form.Item>
                                                </div>
                                            </Form>
                                        </div>
                                    </CommonModal>
                                </div>
                            </div>
                            :
                            <div>Payment history</div>
                    }
                </div>
            </div>
            :
            <Navigate to={'/privatePanelSignIn'}/>
    );

};

export default PrivatePanelTable;