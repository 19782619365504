import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
    DeleteGoogleTableLinkThunk,
    selectCurrentGoogleModalLink
} from "../../store/googleReducer";
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import {
    selectIsMobileMultiLinkOpened,
    setFacebookModalOpen,
    setGoogleTableModalOpen,
    setMobileIsMultiLinkOpened,
    setTikTokTableModalOpen
} from "../../store/utilityReducer";
import PageTitle from "../PageTitle/PageTitle";
import {useNavigate} from "react-router";
import {userDataHelper} from "../../helpers/localStorageHelper";
import {
    DeletePermissionConfigurationConfigThunk,
    selectCurrentConfiguration
} from "../../store/permissionConfigurationReducer";
import {
    DeleteTikTokTableLinkThunk,
    selectCurrentTikTokModalLink
} from "../../store/tikTokReducer";
import {
    DeleteMultiStepTableLinkThunk,
    onSetCurrentPlatformIndex, selectCurrentPlatformIndex,
    selectMultiStepCurrentLinkData
} from "../../store/multiStepReducer";
import {message} from "antd";
import {FacebookModal} from "../FacebookLinksTable/FacebookLinksTable";
import {GoogleModal} from "../GoogleLinksTable/GoogleLinksTable";
import {TikTokModal} from "../TikTokLinksTable/TikTokLinksTable";
import {selectMultiStageServices, setMultiStageServices} from "../../store/linksReducer";
import s from './MobileLink.module.scss'

const MobileLink = () => {
    const dispatch = useAppDispatch()
    const currentGoogleLink = useAppSelector(selectCurrentGoogleModalLink)
    const currentConfiguration = useAppSelector(selectCurrentConfiguration)
    const currentTikTokLink = useAppSelector(selectCurrentTikTokModalLink)
    const currentServiceType = userDataHelper.getMobileLinkServiceType()
    const currentMultiStepLink = useAppSelector(selectMultiStepCurrentLinkData)
    let multiStageServices = useAppSelector(selectMultiStageServices)
    const currentPlatformIndex = useAppSelector(selectCurrentPlatformIndex)
    const navigate = useNavigate()
    const isMultiLinkOpened = useAppSelector(selectIsMobileMultiLinkOpened)
    const platformOrder = ['Facebook', 'Google', 'TikTok'];
    const onDeleteLink = () => {
        if (currentServiceType.toLowerCase() === 'facebook') {
            dispatch(DeletePermissionConfigurationConfigThunk(currentConfiguration.id))
            navigate('/facebookLinksTable')

        } else if (currentServiceType.toLowerCase() === 'google') {
            dispatch(DeleteGoogleTableLinkThunk(currentGoogleLink.id))
            navigate('/googleLinksTable')
        } else if (currentServiceType.toLowerCase() === 'tiktok') {
            dispatch(DeleteTikTokTableLinkThunk(currentTikTokLink.id))
            navigate('/tiktokLinksTable')
        } else if (currentServiceType.toLowerCase() === 'all') {
            //@ts-ignore
            dispatch(DeleteMultiStepTableLinkThunk(currentMultiStepLink.id))
            navigate('/allLinksTable')
        } else {
            //@ts-ignore
            dispatch(DeleteMultiStepTableLinkThunk(currentMultiStepLink.id))
            navigate('/commonLinksTable')
        }
    }

    const onCopyLink = async () => {
        if (currentServiceType.toLowerCase() === 'facebook') {
            try {
                await navigator.clipboard.writeText(currentConfiguration.url);
                message.success('Link was copied successfully', 3)
            } catch (err) {
                message.error('Failed to copy link');
            }
        } else if (currentServiceType.toLowerCase() === 'google') {
            try {
                await navigator.clipboard.writeText(currentGoogleLink.url);
                message.success('Link was copied successfully', 3)
            } catch (err) {
                message.error('Failed to copy link');
            }
        } else if (currentServiceType.toLowerCase() === 'tiktok') {
            try {
                await navigator.clipboard.writeText(currentTikTokLink.url);
                message.success('Link was copied successfully', 3)
            } catch (err) {
                message.error('Failed to copy link');
            }
        } else {
            try {
                await navigator.clipboard.writeText(currentMultiStepLink !== null ? currentMultiStepLink.url : '');
                message.success('Link was copied successfully', 3)
            } catch (err) {
                message.error('Failed to copy link');
            }
        }
    }


    const nextPlatform = () => {
        if (currentPlatformIndex < multiStageServices.length - 1) {
            console.log(currentPlatformIndex)
            dispatch(onSetCurrentPlatformIndex(currentPlatformIndex + 1))
        }
    };

    const previousPlatform = () => {
        if (currentPlatformIndex > 0) {
            dispatch(onSetCurrentPlatformIndex(currentPlatformIndex - 1))
        }
    };

    const handleButtonClick = () => {
        if (currentPlatformIndex < multiStageServices.length - 1) {
            nextPlatform();
        } else {
            previousPlatform();
        }
    };

    useEffect(() => {
        if (currentMultiStepLink !== null) {
            dispatch(setMultiStageServices(
                [
                    currentMultiStepLink.facebook_configuration && Object.keys(currentMultiStepLink.facebook_configuration).length ? 'Facebook' : '',
                    currentMultiStepLink.google_configuration && Object.keys(currentMultiStepLink.google_configuration).length ? 'Google' : '',
                    currentMultiStepLink.tiktok_configuration && Object.keys(currentMultiStepLink.tiktok_configuration).length ? 'TikTok' : ''
                ].filter((l: string) => l !== '')
            ))
        }
    }, [currentMultiStepLink, dispatch])


    useEffect(() => {
        // eslint-disable-next-line
        multiStageServices = [...multiStageServices].sort((a, b) => platformOrder.indexOf(a) - platformOrder.indexOf(b))
        dispatch(onSetCurrentPlatformIndex(0))
    }, [dispatch, multiStageServices]);
    const currentPlatform = multiStageServices[currentPlatformIndex];

    useEffect(() => {
        if (isMultiLinkOpened) {
            dispatch(setFacebookModalOpen(currentPlatform === 'Facebook'))
            dispatch(setGoogleTableModalOpen(currentPlatform === 'Google'))
            dispatch(setTikTokTableModalOpen(currentPlatform === 'TikTok'))
        }
    }, [currentPlatformIndex, multiStageServices, currentPlatform, dispatch, isMultiLinkOpened]);


    const onEditLink = () => {
        if (currentServiceType === 'All' || currentServiceType === 'Common') {
            dispatch(setMobileIsMultiLinkOpened(true))
        } else if (currentServiceType === 'Facebook') {
            dispatch(setFacebookModalOpen(true))
        } else if (currentServiceType === 'Google') {
            dispatch(setGoogleTableModalOpen(true))
        } else if (currentServiceType === 'TikTok') {
            dispatch(setTikTokTableModalOpen(true))
        }
    }

    return (
        <PageWithSidebar>
            <div className={s.inner}>
                <Breadcrumbs
                    mobileAddedTitle={currentServiceType === 'Facebook'
                        ? 'Facebook table'
                        : currentServiceType === 'Google'
                            ? 'Google table'
                            : currentServiceType === 'TikTok'
                                ? 'TikTok table'
                                :
                                currentServiceType === 'All'
                                    ? 'All links table'
                                    :
                                    'Table for common links'
                    }
                />
                <div className={s.content}>
                    <PageTitle
                        isMobile={true}
                        agencyName={
                            currentServiceType === 'Facebook'
                                ? currentConfiguration.name
                                : currentServiceType === 'Google'
                                    ? currentGoogleLink.name
                                    : currentServiceType === 'TikTok'
                                        ? currentTikTokLink.name
                                        : currentServiceType === 'All' || currentServiceType === 'Common'
                                            ? currentMultiStepLink && currentMultiStepLink.name : ''
                        }
                    />
                    <div className={s.content__item}>
                        <p className={s.content__description}>Description</p>
                        <p className={s.content__description_text}>
                            {
                                currentServiceType === 'Facebook'
                                    ? currentConfiguration.description
                                    : currentServiceType === 'Google'
                                        ? currentGoogleLink.description
                                        : currentServiceType === 'TikTok'
                                            ? currentTikTokLink.description
                                            : currentServiceType === 'All' || currentServiceType === 'Common'
                                                ? currentMultiStepLink && currentMultiStepLink.description : ''
                            }
                        </p>
                    </div>
                    <div className={s.content__item}>
                        <p className={s.content__description}>Link URL:</p>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href={currentServiceType === 'Facebook' ? currentConfiguration.url : currentServiceType === 'Google' ? currentGoogleLink.url : currentServiceType === 'TikTok' ? currentTikTokLink.url : currentServiceType === 'All' || currentServiceType === 'Common' ? currentMultiStepLink && currentMultiStepLink.url : '#'}
                           className={s.content__description_link}
                        >
                            {
                                currentServiceType === 'Facebook'
                                    ? currentConfiguration.url
                                    : currentServiceType === 'Google'
                                        ? currentGoogleLink.url
                                        : currentServiceType === 'TikTok'
                                            ? currentTikTokLink.url
                                            : currentServiceType === 'All' || currentServiceType === 'Common'
                                                ? currentMultiStepLink && currentMultiStepLink.url : ''
                            }
                        </a>
                    </div>
                    <div className={s.buttons}>
                        <button onClick={onDeleteLink}>
                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" style={{marginRight: '12px'}}
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1.5 5.00008H3.16667M3.16667 5.00008H16.5M3.16667 5.00008V16.6667C3.16667 17.1088 3.34226 17.5327 3.65482 17.8453C3.96738 18.1578 4.39131 18.3334 4.83333 18.3334H13.1667C13.6087 18.3334 14.0326 18.1578 14.3452 17.8453C14.6577 17.5327 14.8333 17.1088 14.8333 16.6667V5.00008H3.16667ZM5.66667 5.00008V3.33341C5.66667 2.89139 5.84226 2.46746 6.15482 2.1549C6.46738 1.84234 6.89131 1.66675 7.33333 1.66675H10.6667C11.1087 1.66675 11.5326 1.84234 11.8452 2.1549C12.1577 2.46746 12.3333 2.89139 12.3333 3.33341V5.00008M7.33333 9.16675V14.1667M10.6667 9.16675V14.1667"
                                    stroke="#075CEB" strokeWidth="1.66667" strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            Delete
                        </button>
                        <button onClick={onEditLink}>
                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" style={{marginRight: '12px'}}
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13.6665 2.49993C13.8854 2.28106 14.1452 2.10744 14.4312 1.98899C14.7171 1.87054 15.0236 1.80957 15.3332 1.80957C15.6427 1.80957 15.9492 1.87054 16.2352 1.98899C16.5211 2.10744 16.781 2.28106 16.9998 2.49993C17.2187 2.7188 17.3923 2.97863 17.5108 3.2646C17.6292 3.55057 17.6902 3.85706 17.6902 4.16659C17.6902 4.47612 17.6292 4.78262 17.5108 5.06859C17.3923 5.35455 17.2187 5.61439 16.9998 5.83326L5.74984 17.0833L1.1665 18.3333L2.4165 13.7499L13.6665 2.49993Z"
                                    stroke="#344054" strokeWidth="1.66667" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                            </svg>
                            Edit
                        </button>
                        <button onClick={onCopyLink}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={{marginRight: '12px'}}
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4.1665 12.5001H3.33317C2.89114 12.5001 2.46722 12.3245 2.15466 12.0119C1.8421 11.6994 1.6665 11.2754 1.6665 10.8334V3.33341C1.6665 2.89139 1.8421 2.46746 2.15466 2.1549C2.46722 1.84234 2.89114 1.66675 3.33317 1.66675H10.8332C11.2752 1.66675 11.6991 1.84234 12.0117 2.1549C12.3242 2.46746 12.4998 2.89139 12.4998 3.33341V4.16675M9.1665 7.50008H16.6665C17.587 7.50008 18.3332 8.24627 18.3332 9.16675V16.6667C18.3332 17.5872 17.587 18.3334 16.6665 18.3334H9.1665C8.24603 18.3334 7.49984 17.5872 7.49984 16.6667V9.16675C7.49984 8.24627 8.24603 7.50008 9.1665 7.50008Z"
                                    stroke="white" strokeWidth="1.66667" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                            </svg>
                            Copy
                        </button>
                    </div>
                </div>
                {
                    currentMultiStepLink && isMultiLinkOpened
                        ?
                        <>
                            <FacebookModal
                                isMultiStep={true}
                                onStepChange={handleButtonClick}
                                editMode={true}
                                isMobileLink={true}
                            />
                            <GoogleModal
                                isMultiStep={true}
                                onStepChange={handleButtonClick}
                                editMode={true}
                                isMobileLink={true}
                            />
                            <TikTokModal
                                isMultiStep={true}
                                onStepChange={handleButtonClick}
                                editMode={true}
                                isMobileLink={true}
                            />
                        </>
                        :
                        <>
                            <FacebookModal
                                editMode={true}
                                isMobileLink={true}
                            />
                            <GoogleModal
                                editMode={true}
                                isMobileLink={true}
                            />
                            <TikTokModal
                                editMode={true}
                                isMobileLink={true}
                            />
                        </>
                }
            </div>
        </PageWithSidebar>
    );
};

export default MobileLink;