import React, {useEffect} from 'react';
import s from "../ClientSuccesfulLogin/ClientSuccessfulLogin.module.scss";
import {userMultiStepHelper} from "../../helpers/localStorageHelper";

const oneclickLogo = require('../../assets/img/oneclick-logo.svg')
const authPageDecoration = require('../../img/authPageDecoration.svg')
const successIntegrationImg = require('../../img/successIntegration.jpg')

const MultiStepLoginSuccess = () => {
    const isFacebookStep = userMultiStepHelper.getUserMultiStepIsFacebookItem()
    const isGoogleStep = userMultiStepHelper.getUserMultiStepIsGoogleItem()
    const isTikTokStep = userMultiStepHelper.getUserMultiStepIsTikTokItem()

    useEffect(() => {
        userMultiStepHelper.removeUserMultiStepConfigId()
        userMultiStepHelper.removeUserMultiCurrentIndex()
        userMultiStepHelper.removeIsUserMultiStepLogin()
        userMultiStepHelper.removeUserMultiStepLinksArrLength()
        userMultiStepHelper.removeUserMultiStepLinkFacebookConfigId()
        userMultiStepHelper.removeUserMultiStepLinkGoogleConfigId()
        userMultiStepHelper.removeUserMultiStepLinkTikTokConfigId()
        userMultiStepHelper.removeUserMultiStepLinkFacebookRedirectUri()
        userMultiStepHelper.removeUserMultiStepLinkGoogleRedirectUri()
        userMultiStepHelper.removeUserMultiStepLinkTikTokRedirectUri()
        userMultiStepHelper.removeUserMultiStepIsFacebookItem()
        userMultiStepHelper.removeUserMultiStepIsGoogleItem()
        userMultiStepHelper.removeUserMultiStepIsTikTokItem()
    } , [])

    return (
        <div className={s.inner}>
            <oneclickLogo.ReactComponent style={{position: 'absolute', top: '40px', left: '40px'}}/>
            <authPageDecoration.ReactComponent className={s.inner__decoration}/>
            <div className={s.contentBlock}>
                <img src={successIntegrationImg} alt='success'/>
                <div className={s.contentBlock__textWrapper}>
                    <div className={s.contentBlock__separator}>
                    </div>
                    <h1>
                        Successfully granted access to your {isFacebookStep ? 'Facebook' : ''}{isGoogleStep ? 'Google' : ''} {isTikTokStep ? 'and TikTok' : ''} <span>accounts</span> and services!
                    </h1>
                    <p>
                        If you have any questions or concerns, please don't hesitate to reach out to us. We're here to
                        help you achieve your goals and grow your business. Please, check your email for future details.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MultiStepLoginSuccess;


