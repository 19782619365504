import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AsyncThunkConfig, RootState} from "./store";
import {businessAssetPermission, businessesController} from "../api/api";
import {AppStatusType} from "./appStatusReducer";
import { LogoutFacebookThunk } from "./permissionConfigurationReducer";
import { IntegrateWithFacebookThunk } from "./userReducer";

interface InitialStateType {
    permissionCatalog: any
    userBusinesses: any
}

const initialState: InitialStateType = {
    permissionCatalog: {},
    userBusinesses: []

}

export const businessAssetSlice = createSlice({
    name: 'businessAsset',
    initialState,
    reducers: {
        setPermissionCatalog: (state, action: PayloadAction<boolean>) => {
            state.permissionCatalog = action.payload
        },
        setUserBusinesses: (state, action: PayloadAction<any>) => {
            state.userBusinesses = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetBusinessPermissionThunk.fulfilled, (state, action) => {
                state.permissionCatalog = action.payload
            })
            .addCase(GetUserBusinessesThunk.fulfilled, (state, action) => {
                state.userBusinesses = action.payload
            })
            .addCase(IntegrateWithFacebookThunk.fulfilled, (state, action) => {
                state.userBusinesses = action.payload.businesses
            })
            .addCase(LogoutFacebookThunk.fulfilled, (state) => {
                state.permissionCatalog = {}
                state.userBusinesses = []
            })
    }
})

export const {setPermissionCatalog, setUserBusinesses} = businessAssetSlice.actions


export const selectPermissionCatalog = (state: RootState): any => state.businessAsset.permissionCatalog
export const selectUserBusinesses = (state: RootState): any => state.businessAsset.userBusinesses

export const GetBusinessPermissionThunk = createAsyncThunk<any, void, AsyncThunkConfig>(
    'businessAsset/getBusinessPermissions',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await businessAssetPermission.getBusinessAssetPermission()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.permission_catalog, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)



export const GetUserBusinessesThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'businessAsset/getUserBusinesses',
    async (user_id, thunkAPI) => {
        try {
            const {status, data} = await businessesController.getUserBusinesses(user_id)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.business, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const UploadAgentLogoThunk = createAsyncThunk<any, {business_id: number, logo: any}, AsyncThunkConfig>(
    'businessAsset/uploadAgentLogo',
    async (request, thunkAPI) => {
        try {
            const {status, data} = await businessesController.uploadAgentLogo(request.business_id, request.logo)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.business, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export default businessAssetSlice.reducer
