import React, {useState} from 'react';
import CommonModal from "../CommonModal/CommonModal";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
    selectBeforeAddingLinksTableModalOpen,
    setBeforeAddingLinksModalOpen,
} from "../../store/utilityReducer";
import s from "../FacebookLinksTable/FacebookLinksTable.module.scss";
import classes from './BeforeAddingLinkModal.module.scss'
import {Checkbox, Input, message} from "antd";

import {setMultiStageServices} from "../../store/linksReducer";
import {selectTikTokAccount} from "../../store/tikTokReducer";
import {selectGoogleAccount} from "../../store/googleReducer";
import {selectUserBusinesses} from "../../store/businessAssetReducer";
import {
    onChangeGeneralLinkDescription,
    onChangeGeneralLinkName,
    selectGeneralLinkCommonDescription,
    selectGeneralLinkCommonName
} from "../../store/multiStepReducer";


const closeIcon = require('../../img/icons/close.svg')

const BeforeAddingLinkModal = () => {
    const isModalOpen = useAppSelector(selectBeforeAddingLinksTableModalOpen)
    const [checked, setChecked] = useState<any>([]);
    const integrationsArray = ['Facebook', 'Google', 'TikTok']
    const dispatch = useAppDispatch()
    const tikTokAccount = useAppSelector(selectTikTokAccount)
    const googleAccount = useAppSelector(selectGoogleAccount)
    const userBusinesses = useAppSelector(selectUserBusinesses)
    const generalLinkCommonName = useAppSelector(selectGeneralLinkCommonName)
    const generalLinkCommonDescription = useAppSelector(selectGeneralLinkCommonDescription)
    const handleCheck = (event: any) => {
        const name = event.target.name;
        if (event.target.checked) {
            const checkedArray = [...checked, name]
            setChecked(checkedArray);
        } else {
            const checkedArray = checked.filter((c: any) => c !== name)
            setChecked(checkedArray)
        }
    };

    const onCloseModal = () => {
        dispatch(setBeforeAddingLinksModalOpen(false))
    }

    const onConfirmIntegrations = () => {
        if(generalLinkCommonDescription !== '' && generalLinkCommonName !== ''){
            dispatch(setMultiStageServices(checked))
            dispatch(setBeforeAddingLinksModalOpen(false))
        }
       else {
           message.error('Please fill the name and description values!')
        }
    }


    const checkIsIntegrated: (title: string) => boolean = (title) => {
        if (title === 'TikTok') {
            return !(Object.keys(tikTokAccount).length === 0 || tikTokAccount.email === null)
        } else if (title === 'Google') {
            return Object.keys(googleAccount).length !== 0 && googleAccount.google_ads_manager_email !== null
        } else if (title === 'Facebook') {
            return !!userBusinesses.length
        } else {
            return !(Object.keys(tikTokAccount).length === 0 || tikTokAccount.email === null)
                ||
                Object.keys(googleAccount).length !== 0
                ||
                !!userBusinesses.length
        }
    }

    return (
        <div>
            <CommonModal active={isModalOpen} height={'338px'} width={'470px'}>
                <div className={classes.inner}>
                    <button className={s.modal__closeBtn} onClick={onCloseModal}>
                        <closeIcon.ReactComponent/>
                    </button>
                    <h2>Add Link</h2>
                    <p style={{
                        marginBottom: '10px'
                    }}>Select the general name of the link:</p>
                    <div style={{
                        marginBottom: '15px'
                    }}>
                        <Input
                            placeholder={'Enter name please'}
                            className={s.modal__input}
                            required
                            value={generalLinkCommonName}
                            onChange={(e: any) => dispatch(onChangeGeneralLinkName(e.target.value))}
                        />
                    </div>
                    <p style={{
                        marginBottom: '10px'
                    }}>Select the general description of the link:</p>
                    <div style={{
                        marginBottom: '15px'
                    }}>
                        <Input
                            placeholder={'Enter description please'}
                            className={s.modal__input}
                            required
                            value={generalLinkCommonDescription}
                            onChange={(e: any) => dispatch(onChangeGeneralLinkDescription(e.target.value))}
                        />
                    </div>
                    <p>Select the networks to include in the link:</p>
                    {integrationsArray.map((i: string) => {
                        const isIntegrated = checkIsIntegrated(i)
                        return (
                            <div style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                                 key={i}
                                 className={classes.checkboxItem}
                            >
                                <Checkbox
                                    onChange={handleCheck}
                                    name={i}
                                    checked={checked.includes(i)}
                                    className={s.modal__checkbox}
                                    disabled={!isIntegrated}
                                >
                                    {i}
                                </Checkbox>
                                {/*{*/}
                                {/*    i === 'Google' ?*/}
                                {/*        <Tooltip title={'You need to set your Google account configs to enable this integration'}>*/}
                                {/*            <QuestionCircleOutlined/>*/}
                                {/*        </Tooltip>*/}
                                {/*        :*/}
                                {/*        null*/}
                                {/*}*/}
                            </div>
                        )
                    })}
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '30px'
                    }}>
                        <button
                            className={s.modal__btn}
                            type={"submit"}
                            onClick={onConfirmIntegrations}
                        >Confirm
                        </button>
                    </div>
                </div>
            </CommonModal>
        </div>
    );
};

export default BeforeAddingLinkModal;
