import React from 'react';
import s from './IntegrationCard.module.scss'
import {CheckCircleTwoTone, ExclamationCircleTwoTone} from "@ant-design/icons";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {useAppDispatch, useAppSelector} from '../../hooks';
import {
    IntegrateWithFacebookThunk,
    LoginGoogleFirstStageThunk,
    LoginTikTokFirstStageThunk
} from '../../store/userReducer';
import {authApi} from '../../api/api';
import {googleDataHelper, tikTokDataHelper, tutorialDataHelper} from '../../helpers/localStorageHelper';
import {Button, Popconfirm} from 'antd';
import {LogoutFacebookThunk} from '../../store/permissionConfigurationReducer';
import {setGoogleModalOpen} from '../../store/utilityReducer';
import {selectGoogleAccount} from '../../store/googleReducer';
import GoogleAccountConfigsModal from '../GoogleAccountConfigsModal/GoogleAccountConfigsModal';
import {selectCurrentTutorialStep, selectIsTutorial, setCurrentTutorialStep} from "../../store/tutorialReducer";

type IntegrationCardPropsType = {
    image: any,
    title: 'Facebook' | 'Google' | 'Tik Tok' | 'All links' | 'General link',
    text: string,
    isIntegrated: boolean,
    allowIntegration: boolean,
    children?: any,
    link?: string
}

const IntegrationCard = (props: IntegrationCardPropsType) => {
    const currentTutorialStep = useAppSelector(selectCurrentTutorialStep)
    const isTutorial = useAppSelector(selectIsTutorial)
    const dispatch = useAppDispatch()

    const onSetNextStep = () => {
        if(currentTutorialStep === 'Fourth step - Link creation / Home' && isTutorial){
            dispatch(setCurrentTutorialStep('Fourth step - Link creation / Table'))
            tutorialDataHelper.setCurrentTutorialStep('Fourth step - Link creation / Table')
        }
    }

    return (
        <>
            {!!props.link  ? (
                <div
                    className={((currentTutorialStep === 'Third step - Integrations / Google'
                        && isTutorial && props.title === 'Google') || (currentTutorialStep === 'Third step - Integrations / Google'
                        && isTutorial && props.title === 'Tik Tok')) || (currentTutorialStep === 'Fourth step - Link creation / Home'
                        && isTutorial && props.title === 'Google') ? `${s.card} ${s.card_tutorial}` : s.card}
                    style={{
                        backgroundColor: !!props.isIntegrated ? '#EDF4FF' : '#F8F8F8',
                        // opacity: props.title !== 'Facebook' ? 1 : 0.7,
                        // mixBlendMode: currentTutorialStep === 'Fourth step - Link creation / Home' && isTutorial && props.title === 'Google' ? 'hue' : 'initial'
                    }}
                    onClick={onSetNextStep}
                >
                    <CardInner {...props}/>
                </div>
            ) : (
                <div
                    className={((currentTutorialStep === 'Third step - Integrations / Google'
                        && isTutorial && props.title === 'Google') || (currentTutorialStep === 'Third step - Integrations / Google'
                        && isTutorial && props.title === 'Tik Tok')) || (currentTutorialStep === 'Fourth step - Link creation / Home'
                        && isTutorial && props.title === 'Google') ? `${s.card} ${s.card_tutorial}` : s.card}
                    style={{
                        backgroundColor: !!props.isIntegrated ? '#EDF4FF' : '#F8F8F8',
                        opacity: 1
                    }}>
                    <CardInner {...props}/>
                </div>
            )}
            <GoogleAccountConfigsModal/>
        </>
    );
};

const CardInner = ({image, title, text, isIntegrated, allowIntegration}: IntegrationCardPropsType) => {
    const dispatch = useAppDispatch()
    const googleAccount = useAppSelector(selectGoogleAccount)
    const isGeneralTableCard = title === 'All links'


    const onSetGoogleModalOpen = (e: any) => {
        e.stopPropagation()
        e.preventDefault()
        dispatch(setGoogleModalOpen(true))
    }
    
    return (
        <>
            {title !== 'General link'
                ?
                <>
                    {!isGeneralTableCard && !!isIntegrated  &&
                        <div className={s.card__statusWithIntegration}>
                            <div className={s.card__statusIcon}>
                                <CheckCircleTwoTone
                                    twoToneColor={'#52c41a'}
                                    style={{fontSize: '10px'}}
                                />
                            </div>
                            integrated
                        </div>
                    }
                    {!isGeneralTableCard && !isIntegrated  &&
                        <div className={s.card__statusNoIntegration}>
                            <div className={s.card__statusIcon}>
                                <ExclamationCircleTwoTone
                                    twoToneColor={'#F33B12'}
                                    style={{fontSize: '10px'}}
                                />
                            </div>
                            not integrated
                        </div>
                    }
                    {/*{title === 'Facebook'*/}
                    {/*    &&*/}
                    {/*    <div className={`${s.card__statusNoIntegration} ${s.card__statusNoIntegration_coming}`}>*/}
                    {/*        <span>coming soon</span>*/}
                    {/*    </div>}*/}
                </>
                :
                null

            }

            {title !== 'General link'
                ?
                <div className={s.card__img_general}>
                    <img src={image} alt="card-logo" className={isGeneralTableCard ? s.card__img__generalCard : ''}/>
                </div>
                :
                <div className={isGeneralTableCard ? s.card__generalImg : s.card__img}>
                    <img src={image} alt="card-logo" className={isGeneralTableCard ? s.card__img__generalCard : ''}/>
                </div>
            }

            <h3 className={s.card__title}>{title}</h3>
            <p className={s.card__text}>{text}</p>
            {title === 'Google'
                && googleAccount.google_ads_manager_email === null
                && googleAccount.google_ads_manager_id === null &&
                !!allowIntegration &&
                <div
                    onClick={onSetGoogleModalOpen}
                    style={{
                        fontSize: '12px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        color: 'rgba(0, 0, 0, 0.85)',
                        position: 'absolute',
                        bottom: '60px',
                        left: '90px',
                    }}
                >
                    Google account configs
                </div>
            }
            {!!allowIntegration &&
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {title !== 'General link' && title !== 'All links'
                        ?
                        <div
                            onClick={(e) => e.stopPropagation()}
                            style={{
                                alignSelf: 'center',
                                marginTop: 'auto',
                            }}
                            className={s.card__btn_mobile}
                        >

                            {title === 'Facebook' && !isIntegrated &&
                                <>
                                    <FacebookLoginBtn/>

                                </>

                            }
                            {((title === 'Facebook' && isIntegrated) || (title !== 'Facebook' && !isGeneralTableCard)) &&
                                <>
                                    <ServiceIntegrateBtn
                                        isIntegrated={isIntegrated}
                                        cardTitle={title}
                                    />

                                </>

                            }
                        </div>
                        :
                        null
                    }

                </div>

            }
        </>
    )
}

const ServiceIntegrateBtn: React.FC<{ isIntegrated: boolean, cardTitle: 'Tik Tok' | 'Facebook' | 'Google' | 'General link', onClick?: (data: any) => void }> = ({
                                                                                                                                                                    isIntegrated,
                                                                                                                                                                    cardTitle,
                                                                                                                                                                    onClick
                                                                                                                                                                }) => {
    const dispatch = useAppDispatch()
    const onLogin = (service: "Tik Tok" | "Facebook" | "Google" | "General link") => {
        if (service === 'Tik Tok') {
            dispatch(LoginTikTokFirstStageThunk())
        } else if (service === 'Google') {
            dispatch(LoginGoogleFirstStageThunk())
        }
    }

    const onLogout = (service: "Tik Tok" | "Facebook" | "Google" | "General link") => {
        if (service === 'Tik Tok') {
            onTikTokLogout()
        } else if (service === 'Google') {
            onGoogleLogout()
        } else {
            onFacebookLogout()
        }
    }

    const onGoogleLogout = () => {
        authApi.googleLogout()
            .then(() => {
                window.location.reload()
            })
            .catch((e) => {
                console.log(e)
            })
        googleDataHelper.removeGoogleCode()
    }

    const onTikTokLogout = () => {
        authApi.tikTokLogout()
            .then(() => {
                window.location.reload()
            })
            .catch((e) => {
                console.log(e)
            })
        tikTokDataHelper.removeTikTokCode()
    }

    const onFacebookLogout = () => {
        dispatch(LogoutFacebookThunk())
    }

    return (

        cardTitle !== 'General link'
            ?

            <Popconfirm
                title={`Are you sure to logout? Previously created links will be deleted.`}
                onConfirm={() => onLogout(cardTitle)}
                okText="Yes"
                cancelText="No"
                disabled={!isIntegrated}
            >
                <Button
                    type='primary'
                    className={s.card__btn}
                    style={{
                        width: '100%',
                        alignSelf: 'center',
                        marginTop: 'auto',
                        ...(isIntegrated ? {
                                background: '#FFFFFF',
                                border: '1px solid #667085',
                                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                borderRadius: '8px',
                                color: '#667085'
                            } : {}
                        )
                    }}
                    onClick={!!onClick
                        ? (data: any) => onClick(data)
                        : () => isIntegrated ? undefined : onLogin(cardTitle)
                    }
                >
                    {isIntegrated ? 'Log out' : 'Log in'}
                </Button>
            </Popconfirm>
            :
            null
    )
}
const FacebookLoginBtn = () => {
    const dispatch = useAppDispatch()

    const responseFacebook = (response: any) => {
        if (response.accessToken) {
            const request = {
                user_name: response.name,
                account_id: response.userID,
                businesses: response.businesses.data.map((b: any) => ({
                    business_id: b.id,
                    name: b.name
                }))
            }
            dispatch(IntegrateWithFacebookThunk(request))
        }
    }

    return (
        <FacebookLogin
            appId='924057132063159'
            fields='businesses, name, picture'
            scope='business_management'
            callback={responseFacebook}
            render={(renderProps: any) => (
                <ServiceIntegrateBtn
                    isIntegrated={false}
                    cardTitle='Facebook'
                    onClick={renderProps.onClick}
                />
            )}
        />

    )
}

export default IntegrationCard;
