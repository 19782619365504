import Sidebar from "../Sidebar/Sidebar"
import s from './PageWithSidebar.module.scss'
import {useEffect, useState} from "react";
import {useAppSelector} from "../../hooks";
import {selectIsTutorial} from "../../store/tutorialReducer";

const PageWithSidebar:React.FC<{children: any}> = ({children}) => {
    const [isVisible, setIsVisible] = useState(false);
    const isTutorial = useAppSelector(selectIsTutorial)
    useEffect(() => {
        if(isTutorial){
            setIsVisible(true);
        }
    }, [isTutorial])

  return (
    <div
        className={`${s.inner} ${isTutorial && 'fade-in-content'} ${isVisible ? `${s.inner} ${isTutorial && 'fade-in'}` : ''}` }>
      <Sidebar />
      <div style={{width: '100%'}}>
        {children}
      </div>
    </div>
  )
}

export default PageWithSidebar
