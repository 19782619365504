import React, {useEffect, useState} from 'react';
import PageTitle from "../../PageTitle/PageTitle";
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import s from './ClientsTable.module.scss'
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {
    ClientsTabType,
    selectClientsTableCurrentTab,
    selectCurrentScreenHeight, selectCurrentScreenWidth, selectIsSendLinkModalOpened,
    setClientsTableCurrentTab, setCurrentClientModalStep, setIsSendLinkModalOpened
} from "../../../store/utilityReducer";
import RequirementSearchInput from "../../RequirementSearchInput/RequirementSearchInput";
import {DataGridPro, GridSortModel, useGridApiRef} from "@mui/x-data-grid-pro";
import tableS from "../../FacebookLinksTable/FacebookLinksTable.module.scss";
import {Box, CircularProgressProps} from "@material-ui/core";
import SendLinkModal from "../../SendLinkModal/SendLinkModal";
import {
    GetClientsThunk,
    onClearCurrentClientServices,
    onSetClientServices,
    onSetGoogleServiceIds,
    onSetIsGoogleCheckboxTouched,
    onSetResetLinkMode,
    selectClients,
    selectClientsTotalCount,
    selectCurrentPage,
    selectSearchValue,
    setCurrentClient,
    setCurrentPage,
    setIsClientLinkResending,
    UpdateClientThunk
} from "../../../store/clientReducer";
import {useNavigate} from "react-router";
import Avatar from 'react-avatar';
import {onClearGoogleModalLinkPermissions} from "../../../store/googleReducer";
import {clientLoginDataHelper, tokenDataHelper, userDataHelper} from "../../../helpers/localStorageHelper";
import {Empty, Progress} from "antd";
import {ClientServiceType} from "../../../types/clientsTypes";
import moment from "moment/moment";
import {CircularProgress, circularProgressClasses, Tooltip} from "@mui/material";
import {setUserData} from "../../../store/userReducer";
import {GridSortDirection} from "@mui/x-data-grid/models/gridSortModel";
import axios from "axios";
import {pickBy} from "lodash";
import {UserDataType} from "../../../types/userTypes";


const deleteIcon = require('../../../img/icons/delete.svg')
const undoDeleteIcon = require('../../../img/icons/unarchive.svg')

const ClientsTable = () => {
    return (
        <PageWithSidebar>
            <ClientsTableComponent/>
        </PageWithSidebar>
    )
}


const ClientsTableComponent = () => {
    const dispatch = useAppDispatch()
    const isSendLinkModalOpened = useAppSelector(selectIsSendLinkModalOpened)
    const currentTab = useAppSelector(selectClientsTableCurrentTab)
    const apiRef = useGridApiRef();
    const [visibleRows, setVisibleRows] = useState<any[]>([])
    const clients = useAppSelector(selectClients)
    const clientsTotalCount = useAppSelector(selectClientsTotalCount)
    const paginationCurrentPage = useAppSelector(selectCurrentPage)
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const searchValue = useAppSelector(selectSearchValue)
    const [localSortModel, setLocalSortModel] = useState({})
    const token = tokenDataHelper.getTokenData()
    const [sortModelForApi, setSortModelForApi] = useState<{ field: string, sort?: GridSortDirection, direction?: string }>()
    useEffect(() => {
        dispatch(setUserData(userDataHelper.getUserData()))
    }, [dispatch])


    useEffect(() => {
        axios.get('/login/refresh', {
            headers: {
                'Authorization': token.token
            }
        })
            .then((response) => {
                userDataHelper.setUserData(pickBy(response.data, (_, key) => key !== 'token') as UserDataType)
            })
    }, [token.token])


    useEffect(() => {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('filter', new Blob([JSON.stringify({
            search_value: searchValue,
            status: currentTab === 'All clients' ? '' : currentTab
        })], {type: 'application/json'}))
        if (sortModelForApi) {
            formData.append('pagination', new Blob([JSON.stringify({
                page: paginationCurrentPage,
                size: 250,
                sort: [sortModelForApi]
            })], {type: 'application/json'}))
        } else {
            formData.append('pagination', new Blob([JSON.stringify({
                page: paginationCurrentPage,
                size: 250
            })], {type: 'application/json'}))
        }
        dispatch(GetClientsThunk(formData))
            .then(() => {
                setIsLoading(false)
                dispatch(setClientsTableCurrentTab(currentTab))
            })
        // eslint-disable-next-line
    }, [dispatch, paginationCurrentPage, sortModelForApi])

    useEffect(() => {
        dispatch(setUserData(userDataHelper.getUserData()))
    }, [dispatch])

    useEffect(() => {
        dispatch(onClearGoogleModalLinkPermissions())
        clientLoginDataHelper.removeCurrentClientDataId()
    }, [dispatch])

    const currentScreenHeight = useAppSelector(selectCurrentScreenHeight)
    const screenWidth = useAppSelector(selectCurrentScreenWidth)

    function convertToTitleCase(inputWord: string) {
        return inputWord?.charAt(0)?.toUpperCase() + inputWord?.slice(1)?.toLowerCase();
    }

    useEffect(() => {
        setVisibleRows(clients)
        dispatch(setClientsTableCurrentTab('All clients'))

    }, [clients, dispatch])

    const onChangeCurrentTab = (currentTab: ClientsTabType) => {
        dispatch(setClientsTableCurrentTab(currentTab))
        if (currentTab === 'Active') {
            setIsLoading(true)
            const formData = new FormData()
            formData.append('filter', new Blob([JSON.stringify({
                search_value: searchValue,
                status: currentTab
            })], {type: 'application/json'}))
            if (sortModelForApi) {
                formData.append('pagination', new Blob([JSON.stringify({
                    page: paginationCurrentPage,
                    size: 250,
                    sort: [sortModelForApi]
                })], {type: 'application/json'}))
            } else {
                formData.append('pagination', new Blob([JSON.stringify({
                    page: paginationCurrentPage,
                    size: 250
                })], {type: 'application/json'}))
            }
            dispatch(GetClientsThunk(formData))
                .then(() => {
                    setIsLoading(false)
                    dispatch(setClientsTableCurrentTab(currentTab))
                })
        } else if (currentTab === 'Archive') {
            setIsLoading(true)
            const formData = new FormData()
            formData.append('filter', new Blob([JSON.stringify({
                search_value: searchValue,
                status: currentTab
            })], {type: 'application/json'}))
            if (sortModelForApi) {
                formData.append('pagination', new Blob([JSON.stringify({
                    page: paginationCurrentPage,
                    size: 250,
                    sort: [sortModelForApi]
                })], {type: 'application/json'}))
            } else {
                formData.append('pagination', new Blob([JSON.stringify({
                    page: paginationCurrentPage,
                    size: 250
                })], {type: 'application/json'}))
            }
            dispatch(GetClientsThunk(formData))
                .then(() => {
                    setIsLoading(false)
                    dispatch(setClientsTableCurrentTab(currentTab))
                })
        } else {
            setIsLoading(true)
            const formData = new FormData()
            formData.append('filter', new Blob([JSON.stringify({
                search_value: searchValue,
                status: currentTab === 'All clients' ? '' : currentTab
            })], {type: 'application/json'}))
            if (sortModelForApi) {
                formData.append('pagination', new Blob([JSON.stringify({
                    page: paginationCurrentPage,
                    size: 250,
                    sort: [sortModelForApi]
                })], {type: 'application/json'}))
            } else {
                formData.append('pagination', new Blob([JSON.stringify({
                    page: paginationCurrentPage,
                    size: 250
                })], {type: 'application/json'}))
            }
            dispatch(GetClientsThunk(formData))
                .then(() => {
                    setIsLoading(false)
                })
        }
    }

    const onUpdateClient = (data: any) => {
        setIsLoading(true)
        const formDataTable = new FormData()
        formDataTable.append('pagination', new Blob([JSON.stringify({
            page: paginationCurrentPage,
            size: 250
        }, null, 2)], {type: 'application/json'}))
        formDataTable.append('filter', new Blob([JSON.stringify({search_value: searchValue})], {type: 'application/json'}))

            const formData = new FormData()
            formData.append('client', new Blob([JSON.stringify({
                name: data.row.name,
                email: data.row.email,
                status: data.row.status === "Active" || data.row.status === "ACTIVE" ? 'Archive' : 'Active'
            })], {type: 'application/json'}))
            dispatch(UpdateClientThunk({client: formData, clientId: data.row.id, tableData: formDataTable}))
            .then(() => {
                dispatch(GetClientsThunk(formDataTable))
                    .then(() => {
                        setIsLoading(false)
                    })
            })
    }


    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            flex: 0.2,
            color: '#6B7280',
            fontSize: '14px',
            lineHeight: '20px',
            filterable: false,
            renderCell: (data: any) => {
                const firstLetter = data.row.name.split(' ')[0].charAt(0)
                return (
                    <div
                        style={{
                            color: '#101828',
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            padding: '0 10px'
                        }}>
                        {data.row.logo_url !== null
                            ?
                            <img
                                src={data.row.logo_url}
                                alt='logo'
                                className={s.logoImg}
                                style={{
                                    marginRight: '12px',
                                    width: '40px',
                                    height: '40px',
                                    objectFit: 'cover',
                                    borderRadius: '50%'
                                }}
                            />
                            :
                            <Avatar name={firstLetter} size="40" round={'40px'} style={{marginRight: '12px'}}
                                    color={'#F9F5FF'} fgColor={'#0A5BEB'}/>

                        }
                        {data.row.name}
                    </div>)

            }
        },
        {
            field: 'email',
            headerName: 'Email address',
            flex: 0.2,
            filterable: false,
            renderCell: (data: any) => {
                return (
                    <div
                        style={{
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 400,
                            color: '#667085',
                            width: "fit-content",
                            fontSize: '14px',
                            lineHeight: '20px',
                            padding: '0 10px'
                        }}>
                        {data.row.email}
                    </div>)

            }
        },
        {
            field: 'status',
            headerName: 'Client status',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            flex: 0.15,
            filterable: false,
            renderCell: (data: any) => {
                return (
                    <div
                        style={{
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 500,
                            color: data.row.status === "ACTIVE" || data.row.status === "Active" ? '#027A48' : '#344054',
                            backgroundColor: data.row.status === "ACTIVE" || data.row.status === "Active" ? '#ECFDF3' : '#F2F4F7',
                            width: 'fit-content',
                            fontSize: '12px',
                            lineHeight: '18px',
                            padding: '0 10px',
                            borderRadius: '16px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: '22px',
                        }}>
                        <div style={{
                            width: '8px',
                            height: '8px',
                            backgroundColor: data.row.status === "ACTIVE" || data.row.status === "Active" ? '#12B76A' : '#667085',
                            borderRadius: '50%',
                            marginRight: '6px'
                        }}></div>
                        {convertToTitleCase(data.row.status)}
                    </div>)

            }
        },
        {
            field: 'min_status_value',
            headerName: 'Link activity',
            disableExport: true,
            flex: 0.15,
            filterable: false,
            renderCell: (data: any) => (
                data.row.min_status_value !== null
                    ?

                    <div
                        style={{
                            color: data.row.min_status_value === 'SEND' ? '#344054' : data.row.min_status_value === 'OPENED' ? '#B54708' : '#027A48',
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '18px',
                            cursor: 'pointer',
                            borderRadius: '16px',
                            padding: '2px 8px',
                            backgroundColor: data.row.min_status_value === 'SEND' ? '#F2F4F7' : data.row.min_status_value === 'OPENED' ? '#FFFAEB' : '#ECFDF3'
                        }}>
                        {convertToTitleCase(data.row.min_status_value === 'SEND' ? 'SENT' : data.row.min_status_value)}
                    </div>
                    :
                    <>
                    </>

            )

        },
        {
            field: 'integration_progress',
            headerName: '',
            flex: 0.2,
            disableExport: true,
            resizable: false,
            sortable: false,
            filterable: false,
            renderCell: (data: any) => {
                const integratedCount = data.row.configs.filter((s: ClientServiceType) => s.status === 'INTEGRATED')
                const integratedPercentage = (integratedCount.length / data.row.configs.length) * 100;
                return (
                    <div
                        style={{
                            color: '#075CEB',
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '14px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            cursor: 'pointer'
                        }}
                        className={'clientTableProgress'}
                        onClick={() => onRowClick(data)}
                    >
                        <Progress percent={integratedPercentage} style={{
                            marginLeft: '8px'
                        }} strokeColor={['#0A5BEB', '#9233F4']}/>
                    </div>

                )
            }
        },
        {
            field: 'date_created',
            headerName: 'Date Created',
            flex: 0.2,
            filterable: false,
            renderCell: (data: any) => {
                return (
                    <div
                        style={{
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 400,
                            color: '#667085',
                            width: "fit-content",
                            fontSize: '14px',
                            lineHeight: '20px',
                            padding: '0 10px'
                        }}>
                        {data.row.date_created ? moment(data.row.date_created).format('DD MMM YYYY') : ''}
                    </div>)

            }
        },
        {
            field: 'View_client',
            headerName: '',
            flex: 0.1,
            sortable: false,
            resizable: false,
            disableExport: true,
            filterable: false,
            hideable: false,
            renderCell: (data: any) => {
                return (
                    <div
                        style={{
                            color: '#075CEB',
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '14px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            cursor: 'pointer'
                        }}
                        onClick={() => onRowClick(data)}
                    >
                        View client
                    </div>

                )
            }
        },
        {
            field: 'archive',
            headerName: '',
            flex: 0.1,
            sortable: false,
            resizable: false,
            disableExport: true,
            filterable: false,
            renderCell: (data: any) => {
                return (
                    <div
                        className={s.icon}
                        style={{
                            cursor: 'pointer',
                            minWidth: '24px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        onClick={() => onUpdateClient(data)}
                    >
                        {data.row.status === "Active" || data.row.status === "ACTIVE"
                            ?
                            <Tooltip title="Archive" placement={'top'}>
                                <deleteIcon.ReactComponent/>
                            </Tooltip>
                            :
                            <Tooltip title="Unarchive" placement={'top'}>
                                <undoDeleteIcon.ReactComponent/>
                            </Tooltip>
                        }

                    </div>
                )
            }
        }
    ]

    const onAddNewClient = () => {
        dispatch(setCurrentClientModalStep('Create client'))
        dispatch(setIsSendLinkModalOpened(true))
        dispatch(setIsClientLinkResending(false))
        dispatch(onSetClientServices(['Google', 'Tik Tok']))
        dispatch(onSetGoogleServiceIds([1, 2, 3]))
        dispatch(onSetResetLinkMode(false))
        dispatch(onClearCurrentClientServices())
    }

    const onPageChange = (page: any) => {
        dispatch(setCurrentPage(page + 1))
    }

    const onRowClick = (data: any) => {
        dispatch(onSetIsGoogleCheckboxTouched(false))
        dispatch(setCurrentClient(data.row))
        navigate('/clientDetails')
        clientLoginDataHelper.setCurrentClientDataId(data.row.id)
    }

    useEffect(() => {
        dispatch(onSetClientServices([]))
    }, [dispatch])


    const handleSortModelChange = (newModel: GridSortModel) => {
        let sortModel: { field: string, sort?: GridSortDirection, direction?: string } = {...newModel[0]};
        let localSortModel = {...newModel}
        if ('sort' in sortModel) {
            sortModel.direction = sortModel.sort?.toUpperCase();
            delete sortModel.sort;
        }
        setLocalSortModel(localSortModel)
        setIsLoading(true)
        setSortModelForApi(sortModel)
    };
    return (
        <div className={s.inner}>
            <div className={s.inner__header}>
                <PageTitle
                    title='Clients'
                    description='View your clients'
                />
                <button
                    className={s.addClient}
                    onClick={onAddNewClient}
                >
                    + Add new Client
                </button>
            </div>
            <div className={s.tabs}>
                <div
                    className={currentTab === 'All clients' ? `${s.tabs__tab} ${s.tabs__tab_active}` : s.tabs__tab}
                    onClick={() => onChangeCurrentTab('All clients')}
                >
                    All clients
                </div>
                <div
                    className={currentTab === 'Active' ? `${s.tabs__tab} ${s.tabs__tab_active}` : s.tabs__tab}
                    onClick={() => onChangeCurrentTab('Active')}
                >
                    Active
                </div>
                <div
                    className={currentTab === 'Archive' ? `${s.tabs__tab} ${s.tabs__tab_active}` : s.tabs__tab}
                    onClick={() => onChangeCurrentTab('Archive')}
                >
                    Archive
                </div>
            </div>
            <RequirementSearchInput
                apiRef={apiRef}
                // setVisibleRowsCount={(count: number) => setVisibleRowsCount(count)}
                setVisibleRows={(rows: any[]) => setVisibleRows(rows)}
                allRows={clients}
                isClient={true}
                onChangeCurrentTab={(currentTab) => onChangeCurrentTab(currentTab)}
                setIsDataLoading={(isLoading) => setIsLoading(isLoading)}
            />
            <div className={tableS.tableInner}>
                <h2>
                    List of Clients
                </h2>
                {
                    isLoading
                        ?
                        <div style={{
                            height: currentScreenHeight - 387,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative'
                        }}>
                            <div style={{
                                position: "absolute",
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <FacebookCircularProgress size={100}/>
                            </div>

                        </div>
                        :
                        visibleRows.length
                            ?
                            <Box>
                                <DataGridPro
                                    sx={{
                                        borderRight: 'none',
                                        borderLeft: 'none',
                                        borderBottom: 'none',
                                        borderRadius: '0px',
                                        borderTop: '1px solid transparent',
                                        "& .MuiDataGrid-columnHeader": {
                                            background: '#F9FAFB',
                                            padding: '0px 20px',
                                            minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                                        },
                                        "& .MuiDataGrid-columnHeader:nth-child(3)": {
                                            background: '#F9FAFB',
                                            padding: '0px 20px',
                                            // minWidth: "540px !important"
                                        },
                                        // "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                        //     display: "none"
                                        // },
                                        "& .MuiDataGrid-cell": {
                                            minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                                        },
                                        "& .MuiDataGrid-cell:nth-child(3)": {
                                            // minWidth: "540px !important"
                                        },
                                        "& .MuiDataGrid-cellContent": {
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#111827',
                                            padding: '0px 10px',
                                            // minWidth: '540px'
                                        },
                                        "& .MuiDataGrid-cellContent:nth-child(3)": {
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#111827',
                                            padding: '0px 10px',
                                            // minWidth: '540px'
                                        },
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 600,
                                            color: '#6B7280',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                        },
                                        "& .MuiDataGrid-row:hover": {
                                            backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                        },
                                        "& .MuiDataGrid-row.Mui-selected": {
                                            backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                        },
                                        "& .MuiDataGrid-row.Mui-selected:hover": {
                                            backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                        },
                                        "& .MuiDataGrid-cell:focus": {
                                            outline: 'none'
                                        },
                                        "& .MuiDataGrid-toolbarContainer": {
                                            color: '#454545',
                                            position: 'absolute',
                                            top: '-62px',
                                            right: '0px'
                                        },
                                        "& .MuiButtonBase-root": {
                                            fontFamily: 'Inter, sans-serif !important',
                                            fontWeight: 600,
                                            color: '#6B7280',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                        },
                                        "& .MuiTablePagination-displayedRows": {
                                            marginBottom: 0
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            overflowY: 'scroll !important',
                                            overflowX: 'scroll !important',
                                            height: `${currentScreenHeight - 480}px !important`,
                                        },
                                    }}
                                    apiRef={apiRef}
                                    columns={columns}
                                    rows={visibleRows}
                                    {...visibleRows}
                                    rowHeight={58}
                                    getRowId={e => e.id}
                                    headerHeight={39}
                                    pagination={true}
                                    pageSize={250}
                                    page={paginationCurrentPage - 1}
                                    sortingOrder={['desc', 'asc']}
                                    rowCount={clientsTotalCount}
                                    rowsPerPageOptions={[250]}
                                    hideFooterSelectedRowCount
                                    disableColumnSelector={true}
                                    autoHeight={true}
                                    onPageChange={onPageChange}
                                    paginationMode={'server'}
                                    sortModel={Object.values(localSortModel)}
                                    onSortModelChange={handleSortModelChange}
                                />
                            </Box>
                            :
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: currentScreenHeight - 387,
                            }}>
                                <Empty/>
                            </div>
                }

            </div>
            {
                isSendLinkModalOpened
                &&
                <SendLinkModal/>
            }
        </div>
    );
};


export function FacebookCircularProgress(props: CircularProgressProps) {
    return (
        <Box sx={{position: 'relative'}}>
            {/*// @ts-ignore*/}
            <CircularProgress variant="determinate"
                              sx={{
                                  color: () => (['#9233F4']),
                              }}
                              size={props.size && 100}
                              thickness={4}
                              {...props}
                              value={100}
            />
            {/*// @ts-ignore*/}
            <CircularProgress variant="indeterminate"
                              disableShrink
                              sx={{
                                  color: () => (['#0A5BEB']),
                                  animationDuration: '550ms',
                                  position: 'absolute',
                                  left: 0,
                                  [`& .${circularProgressClasses.circle}`]: {
                                      strokeLinecap: 'round',
                                  },
                              }}
                              size={props.size && 100}
                              thickness={4}
                              {...props}
                              value={10}
            />
        </Box>
    );
}

export default ClientsTable;