import { TokenDataHelperType, UserDataHelperType } from '../types/localStorageDataTypes'

export const tokenDataHelper: TokenDataHelperType = {
  setTokenData(data) {
    localStorage.setItem('tokenData', JSON.stringify(data))
  },
  getTokenData() {
    const tokenData = localStorage.getItem('tokenData')
    return !!tokenData && JSON.parse(tokenData)
  },
  removeTokenData() {
    return localStorage.removeItem('tokenData')
  },
  setAdminTokenData(data) {
    localStorage.setItem('adminTokenData', JSON.stringify(data))
  },
  getAdminTokenData() {
    const adminTokenData = localStorage.getItem('adminTokenData')
    return !!adminTokenData && JSON.parse(adminTokenData)
  },
  removeAdminTokenData() {
    return localStorage.removeItem('adminTokenData')
  },
}

export const userDataHelper: UserDataHelperType = {
  setUserData(data) {
    localStorage.setItem('userData', JSON.stringify(data))
  },
  getUserData() {
    const userData = localStorage.getItem('userData')
    return !!userData && JSON.parse(userData)
  },
  removeUserData() {
    return localStorage.removeItem('userData')
  },
  //Setting services type for MobilLink component
  setMobileLinkServiceType(serviceType: string) {
    localStorage.setItem('serviceType', JSON.stringify(serviceType))
  },
  getMobileLinkServiceType() {
    const serviceType = localStorage.getItem('serviceType')
    return !!serviceType && JSON.parse(serviceType)
  },
  removeMobileLinkServiceType() {
    return localStorage.removeItem('serviceType')
  },
  //Setting admin token data
  setAdminData(data: any) {
    localStorage.setItem('adminData', JSON.stringify(data))
  },
  getAdminData() {
    const adminData = localStorage.getItem('adminData')
    return !!adminData && JSON.parse(adminData)
  },
  removeAdminData() {
    return localStorage.removeItem('adminData')
  },
  //Checking if its admin for refresh token
  setIsAdminUserData(data: boolean) {
    localStorage.setItem('isAdminData', JSON.stringify(data))
  },
  getIsAdminUserData() {
    const isAdminData = localStorage.getItem('isAdminData')
    return !!isAdminData && JSON.parse(isAdminData)
  },
  removeIsAdminUserData() {
    return localStorage.removeItem('isAdminData')
  },
}

export const clientLoginDataHelper: any = {
  setRedirectUri(data: any) {
    localStorage.setItem('redirect_uri', JSON.stringify(data))
  },
  getRedirectUri() {
    const redirect_uri = localStorage.getItem('redirect_uri')
    return !!redirect_uri && JSON.parse(redirect_uri)
  },
  setConfigId(data: any) {
    localStorage.setItem('config_id', JSON.stringify(data))
  },
  getConfigId() {
    const config_id = localStorage.getItem('config_id')
    return !!config_id && JSON.parse(config_id)
  },
  setActivityLinkId(data: string) {
    localStorage.setItem('activityLinkId', JSON.stringify(data))
  },
  getActivityLinkId() {
    const activityLinkId = localStorage.getItem('activityLinkId')
    return !!activityLinkId && JSON.parse(activityLinkId)
  },
  removeActivityLinkId() {
    return localStorage.removeItem('activityLinkId')
  },
  setLinkId(data: any) {
    localStorage.setItem('link_id', JSON.stringify(data))
  },
  getLinkId() {
    const link_id = localStorage.getItem('link_id')
    return !!link_id && JSON.parse(link_id)
  },
  setCurrentClientRedirectUrl(data: any) {
    localStorage.setItem('currentClientRedirectUrl', JSON.stringify(data))
  },
  getCurrentClientRedirectUrl() {
    const currentClientRedirectUrl = localStorage.getItem('currentClientRedirectUrl')
    return !!currentClientRedirectUrl && JSON.parse(currentClientRedirectUrl)
  },
  setCurrentClientDataId(data: any) {
    localStorage.setItem('currentClientDataId', JSON.stringify(data))
  },
  getCurrentClientDataId() {
    const currentClientDataId = localStorage.getItem('currentClientDataId')
    return !!currentClientDataId && JSON.parse(currentClientDataId)
  },
  removeCurrentClientDataId() {
    return localStorage.removeItem('currentClientDataId')
  },
}

export const googleDataHelper: any = {
  setGoogleCode(data: string) {
    localStorage.setItem('googleCode', JSON.stringify(data))
  },
  getGoogleCode() {
    const googleCode = localStorage.getItem('googleCode')
    return !!googleCode && JSON.parse(googleCode)
  },
  removeGoogleCode() {
    return localStorage.removeItem('googleCode')
  },
}

export const tikTokDataHelper: any = {
  setTikTokCode(data: string) {
    localStorage.setItem('tikTokCode', JSON.stringify(data))
  },
  getTikTokCode() {
    const tikTokCode = localStorage.getItem('tikTokCode')
    return !!tikTokCode && JSON.parse(tikTokCode)
  },
  removeTikTokCode() {
    return localStorage.removeItem('tikTokCode')
  },
  setTikTokUserType(data: string) {
    localStorage.setItem('TikTokUserType', JSON.stringify(data))
  },
  getTikTokUserType() {
    const TikTokUserType = localStorage.getItem('TikTokUserType')
    return !!TikTokUserType && JSON.parse(TikTokUserType)
  },
  removeTikTokUserType() {
    return localStorage.removeItem('TikTokUserType')
  },
}

export const userMultiStepHelper: any = {
  // id for getting available user links array
  setUserMultiStepConfigId(data: number) {
    localStorage.setItem('userMultiStepConfigId', JSON.stringify(data))
  },
  getUserMultiStepConfigId() {
    const userMultiStepConfigId = localStorage.getItem('userMultiStepConfigId')
    return !!userMultiStepConfigId && JSON.parse(userMultiStepConfigId)
  },
  removeUserMultiStepConfigId() {
    return localStorage.removeItem('userMultiStepConfigId')
  },
  // index of the current step
  setUserMultiStepCurrentIndex(data: number) {
    localStorage.setItem('userMultiCurrentIndex', JSON.stringify(data))
  },
  getUserMultiStepCurrentIndex() {
    const userMultiCurrentIndex = localStorage.getItem('userMultiCurrentIndex')
    return !!userMultiCurrentIndex && JSON.parse(userMultiCurrentIndex)
  },
  removeUserMultiCurrentIndex() {
    return localStorage.removeItem('userMultiCurrentIndex')
  },
  // setting entity for multistep login in user service integration components
  setIsUserMultiStepLogin(data: boolean) {
    localStorage.setItem('userMultiStepLogin', JSON.stringify(data))
  },
  getIsUserMultiStepLogin() {
    const userMultiStepLogin = localStorage.getItem('userMultiStepLogin')
    return !!userMultiStepLogin && JSON.parse(userMultiStepLogin)
  },
  removeIsUserMultiStepLogin() {
    return localStorage.removeItem('userMultiStepLogin')
  },
  // setting multistep clientLoginLinks.length to control stepping
  setUserMultiStepLinksArrLength(data: number) {
    localStorage.setItem('userMultiStepLinksArrLength', JSON.stringify(data))
  },
  getUserMultiStepLinksArrLength() {
    const userMultiStepLinksArrLength = localStorage.getItem('userMultiStepLinksArrLength')
    return !!userMultiStepLinksArrLength && JSON.parse(userMultiStepLinksArrLength)
  },
  removeUserMultiStepLinksArrLength() {
    return localStorage.removeItem('userMultiStepLinksArrLength')
  },
  // setting config id's for the multistep login requests - FB
  setUserMultiStepLinkFacebookConfigId(data: number) {
    localStorage.setItem('userMultiStepLinkFacebookConfigId', JSON.stringify(data))
  },
  getUserMultiStepLinkFacebookConfigId() {
    const userMultiStepLinkFacebookConfigId = localStorage.getItem('userMultiStepLinkFacebookConfigId')
    return !!userMultiStepLinkFacebookConfigId && JSON.parse(userMultiStepLinkFacebookConfigId)
  },
  removeUserMultiStepLinkFacebookConfigId() {
    return localStorage.removeItem('userMultiStepLinkFacebookConfigId')
  },
  // setting config id's for the multistep login requests - GOOGLE
  setUserMultiStepLinkGoogleConfigId(data: number) {
    localStorage.setItem('userMultiStepLinkGoogleConfigId', JSON.stringify(data))
  },
  getUserMultiStepLinkGoogleConfigId() {
    const userMultiStepLinkGoogleConfigId = localStorage.getItem('userMultiStepLinkGoogleConfigId')
    return !!userMultiStepLinkGoogleConfigId && JSON.parse(userMultiStepLinkGoogleConfigId)
  },
  removeUserMultiStepLinkGoogleConfigId() {
    return localStorage.removeItem('userMultiStepLinkGoogleConfigId')
  },
  // setting config id's for the multistep login requests - TT
  setUserMultiStepLinkTikTokConfigId(data: number) {
    localStorage.setItem('userMultiStepLinkTikTokConfigId', JSON.stringify(data))
  },
  getUserMultiStepLinkTikTokConfigId() {
    const userMultiStepLinkTikTokConfigId = localStorage.getItem('userMultiStepLinkTikTokConfigId')
    return !!userMultiStepLinkTikTokConfigId && JSON.parse(userMultiStepLinkTikTokConfigId)
  },
  removeUserMultiStepLinkTikTokConfigId() {
    return localStorage.removeItem('userMultiStepLinkTikTokConfigId')
  },
  // setting redirectUri for the multistep login requests - FB
  setUserMultiStepLinkFacebookRedirectUri(data: string | null) {
    localStorage.setItem('userMultiStepLinkFacebookRedirectUri', JSON.stringify(data))
  },
  getUserMultiStepLinkFacebookRedirectUri() {
    const userMultiStepLinkFacebookRedirectUri = localStorage.getItem('userMultiStepLinkFacebookRedirectUri')
    return !!userMultiStepLinkFacebookRedirectUri && JSON.parse(userMultiStepLinkFacebookRedirectUri)
  },
  removeUserMultiStepLinkFacebookRedirectUri() {
    return localStorage.removeItem('userMultiStepLinkFacebookRedirectUri')
  },
  // setting redirectUri for the multistep login requests - Google
  setUserMultiStepLinkGoogleRedirectUri(data: string | null) {
    localStorage.setItem('userMultiStepLinkGoogleRedirectUri', JSON.stringify(data))
  },
  getUserMultiStepLinkGoogleRedirectUri() {
    const userMultiStepLinkGoogleRedirectUri = localStorage.getItem('userMultiStepLinkGoogleRedirectUri')
    return !!userMultiStepLinkGoogleRedirectUri && JSON.parse(userMultiStepLinkGoogleRedirectUri)
  },
  removeUserMultiStepLinkGoogleRedirectUri() {
    return localStorage.removeItem('userMultiStepLinkGoogleRedirectUri')
  },
  // setting redirectUri for the multistep login requests - TT
  setUserMultiStepLinkTikTokRedirectUri(data: string | null) {
    localStorage.setItem('userMultiStepLinkTikTokRedirectUri', JSON.stringify(data))
  },
  getUserMultiStepLinkTikTolRedirectUri() {
    const userMultiStepLinkTikTokRedirectUri = localStorage.getItem('userMultiStepLinkTikTokRedirectUri')
    return !!userMultiStepLinkTikTokRedirectUri && JSON.parse(userMultiStepLinkTikTokRedirectUri)
  },
  removeUserMultiStepLinkTikTokRedirectUri() {
    return localStorage.removeItem('userMultiStepLinkTikTokRedirectUri')
  },
  // setting services names for multi step indicator and success message fb
  setUserMultiStepIsFacebookItem(data: boolean) {
    localStorage.setItem('userUserMultiStepIsFacebookItem', JSON.stringify(data))
  },
  getUserMultiStepIsFacebookItem() {
    const userUserMultiStepIsFacebookItem = localStorage.getItem('userUserMultiStepIsFacebookItem')
    return !!userUserMultiStepIsFacebookItem && JSON.parse(userUserMultiStepIsFacebookItem)
  },
  removeUserMultiStepIsFacebookItem() {
    return localStorage.removeItem('userUserMultiStepIsFacebookItem')
  },
  // setting services names for multi step indicator and success message google
  setUserMultiStepIsGoogleItem(data: boolean) {
    localStorage.setItem('userUserMultiStepIsGoogleItem', JSON.stringify(data))
  },
  getUserMultiStepIsGoogleItem() {
    const userUserMultiStepIsGoogleItem = localStorage.getItem('userUserMultiStepIsGoogleItem')
    return !!userUserMultiStepIsGoogleItem && JSON.parse(userUserMultiStepIsGoogleItem)
  },
  removeUserMultiStepIsGoogleItem() {
    return localStorage.removeItem('userUserMultiStepIsGoogleItem')
  },
  // setting services names for multi step indicator and success message tt
  setUserMultiStepIsTikTokItem(data: boolean) {
    localStorage.setItem('userUserMultiStepIsTikTokItem', JSON.stringify(data))
  },
  getUserMultiStepIsTikTokItem() {
    const userUserMultiStepIsTikTokItem = localStorage.getItem('userUserMultiStepIsTikTokItem')
    return !!userUserMultiStepIsTikTokItem && JSON.parse(userUserMultiStepIsTikTokItem)
  },
  removeUserMultiStepIsTikTokItem() {
    return localStorage.removeItem('userUserMultiStepIsTikTokItem')
  },
}

export const activityDataHelper: any = {
  setActivityCurrentServiceUrl(data: string) {
    localStorage.setItem('activityCurrentServiceUrl', JSON.stringify(data))
  },
  getActivityCurrentServiceUrl() {
    const activityCurrentServiceUrl = localStorage.getItem('activityCurrentServiceUrl')
    return !!activityCurrentServiceUrl && JSON.parse(activityCurrentServiceUrl)
  },
  removeActivityCurrentService() {
    return localStorage.removeItem('activityCurrentServiceUrl')
  },
  setActivityCurrentServiceNameAndAgentName(data: string) {
    localStorage.setItem('activityCurrentServiceNameAndAgentName', JSON.stringify(data))
  },
  getActivityCurrentServiceNameAndAgentName() {
    const activityCurrentServiceNameAndAgentName = localStorage.getItem('activityCurrentServiceNameAndAgentName')
    return !!activityCurrentServiceNameAndAgentName && JSON.parse(activityCurrentServiceNameAndAgentName)
  },
  removeActivityCurrentServiceNameAndAgentName() {
    return localStorage.removeItem('activityCurrentServiceNameAndAgentName')
  },
  setActivityCurrentLinkId(data: string) {
    localStorage.setItem('activityCurrentLinkId', JSON.stringify(data))
  },
  getActivityCurrentLinkId() {
    const activityCurrentLinkId = localStorage.getItem('activityCurrentLinkId')
    return !!activityCurrentLinkId && JSON.parse(activityCurrentLinkId)
  },
  removeActivityCurrentLinkId() {
    return localStorage.removeItem('activityCurrentLinkId')
  },
}

export const tutorialDataHelper = {
  setIsTutorial(data: boolean) {
    localStorage.setItem('isTutorial', JSON.stringify(data))
  },
  getIsTutorial() {
    const isTutorial = localStorage.getItem('isTutorial')
    return !!isTutorial && JSON.parse(isTutorial)
  },
  removeIsTutorial() {
    return localStorage.removeItem('isTutorial')
  },
  setCurrentTutorialStep(data: string | boolean) {
    localStorage.setItem('currentTutorialStep', JSON.stringify(data))
  },
  getCurrentTutorialStep() {
    const currentTutorialStep = localStorage.getItem('currentTutorialStep')
    return !!currentTutorialStep && JSON.parse(currentTutorialStep)
  },
  removeCurrentTutorialStep() {
    return localStorage.removeItem('currentTutorialStep')
  },
}