import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AsyncThunkConfig, RootState} from './store';
import {membersApi} from '../api/api';
import {AppStatusType} from './appStatusReducer';
import { AcceptInvitationDataType } from '../types/userTypes';

interface RoleType {
    id: number
    name: string
}

interface MemberDataType {
    id: number
    user_name?: string
    roles?: RoleType[]
    email: string
}

interface InitialStateType {
    memberList: MemberDataType[]
    availableRoles: RoleType[]
    agencyName: string
}

const initialState:InitialStateType = {
    memberList: [],
    availableRoles: [],
    agencyName: ''
}

export const membersSlice = createSlice({
    name: 'members',
    initialState,
    reducers: {
        setMemberList: (state, action: PayloadAction<MemberDataType[]>) => {
            state.memberList = action.payload
        },
        setAvailableRoles: (state, action: PayloadAction<RoleType[]>) => {
            state.availableRoles = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAllMembersThunk.fulfilled, (state, action) => {
                state.memberList = action.payload
            })
            .addCase(GetAvailableRolesThunk.fulfilled, (state, action) => {
                state.availableRoles = action.payload
            })
            .addCase(ChangeMemberRoleThunk.fulfilled, (state, action) => {
                state.memberList = state.memberList.map(member => (
                    member.id === action.payload.id && !!member?.roles?.length ? action.payload : member
                ))
            })
            .addCase(InviteMemberThunk.fulfilled, (state, action) => {
                state.memberList = [...state.memberList, action.payload]
            })
            .addCase(CancelMemberInvitationThunk.fulfilled, (state, action) => {
                state.memberList = state.memberList.filter(member => (
                    member.id !== action.payload || (member.id === action.payload && !!member?.roles?.length)
                ))
            })
            .addCase(DeleteMemberThunk.fulfilled, (state, action) => {
                state.memberList = state.memberList.filter(member => (
                    member.id !== action.payload || (member.id === action.payload && !member?.roles?.length)
                ))
            })
            .addCase(GetAgencyForUserThunk.fulfilled, (state, action) => {
                state.agencyName = action.payload
            })
    }
})

export const { setMemberList, setAvailableRoles } = membersSlice.actions

export const selectMemberList = (state: RootState): MemberDataType[] => state.members.memberList
export const selectAvailableRoles = (state: RootState): RoleType[] => state.members.availableRoles
export const selectAgencyName = (state: RootState): any => state.members.agencyName

export const GetAllMembersThunk = createAsyncThunk<MemberDataType[], void, AsyncThunkConfig>(
    'members/getAllMembersLinks',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await membersApi.getAllMembers()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.users_invites, {appStatus: AppStatusType.idle})
            }
            else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error)
        }
    }
)

export const GetAvailableRolesThunk = createAsyncThunk<RoleType[], void, AsyncThunkConfig>(
    'members/getAvailableRoles',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await membersApi.getAllAvailableRoles()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.roles, {appStatus: AppStatusType.idle})
            }
            else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error)
        }
    }
)

export const ChangeMemberRoleThunk = createAsyncThunk<MemberDataType, {userId: number, role: number[]}, AsyncThunkConfig>(
    'members/changeMemberRole',
    async ({userId, role}, thunkAPI) => {
        try {
            const {status, data} = await membersApi.changeMemberRole(userId, role)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            }
            else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error)
        }
    }
)

export const InviteMemberThunk = createAsyncThunk<MemberDataType, string, AsyncThunkConfig>(
    'members/inviteMember',
    async (email, thunkAPI) => {
        try {
            const {status, data} = await membersApi.inviteMember(email)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Invitation has been successfully sent'})
            }
            else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error)
        }
    }
)

export const CheckInvitationTokenThunk = createAsyncThunk<void, string, AsyncThunkConfig>(
    'members/checkInvitationToken',
    async (token, thunkAPI) => {
        try {
            const {status, data} = await membersApi.checkInvitationToken(token)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            }
            else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error)
        }
    }
)

export const AcceptMemberInvitationThunk = createAsyncThunk<void, AcceptInvitationDataType, AsyncThunkConfig>(
    'members/inviteMember',
    async (acceptInvitationData, thunkAPI) => {
        try {
            const {status, data} = await membersApi.acceptMemberInvitation(acceptInvitationData)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data.message, {appStatus: AppStatusType.idle})
            }
            else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error)
        }
    }
)

export const CancelMemberInvitationThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'members/cancelMemberInvitation',
    async (memberId, thunkAPI) => {
        try {
            const {status, data} = await membersApi.cancelMemberInvitation(memberId)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(memberId, {appStatus: AppStatusType.succeeded, appMessage: 'Invitation has been canceled'})
            }
            else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error)
        }
    }
)

export const DeleteMemberThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'members/deleteMember',
    async (memberId, thunkAPI) => {
        try {
            const {status, data} = await membersApi.deleteMember(memberId)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(memberId, {appStatus: AppStatusType.succeeded, appMessage: 'Member has been successfully deleted'})
            }
            else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error)
        }
    }
)

export const GetAgencyForUserThunk = createAsyncThunk<string, string, AsyncThunkConfig>(
    'members/getAgencyForUser',
    async (token, thunkAPI) => {
        try {
            const {status, data} = await membersApi.getAgencyNameForUser(token)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            }
            else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error)
        }
    }
)


export default membersSlice.reducer
