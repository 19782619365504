import React, {useEffect, useState} from 'react';
import {activityDataHelper} from "../../../helpers/localStorageHelper";
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import s from "../../FacebookLinksTable/FacebookLinksTable.module.scss";
import PageTitle from "../../PageTitle/PageTitle";
import {Box} from "@material-ui/core";
import {DataGridPro, useGridApiRef} from "@mui/x-data-grid-pro";
import {useAppSelector} from "../../../hooks";
import {selectActivitiesLinks} from "../../../store/activityTrackerReducer";
import moment from "moment/moment";
import {selectCurrentScreenHeight} from "../../../store/utilityReducer";



const ActivityCurrentLink = () => {
    return (
        <PageWithSidebar>
            <ActivityCurrentLinkComponent/>
        </PageWithSidebar>
    )
};

const ActivityCurrentLinkComponent = () => {
    const currentUrl = activityDataHelper.getActivityCurrentServiceUrl()
    const tableTitle = activityDataHelper.getActivityCurrentServiceNameAndAgentName()
    const [screenWidth, setScreenWidth] = useState<number>(0)
    const activitiesLinks = useAppSelector(selectActivitiesLinks)
    // eslint-disable-next-line
    const [visibleRows, setVisibleRows] = useState<any[]>([])
    const currentScreenHeight = useAppSelector(selectCurrentScreenHeight)
    useEffect(() => {
        setVisibleRows(activitiesLinks)
    }, [activitiesLinks])

    function convertToTitleCase(inputWord: string) {
        return inputWord?.charAt(0)?.toUpperCase() + inputWord?.slice(1)?.toLowerCase();
    }
    //handle current width screen for media queries
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const apiRef = useGridApiRef();

    const columns = [
        {
            field: 'recipient_email',
            headerName: 'Recipient email/username',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            width: 300,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
        },
        {
            field: 'status',
            headerName: 'Status',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            width: 200,
            fontSize: '12px',
            lineHeight: '20px',
            renderCell: (data: any) => (<div
                style={{
                    color: data.row.status === 'SENT' ? '#344054' : data.row.status === 'OPENED' ? '#B54708' : '#027A48',
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '18px',
                    cursor: 'pointer',
                    borderRadius: '16px',
                    padding: '2px 8px',
                    backgroundColor: data.row.status === 'SENT' ? '#F2F4F7' : data.row.status === 'OPENED' ? '#FFFAEB' : '#ECFDF3'
                }}>
                {convertToTitleCase(data.row.status)}
            </div>)
        },
        {
            field: 'user_email',
            headerName: 'Integrated email/username',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            width: 250,
            lineHeight: '20px',
        },
        {
            field: 'permission_list',
            headerName: 'Permissions',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            width: 500,
            renderCell: (data: any) => (<div style={{ fontFamily: 'Inter, sans-serif',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '20px',
                color: '#111827',
                whiteSpace: 'inherit',
                padding: '0px 10px'}}>{data?.row?.permission_list?.split(', ').join(',\n')}</div>)
        },
        {
            field: 'last_activity',
            headerName: 'Last Activity',
            disableExport: true,
            width: 280,
            renderCell: (data: any) => (<div
                style={{
                    color: '#667085',
                    marginLeft: '10px',
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '22px',
                    cursor: 'pointer'
                }}>
                {data.row.last_activity ? moment(data.row.last_activity).format('DD MMM YYYY (hh:mm A)') : ''}
            </div>)
        },
    ]

    return (
        <div
            style={{
                padding: screenWidth > 1160 ? '32px' : screenWidth > 480 ? '10px 20px' : '10px 8px'
            }}>
            <Breadcrumbs/>
            <div className={s.inner__header}>
                <PageTitle
                    title={tableTitle}
                    description={currentUrl}
                    backLink={'/activity'}
                />
            </div>
            <div className={s.tableInner}>
                <h2>
                   Customers
                </h2>
                <Box>
                    <DataGridPro
                        sx={{
                            borderRight: 'none',
                            borderLeft: 'none',
                            borderBottom: 'none',
                            borderRadius: '0px',
                            borderTop: '1px solid transparent',
                            "& .MuiDataGrid-columnHeader": {
                                background: '#F9FAFB',
                                padding: '0px 20px',
                                minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                            },
                            "& .MuiDataGrid-columnHeader:last-child": {
                                background: '#F9FAFB',
                                padding: '0px 20px',
                                // minWidth: "210px !important"
                            },
                            "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {

                            },
                            "& .MuiDataGrid-cell":{
                                minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                            },
                            "& .MuiDataGrid-cell:last-child":{
                                // minWidth: "210px !important"
                            },
                            "& .MuiDataGrid-cellContent": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#111827',
                                padding: '0px 10px',
                                // minWidth: '210px'
                            },
                            "& .MuiDataGrid-cellContent:last-child": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#111827',
                                padding: '0px 10px',
                                // minWidth: '210px'
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 600,
                                color: '#6B7280',
                                fontSize: '12px',
                                lineHeight: '20px',
                            },
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-row.Mui-selected": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-row.Mui-selected:hover": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-cell:focus": {
                                outline: 'none'
                            },
                            "& .MuiDataGrid-toolbarContainer": {
                                color: '#454545',
                                position: 'absolute',
                                top: '-62px',
                                right: '0px'
                            },
                            "& .MuiButtonBase-root": {
                                fontFamily: 'Inter, sans-serif !important',
                                fontWeight: 600,
                                color: '#6B7280',
                                fontSize: '12px',
                                lineHeight: '20px',
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginBottom: 0
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                overflowY: 'scroll !important',
                                overflowX: 'scroll !important',
                                height: `${currentScreenHeight - 355}px !important`,
                            },
                        }}
                        apiRef={apiRef}
                        columns={columns}
                        rows={visibleRows}
                        {...visibleRows}
                        rowHeight={58}
                        getRowId={e => e.id}
                        headerHeight={39}
                        pagination={true}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        hideFooterSelectedRowCount
                        autoHeight={true}
                        autoPageSize={true}
                    />
                </Box>
            </div>
        </div>
    );
};

export default ActivityCurrentLink;
