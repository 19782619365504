import { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectIsLoggedIn } from '../../store/userReducer'
import s from './Auth.module.scss'
import {
    AcceptMemberInvitationThunk,
    CheckInvitationTokenThunk,
    GetAgencyForUserThunk,
    selectAgencyName
} from '../../store/membersReducer'
import { Button, Form, Input } from 'antd'

const oneclickLogo = require('../../assets/img/oneclick-logo.svg')
const authPageDecoration = require('../../img/authPageDecoration.svg')

const AcceptInvitationForm = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const isLoggedIn = useAppSelector(selectIsLoggedIn)
    const [isLoading, setIsLoading] = useState(false)
    const [isValidToken, setIsValidToken] = useState(true)
    const agencyName = useAppSelector(selectAgencyName)
    useEffect(() => {
        const token = pathname.split('/users/invite/')[1]
        dispatch(CheckInvitationTokenThunk(token))
            .then(r => setIsValidToken((r.payload as {message?: string})?.message === 'ACTIVE'))
        dispatch(GetAgencyForUserThunk(token))
    }, [dispatch, pathname])


    const onFinish = (userData: {user_name: string, password: string}) => {
        const token = pathname.split('/users/invite/')[1]
        setIsLoading(true)
        dispatch(AcceptMemberInvitationThunk({...userData, token}))
            .then(() => {
                setIsLoading(false)
                navigate('/sign-in')
            })
    }

    if (isLoggedIn) {
        return <Navigate to='/'/>
    }

    if (!isValidToken) {
        return (
            <div className={s.inner}>
                <authPageDecoration.ReactComponent className={s.inner__decoration}/>
                <div className={s.formBlock}>
                    <oneclickLogo.ReactComponent />
                    <h1>Wrong link</h1>
                </div>
            </div>
        )
    }

    return (
        <div className={s.inner}>
            <authPageDecoration.ReactComponent className={s.inner__decoration}/>
            <div className={s.formBlock}>
                <oneclickLogo.ReactComponent />
                <h1 style={{fontWeight: 700, lineHeight: '38px'}}>
                    You’ve been added<br/>to the team at {agencyName ? agencyName.message : ''}
                </h1>
                <div className={s.formBlock__description}>
                    Please create your account
                </div>
                <Form
                    name='signInForm'
                    onFinish={onFinish}
                    autoComplete='off'
                    validateTrigger='onBlur'
                    className={s.formBlock__form}
                >
                    <div className={s.formBlock__label}>
                        Full Name
                    </div>
                    <Form.Item
                        name='user_name'
                        rules={[{ required: true, message: 'Please enter your name!' }]}
                    >
                        <Input placeholder='Enter your full name' className={s.formBlock__input}/>
                    </Form.Item>

                    <div className={s.formBlock__label}>
                        Password
                    </div>
                    <Form.Item
                        name='password'
                        rules={[ { required: true, message: 'Please enter your password!' } ]}
                    >
                        <Input.Password placeholder='Enter your password' className={s.formBlock__input}/>
                    </Form.Item>

                    <Form.Item>
                        <Button type='primary' htmlType='submit' className={s.formBlock__submit} loading={isLoading}>
                            Create
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default AcceptInvitationForm
