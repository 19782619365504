import React, {useState} from 'react';
import {Button, Form, Input, message, Progress, Upload, UploadFile, UploadProps} from "antd";
import authS from "../Auth/Auth.module.scss";
import {dummyRequest} from "../../helpers/fileHelper";
import s from "../SendLinkModal/SendLinkModal.module.scss";
import CommonModal from "../CommonModal/CommonModal";
import {feedbackApi} from "../../api/api";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {selectFeedbackModalOpened, setFeedbackModalOpen} from "../../store/utilityReducer";
import {userDataHelper} from "../../helpers/localStorageHelper";
import TextArea from "antd/es/input/TextArea";
const uploadIcon = require('../../img/icons/upload.svg')
const deleteIcon = require('../../img/icons/delete.svg')
const imageUploadIcon = require('../../img/icons/fileUpload.png')
const modalIcon = require('../../img/icons/feedBackModalIcon.svg')
const closeIcon = require('../../img/icons/close.svg')
const FeedbackModalForm = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const userData = userDataHelper.getUserData()
    const isModalOpened = useAppSelector(selectFeedbackModalOpened)
    const dispatch = useAppDispatch()
    const [form] = Form.useForm();
    const onFinish = (sentLinkData: { description: string, title_feedback: string}) => {
        setIsLoading(true)
        const formData = new FormData()
        const files = fileList.map(file => file.originFileObj).filter(file => file != null);

        if (files.length > 0) {
            files.forEach((file, index) => {
                const fileName = file ? file?.name : `screen_${index}.png`;
                // @ts-ignore
                formData.append("images", file, fileName);
            });
        }
        formData.append('feedback', new Blob([JSON.stringify({
            user_role: userData.roles[0].name,
            message: sentLinkData.description,
            title: sentLinkData.title_feedback
        })], {type: 'application/json'}))
        feedbackApi.sentFeedback(formData)
            .then(() => {
                message.success('Feedback was sent successfully')
            })
            .catch(() => {
                message.error('Something went wrong during feedback sending')
            })
            .finally(() => {
                dispatch(setFeedbackModalOpen(false))
                setIsLoading(false)
                form.resetFields()
                setFileList([])
            })
    }

    const beforeUpload = (file: File): Promise<void> => {
        return new Promise((resolve, reject) => {
            const validFormats = ['jpg', 'jpeg', 'png'];
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            if (!fileExtension || !validFormats.includes(fileExtension)) {
                message.error('Invalid file format. Please upload a valid file. Valid file formats are png/jpg/jpeg');
                reject();
                return;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('File must be less than 2 MB');
                reject();
                return;
            }
            resolve();
        });
    };

    const handleChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
        const validationPromises = newFileList.map(file => {
            if (file.originFileObj) {
                return beforeUpload(file.originFileObj)
                    .then(() => file)
                    .catch(() => null);
            }
            return Promise.resolve(null);
        });

        const results = await Promise.allSettled(validationPromises);
        const filteredList = results.reduce((acc: UploadFile<any>[], result, index) => {
            if (result.status === 'fulfilled' && result.value) {
                acc.push(newFileList[index]);
            }
            return acc;
        }, []);

        setFileList(filteredList);
    };

     const uploadButton = (
        <div className={s.blockWrapper__upload__text}>
            <uploadIcon.ReactComponent/>
            <div>
                <span style={{fontWeight: 700, marginRight: '5px', color: '#075CEB'}}>
                    Click to upload
                </span>or drag and drop
            </div>
            <span>JPEG, PNG, JPG (max. 800x400px)</span>
        </div>
    );

    const onDeleteImage = (fileId: string) => {
        setFileList(fileList.filter((f) => f.uid !== fileId))
    }


    const onCloseModal = () => {
        dispatch(setFeedbackModalOpen(false))
        form.resetFields()
        setFileList([])

    }

    return (
        <CommonModal
            active={isModalOpened}
            height={'fit-content'}
            width={'545px'}
            borderRadius={'16px'}
            backgroundColor={'#FFF'}
        >
            <Form
                name='feedbackModalForm'
                onFinish={onFinish}
                form={form}
                autoComplete='off'
                validateTrigger='onBlur'
                className={authS.formBlock__form}
            >
                <button className={s.modal__closeBtn} onClick={onCloseModal} type={'button'}>
                    <closeIcon.ReactComponent/>
                </button>
                <div className={s.formHeader}>
                    <modalIcon.ReactComponent/>
                    <h2>Feedback</h2>
                </div>
                <div
                    className={authS.formBlock__label}
                    style={{
                        marginTop: '12px'
                    }}
                >
                    Title
                </div>
                <Form.Item
                    name='title_feedback'
                    style={{
                        marginBottom: '12px',
                    }}
                >
                    <Input
                        placeholder='Enter your feedback title'
                        className={authS.formBlock__input}

                    />
                </Form.Item>
                <div className={authS.formBlock__label}>
                    Description
                </div>
                <Form.Item
                    name='description'
                    rules={[
                        {required: true, message: 'Please enter your description!'},
                    ]}
                >
                    <TextArea
                        placeholder='Enter your description'
                        className={authS.formBlock__input}
                    />
                </Form.Item>
                <div className={authS.formBlock__label} style={{
                    color: '#667085'
                }}>
                    Upload and attach files to your feedback.
                </div>
                <Form.Item
                    name='logo'
                    valuePropName='fileList'
                >
                    <>
                        <Upload.Dragger
                            listType="picture"
                            fileList={fileList}
                            showUploadList={false}
                            onChange={handleChange}
                            customRequest={dummyRequest}
                            beforeUpload={beforeUpload}
                        >
                            {uploadButton}
                            {/*{fileList.length >= 8 ? null : uploadButton}*/}
                        </Upload.Dragger>
                        <div>
                            {
                                fileList.map((file) => {
                                    return (
                                        <div className={'feedbackLinkImageLoader'} style={{
                                            marginTop: '6px'
                                        }}>
                                            <div className={s.sendLinkImageLoader__header}>
                                                <div style={{
                                                    display: 'flex'
                                                }}>
                                                    <div>
                                                        <img src={imageUploadIcon} alt="upload"/>
                                                    </div>
                                                    <div className={s.sendLinkImageLoader__imgInfo}>

                                                        <p>{file.name}</p>


                                                        <p>{file.size} KB</p>

                                                    </div>
                                                </div>
                                                <div className={s.imageLoaderCheck}
                                                     onClick={() => onDeleteImage(file.uid)}>
                                                    <deleteIcon.ReactComponent/>
                                                </div>
                                            </div>

                                            <div>
                                                <Progress percent={100} width={277} style={{
                                                    marginLeft: '50px'
                                                }} strokeColor={['#0A5BEB', '#9233F4']}/>
                                                <span className={s.percentage}>100%</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                </Form.Item>
                <Form.Item style={{
                    marginBottom: '0px',
                    width: '100%',
                    marginTop: '8px'
                }}>
                    <Button type='primary' htmlType='submit' className={authS.formBlock__submit}
                            loading={isLoading}>
                        Send feedback
                    </Button>
                </Form.Item>

            </Form>
        </CommonModal>
    );
};

export default FeedbackModalForm;