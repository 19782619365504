import React, { useState } from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {EmailLoginThunk, selectIsLoggedIn} from "../../store/userReducer";
import {Navigate} from "react-router";
import s from './Auth.module.scss'
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';

const oneclickLogo = require('../../assets/img/oneclick-logo.svg')
const authPageDecoration = require('../../img/authPageDecoration.svg')

const SignIn = () => {
    const dispatch = useAppDispatch()

    const [isLoading, setIsLoading] = useState(false)

    const isLoggedIn = useAppSelector(selectIsLoggedIn)

    const onFinish = (loginData: {email: string, password: string}) => {
        setIsLoading(true)
        dispatch(EmailLoginThunk(loginData))
            .then(() => setIsLoading(false))
    }

    if (isLoggedIn) {
        return <Navigate to='/'/>
    }
    return (
        <div className={s.inner}>
            <authPageDecoration.ReactComponent className={s.inner__decoration}/>
            <div className={s.formBlock}>
                <oneclickLogo.ReactComponent />
                <h1>
                    Log in to your account
                </h1>
                <div className={s.formBlock__description}>
                    Welcome back! Please enter your details.
                </div>
                <Form
                    name='signInForm'
                    onFinish={onFinish}
                    autoComplete='off'
                    validateTrigger='onBlur'
                    className={s.formBlock__form}
                >
                    <div className={s.formBlock__label}>
                        Email Address
                    </div>
                    <Form.Item
                        name='email'
                        rules={[
                            { required: true, message: 'Please enter your email!' },
                            { type: 'email', message: 'Invalid e-mail!' },
                        ]}
                    >
                        <Input placeholder='Enter your email' className={s.formBlock__input}/>
                    </Form.Item>

                    <div className={s.formBlock__label}>
                        Password
                    </div>
                    <Form.Item
                        name='password'
                        rules={[ { required: true, message: 'Please enter your password!' } ]}
                    >
                        <Input.Password placeholder='Enter your password' className={s.formBlock__input}/>
                    </Form.Item>

                    <Link to='/sign-in/forgot-password' className={s.formBlock__forgotPassword}>
                        Forgot your password?
                    </Link>
  
                    <Form.Item style={{
                        marginBottom: '0px'
                    }}>
                        <Button type='primary' htmlType='submit' className={s.formBlock__submit} loading={isLoading}>
                            Sign in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default SignIn;
