import {Form, Select} from 'antd'
import {UpdateGoogleAccountThunk, selectGoogleAccount, selectGoogleAccountMe} from '../../store/googleReducer'
import {selectIsGoogleModalOpen, setGoogleModalOpen} from '../../store/utilityReducer'
import CommonModal from '../CommonModal/CommonModal'
import modalS from './../FacebookLinksTable/FacebookLinksTable.module.scss'
import {useAppDispatch, useAppSelector} from '../../hooks'
import {googleDataHelper} from '../../helpers/localStorageHelper'
import {useLocation, useNavigate} from 'react-router'


const GoogleAccountConfigsModal = () => {
    const dispatch = useAppDispatch()
    const {pathname} = useLocation()
    const navigate = useNavigate()
    const googleModalOpened = useAppSelector(selectIsGoogleModalOpen)
    const googleAccountMe = useAppSelector(selectGoogleAccountMe)
    const googleAccount = useAppSelector(selectGoogleAccount)
    const onFinish = (formData: any) => {
        dispatch(UpdateGoogleAccountThunk({
            google_ads_manager_id: formData.google_ads_manager_id,
            google_ads_manager_email: formData.google_ads_manager_email
        }))
        dispatch(setGoogleModalOpen(false))
        googleDataHelper.removeGoogleCode()
    }

    const onCloseModal = () => {
        dispatch(setGoogleModalOpen(false))
        if (pathname === '/googleLinksTable'
            && googleAccount.google_ads_manager_email === null
            && googleAccount.google_ads_manager_id === null
        ) {
            navigate('/')
        }
    }

    return (
        <CommonModal active={googleModalOpened} height={'320px'}>
            <div className={`${modalS.modal}`}>
                <h2 style={{
                    marginTop: '40px',
                    marginBottom: '25px'
                }}>Set your google account settings</h2>
                <div className={modalS.modal__closeBtn} onClick={onCloseModal}>x</div>
                {pathname === '/googleLinksTable' &&
                    <div style={{marginBottom: '30px', fontSize: '14px', textAlign: 'center'}}>
                        You need to fill in the form before working with Google links
                    </div>
                }
                <Form
                    name='googleLinksTable'
                    onFinish={onFinish}
                    autoComplete='off'
                    validateTrigger='onBlur'
                >
                    <div>
                        <p className={modalS.modal__inputLabel}>Select ads manager id:</p>
                        <Form.Item
                            name={['google_ads_manager_id']}
                            rules={[
                                {required: true, message: 'Select your google ads manager id!'},
                            ]}
                        >
                            <Select placeholder='Select your google ads manager id' className={modalS.modal__select}>
                                {googleAccountMe?.google_ads?.map((g: any) => (
                                    <Select.Option key={g.customer_id}
                                                   value={g.customer_id}>{`${g.customer_id} - ${(g.descriptive_name)}`}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div>
                        <p className={modalS.modal__inputLabel}>Select ads manager email:</p>
                        <Form.Item
                            name={['google_ads_manager_email']}
                            rules={[
                                {required: true, message: 'Select your google ads manager email!'},
                            ]}
                        >
                            <Select placeholder='Select your google ads manager email' className={modalS.modal__select}>
                                {googleAccountMe?.email_addresses?.map((g: any) => (
                                    <Select.Option key={g} value={g}>{g}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <button
                                className={modalS.modal__btn}
                                type={'submit'}
                            >Save
                            </button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </CommonModal>
    )
}

export default GoogleAccountConfigsModal
