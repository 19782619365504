import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {LoginTikTokClientThunk, selectTikTokClientData} from "../../store/userReducer";
import {clientLoginDataHelper, tikTokDataHelper, userMultiStepHelper} from "../../helpers/localStorageHelper";
import s from "../Auth/Auth.module.scss";
import {Button, Checkbox, Form, Spin} from "antd";
import {authApi, clientApi, LinkActivityReqData} from "../../api/api";
import {useNavigate} from "react-router";
import {
    UpdateActivityLinkThunk
} from "../../store/activityTrackerReducer";
import {GetAgencyDataThunk} from "../../store/agencyReducer";


const oneclickLogo = require('../../assets/img/oneclick-logo.svg')
const authPageDecoration = require('../../img/authPageDecoration.svg')


const TikTokClientConfig = () => {
        const tikTokClientConfig = useAppSelector(selectTikTokClientData)
        const dispatch = useAppDispatch()
        const navigate = useNavigate()
        const [checkedBCenters, setCheckedBCenters] = useState<any>([])
        const [checkedBCatalogs, setCheckedBCatalogs] = useState<any>([])
        // eslint-disable-next-line
        const [agencyName, setAgencyName] = useState<any>('')
        const [agencyLogo, setAgencyLogo] = useState('')
        const isMultiStepAuth = userMultiStepHelper.getIsUserMultiStepLogin()
        const [isLoading, setIsLoading] = useState(false)
        // eslint-disable-next-line
        useEffect(() => {
            const authCode = tikTokDataHelper.getTikTokCode()
            const configId = clientLoginDataHelper.getConfigId()
            const multiStepConfigId = userMultiStepHelper.getUserMultiStepLinkTikTokConfigId()
            if (!isMultiStepAuth) {
                dispatch(LoginTikTokClientThunk({authorization_code: authCode, config_id: configId}))
                    .then((r) => {
                            setAgencyName(r.payload.agency_name)
                            setAgencyLogo(r.payload.agency_logo)
                        }
                    )
            } else if (isMultiStepAuth) {
                dispatch(LoginTikTokClientThunk({authorization_code: authCode, config_id: multiStepConfigId}))
                    .then((r) => {
                            setAgencyName(r.payload.agency_name)
                            setAgencyLogo(r.payload.agency_logo)
                        }
                    )
            }
        }, [dispatch, isMultiStepAuth])

        const [form] = Form.useForm()
        useEffect(() => {
            form.setFieldsValue(tikTokClientConfig?.business_centers)
            if (!!tikTokClientConfig?.business_centers?.length) {
                setCheckedBCenters(tikTokClientConfig?.business_centers.map((b: any) => b.bc_id));
                tikTokClientConfig?.business_centers.forEach((b: any) => {
                    if (!!b?.resources?.catalogs?.length) {
                        const checkedArray = b?.resources?.catalogs.map((c: any) => c.asset_id)
                        setCheckedBCatalogs(checkedArray);
                    }
                })
            }
        }, [form, tikTokClientConfig])
        useEffect(() => {
            dispatch(GetAgencyDataThunk())
        }, [dispatch])


        const handleCheckBCenters = (event: any) => {
            const name = event.target.name;
            if (event.target.checked) {
                const checkedArray = [...checkedBCenters, name]
                setCheckedBCenters(checkedArray);

            } else {
                const checkedArray = checkedBCenters.filter((c: any) => c !== name)
                setCheckedBCenters(checkedArray)
            }
        };

        const handleCheckBCatalogs = (event: any) => {
            const name = event.target.name;
            if (event.target.checked) {
                const checkedArray = [...checkedBCatalogs, name]
                setCheckedBCatalogs(checkedArray);

            } else {
                const checkedArray = checkedBCatalogs.filter((c: any) => c !== name)
                setCheckedBCatalogs(checkedArray)
            }
        };

        const onFinish = () => {
            setIsLoading(true)
            const id = clientLoginDataHelper.getConfigId()
            const multiConfigId = userMultiStepHelper.getUserMultiStepLinkTikTokConfigId()
            const linkActivityId = clientLoginDataHelper.getActivityLinkId()
            let filteredBusinessCenters = tikTokClientConfig?.business_centers?.filter((obj: any) => checkedBCenters.includes(obj.bc_id));
            filteredBusinessCenters = filteredBusinessCenters.map((b: any) => b && {
                ...b,
                resources: {
                    ...b.resources,
                    catalogs: b?.resources?.catalogs?.filter((obj: any) => checkedBCatalogs.includes(obj.asset_id))
                }
            })
            // const filteredBusinessCatalogs = tikTokClientConfig?.business_centers?.filter((obj: any) => checkedBCenters.includes(obj.bc_id));
            const reqBody = {
                access_token: tikTokClientConfig.access_token,
                config_id: !isMultiStepAuth ? Number(id) : Number(multiConfigId),
                business_centers: filteredBusinessCenters
            }


            clientApi.checkIfCreditsAvailable(linkActivityId)
                .then((res) => {
                    if (res.data.credit_is_available === true) {
                        authApi.tikTokClientGrantAccess(reqBody)
                            .then(() => {
                                const linkActivityReqData: LinkActivityReqData = {
                                    link_id: !isMultiStepAuth ? Number(id) : Number(multiConfigId),
                                    platform_name: 'TIKTOK',
                                    user_name: tikTokClientConfig.user_name,
                                    user_email: tikTokClientConfig.user_email,
                                    status: 'INTEGRATED'
                                }
                                dispatch(UpdateActivityLinkThunk({
                                    link_activity_id: linkActivityId,
                                    linkActivityReqData: linkActivityReqData
                                }))
                                    .then(() => {
                                        setIsLoading(false)
                                    })
                                navigate('/client-tiktok-login-success')

                            })
                    } else {
                        navigate('/noCreditsLeft')
                    }
                })


        }


        return (
            <>
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        height: '100%'
                    }}
                >
                    {tikTokClientConfig
                        ?
                        <div className={s.inner} style={{width: '100%'}}>
                            <authPageDecoration.ReactComponent className={s.inner__decoration}/>
                            <div className={s.formBlock}>
                                {
                                    agencyLogo && agencyLogo !== null
                                        ?
                                        <img src={agencyLogo} alt="logo"/>
                                        :
                                        <oneclickLogo.ReactComponent/>
                                }
                                <h1>
                                    {agencyName && agencyName !== null ? agencyName : 'Agency'} asking
                                    permission for your Tik Tok business centers:
                                </h1>
                                <Form
                                    name='signInClients'
                                    onFinish={onFinish}
                                    autoComplete='off'
                                    form={form}
                                    validateTrigger='onBlur'
                                    initialValues={tikTokClientConfig?.business_centers}
                                    className={s.formBlock__form}
                                    style={{
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: "center",
                                        flexDirection: 'column'
                                    }}
                                >
                                    <div style={{
                                        marginBottom: '10px'
                                    }}>
                                        {tikTokClientConfig?.business_centers?.map((b: any) => {
                                            return (
                                                <>
                                                    <p className={s.clientForm__title}>Tik Tok business
                                                        centers:</p>
                                                    <div className={s.clientForm__item} key={b.bc_id}>
                                                        <Checkbox
                                                            onChange={handleCheckBCenters}
                                                            name={b.bc_id}
                                                            checked={checkedBCenters.includes(b.bc_id)}
                                                        >
                                                            {`${b.name} - (${b.bc_id})`}
                                                        </Checkbox>
                                                        {/*<Tooltip title={p.description}>*/}
                                                        {/*    <QuestionCircleOutlined/>*/}
                                                        {/*</Tooltip>*/}
                                                        <div>
                                                            {b?.resources?.catalogs?.length ?
                                                                <p className={s.clientForm__subtitle}>Tik Tok
                                                                    business
                                                                    catalogs:</p> : null}
                                                            {b?.resources?.catalogs?.map((c: any) => {
                                                                return (
                                                                    <>
                                                                        <div className={s.clientForm__item}
                                                                             key={c.asset_id}>
                                                                            <Checkbox
                                                                                onChange={handleCheckBCatalogs}
                                                                                name={c.asset_id}
                                                                                checked={checkedBCatalogs.includes(c.asset_id)}
                                                                                disabled={!checkedBCenters.includes(b.bc_id)}
                                                                            >
                                                                                {`${c.asset_name} - (${c.asset_id})`}
                                                                            </Checkbox>
                                                                            {/*<Tooltip title={p.description}>*/}
                                                                            {/*    <QuestionCircleOutlined/>*/}
                                                                            {/*</Tooltip>*/}
                                                                        </div>
                                                                    </>

                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Button
                                            type='primary'
                                            htmlType='submit'
                                            className={s.formBlock__submit}
                                            loading={isLoading}
                                        >
                                            Grant access
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        :
                        <Spin style={
                            {
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: '290px'
                            }
                        }
                        />
                    }
                </div>
                <p className={'powered'}>powered by OneClickOnboard</p>
            </>
        );
    }
;

export default TikTokClientConfig;
