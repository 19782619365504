import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {pickBy} from 'lodash'
import {authApi} from '../api/api'
import {
    activityDataHelper,
    tikTokDataHelper,
    tokenDataHelper,
    userDataHelper,
    userMultiStepHelper
} from '../helpers/localStorageHelper'
import {FacebookIntegrationDataType, UserDataType} from '../types/userTypes'
import {AppStatusType} from './appStatusReducer'
import {AsyncThunkConfig, RootState} from './store'
import {setPixelAccountConfigurations} from "./permissionConfigurationReducer";


interface InitialStateType {
    isLoggedIn: boolean
    userData: UserDataType,
    adminData: UserDataType,
    userAvatar: any,
    clientData: any,
    clientGoogleData: any,
    tikTokClientData: any
    clientPagesCatalogs: any
    clientAddAccounts: any
    clientBusinessAccounts: any
    clientAccountPages: any,
    googleAuthUrl: string
    tikTokAuthUrl: string
}

const initialState: InitialStateType = {
    isLoggedIn: !!userDataHelper.getUserData(),
    userData: userDataHelper.getUserData(),
    adminData: userDataHelper.getAdminData(),
    userAvatar: null,
    clientData: null,
    clientGoogleData: null,
    tikTokClientData: null,
    clientAddAccounts: [],
    clientBusinessAccounts: [],
    clientAccountPages: [],
    clientPagesCatalogs: [],
    googleAuthUrl: '',
    tikTokAuthUrl: ''
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload
        },
        setUserData: (state, action: PayloadAction<UserDataType>) => {
            state.userData = action.payload
        },
        setUserAvatar: (state: any, action: PayloadAction<any>) => {
            state.userAvatar = action.payload
        },
        setClientAddAccounts: (state: any, action: PayloadAction<any>) => {
            state.clientAddAccounts = action.payload.map((m: any) => m && {...m, checked: true} )
        },
        onChangeAddResourcesCheckbox: (state: any, action: PayloadAction<{ checked: boolean, id: number }>) => {
            state.clientAddAccounts = state.clientAddAccounts.map((c: any) => c.id === action.payload.id ? {
                ...c,
                checked: action.payload.checked
            } : c)
        },
        //
        setClientBusinessAccounts: (state: any, action: PayloadAction<any>) => {
            state.clientBusinessAccounts = action.payload.map((m: any) => m && {...m, checked: true} )
            const pixels = state.clientBusinessAccounts.map((c: any) => c.pixels !== null ? c.pixels.map((i: any) => i && {...i, checked: true}) : null).flat(1)
            const instagrams = state.clientBusinessAccounts.map((c: any) => c.instagrams !== null ? c.instagrams.map((i: any) => i && {...i, checked: true}) : null).flat(1)
            const catalogs = state.clientBusinessAccounts.map((c: any) => c.catalogs !== null ? c.catalogs.map((i: any) => i && {...i, checked: true}) : null).flat(1)
            state.clientBusinessAccounts = state.clientBusinessAccounts.map((b: any) => b && {...b, pixels: pixels, instagrams: instagrams, catalogs: catalogs})
        },
        onChangeBusinessResourcesCheckbox: (state: any, action: PayloadAction<{ checked: boolean, id: number }>) => {
            state.clientBusinessAccounts = state.clientBusinessAccounts.map((c: any) => c.id === action.payload.id ? {
                ...c,
                checked: action.payload.checked
            } : c)
        },
        onChangeBusinessResourcesInstagramCheckbox: (state: any, action: PayloadAction<{ checked: boolean, id: number, business_acc_id: number }>) => {
            const instagrams = state.clientBusinessAccounts.map((c: any) => c.id === action.payload.business_acc_id ? c.instagrams !== null ? c.instagrams.map((i: any) => i.id === action.payload.id ? {
                ...i,
                checked: action.payload.checked
            } : i) : null : c).flat(1)
            state.clientBusinessAccounts = state.clientBusinessAccounts.map((b: any) => b && {...b, instagrams: instagrams})
        },
        onChangeCatalogResourcesCheckbox: (state: any, action: PayloadAction<{ checked: boolean, id: number, business_acc_id: number }>) => {
            const catalogs = state.clientBusinessAccounts.map((c: any) => c.id === action.payload.business_acc_id ? c.catalogs !== null ? c.catalogs.map((i: any) => i.id === action.payload.id ? {
                ...i,
                checked: action.payload.checked
            } : i) : null : c).flat(1)
            state.clientBusinessAccounts = state.clientBusinessAccounts.map((b: any) => b && {...b, catalogs: catalogs})
        },
        onChangeBusinessResourcesPixelsCheckbox: (state: any, action: PayloadAction<{ checked: boolean, id: number, business_acc_id: number }>) => {
            const pixels = state.clientBusinessAccounts.map((c: any) => c.id === action.payload.business_acc_id ? c.pixels !== null ? c.pixels.map((i: any) => i.id === action.payload.id ? {
                ...i,
                checked: action.payload.checked
            } : i) : null : c).flat(1)
            state.clientBusinessAccounts = state.clientBusinessAccounts.map((b: any) => b && {...b, pixels: pixels})
        },
        //
        setClientPagesAccounts: (state: any, action: PayloadAction<any>) => {
            state.clientAccountPages = action.payload.map((m: any) => m && {...m, checked: true} )
        },
        onChangePagesResourcesCheckbox: (state: any, action: PayloadAction<{ checked: boolean, id: number }>) => {
            state.clientAccountPages = state.clientAccountPages.map((c: any) => c.id === action.payload.id ? {
                ...c,
                checked: action.payload.checked
            } : c)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(EmailLoginThunk.fulfilled, (state, action) => {
                state.isLoggedIn = true
                state.userData = pickBy(action.payload, (_, key) => key !== 'token') as UserDataType
            })
            .addCase(SignOutThunk.fulfilled, (state) => {
                state.isLoggedIn = false
                state.userData = {} as UserDataType
            })
            .addCase(LoginClientThunk.fulfilled, (state, action) => {
                state.clientData = action.payload
            })
            .addCase(LoginGoogleFirstStageThunk.fulfilled, (state, action) => {
                state.googleAuthUrl = action.payload
            })
            .addCase(LoginGoogleClientThunk.fulfilled, (state, action) => {
                state.clientGoogleData = action.payload
            })
            .addCase(LoginTikTokFirstStageThunk.fulfilled, (state, action) => {
                state.tikTokAuthUrl = action.payload
            })
            .addCase(LoginTikTokClientThunk.fulfilled, (state, action) => {
                state.tikTokClientData = action.payload
            })
    }
})

export const {
    setUserData,
    setClientAddAccounts,
    onChangeAddResourcesCheckbox,
    setClientBusinessAccounts,
    onChangeBusinessResourcesCheckbox,
    setClientPagesAccounts,
    onChangePagesResourcesCheckbox,
    onChangeBusinessResourcesInstagramCheckbox,
    onChangeBusinessResourcesPixelsCheckbox,
    onChangeCatalogResourcesCheckbox
} = userSlice.actions

export const selectIsLoggedIn = (state: RootState): boolean => state.user.isLoggedIn
export const selectUserData = (state: RootState): UserDataType => state.user.userData
export const selectUserAvatar = (state: RootState): any => state.user.userAvatar
export const selectClientData = (state: RootState): any => state.user.clientData
export const selectGoogleClientData = (state: RootState): any => state.user.clientGoogleData
export const selectClientAddAccounts = (state: RootState): any => state.user.clientAddAccounts
export const selectClientBusinessAccounts = (state: RootState): any => state.user.clientBusinessAccounts
export const selectClientPages = (state: RootState): any => state.user.clientAccountPages
export const selectClientPagesCatalogs = (state: RootState): any => state.user.clientPagesCatalogs
export const selectGoogleAuthUrl = (state: RootState): any => state.user.googleAuthUrl
export const selectTikTokAuthUrl = (state: RootState): any => state.user.tikTokAuthUrl
export const selectTikTokClientData = (state: RootState): any => state.user.tikTokClientData

export const IntegrateWithFacebookThunk = createAsyncThunk<FacebookIntegrationDataType, FacebookIntegrationDataType, AsyncThunkConfig>(
    'user/integrateWithFacebook',
    async (loginData, thunkAPI) => {
        try {
            const {status, data} = await authApi.login(loginData)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(loginData, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const EmailLoginThunk = createAsyncThunk<UserDataType, {email: string, password: string}, AsyncThunkConfig>(
    'user/emailLogin',
    async (loginData, thunkAPI) => {
        try {
            const {status, data} = await authApi.emailLogin(loginData)
            if (status === 200 && data) {
                userDataHelper.setUserData(pickBy(data, (_, key) => key !== 'token') as UserDataType)
                tokenDataHelper.setTokenData(data.token!)
                userDataHelper.setIsAdminUserData(false)
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const SendRestorePasswordEmailThunk = createAsyncThunk<UserDataType, {email: string}, AsyncThunkConfig>(
    'user/sendRestorePasswordEmail',
    async (email, thunkAPI) => {
        try {
            const {status, data} = await authApi.sendRestorePasswordEmail(email)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle, appMessage: 'Email has been sent successfully'})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const ResetPasswordThunk = createAsyncThunk<UserDataType, {password: string, token: string}, AsyncThunkConfig>(
    'user/resetPassword',
    async (newPasswordData, thunkAPI) => {
        try {
            const {status, data} = await authApi.resetPassword(newPasswordData)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const LoginClientThunk = createAsyncThunk<any, any, AsyncThunkConfig>(
    'user/loginClient',
    async (loginData, thunkAPI) => {
        try {
            const {status, data} = await authApi.facebookClientLogin(loginData)
            if (status === 200 && data) {
                if(data.resources.ad_accounts){
                    thunkAPI.dispatch(setClientAddAccounts(data.resources.ad_accounts))
                }
                if(data.resources.businesses){
                    thunkAPI.dispatch(setClientBusinessAccounts(data.resources.businesses))
                    if(data.resources.pixels){
                        thunkAPI.dispatch(setPixelAccountConfigurations(data.resources.businesses.pixels))
                    }
                }
                if(data.resources.pages){
                    thunkAPI.dispatch(setClientPagesAccounts(data.resources.pages))
                }
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(null)
            }
        } catch (error: any) {
            return thunkAPI.fulfillWithValue({}, {appStatus: AppStatusType.idle})
        }
    }
)

export const LoginGoogleClientThunk = createAsyncThunk<any, any, AsyncThunkConfig>(
    'user/googleLoginClient',
    async (loginData, thunkAPI) => {
        try {
            const {status, data} = await authApi.googleClientLogin(loginData)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(null)
            }
        } catch (error: any) {
            return thunkAPI.fulfillWithValue({}, {appStatus: AppStatusType.idle})
        }
    }
)

export const LoginGoogleFirstStageThunk = createAsyncThunk<string, void, AsyncThunkConfig>(
    'user/loginWithGoogleFirstStage',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await authApi.googleAuthorizationAdminFirstStage()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.message, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const LoginGoogleSecondStageThunk = createAsyncThunk<void, string, AsyncThunkConfig>(
    'user/loginWithGoogleSecondStage',
    async (code, thunkAPI) => {
        try {
            const {status, data} = await authApi.googleAuthorizationAdminSecondStage(code)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        finally {
            window.location.replace('/settings')
        }
    }
)


export const LoginTikTokFirstStageThunk = createAsyncThunk<string, void, AsyncThunkConfig>(
    'user/loginWithTikTokFirstStage',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await authApi.tiktokAuthorizationAdminFirstStage()
            if (status === 200 && data) {
                console.log(data)
                return thunkAPI.fulfillWithValue(data.message, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        finally {
            window.location.replace('/tiktokAdminAccountAssets')
        }
    }
)

export const LoginTikTokSecondStageThunk = createAsyncThunk<void, {code: string, userType: string}, AsyncThunkConfig>(
    'user/loginWithTikTokSecondStage',
    async (req, thunkAPI) => {
        try {
            const {status, data} = await authApi.tiktokAuthorizationAdminSecondStage(req.code)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(null)
        }
        finally {
            if(req.userType === 'client'){
                window.location.replace('/tiktokClientConfig')
            }
            else if(req.userType === 'agency'){
                window.location.replace('/tiktokAdminAccountAssets')
            }
        }
    }
)


export const LoginTikTokClientThunk = createAsyncThunk<any, {authorization_code: string, config_id: number}, AsyncThunkConfig>(
    'user/tikTokLoginClient',
    async (loginData, thunkAPI) => {
        try {
            const {status, data} = await authApi.tikTokClientLogin(loginData.authorization_code, loginData.config_id)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(null)
            }
        } catch (error: any) {
            return thunkAPI.fulfillWithValue({}, {appStatus: AppStatusType.idle})
        }
    }
)

export const SignOutThunk = createAsyncThunk<void, void, AsyncThunkConfig>(
    'user/signOut', (_, thunkAPI) => {
        userDataHelper.removeUserData()
        tokenDataHelper.removeTokenData()
        activityDataHelper.removeActivityCurrentService()
        activityDataHelper.removeActivityCurrentServiceNameAndAgentName()
        tikTokDataHelper.removeTikTokUserType()
        userMultiStepHelper.removeIsUserMultiStepLogin()
        window.location.replace('/sign-in')
        return thunkAPI.fulfillWithValue(_, {appStatus: AppStatusType.idle})
    }
)

export default userSlice.reducer
 