import React, {useEffect, useState} from 'react';
import {Box} from "@material-ui/core";
import {
    DataGridPro,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import RequirementSearchInput from "../RequirementSearchInput/RequirementSearchInput";
import s from './FacebookLinksTable.module.scss'
import PageTitle from "../PageTitle/PageTitle";
import CommonModal from "../CommonModal/CommonModal";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
    selectCurrentScreenHeight,
    selectIsFacebookModalOpen, selectIsMultiStepLinkFinished,
    setBeforeAddingLinksModalOpen,
    setFacebookModalOpen, setMobileIsMultiLinkOpened
} from "../../store/utilityReducer";
import {
    GetBusinessPermissionThunk,
    GetUserBusinessesThunk,
    selectPermissionCatalog, selectUserBusinesses
} from "../../store/businessAssetReducer";
import {Checkbox, Form, Input, message, Popconfirm, Select, Switch, Tooltip} from "antd";
import {
    addSelectedConfigurations,
    changeAddAccountConfigurationCheckbox,
    changeCatalogAccountConfigurationCheckbox,
    changeInstagramAccountConfigurationCheckbox, changePageAccountConfigurationCheckbox,
    changePixelAccountConfigurationCheckbox, CreatePermissionConfigurationConfigThunk,
    DeletePermissionConfigurationConfigThunk,
    deleteSelectedConfigurations,
    GetPermissionConfigurationConfigByIdThunk,
    GetPermissionConfigurationsThunk,
    resetFacebookModalConfigurations,
    selectAddAccountConfigurations,
    selectCatalogAccountConfigurations,
    selectCurrentConfiguration, selectInstagramAccountConfigurations, selectPageAccountConfigurations,
    selectPermissionConfigurations, selectPixelAccountConfigurations,
    selectSelectedConfigurations,
    setAddAccountConfigurations,
    setCatalogAccountConfigurations,
    setCurrentConfig,
    setInstagramAccountConfigurations, setPageAccountConfigurations, setPixelAccountConfigurations,
    setSelectedConfigurations, switchAllConfigurationsCheckboxes, UpdatePermissionConfigurationConfigThunk,
} from "../../store/permissionConfigurationReducer";
import {selectUserData} from "../../store/userReducer";
import {QuestionCircleOutlined} from "@ant-design/icons";
import PageWithSidebar from '../PageWithSidebar/PageWithSidebar';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import {Navigate, useNavigate} from 'react-router';
import {
    onSetCurrentMultiStepLink,
    selectCurrentPlatformIndex,
    selectMultiStepCurrentLinkData, selectMultiStepLinksReqData,
    setFacebookStepData, setGoogleStepData, setTikTokStepData
} from "../../store/multiStepReducer";
import {selectMultiStageServices, setMultiStageServices} from "../../store/linksReducer";
import MultiStepIndicator from "../MultiStepIndicator/MultiStepIndicator";
import {setCurrentGoogleModalLinkPermissions} from "../../store/googleReducer";
import {setCurrentTikTokModalLinkPermissions} from "../../store/tikTokReducer";
import {userDataHelper, userMultiStepHelper} from "../../helpers/localStorageHelper";
import moment from "moment/moment";

const copyIcon = require('../../img/icons/copy.svg')
const deleteIcon = require('../../img/icons/delete.svg')
const editIcon = require('../../img/icons/edit.svg')
const closeIcon = require('../../img/icons/close.svg')
const facebookIcon = require('../../img/icons/facebook-logo-small.svg')

const FacebookLinksTable = () => {
    const userBusinesses = useAppSelector(selectUserBusinesses)

    if (!userBusinesses.length) {
        return <Navigate to='/'/>
    }

    return (
        <PageWithSidebar>
            <FacebookLinksTableComponent/>
        </PageWithSidebar>
    )
}

const FacebookLinksTableComponent = () => {
    const [editMode, setEditMode] = useState(false)
    const [visibleRowsCount, setVisibleRowsCount] = useState(0)
    const [visibleRows, setVisibleRows] = useState<any[]>([])
    const businessPermissions = useAppSelector(selectPermissionCatalog)
    const tableLinks = useAppSelector(selectPermissionConfigurations)
    const userData = useAppSelector(selectUserData)
    const dispatch = useAppDispatch()
    const apiRef = useGridApiRef();
    const [isColumnVisible, setIsColumnVisible] = useState(true);
    const [screenWidth, setScreenWidth] = useState<number>(0)
    const navigate = useNavigate()
    const currentScreenHeight = useAppSelector(selectCurrentScreenHeight)
    useEffect(() => {
        const handleResize = () => {
            setIsColumnVisible(window.innerWidth > 1160); // Adjust the breakpoint as needed
            setScreenWidth(window.innerWidth)
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        dispatch(GetBusinessPermissionThunk())
        dispatch(GetPermissionConfigurationsThunk())
        dispatch(GetUserBusinessesThunk(userData.user_id))
    }, [dispatch, userData.user_id])

    useEffect(() => {
        setVisibleRowsCount(tableLinks.length)
        setVisibleRows(tableLinks)
    }, [tableLinks])

    useEffect(() => {
        dispatch(setAddAccountConfigurations(businessPermissions.AD_ACCOUNT))
        dispatch(setCatalogAccountConfigurations(businessPermissions.CATALOG))
        dispatch(setInstagramAccountConfigurations(businessPermissions.INSTAGRAM))
        dispatch(setPageAccountConfigurations(businessPermissions.PAGE))
        dispatch(setPixelAccountConfigurations(businessPermissions.PIXEL))
        dispatch(setCurrentGoogleModalLinkPermissions({permissions: []}))
        dispatch(setCurrentTikTokModalLinkPermissions({permissions: []}))
        // eslint-disable-next-line
    }, [dispatch, businessPermissions.AD_ACCOUNT, businessPermissions.CATALOG, businessPermissions.INSTAGRAM, businessPermissions.PAGE, businessPermissions.PIXEL])
    const onDeletePermissionConfiguration = (config_id: number) => {
        dispatch(DeletePermissionConfigurationConfigThunk(config_id))
    }
    const onGetPermissionConfigById = (config_id: number) => {
        dispatch(GetPermissionConfigurationConfigByIdThunk(config_id))
        dispatch(setFacebookModalOpen(true))
        setEditMode(true)
    }


    const onCopyFacebookLink = async (url: string) => {
        try {
            await navigator.clipboard.writeText(url);
            message.success('Link was copied successfully', 3)
        } catch (err) {
            message.error('Failed to copy link');
        }
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: screenWidth > 768 ? 1 : 0.6,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
        },
        {
            field: 'serviceType',
            headerName: 'Service',
            flex: 0.45,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            renderCell: () => <facebookIcon.ReactComponent style={{marginLeft: '10px'}}/>,
            hide: !isColumnVisible,
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 0.8,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
        },
        {
            field: 'url',
            headerName: 'Link URL',
            flex: screenWidth > 1400 ? 2 : screenWidth > 768 ? 1 : 0.5,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            renderCell: (data: any) => (
                <div style={{color: '#667085', marginLeft: '10px'}}>
                    <span style={{display: screenWidth < 768 ? 'none' : 'block'}}>{data.row.url}</span>
                    <span style={{display: screenWidth >= 768 ? 'none' : 'block'}}>Link</span>
                </div>
            )
        },
        {
            field: 'date_created',
            headerName: 'Date Created',
            flex: screenWidth > 768 ? 1 : 0.5,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            renderCell: (data: any) => (
                <div style={{color: '#667085', marginLeft: '10px'}}>
                    {
                        moment(data.row.date_created, "DD-MM-YYYY HH:mm:ss").format('DD MMM YYYY (hh:mm A)')
                    }
                </div>
            )
        },
        {
            field: 'Action',
            headerName: '',
            flex: 0.6,
            disableExport: true,
            hide: screenWidth < 768,
            renderCell: (data: any) => {
                return (
                    <div>
                        <copyIcon.ReactComponent
                            style={
                                {
                                    marginRight: '15px',
                                    cursor: 'pointer',
                                    fontSize: '18px'
                                }
                            }
                            color="action"
                            onClick={() => {
                                onCopyFacebookLink(data.row.url)
                            }}
                        />
                        <Popconfirm
                            title="Are you sure to delete this link?"
                            onConfirm={() => onDeletePermissionConfiguration(data.row.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <deleteIcon.ReactComponent
                                style={
                                    {
                                        marginRight: '15px',
                                        cursor: 'pointer',
                                        fontSize: '18px'
                                    }
                                }
                                color="action"
                            />
                        </Popconfirm>
                        <editIcon.ReactComponent
                            color="action"
                            style={
                                {
                                    marginRight: '15px',
                                    cursor: 'pointer',
                                    fontSize: '18px'
                                }
                            }
                            onClick={() => {
                                onGetPermissionConfigById(data.row.id)
                            }}
                        />
                    </div>

                )
            }
        }
    ]


    const onMobileRowClick = (value: any) => {
        if (screenWidth < 768) {
            dispatch(GetPermissionConfigurationConfigByIdThunk(value.row.id))
            userDataHelper.setMobileLinkServiceType('Facebook')
            navigate('/mobileLink')
        }
    }

    return (
        <div style={{
            padding: screenWidth > 1160 ? '32px' : screenWidth > 480 ? '10px 20px' : '10px 8px'
        }}>
            <Breadcrumbs/>
            <div className={s.inner__header}>
                <PageTitle
                    title='Facebook table'
                    description='Create links for your customers'
                    onAddLink={() => dispatch(setFacebookModalOpen(true))}
                />
            </div>
            <RequirementSearchInput
                apiRef={apiRef}
                setVisibleRowsCount={(count: number) => setVisibleRowsCount(count)}
                setVisibleRows={(rows: any[]) => setVisibleRows(rows)}
                allRows={Array.isArray(tableLinks) ? tableLinks : []}
            />
            <div className={s.tableInner}>
                <h2>
                    All links <span>{visibleRowsCount} links</span>
                </h2>
                <Box>
                    <DataGridPro
                        sx={{
                            borderRight: 'none',
                            borderLeft: 'none',
                            borderBottom: 'none',
                            borderRadius: '0px',
                            borderTop: '1px solid transparent',
                            "& .MuiDataGrid-columnHeader": {
                                background: '#F9FAFB',
                                padding: '0px 20px',
                                // minWidth: "121px !important",
                                minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                            },
                            "& .MuiDataGrid-columnHeader:last-child": {
                                background: '#F9FAFB',
                                padding: '0px 20px',
                            },
                            "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                display: "none"
                            },
                            "& .MuiDataGrid-cell":{
                                minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                            },
                            "& .MuiDataGrid-cell:last-child": {
                                // minWidth: "540px !important"
                            },
                            "& .MuiDataGrid-cellContent": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#111827',
                                padding: '0px 10px',
                                // minWidth: '540px'
                            },
                            "& .MuiDataGrid-cellContent:last-child": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#111827',
                                padding: '0px 10px',
                                // minWidth: '540px'
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 600,
                                color: '#6B7280',
                                fontSize: '12px',
                                lineHeight: '20px',
                            },
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-row.Mui-selected": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-row.Mui-selected:hover": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-cell:focus": {
                                outline: 'none'
                            },
                            "& .MuiDataGrid-toolbarContainer": {
                                color: '#454545',
                                position: 'absolute',
                                top: '-62px',
                                right: '0px'
                            },
                            "& .MuiButtonBase-root": {
                                fontFamily: 'Inter, sans-serif !important',
                                fontWeight: 600,
                                color: '#6B7280',
                                fontSize: '12px',
                                lineHeight: '20px',
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginBottom: 0
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                overflowY: 'scroll !important',
                                overflowX: 'scroll !important',
                                height: `${currentScreenHeight - 420}px !important`,
                            },
                        }}
                        apiRef={apiRef}
                        getRowId={e => e.id}
                        columns={columns}
                        rows={visibleRows}
                        {...visibleRows}
                        rowHeight={58}
                        headerHeight={39}
                        pagination={true}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        hideFooterSelectedRowCount
                        autoHeight={true}
                        onRowClick={onMobileRowClick}
                    />
                </Box>
            </div>
            <FacebookModal
                editMode={editMode}
                setEditMode={(editMode) => setEditMode(editMode)}
            />
        </div>
    );
};

export const FacebookModal: React.FC<{ editMode?: boolean, setEditMode?: (editMode: boolean) => void, isMultiStep?: boolean, onStepChange?: () => void, isMobileLink?: boolean }> = ({
                                                                                                                                                                                         editMode,
                                                                                                                                                                                         setEditMode,
                                                                                                                                                                                         isMultiStep,
                                                                                                                                                                                         onStepChange,
                                                                                                                                                                                         isMobileLink
                                                                                                                                                                                     }) => {
    const dispatch = useAppDispatch()
    const isModalOpen = useAppSelector(selectIsFacebookModalOpen)
    const userBusinesses = useAppSelector(selectUserBusinesses)
    const addAccountConfigurations = useAppSelector(selectAddAccountConfigurations)
    const catalogAccountConfigurations = useAppSelector(selectCatalogAccountConfigurations)
    const instagramAccountConfigurations = useAppSelector(selectInstagramAccountConfigurations)
    const pageAccountConfigurations = useAppSelector(selectPageAccountConfigurations)
    const pagePixelConfigurations = useAppSelector(selectPixelAccountConfigurations)
    const selectedConfigurations = useAppSelector(selectSelectedConfigurations)
    const currentConfiguration = useAppSelector(selectCurrentConfiguration)
    const businessPermissions = useAppSelector(selectPermissionCatalog)
    const currentPlatformIndex = useAppSelector(selectCurrentPlatformIndex)
    const multiStageServices = useAppSelector(selectMultiStageServices)
    const currentMultiStepLink = useAppSelector(selectMultiStepCurrentLinkData)
    const [form] = Form.useForm()
    const multiStepReqData = useAppSelector(selectMultiStepLinksReqData)
    const currentServiceType = userDataHelper.getMobileLinkServiceType()
    const navigate = useNavigate()
    const isMultiStepLinkFinished = useAppSelector(selectIsMultiStepLinkFinished)

    useEffect(() => {
        if (isMultiStep && currentMultiStepLink) {
            form.setFieldsValue(currentMultiStepLink && currentMultiStepLink.facebook_configuration)
        } else if (editMode) {
            form.setFieldsValue(currentConfiguration)
        } else if (isMultiStep && !editMode && !isMultiStepLinkFinished) {
            form.setFieldsValue({name: ''})
            form.setFieldsValue({business: ''})
            form.setFieldsValue({description: ''})
        } else {
            form.setFieldsValue({name: ''})
            form.setFieldsValue({business: ''})
            form.setFieldsValue({description: ''})
            userMultiStepHelper.setIsUserMultiStepLogin(false)
        }
    }, [form, currentConfiguration, currentMultiStepLink, isMultiStep, editMode, isMultiStepLinkFinished])


    const onFinish = (formData: any, button?: string) => {
        let values = form.getFieldsValue();
        if (!isMultiStep) {
            if (!selectedConfigurations.length) {
                message.error('Please, select at least one configuration!')
            } else {
                if (!editMode) {
                    const requestBody = {
                        name: formData.name,
                        description: formData.description,
                        business_id: formData.business.id,
                        permission_id_list: selectedConfigurations,
                        is_multi_service_configuration: false
                    }
                    dispatch(CreatePermissionConfigurationConfigThunk({request: requestBody}))
                    dispatch(setFacebookModalOpen(false))
                    dispatch(resetFacebookModalConfigurations())
                } else if (editMode) {
                    const requestBody = {
                        name: formData.name,
                        description: formData.description,
                        business_id: formData.business.id,
                        permission_id_list: selectedConfigurations,
                        is_multi_service_configuration: false
                    }
                    dispatch(UpdatePermissionConfigurationConfigThunk({
                        request: {
                            configId: currentConfiguration.id,
                            configData: requestBody
                        }
                    }))
                    dispatch(setFacebookModalOpen(false))
                }
            }
        } else {
            if (button === 'Next') {
                const requestBody = {
                    name: values.name,
                    description: values.description,
                    business_id: values.business.id,
                    permission_id_list: selectedConfigurations,
                    is_multi_service_configuration: true
                }
                if (onStepChange && values.business.id && selectedConfigurations.length) {
                    dispatch(setFacebookStepData({
                        name: values.name,
                        description: values.description,
                        business_id: values.business.id,
                        permission_id_list: selectedConfigurations
                    }))
                    if (!editMode) {
                        dispatch(CreatePermissionConfigurationConfigThunk({request: requestBody, isMultiStep: true}))
                        onStepChange()
                    } else if (editMode) {
                        dispatch(UpdatePermissionConfigurationConfigThunk({
                            request: {
                                configId: currentMultiStepLink?.facebook_configuration.id,
                                configData: requestBody
                            },
                            isMultiStep: true
                        }))
                    }
                    onStepChange()
                } else {
                    if (onStepChange) {
                        onStepChange()
                    }
                }
            }
        }
        if (isMobileLink && !isMultiStep) {
            navigate('/facebookLinksTable')
        }
    }

    const onChange = (e: any, id: number) => {
        if (e.target.checked === true) {
            dispatch(addSelectedConfigurations(e.target.value))
        } else if (e.target.checked === false) {
            dispatch(deleteSelectedConfigurations(e.target.value))
        }
        dispatch(changeAddAccountConfigurationCheckbox({checked: e.target.checked, id}))
        dispatch(changeCatalogAccountConfigurationCheckbox({checked: e.target.checked, id}))
        dispatch(changeInstagramAccountConfigurationCheckbox({checked: e.target.checked, id}))
        dispatch(changePageAccountConfigurationCheckbox({checked: e.target.checked, id}))
        dispatch(changePixelAccountConfigurationCheckbox({checked: e.target.checked, id}))
    }

    const onCloseModal = () => {
        if (!isMobileLink) {
            dispatch(setFacebookModalOpen(false))
            dispatch(setCurrentConfig({}))
            dispatch(GetPermissionConfigurationsThunk())
            dispatch(setAddAccountConfigurations(businessPermissions.AD_ACCOUNT))
            dispatch(setCatalogAccountConfigurations(businessPermissions.CATALOG))
            dispatch(setInstagramAccountConfigurations(businessPermissions.INSTAGRAM))
            dispatch(setPageAccountConfigurations(businessPermissions.PAGE))
            dispatch(setPixelAccountConfigurations(businessPermissions.PIXEL))
            dispatch(setSelectedConfigurations({isEdit: false, emptyData: []}))
            dispatch(setMultiStageServices([]))
            dispatch(setCurrentGoogleModalLinkPermissions({permissions: []}))
            dispatch(setCurrentTikTokModalLinkPermissions({permissions: []}))
            dispatch(setBeforeAddingLinksModalOpen(false))
            form.setFieldsValue({name: ''})
            form.setFieldsValue({description: ''})
            form.setFieldsValue({business: ''})
            dispatch(onSetCurrentMultiStepLink(null))
            if (setEditMode) {
                setEditMode(false)
            }
        }
        if (isMultiStep) {
            window.location.reload()
        } else {
            dispatch(resetFacebookModalConfigurations())
            dispatch(setMultiStageServices([]))
            dispatch(setFacebookModalOpen(false))
            dispatch(onSetCurrentMultiStepLink(null))
            dispatch(setMobileIsMultiLinkOpened(false))
        }


    }

    const onChangeSwitch = (checked: boolean) => {
        dispatch(switchAllConfigurationsCheckboxes(checked))
    };


    const onSendAllLinksData = () => {
        dispatch(setMobileIsMultiLinkOpened(false))
        let values = form.getFieldsValue();
        const requestBody = {
            name: values.name,
            description: values.description,
            business_id: values.business.id,
            permission_id_list: selectedConfigurations,
            is_multi_service_configuration: true
        }
        if (!editMode) {
            dispatch(CreatePermissionConfigurationConfigThunk({
                request: requestBody,
                isMultiStep: true,
                multiStepReqData: multiStepReqData
            }))
        } else if (editMode) {
            dispatch(UpdatePermissionConfigurationConfigThunk({
                request: {
                    configId: currentMultiStepLink?.facebook_configuration.id,
                    configData: requestBody
                },
                isMultiStep: true,
                multiStepReqData: multiStepReqData
            }))
        }
        dispatch(setBeforeAddingLinksModalOpen(null))
        dispatch(setMultiStageServices([]))
        dispatch(setSelectedConfigurations({isEdit: false, emptyData: []}))
        dispatch(setCurrentGoogleModalLinkPermissions({permissions: []}))
        dispatch(setCurrentTikTokModalLinkPermissions({permissions: []}))
        dispatch(switchAllConfigurationsCheckboxes(false))
        dispatch(setFacebookStepData({}))
        dispatch(setGoogleStepData({}))
        dispatch(setTikTokStepData({}))
        setTimeout(() => {
            window.location.reload()
        }, 500)
        if (isMobileLink && !isMultiStep) {
            navigate('/facebookLinksTable')
        } else if (isMobileLink && isMultiStep && currentServiceType === 'All') {
            dispatch(setMobileIsMultiLinkOpened(false))
            navigate('/allLinksTable')
        } else if (isMobileLink && isMultiStep && currentServiceType === 'Common') {
            dispatch(setMobileIsMultiLinkOpened(false))
            navigate('/commonLinksTable')
        }
    }


    return (
        <CommonModal active={isModalOpen} setEditMode={(editMode) => setEditMode && setEditMode(editMode)}
                     height={'600px'}>
            <div className={`${s.modal} facebookModal`}>
                <button className={s.modal__closeBtn} onClick={onCloseModal}>
                    <closeIcon.ReactComponent/>
                </button>
                <h2>{editMode ? 'Edit Link' : 'Add Link'}</h2>
                <MultiStepIndicator currentStage={'Facebook'} editMode={editMode && editMode}/>
                <Form
                    name='facebookLinksTable'
                    onFinish={(formData) => onFinish(formData)}
                    form={form}
                    autoComplete='off'
                    validateTrigger='onBlur'
                    initialValues={currentConfiguration}
                >
                    <div>
                        <div>
                            <p className={s.modal__subtitle}>Name:</p>

                                <Form.Item
                                    name='name'
                                    rules={[
                                        {required: true, message: 'Enter configuration name!'},
                                    ]}
                                >

                                    <Input
                                        placeholder={'Enter name please'}
                                        className={s.modal__input}


                                    />

                                </Form.Item>

                        </div>
                        <div>
                            <p className={s.modal__subtitle}>Configuration description:</p>

                                <Form.Item
                                    name='description'
                                    rules={[
                                        {required: true, message: 'Enter configuration description!'},
                                    ]}
                                >

                                            <Input
                                                placeholder={'Enter configuration description please'}
                                                className={s.modal__input}

                                            />

                                </Form.Item>



                        </div>
                        <div>
                            <p className={s.modal__subtitle}>Your business:</p>
                            <Form.Item
                                name={['business', 'id']}
                                rules={[
                                    {required: true, message: 'Select your business please!'},
                                ]}
                            >
                                <Select placeholder="Select your business" className={s.modal__select}>
                                    {userBusinesses.map((b: any) => (
                                        <Select.Option key={b.id} value={b.id}>{b.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <p className={s.modal__subtitle}>Select permissions required from client.</p>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px'
                        }}>
                            <p className={s.modal__inputLabel}>Select all configurations:</p>
                            <Switch defaultChecked={false} onChange={onChangeSwitch} style={{marginLeft: '15px'}}/>
                        </div>
                        <p className={s.modal__categoryTitle}>
                            AD_ACCOUNT :
                        </p>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            {addAccountConfigurations?.map((p: any) => {
                                return (
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Checkbox
                                            onChange={(e) => onChange(e, p.id)}
                                            value={p.id}
                                            checked={p.checked === true}
                                            className={s.modal__checkbox}
                                            disabled={!p.is_active}
                                        >
                                            {p.name}
                                        </Checkbox>
                                        <Tooltip title={p.description}>
                                            <QuestionCircleOutlined/>
                                        </Tooltip>
                                    </div>
                                )
                            })}
                        </div>
                        <p className={s.modal__categoryTitle}>
                            CATALOG :
                        </p>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            {catalogAccountConfigurations?.map((p: any) => {
                                return (
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Checkbox
                                            onChange={(e) => onChange(e, p.id)}
                                            value={p.id}
                                            checked={p.checked === true}
                                            className={s.modal__checkbox}
                                            disabled={!p.is_active}
                                        >
                                            {p.name}
                                        </Checkbox>
                                        <Tooltip title={p.description}>
                                            <QuestionCircleOutlined/>
                                        </Tooltip>
                                    </div>
                                )
                            })}
                        </div>
                        <p className={s.modal__categoryTitle}>
                            INSTAGRAM :
                        </p>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            {instagramAccountConfigurations?.map((p: any) => {
                                return (
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Checkbox
                                            onChange={(e) => onChange(e, p.id)}
                                            value={p.id}
                                            checked={p.checked === true}
                                            className={s.modal__checkbox}
                                            disabled={!p.is_active}
                                        >
                                            {p.name}
                                        </Checkbox>
                                        <Tooltip title={p.description}>
                                            <QuestionCircleOutlined/>
                                        </Tooltip>
                                    </div>

                                )
                            })}
                        </div>
                        <p className={s.modal__categoryTitle}>
                            PAGE :
                        </p>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            {pageAccountConfigurations?.map((p: any) => {
                                return (
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Checkbox
                                            onChange={(e) => onChange(e, p.id)}
                                            value={p.id}
                                            checked={p.checked === true}
                                            className={s.modal__checkbox}
                                            disabled={!p.is_active}
                                        >
                                            {p.name}
                                        </Checkbox>
                                        <Tooltip title={p.description}>
                                            <QuestionCircleOutlined/>
                                        </Tooltip>
                                    </div>
                                )
                            })}
                        </div>
                        <p className={s.modal__categoryTitle}>
                            PIXEL :
                        </p>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            {pagePixelConfigurations?.map((p: any) => {
                                return (
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Checkbox
                                            onChange={(e) => onChange(e, p.id)}
                                            value={p.id}
                                            checked={p.checked === true}
                                            className={s.modal__checkbox}
                                            disabled={!p.is_active}
                                        >
                                            {p.name}
                                        </Checkbox>
                                        <Tooltip title={p.description}>
                                            <QuestionCircleOutlined/>
                                        </Tooltip>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                    {!isMultiStep
                        ?
                        <Form.Item>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <button
                                    className={s.modal__btn}
                                    type={"submit"}
                                >Confirm
                                </button>
                            </div>
                        </Form.Item>
                        :
                        <div>
                            {
                                currentPlatformIndex !== multiStageServices.length - 1
                                    ?
                                    <Form.Item>
                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <button
                                                className={s.modal__btn}
                                                type={"button"}
                                                onClick={(formData: any) => onFinish(formData, 'Next')}
                                            >Next
                                            </button>
                                        </div>
                                    </Form.Item>
                                    :
                                    <Form.Item>
                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <button
                                                className={s.modal__btn}
                                                type={"button"}
                                                onClick={onSendAllLinksData}
                                            >Add link
                                            </button>
                                        </div>
                                    </Form.Item>
                            }

                        </div>
                    }
                </Form>

            </div>
        </CommonModal>
    )
}

export default FacebookLinksTable;
