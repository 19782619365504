import React, {useEffect, useState} from 'react';
import PageWithSidebar from '../PageWithSidebar/PageWithSidebar';
import s from './../FacebookLinksTable/FacebookLinksTable.module.scss'
import {useAppDispatch, useAppSelector} from '../../hooks';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import PageTitle from '../PageTitle/PageTitle';
import {DataGridPro, useGridApiRef} from "@mui/x-data-grid-pro";
import RequirementSearchInput from '../RequirementSearchInput/RequirementSearchInput';
import {Box} from "@material-ui/core";
import {Popconfirm, message} from 'antd';
import {
    GetPermissionConfigurationsThunk,
    setAddAccountConfigurations,
    setCatalogAccountConfigurations,
    setInstagramAccountConfigurations,
    setPageAccountConfigurations,
    setPixelAccountConfigurations
} from '../../store/permissionConfigurationReducer';
import {
    GetBusinessPermissionThunk,
    GetUserBusinessesThunk,
    selectPermissionCatalog
} from '../../store/businessAssetReducer';
import {
    GetTikTokTablePermissionsThunk
} from "../../store/tikTokReducer";
import {
    selectBeforeAddingLinksTableModalOpen,
    setBeforeAddingLinksModalOpen,
    setFacebookModalOpen,
    setGoogleTableModalOpen,
    setTikTokTableModalOpen
} from '../../store/utilityReducer';
import {FacebookModal} from '../FacebookLinksTable/FacebookLinksTable';
import {TikTokModal} from '../TikTokLinksTable/TikTokLinksTable';
import {
    GetGoogleTablePermissionsThunk
} from '../../store/googleReducer';
import {GoogleModal} from '../GoogleLinksTable/GoogleLinksTable';
import {
    GetAllServicesLinksThunk,
    selectMultiStageServices,
    setMultiStageServices
} from '../../store/linksReducer';
import BeforeAddingLinkModal from "../BeforeAddingLinkModal/BeforeAddingLinkModal";
import {
    DeleteMultiStepTableLinkThunk, GetMultiStepLinkByIdThunk,
    GetMultiStepLinksThunk,
    onSetCurrentPlatformIndex,
    selectCurrentPlatformIndex,
    selectMultiStepCurrentLinkData, selectMultiStepLinks
} from "../../store/multiStepReducer";
import {useNavigate} from "react-router";
import {userDataHelper} from "../../helpers/localStorageHelper";

const copyIcon = require('../../img/icons/copy.svg')
const deleteIcon = require('../../img/icons/delete.svg')
const editIcon = require('../../img/icons/edit.svg')


const TableForCommonLinks = () => {
    return (
        <PageWithSidebar>
            <TableForCommonLinksComponent/>
        </PageWithSidebar>
    )
}

const TableForCommonLinksComponent = () => {
    const dispatch = useAppDispatch()
    const [visibleRowsCount, setVisibleRowsCount] = useState(0)
    const [visibleRows, setVisibleRows] = useState<any[]>([])
    const platformOrder = ['Facebook', 'Google', 'TikTok'];
    const businessPermissions = useAppSelector(selectPermissionCatalog)
    const beforeAllStages = useAppSelector(selectBeforeAddingLinksTableModalOpen)
    let multiStageServices = useAppSelector(selectMultiStageServices)
    const userData = userDataHelper.getUserData()
    const currentPlatformIndex = useAppSelector(selectCurrentPlatformIndex)
    const multiStepLinks = useAppSelector(selectMultiStepLinks)
    const currentMultiStepLink = useAppSelector(selectMultiStepCurrentLinkData)


    const [screenWidth, setScreenWidth] = useState<number>(0)
    const navigate = useNavigate()
    useEffect(() => {
        const handleResize = () => {

            setScreenWidth(window.innerWidth)
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        dispatch(GetMultiStepLinksThunk())
    }, [dispatch])

    useEffect(() => {
        setVisibleRowsCount(multiStepLinks?.length)
        setVisibleRows(multiStepLinks)
    }, [multiStepLinks])

    useEffect(() => {
        dispatch(GetBusinessPermissionThunk())
        dispatch(GetPermissionConfigurationsThunk())
        dispatch(GetUserBusinessesThunk(userData.user_id))
    }, [dispatch, userData.user_id])
    useEffect(() => {
        dispatch(setAddAccountConfigurations(businessPermissions.AD_ACCOUNT))
        dispatch(setCatalogAccountConfigurations(businessPermissions.CATALOG))
        dispatch(setInstagramAccountConfigurations(businessPermissions.INSTAGRAM))
        dispatch(setPageAccountConfigurations(businessPermissions.PAGE))
        dispatch(setPixelAccountConfigurations(businessPermissions.PIXEL))
        // eslint-disable-next-line
    }, [dispatch, businessPermissions.AD_ACCOUNT, businessPermissions.CATALOG, businessPermissions.INSTAGRAM, businessPermissions.PAGE, businessPermissions.PIXEL])


    useEffect(() => {
        if (currentMultiStepLink !== null) {
            dispatch(setMultiStageServices(
                [
                    currentMultiStepLink.facebook_configuration && Object.keys(currentMultiStepLink.facebook_configuration).length ? 'Facebook' : '',
                    currentMultiStepLink.google_configuration && Object.keys(currentMultiStepLink.google_configuration).length ? 'Google' : '',
                    currentMultiStepLink.tiktok_configuration && Object.keys(currentMultiStepLink.tiktok_configuration).length ? 'TikTok' : ''
                ].filter((l: string) => l !== '')
            ))
        }
    }, [currentMultiStepLink, dispatch])


    useEffect(() => {
        dispatch(GetGoogleTablePermissionsThunk())
    }, [dispatch])

    useEffect(() => {
        dispatch(GetTikTokTablePermissionsThunk())
    }, [dispatch])

    useEffect(() => {
        // eslint-disable-next-line
        multiStageServices = [...multiStageServices].sort((a, b) => platformOrder.indexOf(a) - platformOrder.indexOf(b))
        dispatch(onSetCurrentPlatformIndex(0))
    }, [dispatch, multiStageServices]);
    const currentPlatform = multiStageServices[currentPlatformIndex];


    useEffect(() => {
        dispatch(setFacebookModalOpen(currentPlatform === 'Facebook'))
        dispatch(setGoogleTableModalOpen(currentPlatform === 'Google'))
        dispatch(setTikTokTableModalOpen(currentPlatform === 'TikTok'))
    }, [currentPlatformIndex, multiStageServices, currentPlatform, dispatch]);


    const nextPlatform = () => {
        if (currentPlatformIndex < multiStageServices.length - 1) {
            dispatch(onSetCurrentPlatformIndex(currentPlatformIndex + 1))
        }
    };

    const previousPlatform = () => {
        if (currentPlatformIndex > 0) {
            dispatch(onSetCurrentPlatformIndex(currentPlatformIndex - 1))
        }
    };

    const handleButtonClick = () => {
        if (currentPlatformIndex < multiStageServices.length - 1) {
            nextPlatform();
        } else {
            previousPlatform();
        }
    };

    useEffect(() => {
        dispatch(GetAllServicesLinksThunk())
    }, [dispatch])

    // get data for facebook modal form
    useEffect(() => {
        dispatch(setAddAccountConfigurations(businessPermissions.AD_ACCOUNT))
        dispatch(setCatalogAccountConfigurations(businessPermissions.CATALOG))
        dispatch(setInstagramAccountConfigurations(businessPermissions.INSTAGRAM))
        dispatch(setPageAccountConfigurations(businessPermissions.PAGE))
        dispatch(setPixelAccountConfigurations(businessPermissions.PIXEL))
        // eslint-disable-next-line
    }, [dispatch, businessPermissions.AD_ACCOUNT, businessPermissions.CATALOG, businessPermissions.INSTAGRAM, businessPermissions.PAGE, businessPermissions.PIXEL])


    const onCopyLink = async (url: string) => {
        try {
            await navigator.clipboard.writeText(url);
            message.success('Link was copied successfully', 3)
        } catch (err) {
            message.error('Failed to copy link');
        }
    }


    const onDeleteMultiStepLink = (configId: number) => {
        dispatch(DeleteMultiStepTableLinkThunk(configId))
    }

    const onEditMultiStepLink = (configId: number) => {
        dispatch(GetMultiStepLinkByIdThunk(configId))

    }

    const onAddLink = () => {
        if (!multiStageServices.length) {
            dispatch(setBeforeAddingLinksModalOpen(true))
        }
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: screenWidth > 768 ? 1 : 0.6,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
        },
        {
            field: 'url',
            headerName: 'Link URL',
            flex: screenWidth > 1400 ? 2 : screenWidth > 768 ? 1 : 0.5,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            renderCell: (data: any) => (
                <div style={{color: '#667085', marginLeft: '10px'}}>
                    <span style={{display: screenWidth < 768 ? 'none' : 'block'}}>{data.row.url}</span>
                    <span style={{display: screenWidth >= 768 ? 'none' : 'block'}}>Link</span>
                </div>
            )
        },
        {
            field: 'date_created',
            headerName: 'Date Created',
            flex: screenWidth > 768 ? 1 : 0.5,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            renderCell: (data: any) => (
                <div style={{color: '#667085', marginLeft: '10px'}}>
                    {/* eslint-disable */}
                    {data.row.facebook_configuration && Object.keys(data.row.facebook_configuration).length && data.row.facebook_configuration.date_created || data.row.google_configuration && Object.keys(data.row.google_configuration).length && data.row.google_configuration.date_created || data.row.tiktok_configuration && Object.keys(data.row.tiktok_configuration).length && data.row.tiktok_configuration.date_created}
                </div>
            )
        },
        {
            field: 'Action',
            headerName: '',
            flex: 0.6,
            disableExport: true,
            hide: screenWidth < 768,
            renderCell: (data: any) => {
                return (
                    <div>
                        <copyIcon.ReactComponent
                            style={
                                {
                                    marginRight: '15px',
                                    cursor: 'pointer',
                                    fontSize: '18px'
                                }
                            }
                            color="action"
                            onClick={() => {
                                onCopyLink(data.row.url)
                            }}
                        />
                        <Popconfirm
                            title="Are you sure to delete this link?"
                            onConfirm={() => {
                                onDeleteMultiStepLink(data.row.id)
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <deleteIcon.ReactComponent
                                style={
                                    {
                                        marginRight: '15px',
                                        cursor: 'pointer',
                                        fontSize: '18px'
                                    }
                                }
                                color="action"
                            />
                        </Popconfirm>
                        <editIcon.ReactComponent
                            color="action"
                            style={
                                {
                                    marginRight: '15px',
                                    cursor: 'pointer',
                                    fontSize: '18px'
                                }
                            }
                            onClick={() => {
                                onEditMultiStepLink(data.row.id)
                            }}
                        />
                    </div>
                )
            }
        }
    ]
    const apiRef = useGridApiRef();


    const onMobileRowClick = (value: any) => {
        if (screenWidth < 768) {
            dispatch(GetMultiStepLinkByIdThunk(value.row.id))
            userDataHelper.setMobileLinkServiceType('Common')
            navigate('/mobileLink')
        }
    }

    return (
        <div style={{
            padding: screenWidth > 1160 ? '32px' : screenWidth > 480 ? '10px 20px' : '10px 8px'
        }}>
            <Breadcrumbs/>
            <div className={s.inner__header}>
                <PageTitle
                    title='Table for common links'
                    description='Create links for your customers'
                    onAddLink={onAddLink}
                />
            </div>
            <RequirementSearchInput
                apiRef={apiRef}
                setVisibleRowsCount={(count: number) => setVisibleRowsCount(count)}
                setVisibleRows={(rows: any[]) => setVisibleRows(rows)}
                allRows={multiStepLinks}
            />
            <div className={s.tableInner}>
                <h2>
                    All links <span>{visibleRowsCount} links</span>
                </h2>
                <Box>
                    <DataGridPro
                        sx={{
                            borderRight: 'none',
                            borderLeft: 'none',
                            borderBottom: 'none',
                            borderRadius: '0px',
                            borderTop: '1px solid transparent',
                            "& .MuiDataGrid-columnHeader": {
                                background: '#F9FAFB',
                                padding: '0px 20px',
                                minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                            },
                            "& .MuiDataGrid-columnHeader:last-child": {
                                background: '#F9FAFB',
                                padding: '0px 20px',
                                // minWidth: "540px !important"
                            },
                            "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                display: "none"
                            },
                            "& .MuiDataGrid-cell": {
                                minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                            },
                            "& .MuiDataGrid-cell:last-child": {
                                // minWidth: "540px !important"
                            },
                            "& .MuiDataGrid-cellContent": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#111827',
                                padding: '0px 10px',
                                // minWidth: '540px'
                            },
                            "& .MuiDataGrid-cellContent:last-child": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#111827',
                                padding: '0px 10px',
                                // minWidth: '540px'
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 600,
                                color: '#6B7280',
                                fontSize: '12px',
                                lineHeight: '20px',
                            },
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-row.Mui-selected": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-row.Mui-selected:hover": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-cell:focus": {
                                outline: 'none'
                            },
                            "& .MuiDataGrid-toolbarContainer": {
                                color: '#454545',
                                position: 'absolute',
                                top: '-62px',
                                right: '0px'
                            },
                            "& .MuiButtonBase-root": {
                                fontFamily: 'Inter, sans-serif !important',
                                fontWeight: 600,
                                color: '#6B7280',
                                fontSize: '12px',
                                lineHeight: '20px',
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginBottom: 0
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                overflowY: 'scroll',
                                overflowX: 'scroll !important',
                            },
                        }}
                        apiRef={apiRef}
                        getRowId={e => e.id}
                        columns={columns}
                        rows={visibleRows}
                        {...visibleRows}
                        rowHeight={58}
                        headerHeight={39}
                        pagination={true}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        hideFooterSelectedRowCount
                        autoHeight={true}
                        onRowClick={onMobileRowClick}
                    />
                </Box>
            </div>
            {beforeAllStages && !multiStageServices.length
                &&
                <BeforeAddingLinkModal/>
            }
            <FacebookModal
                isMultiStep={true}
                onStepChange={handleButtonClick}
                editMode={!!(currentMultiStepLink && currentMultiStepLink.facebook_configuration && Object.keys(currentMultiStepLink.facebook_configuration).length)}
            />
            <GoogleModal
                isMultiStep={true}
                onStepChange={handleButtonClick}
                editMode={!!(currentMultiStepLink && currentMultiStepLink.google_configuration && Object.keys(currentMultiStepLink.google_configuration).length)}
            />
            <TikTokModal
                isMultiStep={true}
                onStepChange={handleButtonClick}
                editMode={!!(currentMultiStepLink && currentMultiStepLink.tiktok_configuration && Object.keys(currentMultiStepLink.tiktok_configuration).length)}
            />
        </div>
    );
};

export default TableForCommonLinks
