import React, {useEffect, useState} from 'react';
import tableS from "../FacebookLinksTable/FacebookLinksTable.module.scss";
import s from './TableOfCredits.module.scss'
import moment from "moment/moment";
import PageTitle from "../PageTitle/PageTitle";
import {Box} from "@material-ui/core";
import {DataGridPro, useGridApiRef} from "@mui/x-data-grid-pro";
import {useAppDispatch, useAppSelector} from "../../hooks";
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import {GetAgencyDataThunk, selectAgencyData} from "../../store/agencyReducer";
import {DatePicker, Empty} from "antd";
import {selectCurrentScreenHeight} from "../../store/utilityReducer";
import {GetClientsThunk, onClearSearchValue, selectClients} from "../../store/clientReducer";
import {tokenDataHelper, userDataHelper} from "../../helpers/localStorageHelper";
import {setUserData} from "../../store/userReducer";
import {FacebookCircularProgress} from "../Client/ClientsTable/ClientsTable";
import axios from "axios";
import {pickBy} from "lodash";
import {UserDataType} from "../../types/userTypes";
const calendarIcon = require('./../../img/icons/calendar.svg')
const { RangePicker } = DatePicker;


const TableOfCredits = () => {
    return (
        <PageWithSidebar>
            <TableOfCreditsComponent/>
        </PageWithSidebar>
    )
}


const TableOfCreditsComponent = () => {
    const [screenWidth, setScreenWidth] = useState<number>(0)
    const [visibleRows, setVisibleRows] = useState<any[]>([])
    const dispatch = useAppDispatch()
    const agencyData = useAppSelector(selectAgencyData)
    const clients = useAppSelector(selectClients)
    const currentScreenHeight = useAppSelector(selectCurrentScreenHeight)
    // eslint-disable-next-line
    const [currentTab, setCurrentTab] = useState('Credits')
    const usedCreditsQuantity = clients.filter((c: any) => c.credits)
    const [isLoading, setIsLoading] = useState(false)
    const token = tokenDataHelper.getTokenData()

    useEffect(() => {
        axios.get('/login/refresh', {
            headers: {
                'Authorization': token.token
            }
        })
            .then((response) => {
                userDataHelper.setUserData(pickBy(response.data, (_, key) => key !== 'token') as UserDataType)
            })
    }, [token.token])

    useEffect(() => {
        dispatch(setUserData(userDataHelper.getUserData()))
    }, [dispatch])
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);





    useEffect(() => {
        dispatch(GetAgencyDataThunk())
        dispatch(onClearSearchValue())
    }, [dispatch])



    const columns = [
        {
            field: 'name',
            headerName: 'Client name',
            flex: 0.35,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            filterable: false,
        },
        {
            field: 'email',
            headerName: 'Client email',
            flex: 0.35,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            filterable: false,
        },
        {
            field: 'credits',
            headerName: 'Amount',
            flex: 0.1,
            filterable: false,
            renderCell: (data: any) => (
                <div className={data.row.credits !== 0 ? s.badge : ''}>
                    {
                        data.row.credits !== 0
                        &&
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                            <path d="M6.5 2.5V9.5M6.5 9.5L10 6M6.5 9.5L3 6" stroke="#F04438" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    }
                    <span style={{
                        marginLeft: '4px'
                    }}>{data.row.credits === 0 ? null : '-1'}</span>
                </div>
            )
        },
        {
            field: 'date_created',
            headerName: 'Last date of use',
            flex: 0.2,
            filterable: false,
            disableExport: true,
            renderCell: (data: any) => (<div
                style={{
                    color: '#667085',
                    marginLeft: '10px',
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '22px',
                    cursor: 'pointer'
                }}>
                {data.row.date_created ? moment(data.row.date_created).format('DD MMM YYYY') : ''}
            </div>)
        },
    ]



    const apiRef = useGridApiRef();


    const onDateChange = (moments: any, dates: any) => {
        if(dates[0] !== '' && dates[1] !== ''){
            let tableLinksCopy = [...clients.filter((c: any) => c.credits !== 0)]
            tableLinksCopy = tableLinksCopy.filter((l: any) => {
                return moment(l.date_created).isSameOrAfter(dates[0] , 'day')  && (moment(l.date_created).isSameOrBefore(dates[1] , 'day'))
            })
            setVisibleRows(tableLinksCopy)
        }
        else{
            setVisibleRows(clients.filter((c: any) => c.credits !== 0))
        }

    }
    useEffect(() => {
        setVisibleRows(clients.filter((c: any) => c.credits !== 0))
    }, [clients])

    useEffect(() => {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('filter', new Blob([JSON.stringify({search_value: ''})], {type: 'application/json'}))
        dispatch(GetClientsThunk(formData))
            .then(() => {
                setIsLoading(false)
            })
    }, [dispatch])

    return (
        <div
            style={{
                padding: screenWidth > 1160 ? '32px' : screenWidth > 480 ? '10px 20px' : '16px'
            }}>
            <div className={tableS.inner__header}>
                <PageTitle
                    title='Table of credits'
                    description='Check all credits'
                />
                <div className={s.buttonsInner}>
                    <div className={s.creditsQuantityBtn}>{agencyData.credits} credits</div>
                    <button className={s.buyCreditsBtn}>+ Buy credits</button>
                </div>
            </div>
            <div className={s.tabs}>
                <div className={currentTab === 'Credits' ? `${s.tabs__tab} ${s.tabs__tab_active}` : s.tabs__tab}>Credits</div>
                <div className={s.tabs__tab}>History</div>
            </div>
            <RangePicker
                onChange={onDateChange}
                className={`${s.datePicker}`}
                suffixIcon={<calendarIcon.ReactComponent/>}
                dropdownClassName={'creditsTableDatePicker'}
                format="MMM DD, YYYY"
            />
            <div className={tableS.tableInner}>
                <h2>
                     Used credits<span>{usedCreditsQuantity.length} </span>
                </h2>
                {
                    isLoading
                        ?
                        <div style={{
                            height: currentScreenHeight - 387,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative'
                        }}>
                            <div style={{
                                position: "absolute",
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <FacebookCircularProgress size={100}/>
                            </div>

                        </div>
                        :
                        visibleRows.length
                            ?
                            <Box>
                                <DataGridPro

                                    sx={{
                                        borderRight: 'none',
                                        borderLeft: 'none',
                                        borderBottom: 'none',
                                        borderRadius: '0px',
                                        borderTop: '1px solid transparent',
                                        "& .MuiDataGrid-columnHeader": {
                                            background: '#F9FAFB',
                                            padding: '0px 20px',
                                            minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                                        },
                                        "& .MuiDataGrid-columnHeader:nth-child(3)": {
                                            background: '#F9FAFB',
                                            padding: '0px 20px',
                                            // minWidth: "540px !important"
                                        },
                                        "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                            display: "none"
                                        },
                                        "& .MuiDataGrid-cell":{
                                            minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                                        },
                                        "& .MuiDataGrid-cell:nth-child(3)":{
                                            // minWidth: "540px !important"
                                        },
                                        "& .MuiDataGrid-cellContent": {
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#111827',
                                            padding: '0px 10px',
                                            // minWidth: '540px'
                                        },
                                        "& .MuiDataGrid-cellContent:nth-child(3)": {
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#111827',
                                            padding: '0px 10px',
                                            // minWidth: '540px'
                                        },
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 600,
                                            color: '#6B7280',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                        },
                                        "& .MuiDataGrid-row:hover": {
                                            backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                        },
                                        "& .MuiDataGrid-row.Mui-selected": {
                                            backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                        },
                                        "& .MuiDataGrid-row.Mui-selected:hover": {
                                            backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                        },
                                        "& .MuiDataGrid-cell:focus": {
                                            outline: 'none'
                                        },
                                        "& .MuiDataGrid-toolbarContainer": {
                                            color: '#454545',
                                            position: 'absolute',
                                            top: '-62px',
                                            right: '0px'
                                        },
                                        "& .MuiButtonBase-root": {
                                            fontFamily: 'Inter, sans-serif !important',
                                            fontWeight: 600,
                                            color: '#6B7280',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                        },
                                        "& .MuiTablePagination-displayedRows": {
                                            marginBottom: 0
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            overflowY: 'scroll !important',
                                            overflowX: 'scroll !important',
                                            height: `${currentScreenHeight - 450}px !important`,
                                        },
                                    }}
                                    apiRef={apiRef}
                                    columns={columns}
                                    rows={visibleRows}
                                    {...visibleRows}
                                    rowHeight={58}
                                    getRowId={e => e.id}
                                    headerHeight={39}
                                    pagination={true}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    hideFooterSelectedRowCount
                                    autoHeight={true}
                                    autoPageSize={true}
                                    // onRowClick={onRowClick}
                                />
                            </Box>
                            :
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: currentScreenHeight - 387,
                            }}>
                                <Empty/>
                            </div>
                }

            </div>
        </div>
    );
};

export default TableOfCredits;