import React from 'react';
import s from "../ClientSuccesfulLogin/ClientSuccessfulLogin.module.scss";

const oneclickLogo = require('../../assets/img/oneclick-logo.svg')
const authPageDecoration = require('../../img/authPageDecoration.svg')
const successIntegrationImg = require('../../img/successIntegration.jpg')


const NoCreditsLeft = () => {
    return (
        <div className={s.inner}>
            <oneclickLogo.ReactComponent style={{position: 'absolute', top: '40px', left: '40px'}}/>
            <authPageDecoration.ReactComponent className={s.inner__decoration}/>
            <div className={s.contentBlock}>
                <img src={successIntegrationImg} alt='success'/>
                <div className={s.contentBlock__textWrapper}>
                    <div className={s.contentBlock__separator}>
                    </div>
                    <h1>
                        No <span>credits</span> left available to successfully complete the integration, please contact your manager for details and try again
                    </h1>
                </div>
            </div>
        </div>
    );
};

export default NoCreditsLeft;