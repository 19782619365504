import PageWithSidebar from '../PageWithSidebar/PageWithSidebar'
import s from './Settings.module.scss'
import AgencyInformation from './AgencyInformation'
import AddTeamMembers from './AddTeamMembers'
import React, {useEffect, useRef} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {selectIsTutorial} from "../../store/tutorialReducer";
import TutorialModal from '../TutorialModal/TutorialModal'
import {onClearSearchValue} from "../../store/clientReducer";
import {setUserData} from "../../store/userReducer";
import {tokenDataHelper, userDataHelper} from "../../helpers/localStorageHelper";
import axios from "axios";
import {pickBy} from "lodash";
import {UserDataType} from "../../types/userTypes";

const Settings = () => {
    return (
        <PageWithSidebar>
            <SettingsComponent />
        </PageWithSidebar>
    )
}

const SettingsComponent = () => {
    const isTutorial = useAppSelector(selectIsTutorial)
    const scrollableDivRef = useRef<HTMLDivElement | null>(null);
    // const currentTutorialStep = useAppSelector(selectCurrentTutorialStep)
    const dispatch = useAppDispatch()

    const token = tokenDataHelper.getTokenData()

    useEffect(() => {
        axios.get('/login/refresh', {
            headers: {
                'Authorization': token.token
            }
        })
            .then((response) => {
                userDataHelper.setUserData(pickBy(response.data, (_, key) => key !== 'token') as UserDataType)
            })
    }, [token.token])

    // useEffect(() => {
    //     if (currentTutorialStep === 'Third step - Integrations / TikTok'){
    //         const scrollableDiv = scrollableDivRef.current;
    //         if (scrollableDiv) {
    //             scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
    //         }
    //     }
    //
    // }, [currentTutorialStep])
    useEffect(() => {
        dispatch(setUserData(userDataHelper.getUserData()))
    }, [dispatch])

    useEffect(() => {
        dispatch(onClearSearchValue())
    }, [dispatch])

    return (
        <div className={s.inner} ref={scrollableDivRef}>
            <h2 className={s.title}>
                Settings
            </h2>
            <div className={s.doubleBlockArea}>
                <AgencyInformation />
                <AddTeamMembers />
            </div>
            {
                isTutorial
                &&
                <TutorialModal/>
            }
        </div>
    )
}

export default Settings
