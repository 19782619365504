import { Button, Form, Input, Popconfirm, Select } from 'antd'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useEffect, useState } from 'react'
import { CancelMemberInvitationThunk, ChangeMemberRoleThunk, DeleteMemberThunk, GetAllMembersThunk, GetAvailableRolesThunk, InviteMemberThunk, selectAvailableRoles, selectMemberList } from '../../store/membersReducer'
import s from './Settings.module.scss'
import {
    selectCurrentTutorialStep,
    selectIsTutorial,
    setCurrentTutorialStep,
    setIsTutorialStarted
} from "../../store/tutorialReducer";
import {tutorialDataHelper, userDataHelper} from "../../helpers/localStorageHelper";

const deleteIcon = require('../../img/icons/delete.svg')
const avatarGroup = require('../../img/avatarGroup.svg')

const AddTeamMembers = () => {
    const dispatch = useAppDispatch()
    const members = useAppSelector(selectMemberList)
    const availableRoles = useAppSelector(selectAvailableRoles)
    const userData = userDataHelper.getUserData()
    const [form] = Form.useForm()
    const currentTutorialStep = useAppSelector(selectCurrentTutorialStep)
    const isTutorial = useAppSelector(selectIsTutorial)
    const [isLoading, setIsLoading] = useState(false)
    const isTutorialLocalStorage = tutorialDataHelper.getIsTutorial()
    const tutorialCurrentStepLocalStorage = tutorialDataHelper.getCurrentTutorialStep()
    useEffect(() => {
        dispatch(GetAllMembersThunk())
        dispatch(GetAvailableRolesThunk())
    }, [dispatch])

    const inviteMember = ({email}: {email: string}) => {
        setIsLoading(true)
        dispatch(InviteMemberThunk(email))
            .then(() => {
                setIsLoading(false)
                form.resetFields()
            })
    }

    const changeMemberRole = (userId: number, role: number[]) => {
        dispatch(ChangeMemberRoleThunk({userId, role}))
    }

    const onDelete = (id: number, isRequestedMember: boolean) => {
        setIsLoading(true)
        dispatch(isRequestedMember ? CancelMemberInvitationThunk(id) : DeleteMemberThunk(id))
            .then(() => setIsLoading(false)) 
    }


    useEffect(() => {
        if(isTutorialLocalStorage === true && tutorialCurrentStepLocalStorage === 'Third step - Integrations / Team members') {
            dispatch(setCurrentTutorialStep('Third step - Integrations / Team members'))
            dispatch(setIsTutorialStarted(true))
        }
    },[dispatch, isTutorialLocalStorage, tutorialCurrentStepLocalStorage])

    return (
        <div className={currentTutorialStep === 'Third step - Integrations / Team members' && isTutorial ? `${s.blockWrapper} ${s.blockWrapper__tutorial}` : s.blockWrapper}>
            <avatarGroup.ReactComponent style={{alignSelf: 'center', marginBottom: '20px'}}/>
            <h4>
                Add your team members
            </h4>
            <div style={{color: '#667085', fontSize: '14px', textAlign: 'center', margin: '10px 0px 20px 0px'}}>
                Invite colleagues to collaborate.
            </div>
            <div className={s.blockWrapper__label}>
                Email
            </div>
            <Form
                name='inviteMember'
                onFinish={inviteMember}
                autoComplete='off'
                validateTrigger='onBlur'
                form={form}
            >
                <Form.Item
                    name='email'
                    rules={[
                        { type: 'email', message: 'Invalid email!' },
                        { required: true, message: 'Email is empty!' }
                    ]}
                >
                    <Input
                        placeholder={'Enter new member email'}
                        className={s.blockWrapper__input}
                        suffix={
                            <Form.Item style={{marginBottom: '0px'}}>
                                <Button type='primary' htmlType='submit' loading={isLoading}>
                                    Send Invite
                                </Button>
                            </Form.Item>
                        }
                    />
                </Form.Item>
            </Form>
            <div style={{marginTop: '20px'}}>
                {!!members?.length && members?.map(member => (
                    <div className={s.member} key={member.id}>
                        <div>
                            <div className={s.member__name}>
                                {member?.user_name}
                            </div>
                            <div className={s.member__email}>
                                {member?.email}
                            </div>
                        </div>
                        {member.id === userData.user_id && !!member?.roles?.length &&
                            <div style={{color: '#667085'}}>
                                my account
                            </div>
                        }
                        {(member.id !== userData.user_id || (member.id === userData.user_id && !member?.roles?.length)) && (
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {!member?.roles ? (
                                    <div style={{color: '#667085'}}>pending</div>
                                ) : (
                                    <Select
                                        className={s.blockWrapper__select}
                                        value={member?.roles?.[0]?.id}
                                        onSelect={val => changeMemberRole(member.id, [val])}
                                        style={{width: '100px'}}
                                    >
                                        {availableRoles?.map(role => (
                                            <Select.Option value={role.id} key={role.id}>
                                                {role.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                                <Popconfirm
                                    title={`Are you sure to delete this ${!!member?.roles?.length ? 'member' : 'invitation'}?`}
                                    onConfirm={() => onDelete(member.id, !member?.roles?.length)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <deleteIcon.ReactComponent/>
                                </Popconfirm>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AddTeamMembers
