import { Link } from 'react-router-dom'
import s from './Breadcrumbs.module.scss'
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs'
import routes from '../../routes'

interface BreadcrumbsType  {
    mobileAddedTitle?: string
}

const Breadcrumbs = ({mobileAddedTitle} : BreadcrumbsType) => {
  // @ts-ignore
  const breadcrumbs: {match: any, breadcrumb: any}[] = useReactRouterBreadcrumbs(routes)

  return (
      <div className={s.wrapper}>
          {breadcrumbs.map(({
              match,
              breadcrumb
          }, index) => (
              <span key={match.pathname}>
              {index + 1 < breadcrumbs.length ? (
                  <Link to={match.pathname} className={s.crumb}>
                  {breadcrumb} <span className={s.separator}>/</span>
                  </Link>
              ) : (
                  <span className={s.currentCrumb}>
                  {mobileAddedTitle ? `${mobileAddedTitle}` : breadcrumb}
                  </span>
              )}
              </span>
          ))}
      </div>
  )
}

export default Breadcrumbs
