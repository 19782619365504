import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AsyncThunkConfig, RootState} from "./store";
import {authApi, tikTokIntegration, UpdateTikTokAccount} from "../api/api";
import {AppStatusType} from "./appStatusReducer";
import {setTikTokTableModalOpen} from "./utilityReducer";
import {
    CreateMultiStepLinksThunk, GetMultiStepLinksThunk,
    MultiStepRequestData,
    onSetRequestDataTikTokId
} from "./multiStepReducer";
import {GetAllServicesLinksThunk} from "./linksReducer";



interface TikTokAccount{
    email: string,
    business_center_id: string,
    business_center_name: string,
    business_center_company_name: string
}


interface BusinessCenter {
    bc_id: string | null
    name: string | null
    company: null | string
}

interface TikTokTableLinksConfigurations {
    id: number
    name: string
    description: string
    url: string
    permissions: [
        {
            id: number
            name: string
            description: string
            asset_type: string
        }
    ]
}

interface TikTokTablePermission {
    id: number
    name: string
    description: string
    asset_type: string
}


interface InitialStateType {
    tikTokAccount: {}
    tikTokAccountMe: {}
    tiktokTableLinks: {
        configurations: TikTokTableLinksConfigurations[] | []
    },
    tikTokPermissions: TikTokTablePermission[] | []
    tikTokSelectedConfigurations: number[]
    currentTikTokModalLink: TikTokTableLinksConfigurations | {}
    currentTikTokModalLinkPermissions: number[]
    businessCenters: BusinessCenter[],
    businessCentersAfterEmail: []
}



const initialState: InitialStateType = {
    tikTokAccount: {},
    tikTokAccountMe: {},
    tiktokTableLinks: {
        configurations: []
    },
    tikTokPermissions: [],
    tikTokSelectedConfigurations: [],
    currentTikTokModalLink: {},
    currentTikTokModalLinkPermissions: [],
    businessCenters: [],
    businessCentersAfterEmail: []

}

export const tikTokSlice = createSlice({
    name: 'tikTok',
    initialState,
    reducers: {
        setCurrentTikTokModalLink: (state, action: PayloadAction<number | {}>) => {
            state.currentTikTokModalLink = action.payload
        },
        setCurrentTikTokModalLinkPermissions: (state, action: PayloadAction<any>) => {
            state.currentTikTokModalLinkPermissions = action.payload.permissions.map((l:any) => l.id)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetTikTokTableLinksThunk.fulfilled, (state, action) => {
                state.tiktokTableLinks.configurations = action.payload
            })
            .addCase(GetTikTokTablePermissionsThunk.fulfilled, (state, action) => {
                state.tikTokPermissions = action.payload
            })
            .addCase(GetTikTokAccountThunk.fulfilled, (state, action) => {
                state.tikTokAccount = action.payload
            })
            .addCase(GetTikTokBusinessCentersThunk.fulfilled, (state, action) => {
                state.businessCenters = action.payload
            })
            .addCase(GetTikTokBusinessCentersAfterEmailThunk.fulfilled, (state, action) => {
                state.businessCentersAfterEmail = action.payload
            })

    }
})

export const {
    setCurrentTikTokModalLink,
    setCurrentTikTokModalLinkPermissions
} = tikTokSlice.actions

//
export const selectTikTokAccount = (state: RootState): any => state.tiktok.tikTokAccount
export const selectTikTokTableLinks = (state: RootState): any => state.tiktok.tiktokTableLinks.configurations
export const selectTikTokTablePermissions = (state: RootState): any => state.tiktok.tikTokPermissions
export const selectCurrentTikTokModalLink = (state: RootState): any => state.tiktok.currentTikTokModalLink
export const selectCurrentTikTokModalLinkPermissions = (state: RootState): any => state.tiktok.currentTikTokModalLinkPermissions
export const selectTikTokBusinessCenters = (state: RootState): any => state.tiktok.businessCenters
export const selectTikTokBusinessCentersAfterEmail = (state: RootState): any => state.tiktok.businessCentersAfterEmail
export const GetTikTokAccountThunk = createAsyncThunk<TikTokAccount, void, AsyncThunkConfig>(
    'tikTok/getTikTokAccount',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await tikTokIntegration.getTikTokAccount()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            }
            else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            console.log(error)
            return thunkAPI.fulfillWithValue({}, {appStatus: AppStatusType.idle})
        }
    }
)


export const GetTikTokTableLinksThunk = createAsyncThunk<TikTokTableLinksConfigurations[], void, AsyncThunkConfig>(
    'tikTok/getTikTokTableLinks',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await tikTokIntegration.getTikTokAccountTableLinks()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.configurations, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data.configurations)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetTikTokTablePermissionsThunk = createAsyncThunk<TikTokTablePermission[], void, AsyncThunkConfig>(
    'tikTok/getTikTokTablePermissions',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await tikTokIntegration.getTikTokPermissions()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.tiktok_permissions, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data.tiktok_permissions)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(null)
        }
    }
)

export const CreateTikTokAccountLinkThunk = createAsyncThunk<TikTokTableLinksConfigurations, {request: any, isMultiStep?: boolean , multiStepReqData?: MultiStepRequestData | {}}, AsyncThunkConfig>(
    'tikTok/createTikTokLinkAccount',
    async (request, thunkAPI) => {
        try {
            const {status, data} = await tikTokIntegration.createTikTokTableLink(request.request)
            if (status === 200 && data) {
                if(request.isMultiStep && request.multiStepReqData){
                    const req: any = {
                        ...request.multiStepReqData,
                        tiktok_configuration_id: data.id
                    }
                    console.log(req)
                    thunkAPI.dispatch(CreateMultiStepLinksThunk(req))
                }
                thunkAPI.dispatch(onSetRequestDataTikTokId(data.id))
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(setTikTokTableModalOpen(false))
            thunkAPI.dispatch(GetTikTokTableLinksThunk())
            if(request.isMultiStep){
                thunkAPI.dispatch(GetMultiStepLinksThunk())
            }
        }
    }
)

export const UpdateTikTokAccountLinkThunk = createAsyncThunk<TikTokTableLinksConfigurations, {req: any, config_id: number | null, isMultiStep?: boolean , multiStepReqData?: MultiStepRequestData | {}}, AsyncThunkConfig>(
    'tikTok/updateTikTokLinkAccount',
    async (request, thunkAPI) => {
        try {
            const {status, data} = await tikTokIntegration.updateTikTokTableLink(request.req, request.config_id)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(setTikTokTableModalOpen(false))
            thunkAPI.dispatch(GetTikTokTableLinksThunk())
        }
    }
)

export const UpdateTikTokAccountAssetsThunk = createAsyncThunk<any, UpdateTikTokAccount, AsyncThunkConfig>(
    'tikTok/updateTikTokAccountAssets',
    async (request, thunkAPI) => {
        try {
            const {status, data} = await tikTokIntegration.updateTikTokAccount(request)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Account settings was saved successfully'})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            setTimeout(() => {
                window.location.replace('/')
            }, 1000)
        }
    }
)


export const DeleteTikTokTableLinkThunk = createAsyncThunk<null, number, AsyncThunkConfig>(
    'tikTok/deleteTikTokTableLink',
    async (configId, thunkAPI) => {
        try {
            const {status} = await tikTokIntegration.deleteTikTokTableLink(configId)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(null, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: 'Deleted successfully'
                })
            } else {
                return thunkAPI.rejectWithValue(null)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(GetTikTokTableLinksThunk())
            thunkAPI.dispatch(GetAllServicesLinksThunk())
        }
    }
)

export const GetTikTokTableLinkByIdThunk = createAsyncThunk<null, number, AsyncThunkConfig>(
    'tikTok/getTikTokTableLinkById',
    async (configId, thunkAPI) => {
        try {
            const {status, data} = await tikTokIntegration.getTikTokLinkByConfigId(configId)
            if (status === 200 && data) {
                thunkAPI.dispatch(setCurrentTikTokModalLink(data))
                thunkAPI.dispatch(setCurrentTikTokModalLinkPermissions(data))
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetTikTokBusinessCentersThunk = createAsyncThunk<BusinessCenter[], void, AsyncThunkConfig>(
    'tikTok/getTikTokBusinessCenters',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await tikTokIntegration.getTikTokBusinessCenters()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.business_centers, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data.business_centers)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({})
        }
    }
)


export const GetTikTokBusinessCentersAfterEmailThunk = createAsyncThunk<any, string, AsyncThunkConfig>(
    'tikTok/getTikTokBusinessCentersAfterEmail',
    async (id, thunkAPI) => {
        try {
            const {status, data} = await authApi.tikTokClientAssetsAfterEmailMessage(id)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.business_centers, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data.business_centers)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export default tikTokSlice.reducer
