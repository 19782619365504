import {useEffect, useState} from 'react'
import {Button, Form, Input, Upload} from 'antd'
import {useAppDispatch, useAppSelector} from '../../hooks'
import {EditAgencyThunk, GetAgencyDataThunk, selectAgencyData} from '../../store/agencyReducer'
import {beforeUpload, dummyRequest, getBase64, normFile} from '../../helpers/fileHelper'
import s from './Settings.module.scss'
import {selectCurrentTutorialStep, selectIsTutorial} from "../../store/tutorialReducer";



const uploadIcon = require('../../img/icons/upload.svg')

const AgencyInformation = () => {
    const dispatch = useAppDispatch()
    const agencyData = useAppSelector(selectAgencyData)
    const currentTutorialStep = useAppSelector(selectCurrentTutorialStep)
    const isTutorial = useAppSelector(selectIsTutorial)
    const [form] = Form.useForm()
    const [file, setFile] = useState<any>()
    const [fileX, setFilex] = useState<any>()
    const [updatedLogo, setUpdatedLogo] = useState<any>()
    const [isSavingChanges, setIsSavingChanges] = useState(false)

    useEffect(() => {
        dispatch(GetAgencyDataThunk())
    }, [dispatch])

    useEffect(() => {
        form.setFieldsValue({name: agencyData.name})
    }, [form, agencyData])

    const addPhoto = async (file: any) => {
        const fileBase64 = await getBase64(file.originFileObj) as string
        const fileBase64Formatted = fileBase64.split('base64,')[1]
        setFile(file.originFileObj)
        setFilex(fileBase64Formatted)
        setUpdatedLogo(true)
    }

    const onFinish = (data: any) => {
        setIsSavingChanges(true)
        const formData = new FormData()
        if (!!file) {
            formData.append('logo', file)
        }
        formData.append('agency', new Blob([JSON.stringify({name: data.name})], {type: 'application/json'}))
        dispatch(EditAgencyThunk(formData))
            .then(() => {
                    setIsSavingChanges(false)
                    // dispatch(setCurrentTutorialStep('Third step - Integrations / Google'))
                    // tutorialDataHelper.setCurrentTutorialStep('Third step - Integrations / Google')
                }
            )
        setUpdatedLogo(false)


    }

    return (
        <div
            className={currentTutorialStep === 'Second step - Logo' && isTutorial ? `${s.blockWrapper} ${s.blockWrapper__tutorial}` : s.blockWrapper}>
            <h2 className={s.blockWrapper__agencyInfo}>
                Agency information
            </h2>
            <Form
                name='agencyInformation'
                onFinish={onFinish}
                autoComplete='off'
                validateTrigger='onBlur'
                form={form}
            >
                <div className={s.blockWrapper__label}>
                    Agency business logo:
                </div>
                <Form.Item
                    name='logo'
                    valuePropName='fileList'
                    getValueFromEvent={(e) => normFile(e, addPhoto)}
                >
                    <Upload.Dragger
                        name='files'
                        customRequest={dummyRequest}
                        listType={'picture'}
                        accept={'image/png'}
                        showUploadList={false}
                        className={s.blockWrapper__upload}
                        beforeUpload={beforeUpload}
                    >
                        {agencyData?.logo_url || fileX ? (
                            agencyData && !updatedLogo ? (
                                <img
                                    src={agencyData.logo_url}
                                    alt='logo'
                                    className={s.logoImg}
                                />
                            ) : (
                                <img
                                    src={`data:image/png;base64, ${fileX}`}
                                    alt='logo'
                                    className={s.logoImg}
                                />
                            )
                        ) : (
                            <div className={s.blockWrapper__upload__text}>
                                <uploadIcon.ReactComponent/>
                                <div>
                                    <span style={{fontWeight: 700, marginRight: '5px'}}>
                                        Click to upload
                                    </span>
                                    or drag and drop
                                </div>
                                <span>
                                    JPEG, PNG, JPG
                                </span>
                            </div>
                        )}
                    </Upload.Dragger>
                </Form.Item>

                <div className={s.blockWrapper__label}>
                    Agency name:
                </div>
                <Form.Item
                    name='name'
                    rules={[
                        {required: true, message: 'Enter Agency name!'},
                    ]}
                >
                    <Input
                        placeholder={'Enter Agency name'}
                        className={s.blockWrapper__input}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        htmlType='submit'
                        type='primary'
                        style={{height: '40px', width: '100%', marginTop: '10px'}}
                        loading={isSavingChanges}
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default AgencyInformation
