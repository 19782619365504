import React, {useEffect} from 'react';
import commonS from "../../CommonAppStyles.module.scss";
import Sidebar from "../Sidebar/Sidebar";
import s from "../Home/Home.module.scss";
import IntegrationCards from "../IntegrationCards/IntegrationCards";
import {useAppDispatch} from "../../hooks";
import {onClearSearchValue} from "../../store/clientReducer";
import {setUserData} from "../../store/userReducer";
import {tokenDataHelper, userDataHelper} from "../../helpers/localStorageHelper";
import axios from "axios";
import {pickBy} from "lodash";
import {UserDataType} from "../../types/userTypes";


const Integrations = () => {
    const dispatch = useAppDispatch()
    const token = tokenDataHelper.getTokenData()

    useEffect(() => {
        axios.get('/login/refresh', {
            headers: {
                'Authorization': token.token
            }
        })
            .then((response) => {
                userDataHelper.setUserData(pickBy(response.data, (_, key) => key !== 'token') as UserDataType)
            })
    }, [token.token])



    useEffect(() => {
        dispatch(onClearSearchValue())
    }, [dispatch])
    useEffect(() => {
        dispatch(setUserData(userDataHelper.getUserData()))
    }, [dispatch])




    return (
        <div>
            <main className={`${commonS.main} blurred-page`}>
                <Sidebar/>
                <div className={s.inner}>
                    <IntegrationCards title={'Most Popular'}/>
                </div>
            </main>
        </div>
    );
};

export default Integrations;