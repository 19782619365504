import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import s from "./RequirementsSearchInput.module.scss";
import {InputLabel} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {GetClientsThunk, onChangeSearchValue, onClearSearchValue} from "../../store/clientReducer";
import {ClientsTabType, selectClientsTableCurrentTab, setClientsTableCurrentTab} from "../../store/utilityReducer";

type RequirementSearchInputType = {
    apiRef: any
    setVisibleRowsCount?: (count: number) => void
    setVisibleRows?: (rows: any[]) => void
    allRows: any[]
    isClient?: boolean
    onChangeCurrentTab?: (currentTab: ClientsTabType) => void
    setIsDataLoading?: (isLoading: boolean) => void
}

const RequirementSearchInput = ({
                                    setVisibleRowsCount,
                                    setVisibleRows,
                                    allRows,
                                    isClient,
                                    setIsDataLoading
                                }: RequirementSearchInputType) => {
    const dispatch = useAppDispatch()
    const [searchTerm, setSearchTerm] = useState('');
    const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null)
    const currentTab = useAppSelector(selectClientsTableCurrentTab)

    const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (isClient && setIsDataLoading) {
            setIsDataLoading(true)
            if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
            setSearchTerm(event.target.value)
            dispatch(onChangeSearchValue(event.target.value))
            typingTimeoutRef.current = setTimeout(() => {
                const formData = new FormData();
                formData.append('filter', new Blob([JSON.stringify({
                    search_value: event.target.value,
                    status: currentTab === 'All clients' ? '' : currentTab
                })], { type: 'application/json' }));
                dispatch(GetClientsThunk(formData))
                    .then(() => {
                        dispatch(setClientsTableCurrentTab(currentTab));
                    })
                    .finally(() => {
                        setIsDataLoading(false)
                    })
            }, 2000);
        } else {
            setSearchTerm(event.target.value);
        }
    };


    useEffect(() => {
        return () => {
            if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
        };
    }, []);

    const onClearInput = () => {
        if (isClient && setIsDataLoading) {
            setIsDataLoading(true)
            const formData = new FormData()
            formData.append('filter', new Blob([JSON.stringify({search_value: '',  status: currentTab === 'All clients' ? '' : currentTab})], {type: 'application/json'}))
            dispatch(GetClientsThunk(formData))
                .then(() => {
                    dispatch(setClientsTableCurrentTab(currentTab))
                })
                .then(() => {
                    setIsDataLoading(false)
                })
            setSearchTerm('')
            dispatch(onClearSearchValue())
        } else {
            setSearchTerm('')
        }

    }

    useEffect(() => {
        const rows = !!searchTerm.length
            ? allRows.filter(row => row?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) || row?.description?.toLowerCase().includes(searchTerm?.toLowerCase()) || row?.date?.toLowerCase().includes(searchTerm?.toLowerCase()) || row?.email?.toLowerCase().includes(searchTerm?.toLowerCase()))
            : allRows
        if (setVisibleRows) {
            setVisibleRows(rows)
        }
        if (setVisibleRowsCount) {
            setVisibleRowsCount(rows.length)
        }

        // eslint-disable-next-line
    }, [searchTerm])
    return (
        <div>
            <div className={s.search}>
                <InputLabel htmlFor="searchInput" style={{marginRight: '8px'}}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                         style={{marginTop: '5px'}}>
                        <path
                            d="M17.4999 17.5L13.8749 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                            stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </InputLabel>
                <input
                    className={s.search__input}
                    type="text"
                    value={searchTerm}
                    placeholder={'Search'}
                    onChange={handleSearchInputChange}
                />
                <div>
                    {searchTerm !== ''
                        &&
                        <p
                            onClick={onClearInput}
                            style={{
                                marginBottom: '0',
                                fontSize: '12px',
                                color: '#9CA3AF',
                                cursor: "pointer"
                            }}>x</p>
                    }
                </div>
            </div>
        </div>
    );
};

export default RequirementSearchInput;
