import React from 'react';
import s from "./ClientSuccessfulLogin.module.scss";
import {clientLoginDataHelper} from "../../helpers/localStorageHelper";

const oneclickLogo = require('../../assets/img/oneclick-logo.svg')
const authPageDecoration = require('../../img/authPageDecoration.svg')
const successIntegrationImg = require('../../img/successIntegration.jpg')

const ClientTikTokSuccessfulLogin = () => {
    const redirectUrl = clientLoginDataHelper.getCurrentClientRedirectUrl()
    return (
        <div className={s.inner}>
            <oneclickLogo.ReactComponent style={{position: 'absolute', top: '40px', left: '40px'}}/>
            <authPageDecoration.ReactComponent className={s.inner__decoration}/>
            <div className={s.contentBlock}>
                <img src={successIntegrationImg} alt='success'/>
                <div className={s.contentBlock__textWrapper}>
                    <div className={s.contentBlock__separator}>
                    </div>
                    <h1>
                        Successfully granted access to your Tik Tok <span>account</span> and services!
                    </h1>
                    <button
                        onClick={() => window.location.replace(redirectUrl)}
                        style={{
                            color: '#075CEB',
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '24px',
                            cursor: 'pointer',
                            margin: '20px auto',
                            backgroundColor: 'transparent',
                            border: 'none',
                            outline: 'none',
                            display: 'flex',
                            justifyContent:'center'
                        }}
                    >Back to integrations page
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ClientTikTokSuccessfulLogin;
