import React, {useState} from 'react';
import {Checkbox, Tooltip} from "antd";
import s from './ClientModalService.module.scss'
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {
    onCheckGoogleServiceIds, onSetIsGoogleCheckboxTouched,
    selectCheckedGoogleServiceIds,
    selectClientServices, selectCurrentClientServices, selectIsGoogleCheckboxTouched,
    setClientServices
} from "../../../store/clientReducer";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {
    selectCurrentGoogleModalLinkPermissions, selectGoogleAccount,
    selectGoogleTablePermissions
} from "../../../store/googleReducer";
import {ClientServiceType} from "../../../types/clientsTypes";
import {selectTikTokAccount} from "../../../store/tikTokReducer";

const checkIconLogo = require('../../../img/icons/check_icon.svg')

type ClientModalServiceType = {
    image: string
    serviceName: string
}

const ClientModalService = ({image, serviceName}: ClientModalServiceType) => {
    const dispatch = useAppDispatch()
    const clientServices = useAppSelector(selectClientServices)
    const checkedGoogleServiceIds = useAppSelector(selectCheckedGoogleServiceIds)
    const googleTablePermissions = useAppSelector(selectGoogleTablePermissions)
    const [isAdvancedOptionsOpened, setIsAdvancedOptionsOpened] = useState(false)
    const googleModalPermissions = useAppSelector(selectCurrentGoogleModalLinkPermissions)
    const currentClientServices = useAppSelector(selectCurrentClientServices)
    const currentGoogleService = currentClientServices.find((s: ClientServiceType) => s.service_type === 'GOOGLE')
    const currentTikTokService = currentClientServices.find((s: ClientServiceType) => s.service_type === 'TIKTOK')
    const isGoogleCheckBoxTouched = useAppSelector(selectIsGoogleCheckboxTouched)
    const tikTokAccount = useAppSelector(selectTikTokAccount)
    const googleAccount = useAppSelector(selectGoogleAccount)


    const onChangeCheckboxValue = (value: any) => {
        dispatch(setClientServices({
            service: value.target.name,
            serverGooglePermissions: googleModalPermissions,
            isResend: false
        }))
    }

    const onOpenAdvancedSettings = () => {
        setIsAdvancedOptionsOpened(!isAdvancedOptionsOpened)
    }
    const handleCheck = (event: any) => {
        const name = event.target.name;
        dispatch(onCheckGoogleServiceIds(name));
        dispatch(onSetIsGoogleCheckboxTouched(true))
    };


    return (
        <div className={s.service__item}>
            <div className={s.service__itemHeader}>
                <div className={s.service__desc}>
                    <img src={image} alt="sevice_img" className={s.service__img}/>
                    <p>{serviceName}</p>
                </div>
                {
                    serviceName === 'Google'
                        ?
                        Object.keys(googleAccount).length !== 0
                            ?
                            <Checkbox
                                onChange={(e) => onChangeCheckboxValue(e)}
                                className={'serviceCheckbox'}
                                name={serviceName}
                                // value={p.id}
                                checked={(clientServices.includes(serviceName) && checkedGoogleServiceIds.length > 0 && (Object.keys(googleAccount).length !== 0)) || currentGoogleService?.status === 'INTEGRATED'}
                                // className={s.modal__checkbox}
                                // disabled={!p.is_active}
                                defaultChecked={Object.keys(googleAccount).length !== 0 || currentGoogleService?.status === 'INTEGRATED'}
                                disabled={(currentGoogleService?.status === 'INTEGRATED' && !isGoogleCheckBoxTouched) || (Object.keys(googleAccount).length === 0)}
                            />
                            :
                            <Tooltip title={'Service is not integrated'}>
                                <Checkbox
                                    onChange={(e) => onChangeCheckboxValue(e)}
                                    className={'serviceCheckbox'}
                                    name={serviceName}
                                    // value={p.id}
                                    checked={clientServices.includes(serviceName) && checkedGoogleServiceIds.length > 0 && (Object.keys(googleAccount).length !== 0)}
                                    // className={s.modal__checkbox}
                                    // disabled={!p.is_active}
                                    defaultChecked={Object.keys(googleAccount).length !== 0 || currentTikTokService?.status === 'INTEGRATED'}
                                    disabled={((currentGoogleService?.status === 'INTEGRATED' && !isGoogleCheckBoxTouched) || (Object.keys(googleAccount).length === 0)) || currentTikTokService?.status === 'INTEGRATED'}
                                />
                            </Tooltip>

                        :
                        Object.keys(tikTokAccount).length !== 0
                            ?
                            <Checkbox
                                onChange={(e) => onChangeCheckboxValue(e)}
                                className={'serviceCheckbox'}
                                name={serviceName}
                                // value={p.id}
                                defaultChecked={Object.keys(tikTokAccount).length !== 0}
                                checked={Object.keys(tikTokAccount).length !== 0 && clientServices.includes(serviceName)}
                                // className={s.modal__checkbox}
                                // disabled={!p.is_active}
                                disabled={currentTikTokService?.status === 'INTEGRATED' || (Object.keys(tikTokAccount).length === 0 || tikTokAccount.email === null)}
                            />
                            :
                            <Tooltip title={'Service is not integrated'}>
                                <Checkbox
                                    onChange={(e) => onChangeCheckboxValue(e)}
                                    className={'serviceCheckbox'}
                                    name={serviceName}
                                    // value={p.id}
                                    defaultChecked={Object.keys(tikTokAccount).length !== 0}
                                    checked={Object.keys(tikTokAccount).length !== 0 && clientServices.includes(serviceName)}
                                    // className={s.modal__checkbox}
                                    // disabled={!p.is_active}
                                    disabled={currentTikTokService?.status === 'INTEGRATED' || (Object.keys(tikTokAccount).length === 0 || tikTokAccount.email === null)}
                                />
                            </Tooltip>
                }

            </div>
            <div className={s.service__footer}>
                {
                    serviceName === 'Google'
                        ?
                        <>
                            {
                                isAdvancedOptionsOpened
                                &&
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}>
                                    <div>
                                        {googleTablePermissions.filter((p: any) => p.id !== 4)?.map((p: any) => {
                                            return (
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                                     key={p.id}
                                                >
                                                    <Checkbox
                                                        onChange={handleCheck}
                                                        name={p.id}
                                                        checked={checkedGoogleServiceIds.includes(p.id)}
                                                        className={s.modal__checkbox}
                                                        disabled={googleModalPermissions.includes(p.id) || currentGoogleService?.status === 'INTEGRATED'}
                                                    >
                                                        {p.name}
                                                    </Checkbox>
                                                    <div style={{
                                                        opacity: !p.is_active ? '0.5' : 1,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <Tooltip title={p.description}>
                                                            <QuestionCircleOutlined/>
                                                        </Tooltip>
                                                    </div>

                                                </div>
                                            )
                                        })}
                                    </div>
                                    <button style={{
                                        alignSelf: 'flex-end'
                                    }} onClick={onOpenAdvancedSettings}>Save
                                    </button>
                                </div>
                            }

                            {
                                Object.keys(googleAccount).length !== 0 && !isAdvancedOptionsOpened
                                    ?
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}>
                                        <div style={{
                                            display: 'flex'
                                        }}>
                                            <checkIconLogo.ReactComponent/>
                                            <p>Access to selected functions</p>
                                        </div>
                                        <button onClick={onOpenAdvancedSettings}
                                                disabled={Object.keys(googleAccount).length === 0}>Advanced
                                        </button>
                                    </div>
                                    :
                                    <></>
                            }
                        </>

                        :
                        Object.keys(tikTokAccount).length !== 0
                            ?
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%'
                            }}>
                                <div style={{
                                    display: 'flex'
                                }}>
                                    <checkIconLogo.ReactComponent/>
                                    <p>Access to selected functions</p>
                                </div>
                                <button onClick={onOpenAdvancedSettings}
                                        disabled={(Object.keys(tikTokAccount).length === 0 || tikTokAccount.email === null)}>Advanced
                                </button>
                            </div>
                            :
                            <></>

                }

            </div>
        </div>

    );
};

export default ClientModalService;