import React, {useEffect} from 'react';
import {LoginTikTokSecondStageThunk} from "../../store/userReducer";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {tikTokDataHelper} from "../../helpers/localStorageHelper";
import {
    GetTikTokAccountThunk,
    GetTikTokBusinessCentersThunk,
    selectTikTokBusinessCenters, UpdateTikTokAccountAssetsThunk
} from "../../store/tikTokReducer";
import {Button, Form, Input, Select, Spin} from "antd";
import s from "../Auth/Auth.module.scss";

const oneclickLogo = require('../../assets/img/oneclick-logo.svg')
const authPageDecoration = require('../../img/authPageDecoration.svg')

const TikTokAccountAssets = () => {
    const dispatch = useAppDispatch()
    // eslint-disable-next-line
    let auth_code = location.search.split('auth_code=')[1]
    // eslint-disable-next-line
    let state = location.search.split('state=')[1]
    auth_code = auth_code?.split('&')[0]
    const businessCenters = useAppSelector(selectTikTokBusinessCenters)
    let userType = state?.split('%7Btype%3D')[1]
    userType = (userType?.split('%')[0])
    // const [redirectUserType, setRedirectUserType] = useState<string>(''
    // eslint-disable-next-line
    let redirectUserType = 'agency'


    //
    useEffect(() => {
        if (userType) {
            tikTokDataHelper.setTikTokUserType(userType)
        }
    }, [userType])


    useEffect(() => {
        // eslint-disable-next-line
        redirectUserType = tikTokDataHelper.getTikTokUserType()
    }, [userType])

    useEffect(() => {
        if (auth_code) {
            dispatch(LoginTikTokSecondStageThunk({code: auth_code, userType: userType}))
            tikTokDataHelper.setTikTokCode(auth_code)
        }
    }, [auth_code, userType, dispatch])

    useEffect(() => {
        const tikTokCode = tikTokDataHelper.getTikTokCode()
        // const tikTokUserType = tikTokDataHelper.getTikTokUserType()
        // eslint-disable-next-line
        redirectUserType = tikTokDataHelper.getTikTokUserType()
        if (tikTokCode && !auth_code) {
            dispatch(GetTikTokAccountThunk())
            dispatch(GetTikTokBusinessCentersThunk())
        }
    }, [dispatch, auth_code, userType])


    const onFinish = (values: any) => {
        const center_name = businessCenters.find((b: any) => b.bc_id === values.business_center_id)
        const req = {
            email: values.email,
            business_center_id: values.business_center_id,
            business_center_name: center_name.name,
            business_center_company_name: center_name.company
        }
        dispatch(UpdateTikTokAccountAssetsThunk(req))
    }

    const validateEmail = (rule: any, value: any, callback: any) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value || regex.test(value)) {
            callback();
        } else {
            callback('Please enter a valid email address');
        }
    };


    return (
        tikTokDataHelper.getTikTokUserType() === 'agency'
            ?
            <div className={s.inner}>
                <authPageDecoration.ReactComponent className={s.inner__decoration}/>
                <div className={s.formBlock}>
                    <oneclickLogo.ReactComponent />
                    <h1>
                        Set your Tik Tok account settings:
                    </h1>
                    <Form
                        name='tikTokAssets'
                        onFinish={onFinish}
                        autoComplete='off'
                        // form={form}
                        validateTrigger='onBlur'
                        // initialValues={googleClientData?.resources}
                        className={s.formBlock__form}
                        style={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: "center",
                            flexDirection: 'column'
                        }}
                    >
                        <div>
                            <p className={s.formBlock__label}>Tik Tok account email:</p>
                            <Form.Item
                                name='email'
                                rules={[{validator: validateEmail, required: true}]}
                            >
                                <Input
                                    placeholder={'Enter your TikTok account email please'}
                                    className={s.formBlock__input}
                                />
                            </Form.Item>
                        </div>
                        <div style={{
                            marginBottom: '10px'
                        }}>
                            <p className={s.formBlock__label}>Tik Tok business centers:</p>
                            <Form.Item
                                name={['business_center_id']}
                                rules={[
                                    {required: true, message: 'Select your business center id!'},
                                ]}
                            >
                                <Select
                                    placeholder="Select your business center id"
                                    className={s.formBlock__select}
                                    style={{height: '42px'}}
                                >
                                    {businessCenters?.map((g: any) => (
                                        <Select.Option key={g.bc_id}
                                                       value={g.bc_id}>{`${g.bc_id} - ${(g.name)}`}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <Button
                            type='primary'
                            htmlType='submit'
                            className={s.formBlock__submitGradient}
                        >
                            Save
                        </Button>
                    </Form>
                </div>
            </div>
            :
            <Spin/>
    );
};

export default TikTokAccountAssets;
