import axios from 'axios'
import moment from 'moment'
import {tokenDataHelper, userDataHelper} from './localStorageHelper'
import {pickBy} from "lodash";
import {UserDataType} from "../types/userTypes";

declare global {
  interface Window {
    env: {
      API_DOMAIN_ADDR: string,
    }
  }
}
const target = window.env && window.env.API_DOMAIN_ADDR

axios.interceptors.request.use(async (request:any) => {
  const isAdmin = userDataHelper.getIsAdminUserData()
  if(isAdmin){
    const {token, token_expiry_date} = tokenDataHelper.getAdminTokenData()
    if (token && !request.url?.includes('/login/admin/refresh')) {
      request.headers['Authorization'] = token
      const isTokenExpired = moment().isAfter(token_expiry_date)
      if (isTokenExpired) {
        const response  = await axios.get('/login/admin/refresh', {
          headers: {
            'Authorization': token
          }
        })
        tokenDataHelper.setAdminTokenData({
          token: response.data.token.token,
          token_expiry_date: response.data.token.token_expiry_date
        })
        userDataHelper.setUserData(pickBy(response.data, (_, key) => key !== 'token') as UserDataType)
        window.dispatchEvent(new Event('storage'))
        request.headers['Authorization'] = response.data.token.token
      }
    }
    return request
  }
  else{
    const {token, token_expiry_date} = tokenDataHelper.getTokenData()
    if (token && !request.url?.includes('/login/refresh')) {
      request.headers['Authorization'] = token
      const isTokenExpired = moment().isAfter(token_expiry_date)
      if (isTokenExpired) {
        console.log(token)
        const response  = await axios.get('/login/refresh', {
          headers: {
            'Authorization': token
          }
        })
        tokenDataHelper.setTokenData({
          token: response.data.token.token,
          token_expiry_date: response.data.token.token_expiry_date
        })
        userDataHelper.setUserData(pickBy(response.data, (_, key) => key !== 'token') as UserDataType)
        window.dispatchEvent(new Event('storage'))
        request.headers['Authorization'] = response.data.token.token

      }
    }
    return request
  }
})

axios.defaults.baseURL = target

export default axios
