import React, {useEffect, useState} from 'react';
import {ClientLoginLink} from "../../store/multiStepReducer";
import {userMultiStepHelper} from "../../helpers/localStorageHelper";
import c from "../MultiStepIndicator/MultiStepIndicator.module.scss";
const multiStepLine = require('../../img/icons/multiStepLine.png')
const multiStepDot = require('../../img/icons/multiStepDot.png')
const activeMultiStepDot = require('../../img/icons/activeMultiStepDot.png')
const doneMultiStepDot = require('../../img/icons/multiStepDone.png')
const multiStepLineActive = require('../../img/icons/multiStepLineActive.png')

interface ClientMultiStepIndicatorType {
    services: ClientLoginLink[]
}

const ClientMultiStepIndicator = ({services}: ClientMultiStepIndicatorType) => {
    const currentPlatformIndex = userMultiStepHelper.getUserMultiStepCurrentIndex()
    const isFacebookStep = userMultiStepHelper.getUserMultiStepIsFacebookItem()
    const isGoogleStep = userMultiStepHelper.getUserMultiStepIsGoogleItem()
    const isTikTokStep = userMultiStepHelper.getUserMultiStepIsTikTokItem()

    const [screenWidth, setScreenWidth] = useState<number>(0)

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div
            className={c.inner}
            style={{
                marginBottom: '40px'
            }}
        >
            {services?.map((s, index) => {
                const currentItem = index === currentPlatformIndex
                return (
                    currentItem
                        ?
                        <>
                            {
                                index !== services.length - 1
                                    ?
                                    <div className={c.item}>
                                        <img
                                            className={c.item__lineImg}
                                            src={multiStepLine} alt="line"
                                            style={{
                                                position: 'absolute',
                                                // width: services.length <= 2 ? '356px' : '165px',
                                                width: services.length <= 2 && screenWidth > 500 ? '356px'
                                                    : services.length > 2 && screenWidth > 500 ? '165px'
                                                        : services.length <= 2 && screenWidth <= 450 ? '236px'
                                                            : ' 103px',
                                                left: '44px'
                                            }}
                                        />
                                        <div className={c.item__dot}>
                                            <img src={activeMultiStepDot} alt="active_dot"
                                                 className={c.item__dotImg}/>
                                            <p className={c.item__dot_step}>{index + 1} Step</p>
                                            <p className={c.item__dot_service}>{s?.service_type?.charAt(0) + s?.service_type?.slice(1).toLowerCase()}</p>
                                        </div>
                                    </div>
                                    :
                                    <div className={c.item}>
                                        <div className={c.item__dot}>
                                            <img src={activeMultiStepDot} alt="active_dot"
                                                 className={c.item__dotImg}/>
                                            <p className={c.item__dot_step}>{index + 1} Step</p>
                                            <p className={c.item__dot_service}>{s?.service_type?.charAt(0) + s?.service_type?.slice(1).toLowerCase()}</p>
                                        </div>
                                    </div>
                            }
                        </>
                        :
                        <>
                            {
                                index !== services.length - 1
                                    ?
                                    <div className={c.item}>
                                        <img
                                            className={c.item__lineImg}
                                            src={
                                                isFacebookStep
                                                    ?
                                                    multiStepLineActive
                                                    :
                                                    isGoogleStep
                                                        ?
                                                        multiStepLineActive
                                                        :
                                                        isTikTokStep
                                                            ?
                                                            multiStepLineActive
                                                            :
                                                            multiStepLine
                                            }
                                            alt="line"
                                            style={{
                                                position: 'absolute',
                                                width: services.length <= 2 && screenWidth > 500 ? '356px'
                                                    : services.length > 2 && screenWidth > 500 ? '165px'
                                                        : services.length <= 2 && screenWidth <= 450 ? '236px'
                                                            : ' 103px',
                                                left: '44px'
                                            }}
                                        />
                                        <div className={c.item__dot}>
                                            {
                                                isFacebookStep
                                                    ?
                                                    <>
                                                        <img src={doneMultiStepDot} alt="dot"
                                                             className={c.item__dotImg}/>
                                                        <p className={c.item__dot_step}>{index + 1} Step</p>
                                                        <p className={c.item__dot_service}>{s?.service_type?.charAt(0) + s?.service_type?.slice(1).toLowerCase()}</p>
                                                    </>
                                                    :
                                                    isGoogleStep
                                                        ?
                                                        <>
                                                            <img src={doneMultiStepDot} alt="dot"
                                                                 className={c.item__dotImg}/>
                                                            <p className={c.item__dot_step}>{index + 1} Step</p>
                                                            <p className={c.item__dot_service}>{s?.service_type?.charAt(0) + s?.service_type?.slice(1).toLowerCase()}</p>
                                                        </>
                                                        :
                                                        isTikTokStep
                                                            ?
                                                            <>
                                                                <img src={doneMultiStepDot} alt="dot"
                                                                     className={c.item__dotImg}/>
                                                                <p className={c.item__dot_step}>{index + 1} Step</p>
                                                                <p className={c.item__dot_service}>{s?.service_type?.charAt(0) + s?.service_type?.slice(1).toLowerCase()}</p>
                                                            </>
                                                            :
                                                            <>
                                                                <img src={multiStepDot} alt="dot"
                                                                     className={c.item__dotImg}/>
                                                                <p className={c.item__dot_step}>{index + 1} Step</p>
                                                                <p className={c.item__dot_service}>{s?.service_type?.charAt(0) + s?.service_type?.slice(1).toLowerCase()}</p>
                                                            </>
                                            }

                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className={c.item__dot}>
                                            <img src={multiStepDot} alt="dot" className={c.item__dotImg}/>
                                            <p className={c.item__dot_step}>{index + 1} Step</p>
                                            <p className={c.item__dot_service}>{s?.service_type?.charAt(0) + s?.service_type?.slice(1).toLowerCase()}</p>
                                        </div>
                                    </div>

                            }
                        </>

                )
            })}
        </div>
    );
};

export default ClientMultiStepIndicator;