import s from './ClientSuccessfulLogin.module.scss'

const oneclickLogo = require('../../assets/img/oneclick-logo.svg')
const authPageDecoration = require('../../img/authPageDecoration.svg')
const successIntegrationImg = require('../../img/successIntegration.jpg')

const ClientSuccessfulLogin = () => {
    return (
        <div className={s.inner}>
            <oneclickLogo.ReactComponent style={{position: 'absolute', top: '40px', left: '40px'}}/>
            <authPageDecoration.ReactComponent className={s.inner__decoration}/>
            <div className={s.contentBlock}>
                <img src={successIntegrationImg} alt='success'/>
                <div className={s.contentBlock__textWrapper}>
                    <div className={s.contentBlock__separator}>
                    </div>
                    <h1>
                        Successfully granted access to your Facebook <span>account</span> and services!
                    </h1>
                    <div className={s.contentBlock__description}>
                        If you have questions, contact your manager for details.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientSuccessfulLogin;
