import './App.less'
import {useAppDispatch, useAppSelector} from "./hooks";
import {AppStatusType, selectAppStatus, selectErrorMessage, selectSuccessMessage} from "./store/appStatusReducer";
import {useEffect} from "react";
import {Navigate, Outlet, Route, Routes} from "react-router";
import routes from "./routes";
import {message} from "antd";
import {LicenseInfo} from "@mui/x-license-pro";
import {setCurrentScreenHeight, setCurrentScreenWidth} from "./store/utilityReducer";
import {tutorialDataHelper} from "./helpers/localStorageHelper";
import {selectIsLoggedIn} from "./store/userReducer";

function App() {
    LicenseInfo.setLicenseKey('9d97c43dbb954ce971f2fbc4846f34b9Tz04MTkxMSxFPTE3MzY0OTYzNjUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
    const appStatus = useAppSelector(selectAppStatus)
    const error = useAppSelector(selectErrorMessage)
    const successMessage = useAppSelector(selectSuccessMessage)
    const dispatch = useAppDispatch()


    // const isTutorialLocalStorage = tutorialDataHelper.getIsTutorial()
    // const tutorialCurrentStepLocalStorage = tutorialDataHelper.getCurrentTutorialStep()
    // const navigate = useNavigate()
    // const [isTooltipShown, setIsTooltipShown] = useState(false)
    // const currentPath = window.location.pathname
    useEffect(() => {
        appStatus === AppStatusType.failed && error?.length && message.error(error, 5)
        appStatus === AppStatusType.succeeded && message.success(successMessage, 5)
    }, [appStatus, successMessage, error])


    // useEffect(() => {
    //     if (!isTutorialLocalStorage && tutorialCurrentStepLocalStorage !== 'Finished') {
    //         dispatch(setCurrentTutorialStep('Pre tutorial'))
    //         tutorialDataHelper.setCurrentTutorialStep('Pre tutorial')
    //         dispatch(setIsTutorialStarted(true))
    //         tutorialDataHelper.setIsTutorial(true)
    //     }
    // }, [dispatch, isTutorialLocalStorage, tutorialCurrentStepLocalStorage])


    useEffect(() => {
        const handleResize = () => {
            dispatch(setCurrentScreenWidth(window.innerWidth))
            dispatch(setCurrentScreenHeight(window.innerHeight))
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]);


    useEffect(() => {
        tutorialDataHelper.setIsTutorial(false)
    }, [])



    // useLayoutEffect(() => {
    //     const handleResize = debounce(function () {
    //         setHeight(window.innerHeight);
    //         const heightForPage = window.innerHeight;
    //         setGridContentHeight(heightForPage - 340);
    //         dispatch(onSetReqGridHeight(heightForPage))
    //     }, 100);
    //
    //     window.addEventListener("resize", handleResize);
    //     return () => window.removeEventListener("resize", handleResize);
    // }, []);
    // const onStartTutorial = () => {
    //     dispatch(setCurrentTutorialStep('Pre tutorial'))
    //     tutorialDataHelper.setCurrentTutorialStep('Pre tutorial')
    //     dispatch(setIsTutorialStarted(true))
    //     tutorialDataHelper.setIsTutorial(true)
    //     navigate('/')
    //     dispatch(setTutorialRole(userDataHelper?.getUserData()?.roles[0]?.name))
    // }


    return (

        <div className='App' style={{
            backgroundColor: '#FCFCFD',
            height: '100%',
            minHeight: '100vh',
        }}>
            <Routes>
                {routes.map((route) => (
                    route.auth ? (
                        <Route key={route.path} path={route.path} element={<PrivateRoute/>}>
                            <Route path={route.path} element={<route.component {...route.props}/>}/>
                        </Route>
                    ) : (
                        <Route key={route.path} element={<route.component {...route.props}/>} path={route.path}/>
                    )
                ))}
            </Routes>
            {/*{*/}
            {/*    currentPath === '/'*/}
            {/*    ||*/}
            {/*    currentPath === '/facebookLinksTable'*/}
            {/*    ||*/}
            {/*    currentPath === '/googleLinksTable'*/}
            {/*    ||*/}
            {/*    currentPath === '/tiktokLinksTable'*/}
            {/*    ||*/}
            {/*    currentPath === '/allLinksTable'*/}
            {/*    ||*/}
            {/*    currentPath === '/commonLinksTable'*/}
            {/*    ||*/}
            {/*    currentPath === '/settings'*/}
            {/*    ||*/}
            {/*    currentPath === '/activity'*/}
            {/*    ||*/}
            {/*    currentPath === '/activity/activityCurrentLink'*/}
            {/*    ||*/}
            {/*    currentPath === '/activityMulti'*/}
            {/*    ||*/}
            {/*    currentPath === '/activity/activityCurrentLinkMulti'*/}
            {/*    ||*/}
            {/*    currentPath === '/activity/activityCurrentLink'*/}
            {/*    ||*/}
            {/*    currentPath === '/activityMulti'*/}
            {/*        ?*/}
            {/*        <div*/}
            {/*            onClick={onStartTutorial}*/}
            {/*            className={'tutorialButton'}*/}
            {/*            onMouseEnter={() => setIsTooltipShown(true)}*/}
            {/*            onMouseLeave={() => setIsTooltipShown(false)}*/}
            {/*        >*/}
            {/*            {*/}
            {/*                isTooltipShown*/}
            {/*                &&*/}
            {/*                <div className={'tutorialTooltip'}>Tutorial</div>*/}
            {/*            }*/}
            {/*            <tutorialButtonLogo.ReactComponent/>*/}
            {/*        </div>*/}
            {/*        :*/}
            {/*        null*/}
            {/*}*/}

        </div>


    )
}

const PrivateRoute = () => {
    const isLoggedIn = useAppSelector(selectIsLoggedIn)


    return isLoggedIn ? (
        <div>
            <div className='appContent'>
                <Outlet/>
            </div>
        </div>
    ) : (
        <Navigate to='/sign-in'/>
    )
}

export default App;
