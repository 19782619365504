import React, {useEffect, useState} from 'react';
import PageWithSidebar from '../PageWithSidebar/PageWithSidebar';
import s from './../FacebookLinksTable/FacebookLinksTable.module.scss'
import {useAppDispatch, useAppSelector} from '../../hooks';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import PageTitle from '../PageTitle/PageTitle';
import {DataGridPro, useGridApiRef} from "@mui/x-data-grid-pro";
import RequirementSearchInput from '../RequirementSearchInput/RequirementSearchInput';
import {Box} from "@material-ui/core";
import {Popconfirm} from 'antd';
import {
    DeletePermissionConfigurationConfigThunk,
    GetPermissionConfigurationConfigByIdThunk, GetPermissionConfigurationsThunk,
    setAddAccountConfigurations,
    setCatalogAccountConfigurations,
    setInstagramAccountConfigurations,
    setPageAccountConfigurations,
    setPixelAccountConfigurations
} from '../../store/permissionConfigurationReducer';
import {
    GetBusinessPermissionThunk,
    GetUserBusinessesThunk,
    selectPermissionCatalog
} from '../../store/businessAssetReducer';
import {
    DeleteTikTokTableLinkThunk,
    GetTikTokTableLinkByIdThunk,
    GetTikTokTablePermissionsThunk
} from "../../store/tikTokReducer";
import {
    selectCurrentScreenHeight,
    selectIsSendLinkModalOpened,
    setFacebookModalOpen,
    setGoogleTableModalOpen,
    setIsSendLinkModalOpened, setSendLinkModalCurrentLink,
    setTikTokTableModalOpen
} from '../../store/utilityReducer';
import {FacebookModal} from '../FacebookLinksTable/FacebookLinksTable';
import {TikTokModal} from '../TikTokLinksTable/TikTokLinksTable';
import {
    DeleteGoogleTableLinkThunk,
    GetGoogleTableLinkByIdThunk,
    GetGoogleTablePermissionsThunk
} from '../../store/googleReducer';
import {GoogleModal} from '../GoogleLinksTable/GoogleLinksTable';
import {
    GetAllServicesLinksThunk,
    selectAllServicesLinks,
    selectMultiStageServices,
    setMultiStageServices
} from '../../store/linksReducer';
import {
    DeleteMultiStepTableLinkThunk,
    GetMultiStepLinkByIdThunk, onSetCurrentPlatformIndex,
    selectCurrentPlatformIndex,
    selectMultiStepCurrentLinkData
} from "../../store/multiStepReducer";
import {selectUserData} from "../../store/userReducer";
import {userDataHelper} from "../../helpers/localStorageHelper";
import {useNavigate} from "react-router";
import SendLinkModal from "../SendLinkModal/SendLinkModal";


const deleteIcon = require('../../img/icons/delete.svg')
const editIcon = require('../../img/icons/edit.svg')
const facebookIcon = require('../../img/icons/facebook-logo-small.svg')
const googleIcon = require('../../img/icons/google-logo-small.svg')
const tiktokIcon = require('../../img/icons/tiktok-logo-small.svg')

const AllLinksTable = () => {
    return (
        <PageWithSidebar>
            <AllLinksTableComponent/>
        </PageWithSidebar>
    )
}

const AllLinksTableComponent = () => {
    const dispatch = useAppDispatch()
    const [editMode, setEditMode] = useState(false)
    const [visibleRowsCount, setVisibleRowsCount] = useState(0)
    const [visibleRows, setVisibleRows] = useState<any[]>([])
    let multiStageServices = useAppSelector(selectMultiStageServices)
    const tableLinks = useAppSelector(selectAllServicesLinks)
    const businessPermissions = useAppSelector(selectPermissionCatalog)
    const currentMultiStepLink = useAppSelector(selectMultiStepCurrentLinkData)
    const currentPlatformIndex = useAppSelector(selectCurrentPlatformIndex)
    const platformOrder = ['Facebook', 'Google', 'TikTok'];
    const userData = useAppSelector(selectUserData)
    const [isColumnVisible, setIsColumnVisible] = useState(true);
    const isSendLinkModalOpened = useAppSelector(selectIsSendLinkModalOpened)
    const currentScreenHeight = useAppSelector(selectCurrentScreenHeight)
    useEffect(() => {
        dispatch(GetAllServicesLinksThunk())
    }, [dispatch])
    const [screenWidth, setScreenWidth] = useState<number>(0)
    const navigate = useNavigate()
    useEffect(() => {
        const handleResize = () => {
            setIsColumnVisible(window.innerWidth > 1160); // Adjust the breakpoint as needed
            setScreenWidth(window.innerWidth)
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // get data for facebook modal form
    useEffect(() => {
        dispatch(setAddAccountConfigurations(businessPermissions.AD_ACCOUNT))
        dispatch(setCatalogAccountConfigurations(businessPermissions.CATALOG))
        dispatch(setInstagramAccountConfigurations(businessPermissions.INSTAGRAM))
        dispatch(setPageAccountConfigurations(businessPermissions.PAGE))
        dispatch(setPixelAccountConfigurations(businessPermissions.PIXEL))
        // eslint-disable-next-line
    }, [dispatch, businessPermissions.AD_ACCOUNT, businessPermissions.CATALOG, businessPermissions.INSTAGRAM, businessPermissions.PAGE, businessPermissions.PIXEL])

    useEffect(() => {
        dispatch(GetBusinessPermissionThunk())
        dispatch(GetPermissionConfigurationsThunk())
        dispatch(GetUserBusinessesThunk(userData.user_id))
    }, [dispatch, userData.user_id])

    useEffect(() => {
        dispatch(GetGoogleTablePermissionsThunk())
        dispatch(GetTikTokTablePermissionsThunk())
    }, [dispatch])

    useEffect(() => {
        setVisibleRowsCount(tableLinks.length)
        setVisibleRows(tableLinks)
    }, [tableLinks])


    const onEditFacebookLink = (config_id: number) => {
        dispatch(GetPermissionConfigurationConfigByIdThunk(config_id))
        dispatch(setFacebookModalOpen(true))
        setEditMode(true)
    }

    const onEditTikTokLink = (configId: number) => {
        dispatch(GetTikTokTableLinkByIdThunk(configId))
        dispatch(setTikTokTableModalOpen(true))
        setEditMode(true)
    }


    const onEditMultiStepLink = (configId: number) => {
        dispatch(GetMultiStepLinkByIdThunk(configId))
    }

    const onEditGoogleLink = (configId: number) => {
        dispatch(GetGoogleTableLinkByIdThunk(configId))
        dispatch(setGoogleTableModalOpen(true))
        setEditMode(true)
    }

    const onDeleteFacebookLink = (config_id: number) => {
        dispatch(DeletePermissionConfigurationConfigThunk(config_id))
    }

    const onDeleteGoogleLink = (configId: number) => {
        dispatch(DeleteGoogleTableLinkThunk(configId))
    }

    const onDeleteTikTokLink = (configId: number) => {
        dispatch(DeleteTikTokTableLinkThunk(configId))
    }

    const nextPlatform = () => {
        if (currentPlatformIndex < multiStageServices.length - 1) {
            dispatch(onSetCurrentPlatformIndex(currentPlatformIndex + 1))
        }
    };

    const previousPlatform = () => {
        if (currentPlatformIndex > 0) {
            dispatch(onSetCurrentPlatformIndex(currentPlatformIndex - 1))
        }
    };

    const handleButtonClick = () => {
        if (currentPlatformIndex < multiStageServices.length - 1) {
            nextPlatform();
        } else {
            previousPlatform();
        }
    };


    useEffect(() => {
        if (currentMultiStepLink !== null) {
            dispatch(setMultiStageServices(
                [
                    currentMultiStepLink.facebook_configuration && Object.keys(currentMultiStepLink.facebook_configuration).length ? 'Facebook' : '',
                    currentMultiStepLink.google_configuration && Object.keys(currentMultiStepLink.google_configuration).length ? 'Google' : '',
                    currentMultiStepLink.tiktok_configuration && Object.keys(currentMultiStepLink.tiktok_configuration).length ? 'TikTok' : ''
                ].filter((l: string) => l !== '')
            ))
        }
    }, [currentMultiStepLink, dispatch])


    useEffect(() => {
        // eslint-disable-next-line
        multiStageServices = [...multiStageServices].sort((a, b) => platformOrder.indexOf(a) - platformOrder.indexOf(b))
        dispatch(onSetCurrentPlatformIndex(0))
    }, [dispatch, multiStageServices]);
    const currentPlatform = multiStageServices[currentPlatformIndex];


    useEffect(() => {
        dispatch(setFacebookModalOpen(currentPlatform === 'Facebook'))
        dispatch(setGoogleTableModalOpen(currentPlatform === 'Google'))
        dispatch(setTikTokTableModalOpen(currentPlatform === 'TikTok'))
    }, [currentPlatformIndex, multiStageServices, currentPlatform, dispatch]);


    const onOpenSendModalLink = (linkData: any) => {
        dispatch(setIsSendLinkModalOpened(true))
        dispatch(setSendLinkModalCurrentLink({...linkData, platform: linkData.service_type}))
    }


    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: screenWidth > 768 ? 1 : 0.6,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
        },
        {
            field: 'service_type',
            headerName: 'Service',
            flex: 0.45,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            hide: !isColumnVisible,
            renderCell: (data: any) => {
                if (data.row.service_type.toLowerCase() === 'facebook') {
                    return <facebookIcon.ReactComponent style={{marginLeft: '10px'}}/>
                } else if (data.row.service_type.toLowerCase() === 'google') {
                    return <googleIcon.ReactComponent style={{marginLeft: '10px'}}/>
                } else if (data.row.service_type.toLowerCase() === 'tiktok') {
                    return <tiktokIcon.ReactComponent style={{marginLeft: '10px'}}/>
                } else {
                    return (
                        <div className={s.servicesIcons}>
                            <div className={s.servicesIcons__icon}>
                                <facebookIcon.ReactComponent/>
                            </div>
                            <div className={s.servicesIcons__icon} style={{marginLeft: '-5px'}}>
                                <googleIcon.ReactComponent/>
                            </div>
                            <div className={s.servicesIcons__icon} style={{marginLeft: '-5px'}}>
                                <tiktokIcon.ReactComponent/>
                            </div>
                        </div>
                    )
                }
            }
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: screenWidth > 1160 ? 1 : 1,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
        },
        // {
        //     field: 'url',
        //     headerName: 'Link URL',
        //     flex: screenWidth > 1160 ? 2 : 1,
        //     fontFamily: 'Inter, sans-serif',
        //     fontWeight: 600,
        //     color: '#6B7280',
        //     fontSize: '12px',
        //     lineHeight: '20px',
        //     renderCell: (data: any) => (
        //         <div style={{color: '#667085', marginLeft: '10px'}}>
        //             <span style={{display: screenWidth < 768 ? 'none' : 'block'}}>{data.row.url}</span>
        //             <span style={{display: screenWidth >= 768 ? 'none' : 'block'}}>Link</span>
        //         </div>
        //     )
        // },
        {
            field: '',
            headerName: '',
            flex: 0.5,
            renderCell: (data: any) => {
                return (
                    <button
                        onClick={() => onOpenSendModalLink(data.row)}
                        className={s.sendButton}
                        style={{
                            color: '#075CEB',
                            marginLeft: '10px',
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: '20px',
                            backgroundColor: 'transparent',
                            border: 'none',
                            outline: 'none',
                            cursor: 'pointer'
                        }}>
                        Send
                    </button>
                )
            }
        },
        {
            field: 'Action',
            headerName: '',
            flex: 0.6,
            disableExport: true,
            hide: screenWidth < 768,
            renderCell: (data: any) => {
                return (
                    <div>
                        {/*<copyIcon.ReactComponent*/}
                        {/*    style={*/}
                        {/*        {*/}
                        {/*            marginRight: '15px',*/}
                        {/*            cursor: 'pointer',*/}
                        {/*            fontSize: '18px'*/}
                        {/*        }*/}
                        {/*    }*/}
                        {/*    color="action"*/}
                        {/*    onClick={() => {*/}
                        {/*        onCopyLink(data.row.url)*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <Popconfirm
                            title="Are you sure to delete this link?"
                            onConfirm={() => {
                                if (data.row.service_type.toLowerCase() === 'facebook') {
                                    onDeleteFacebookLink(data.row.id)
                                } else if (data.row.service_type.toLowerCase() === 'google') {
                                    onDeleteGoogleLink(data.row.id)
                                } else if (data.row.service_type.toLowerCase() === 'tiktok') {
                                    onDeleteTikTokLink(data.row.id)
                                } else {
                                    dispatch(DeleteMultiStepTableLinkThunk(data.row.id))
                                }
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <deleteIcon.ReactComponent
                                style={
                                    {
                                        marginRight: '15px',
                                        cursor: 'pointer',
                                        fontSize: '18px'
                                    }
                                }
                                color="action"
                            />
                        </Popconfirm>
                        <editIcon.ReactComponent
                            color="action"
                            style={
                                {
                                    marginRight: '15px',
                                    cursor: 'pointer',
                                    fontSize: '18px'
                                }
                            }
                            onClick={() => {
                                if (data.row.service_type.toLowerCase() === 'facebook') {
                                    onEditFacebookLink(data.row.id)
                                } else if (data.row.service_type.toLowerCase() === 'google') {
                                    onEditGoogleLink(data.row.id)
                                } else if (data.row.service_type.toLowerCase() === 'tiktok') {
                                    onEditTikTokLink(data.row.id)
                                } else {
                                    onEditMultiStepLink(data.row.id)
                                }
                            }}
                        />
                    </div>
                )
            }
        }
    ]
    const apiRef = useGridApiRef();


    const onMobileRowClick = (data: any) => {
        if (screenWidth < 768) {
            if (data.row.service_type.toLowerCase() === 'facebook') {
                dispatch(GetPermissionConfigurationConfigByIdThunk(data.row.id))
                userDataHelper.setMobileLinkServiceType('Facebook')
                navigate('/mobileLink')
            } else if (data.row.service_type.toLowerCase() === 'google') {
                dispatch(GetGoogleTableLinkByIdThunk(data.row.id))
                userDataHelper.setMobileLinkServiceType('Google')
                navigate('/mobileLink')
            } else if (data.row.service_type.toLowerCase() === 'tiktok') {
                dispatch(GetTikTokTableLinkByIdThunk(data.row.id))
                userDataHelper.setMobileLinkServiceType('TikTok')
                navigate('/mobileLink')
            } else {
                dispatch(GetMultiStepLinkByIdThunk(data.row.id))
                userDataHelper.setMobileLinkServiceType('All')
                navigate('/mobileLink')
            }
        }
    }

    return (
        <div style={{
            padding: screenWidth > 1160 ? '32px' : screenWidth > 480 ? '10px 20px' : '10px 8px'
        }}>
            <Breadcrumbs/>
            <div className={s.inner__header}>
                <PageTitle
                    title='All links table'
                    description='Create links for your customers'
                />
            </div>
            <RequirementSearchInput
                apiRef={apiRef}
                setVisibleRowsCount={(count: number) => setVisibleRowsCount(count)}
                setVisibleRows={(rows: any[]) => setVisibleRows(rows)}
                allRows={tableLinks}
            />
            <div className={s.tableInner}>
                <h2>
                    All links <span>{visibleRowsCount} links</span>
                </h2>
                <Box>
                    <DataGridPro
                        sx={{
                            borderRight: 'none',
                            borderLeft: 'none',
                            borderBottom: 'none',
                            borderRadius: '0px',
                            borderTop: '1px solid transparent',
                            "& .MuiDataGrid-columnHeader": {
                                background: '#F9FAFB',
                                padding: '0px 20px',
                                minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                            },
                            "& .MuiDataGrid-columnHeader:last-child": {
                                background: '#F9FAFB',
                                padding: '0px 20px',
                                // minWidth: "540px !important"
                            },
                            "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                display: "none"
                            },
                            "& .MuiDataGrid-cell": {
                                minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                            },
                            "& .MuiDataGrid-cell:last-child": {
                                // minWidth: "540px !important"
                            },
                            "& .MuiDataGrid-cellContent": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#111827',
                                padding: '0px 10px',
                                // minWidth: '540px'
                            },
                            "& .MuiDataGrid-cellContent:last-child": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#111827',
                                padding: '0px 10px',
                                // minWidth: '540px'
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 600,
                                color: '#6B7280',
                                fontSize: '12px',
                                lineHeight: '20px',
                            },
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-row.Mui-selected": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-row.Mui-selected:hover": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-cell:focus": {
                                outline: 'none'
                            },
                            "& .MuiDataGrid-toolbarContainer": {
                                color: '#454545',
                                position: 'absolute',
                                top: '-62px',
                                right: '0px'
                            },
                            "& .MuiButtonBase-root": {
                                fontFamily: 'Inter, sans-serif !important',
                                fontWeight: 600,
                                color: '#6B7280',
                                fontSize: '12px',
                                lineHeight: '20px',
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginBottom: 0
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                overflowY: 'scroll !important',
                                overflowX: 'scroll !important',
                                height: `${currentScreenHeight - 420}px !important`,
                            },
                        }}
                        apiRef={apiRef}
                        getRowId={e => e.service_type + e.id}
                        columns={columns}
                        rows={visibleRows}
                        {...visibleRows}
                        rowHeight={58}
                        headerHeight={39}
                        pagination={true}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        hideFooterSelectedRowCount
                        autoHeight={true}
                        onRowClick={onMobileRowClick}
                    />
                </Box>
            </div>
            {
                currentMultiStepLink
                    ?
                    <>
                        <FacebookModal
                            isMultiStep={true}
                            onStepChange={handleButtonClick}
                            editMode={true}
                        />
                        <GoogleModal
                            isMultiStep={true}
                            onStepChange={handleButtonClick}
                            editMode={true}
                        />
                        <TikTokModal
                            isMultiStep={true}
                            onStepChange={handleButtonClick}
                            editMode={true}
                        />
                    </>
                    :
                    <>
                        <FacebookModal
                            editMode={editMode}
                            setEditMode={(mode: boolean) => setEditMode(mode)}
                        />
                        <TikTokModal
                            editMode={editMode}
                            setEditMode={(mode: boolean) => setEditMode(mode)}
                        />
                        <GoogleModal
                            editMode={editMode}
                            setEditMode={(mode: boolean) => setEditMode(mode)}
                        />
                    </>
            }
            {
                isSendLinkModalOpened
                &&
                <SendLinkModal/>
            }
        </div>
    );
};

export default AllLinksTable

