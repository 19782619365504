import React, {useEffect, useState} from 'react';
import s from './Sidebar.module.scss'
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
    LoginGoogleSecondStageThunk,
    selectGoogleAuthUrl,
    selectTikTokAuthUrl,
    SignOutThunk
} from "../../store/userReducer";
import {
    GetGoogleAccountMeThunk,
    GetGoogleAccountThunk,
} from "../../store/googleReducer";
import {Divider} from 'antd';
import {
    googleDataHelper, tutorialDataHelper, userDataHelper,
} from "../../helpers/localStorageHelper";
import {
    GetTikTokAccountThunk,
} from "../../store/tikTokReducer";
import {Link, useLocation} from 'react-router-dom';
import {
    selectCurrentTutorialStep,
    selectIsTutorial,
    setCurrentTutorialStep,
} from "../../store/tutorialReducer";
import {GetAgencyDataThunk, selectAgencyData} from "../../store/agencyReducer";
import ClientSelect from "../Client/ClientsSelect/ClientSelect";
import FeedbackModalForm from "../FeedbackModalForm/FeedbackModalForm";
import {setFeedbackModalOpen} from "../../store/utilityReducer";



const oneclickLogo = require('../../assets/img/oneclick-logo.svg')
const sidebarMenuLogo = require('../../img/icons/chevron-down.svg')
const settingsLogo = require('../../img/icons/settings.svg')
const logoutIcon = require('../../img/icons/logout.svg')
const avatar = require('../../img/avatar.svg')
const burgerMenu = require('../../img/icons/hamburger_menu.png')
const avatarPng = require('../../img/icons/avatar.png')
const usersLogo = require('../../img/icons/users.svg')
const feedbackLogo = require('../../img/icons/feedbackSidebar.svg')

const Sidebar = () => {
    const dispatch = useAppDispatch()
    const {pathname} = useLocation()
    const userData = userDataHelper.getUserData()
    const googleAuthUrl = useAppSelector(selectGoogleAuthUrl)
    const tikTokAuthUrl = useAppSelector(selectTikTokAuthUrl)
    const [mobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false)
    const currentTutorialStep = useAppSelector(selectCurrentTutorialStep)
    const isTutorial = useAppSelector(selectIsTutorial)
    const currentPath = window.location.pathname
    const agencyData = useAppSelector(selectAgencyData)
    const [openSettingsMenuItem, setOpenSettingsMenuItem] = useState(false)

    // eslint-disable-next-line
    let code = location.search.split('code=')[1]
    code = code?.split('&')[0]

    useEffect(() => {
        if (googleAuthUrl !== '') {
            window.location.href = googleAuthUrl
        }
    }, [googleAuthUrl])

    useEffect(() => {
        if (code) {
            dispatch(LoginGoogleSecondStageThunk(code))
            googleDataHelper.setGoogleCode(code)
        }
    }, [code, dispatch])

    useEffect(() => {
        if (tikTokAuthUrl !== '') {
            window.location.href = tikTokAuthUrl
        }
    }, [tikTokAuthUrl])

    useEffect(() => {
        const googleCode = googleDataHelper.getGoogleCode()
        if (googleCode && !code) {
            dispatch(GetGoogleAccountThunk())
            dispatch(GetGoogleAccountMeThunk())
        }
    }, [dispatch, code])

    useEffect(() => {
        dispatch(GetGoogleAccountThunk())
    }, [dispatch])

    useEffect(() => {
        dispatch(GetTikTokAccountThunk())
    }, [dispatch])


    const [activeMenuItem, setActiveMenuItem] = useState(pathname === '/settings' ? '/settings' : '/')

    useEffect(() => {
        setActiveMenuItem(pathname)
    }, [pathname])

    const menuItems = [
        ...(userData?.roles?.some(r => r.name.toLowerCase() === 'admin') ? [
            {icon: usersLogo.ReactComponent, name: 'clients', link: '/'},
            {icon: settingsLogo.ReactComponent, name: 'settings', link: '/settings'},
        ] : [{icon: usersLogo.ReactComponent, name: 'clients', link: '/'}]),
    ]



    const onNextTutorialStep = () => {
        dispatch(setCurrentTutorialStep('Second step - Logo'))
        tutorialDataHelper.setCurrentTutorialStep('Second step - Logo')
    }

    useEffect(() => {
        dispatch(GetAgencyDataThunk())
    }, [dispatch])





    return (
        <div className={s.inner}>
            <Link to='/'>
                <img src={oneclickLogo.default} alt='logo' className={s.logo}/>
            </Link>
            {/*desktop menu*/}

            <div className={s.divider}>
                <Divider/>
            </div>

            <nav className={s.inner__navDesktop}>
                {
                    currentPath === '/clientDetails'
                    &&
                    <ClientSelect/>
                }
                <ul className={s.navigation}>
                    {menuItems.map(menuItem => {
                            if (menuItem.name === 'settings' && currentTutorialStep === 'First step - Agency Setup' && isTutorial) {
                                return (
                                    <li
                                        className={
                                            activeMenuItem === menuItem.link
                                                ? s.menuItem + ' ' + s.active
                                                : menuItem.name === 'settings' && currentTutorialStep === 'First step - Agency Setup' && isTutorial
                                                    ? s.menuItem + ' ' + s.active + ' ' + s.menuItem__tutorial
                                                    :
                                                    s.menuItem}
                                        key={menuItem.name}
                                        onClick={onNextTutorialStep}
                                    >
                                        <div>
                                            <menuItem.icon/>
                                            <div style={{marginLeft: '12px'}}>
                                                {menuItem.name}
                                            </div>

                                        </div>
                                    </li>


                                )
                            } else if (menuItem.name === 'activity' && currentTutorialStep === 'First step - Agency Setup' && isTutorial) {
                                return (
                                    <li
                                        className={activeMenuItem === menuItem.link ? s.menuItem + ' ' + s.active : s.menuItem}
                                        key={menuItem.name}
                                    >
                                        <Link to={menuItem.link}>
                                            <menuItem.icon/>
                                            <div style={{marginLeft: '12px'}}>
                                                {menuItem.name}
                                            </div>
                                        </Link>
                                    </li>


                                )
                            } else if (menuItem.name === 'settings') {
                                return (
                                    <>
                                        <li
                                            className={activeMenuItem === menuItem.link || (currentPath === '/settings' || currentPath === '/integrations') ? s.menuItem + ' ' + s.active_settings : s.menuItem}
                                            key={menuItem.name}
                                            onClick={() => setOpenSettingsMenuItem(!openSettingsMenuItem)}
                                        >
                                        <span>
                                            <menuItem.icon/>
                                            <div style={{marginLeft: '12px'}}>
                                                {menuItem.name}
                                            </div>
                                            <p>
                                                <sidebarMenuLogo.ReactComponent style={{
                                                    transform: openSettingsMenuItem ? 'rotate(180deg)' : 'initial',
                                                    position: 'absolute',
                                                    top: '11px',
                                                    right: '50px'
                                                }}/>
                                            </p>
                                        </span>
                                        </li>
                                        <div>
                                            {
                                                openSettingsMenuItem
                                                &&
                                                <>
                                                    <li
                                                        className={activeMenuItem === '/settings' ? s.menuItem + ' ' + s.active : s.menuItem}
                                                    >
                                                        <Link to={'/settings'}>
                                                            <div style={{width: '100%', marginLeft: '37px'}}>
                                                                Agency details
                                                            </div>
                                                        </Link>
                                                    </li>
                                                    <li
                                                        className={activeMenuItem === '/integrations' ? s.menuItem + ' ' + s.active : s.menuItem}
                                                    >
                                                        <Link to={'/integrations'}>
                                                            <div style={{width: '100%', marginLeft: '37px'}}>
                                                                Integrations
                                                            </div>
                                                        </Link>
                                                    </li>
                                                </>
                                            }
                                        </div>
                                    </>

                                )
                            } else if (menuItem.name !== 'settings') {
                                return (
                                    <li
                                        className={activeMenuItem === menuItem.link || (currentPath === '/activity/activityCurrentLink' && menuItem.name === 'activity') ? s.menuItem + ' ' + s.active : s.menuItem}
                                        key={menuItem.name}
                                    >
                                        <Link to={menuItem.link}>
                                            <menuItem.icon/>
                                            <div style={{marginLeft: '12px'}}>
                                                {menuItem.name}
                                            </div>
                                        </Link>
                                    </li>
                                )
                            } else {
                                return <></>
                            }
                        }
                    )}
                </ul>
                <div style={{marginTop: 'auto'}} className={s.inner__menuDesktop}>
                    {
                        userData?.roles?.some(r => r.name.toLowerCase() === 'admin')
                        &&
                        <div
                            // className={agencyData.credits > 3 ? s.credits : s.credits_buyNew}
                        >
                            <div className={s.credits__inner} style={{
                                pointerEvents: userData?.roles?.some(r => r.name.toLowerCase() === 'admin') ? 'initial' : 'none'
                            }}>
                                <Link to={'/tableOfCredits'}
                                      style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          width: '100%'
                                      }}
                                      className={activeMenuItem === '/tableOfCredits' ? s.menuItem + ' ' + s.active : s.menuItem}
                                >
                                    <div className={s.credits__content}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path id="Vector"
                                                  d="M11 14.5C11 15.88 13.239 17 16 17C18.761 17 21 15.88 21 14.5M3 9.5C3 10.88 5.239 12 8 12C9.126 12 10.165 11.814 11 11.5M3 13C3 14.38 5.239 15.5 8 15.5C9.126 15.5 10.164 15.314 11 15M16 13C13.239 13 11 11.88 11 10.5C11 9.12 13.239 8 16 8C18.761 8 21 9.12 21 10.5C21 11.88 18.761 13 16 13Z"
                                                  stroke={agencyData.credits > 3 ? "#667085" : '#075CEB'}
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path id="Vector_2"
                                                  d="M3 5.5V16.5C3 17.88 5.239 19 8 19C9.126 19 10.164 18.814 11 18.5M11 18.5V10.5M11 18.5C11 19.88 13.239 21 16 21C18.761 21 21 19.88 21 18.5V10.5M13 8.5V5.5"
                                                  stroke={agencyData.credits > 3 ? "#667085" : '#075CEB'}
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path id="Vector_3"
                                                  d="M8 8C5.239 8 3 6.88 3 5.5C3 4.12 5.239 3 8 3C10.761 3 13 4.12 13 5.5C13 6.88 10.761 8 8 8Z"
                                                  stroke={agencyData.credits > 3 ? "#667085" : '#075CEB'}
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                            />
                                        </svg>
                                        <p
                                            style={{
                                                color: "#344054",
                                                marginBottom: 0,
                                                marginLeft: '12px'
                                            }}
                                        >Credits</p>
                                    </div>
                                    <p className={s.credits__badge}>{agencyData.credits}</p>
                                </Link>
                            </div>
                        </div>
                    }
                    <div className={s.withoutLinkItem} onClick={() => dispatch(setFeedbackModalOpen(true))}>
                        <feedbackLogo.ReactComponent/>
                        <p>Feedback</p>
                    </div>
                    {
                        agencyData.credits < 4 && userData?.roles?.some(r => r.name.toLowerCase() === 'admin')
                        &&
                        <div className={s.buyMore}>
                            <p>Used Credits</p>
                            <p>You have less than 3 credits left on your account. Need more?</p>
                            <div>
                                <Link to={'/tableOfCredits'}>
                                    <div style={{width: '100%'}} className={s.buyMore__link}>
                                        View details
                                    </div>
                                </Link>
                                <Link to={'/tableOfCredits'} className={s.buyMore__link}>
                                    <div style={{width: '100%'}}>
                                        Buy more
                                    </div>
                                </Link>
                            </div>
                        </div>
                    }
                    <Divider/>
                    <div className={s.userAvatar}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <avatar.ReactComponent style={{width: '40px', height: '40px', marginRight: '10px'}}/>
                            <p>{userData.user_name}</p>
                        </div>
                        <logoutIcon.ReactComponent onClick={() => dispatch(SignOutThunk())}/>
                    </div>
                </div>
            </nav>

            {/*desktop menu*/}

            {/*mobile menu*/}
            <div className={s.mobile}>
                <div onClick={() => setMobileMenuOpened(!mobileMenuOpened)} style={{
                    marginRight: '15px',
                    cursor: 'pointer'
                }}>
                    {
                        mobileMenuOpened
                        ?
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L6 18M6 6L18 18" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            :
                            <img src={burgerMenu} alt="burgerMenu"/>
                    }

                </div>
                {
                    mobileMenuOpened
                    &&
                    <div className={s.mobile__menu}>
                        <div style={{
                            marginTop: '24px'
                        }}>
                            <div className={s.mobile__menu_item}>
                                <Link to={'/clients'}>
                                    <usersLogo.ReactComponent/>
                                    <p>Clients</p>
                                </Link>
                            </div>
                            <div className={s.mobile__menu_item}>
                                {
                                    userData?.roles?.some(r => r.name.toLowerCase() === 'admin')
                                    &&
                                    <>
                                        <li
                                            style={{
                                                position: "relative",
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                            // className={activeMenuItem === menuItem.link || (currentPath === '/settings' || currentPath === '/integrations') ? s.menuItem + ' ' + s.active_settings : s.menuItem}
                                            // key={menuItem.name}
                                            onClick={() => setOpenSettingsMenuItem(!openSettingsMenuItem)}
                                        >

                                            <settingsLogo.ReactComponent/>
                                            <p style={{marginLeft: '12px'}}>
                                                Settings
                                            </p>
                                            <p>
                                                <sidebarMenuLogo.ReactComponent style={{
                                                    transform: openSettingsMenuItem ? 'rotate(180deg)' : 'initial',
                                                    position: 'absolute',
                                                    top: '11px',
                                                    right: '30px',
                                                    cursor: 'pointer'
                                                }}/>
                                            </p>
                                        </li>
                                        <div className={s.mobile__settings}>
                                            {
                                                openSettingsMenuItem
                                                &&
                                                <>
                                                    <li
                                                        className={activeMenuItem === '/settings' ? s.menuItem + ' ' + s.active : s.menuItem}
                                                    >
                                                        <Link to={'/settings'}>
                                                            <div style={{width: '100%', marginLeft: '37px'}}>
                                                                Agency details
                                                            </div>
                                                        </Link>
                                                    </li>
                                                    <li
                                                        className={activeMenuItem === '/integrations' ? s.menuItem + ' ' + s.active : s.menuItem}
                                                    >
                                                        <Link to={'/integrations'}>
                                                            <div style={{width: '100%', marginLeft: '37px'}}>
                                                                Integrations
                                                            </div>
                                                        </Link>
                                                    </li>
                                                </>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                            <div className={s.mobile__menu_item}>
                                {
                                    userData?.roles?.some(r => r.name.toLowerCase() === 'admin')
                                    &&
                                    <Link to={'/tableOfCredits'}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path id="Vector"
                                                  d="M11 14.5C11 15.88 13.239 17 16 17C18.761 17 21 15.88 21 14.5M3 9.5C3 10.88 5.239 12 8 12C9.126 12 10.165 11.814 11 11.5M3 13C3 14.38 5.239 15.5 8 15.5C9.126 15.5 10.164 15.314 11 15M16 13C13.239 13 11 11.88 11 10.5C11 9.12 13.239 8 16 8C18.761 8 21 9.12 21 10.5C21 11.88 18.761 13 16 13Z"
                                                  stroke={agencyData.credits > 3 ? "#667085" : '#075CEB'}
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path id="Vector_2"
                                                  d="M3 5.5V16.5C3 17.88 5.239 19 8 19C9.126 19 10.164 18.814 11 18.5M11 18.5V10.5M11 18.5C11 19.88 13.239 21 16 21C18.761 21 21 19.88 21 18.5V10.5M13 8.5V5.5"
                                                  stroke={agencyData.credits > 3 ? "#667085" : '#075CEB'}
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path id="Vector_3"
                                                  d="M8 8C5.239 8 3 6.88 3 5.5C3 4.12 5.239 3 8 3C10.761 3 13 4.12 13 5.5C13 6.88 10.761 8 8 8Z"
                                                  stroke={agencyData.credits > 3 ? "#667085" : '#075CEB'}
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                            />
                                        </svg>
                                        <p>Credits</p>
                                        <span className={s.credits__badge} style={{
                                            position: "absolute",
                                            right: '30px',
                                            marginRight: '0'
                                        }}>{agencyData.credits}</span>
                                    </Link>
                                }
                            </div>
                            <div className={s.mobile__menu_item} onClick={() => dispatch(setFeedbackModalOpen(true))}>
                                <li>
                                    <feedbackLogo.ReactComponent/>
                                    <p>Feedback</p>
                                </li>

                            </div>
                            <div
                                className={`${s.mobile__menu_item} ${s.mobile__menu_itemFooter}`}
                                onClick={() => dispatch(SignOutThunk())}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                    className={s.mobile__menu_avatar}
                                >
                                    <img src={avatarPng} alt="avatar"/>
                                    <p>{userData.user_name}</p>
                                </div>

                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg" style={{
                                    position: "absolute",
                                    right: '30px',
                                    marginRight: '0',
                                    cursor: 'pointer'
                                }}>
                                    <path
                                        d="M6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H6M10.6667 11.3333L14 8M14 8L10.6667 4.66667M14 8H6"
                                        stroke="#344054" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </div>

                    </div>
                }
            </div>
            <FeedbackModalForm/>
        </div>
    );
}

export default Sidebar;
