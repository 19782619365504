import { Upload, message } from "antd"

export const normFile = (e: any, addPhoto: (file: any) => Promise<void>) => {
    addPhoto(e.file)
    if (Array.isArray(e)) {
        return e
    }
    return e?.fileList
}

export const dummyRequest = ({onSuccess}: any) => {
    setTimeout(() => {
        onSuccess('ok')
    }, 0)
}

export const beforeUpload = (file: any) => {
    const validFormats = ['jpg', 'jpeg', 'png']
    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (!validFormats.includes(fileExtension)) {
        message.error('Invalid file format. Please upload a valid file. Valid file formats are png/jpg/jpeg');
        return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) message.error('File must be less than 2 mb')
    return isLt2M ? true : Upload.LIST_IGNORE
}

export const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })
}
