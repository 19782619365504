import React from 'react';
import s from './TutorialStepButtons.module.scss'
import {useAppSelector} from "../../hooks";
import {selectCurrentTutorialStep} from "../../store/tutorialReducer";


const TutorialStepButtons = () => {
    const currentTutorialStep = useAppSelector(selectCurrentTutorialStep)
    return (
        <div className={s.tutorialDots}>
            <div
                className={currentTutorialStep === 'First step - Agency Setup' ? `${s.tutorialDot} ${s.tutorialDot_current}` : s.tutorialDot}></div>
            <div
                className={currentTutorialStep === 'Second step - Logo' ? `${s.tutorialDot} ${s.tutorialDot_current}` : s.tutorialDot}></div>
            <div
                className={currentTutorialStep === 'Third step - Integrations / Facebook' || currentTutorialStep === 'Third step - Integrations / Google' || currentTutorialStep === 'Third step - Integrations / TikTok' || currentTutorialStep === 'Third step - Integrations / Team members' ? `${s.tutorialDot} ${s.tutorialDot_current}` : s.tutorialDot}></div>
            <div
                className={currentTutorialStep === 'Fourth step - Link creation / Home'
                || currentTutorialStep ===  'Fourth step - Link creation / Table'
                || currentTutorialStep ===  'Fourth step - Link creation / Modal'
                || currentTutorialStep ===  'Fourth step - Link creation / Send link' ? `${s.tutorialDot} ${s.tutorialDot_current}` : s.tutorialDot}></div>
        </div>
    );
};

export default TutorialStepButtons;