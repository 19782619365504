import React from 'react';

type TooltipProps = {
    text: string;
    children: React.ReactNode;
    classname?: string
};

const Tooltip = ({ text, children, classname }: TooltipProps) => {
    return (
        <div style={{
            display: 'block'
        }} className={`${classname} tooltip` || "tooltip"}>
            {children}
            <span className={"tooltiptext"}>{text}</span>
        </div>
    );
};

export default Tooltip;