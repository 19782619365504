import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
    selectCurrentScreenHeight,
    selectIsMultiStepLinkFinished,
    selectIsSendLinkModalOpened,
    selectIsTikTokTableModalOpen,
    setBeforeAddingLinksModalOpen,
    setIsMultiStepLinkFinished,
    setIsSendLinkModalOpened,
    setMobileIsMultiLinkOpened, setSendLinkModalCurrentLink,
    setTikTokTableModalOpen
} from "../../store/utilityReducer";
import {Form, Input, Popconfirm, Tooltip} from "antd";
import {DataGridPro, useGridApiRef} from "@mui/x-data-grid-pro";
import s from "../FacebookLinksTable/FacebookLinksTable.module.scss";
import PageTitle from "../PageTitle/PageTitle";
import RequirementSearchInput from "../RequirementSearchInput/RequirementSearchInput";
import {Box} from "@material-ui/core";
import CommonModal from "../CommonModal/CommonModal";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {
    CreateTikTokAccountLinkThunk,
    DeleteTikTokTableLinkThunk,
    GetTikTokTableLinkByIdThunk,
    GetTikTokTableLinksThunk,
    GetTikTokTablePermissionsThunk,
    selectCurrentTikTokModalLink,
    selectTikTokAccount,
    selectTikTokTableLinks,
    selectTikTokTablePermissions,
    setCurrentTikTokModalLink,
    setCurrentTikTokModalLinkPermissions,
    UpdateTikTokAccountLinkThunk
} from "../../store/tikTokReducer";
import {CreateGoogleAndTikTokLink} from "../../api/api";
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import PageWithSidebar from '../PageWithSidebar/PageWithSidebar';
import {Navigate, useNavigate} from 'react-router';
import MultiStepIndicator from "../MultiStepIndicator/MultiStepIndicator";
import {selectMultiStageServices, setMultiStageServices} from "../../store/linksReducer";
import {
    onSetCurrentMultiStepLink, onSetCurrentPlatformIndex,
    selectCurrentPlatformIndex,
    selectMultiStepCurrentLinkData,
    selectMultiStepLinksReqData, setFacebookStepData, setGoogleStepData,
    setTikTokStepData
} from "../../store/multiStepReducer";
import {
    setCurrentGoogleModalLinkPermissions,
} from "../../store/googleReducer";
import {
    resetFacebookModalConfigurations,
    setAddAccountConfigurations,
    setCatalogAccountConfigurations,
    setInstagramAccountConfigurations,
    setPageAccountConfigurations,
    setPixelAccountConfigurations,
    setSelectedConfigurations, switchAllConfigurationsCheckboxes
} from "../../store/permissionConfigurationReducer";
import {selectPermissionCatalog} from "../../store/businessAssetReducer";
import {userDataHelper, userMultiStepHelper} from "../../helpers/localStorageHelper";
import SendLinkModal from "../SendLinkModal/SendLinkModal";
import moment from "moment/moment";


const deleteIcon = require('../../img/icons/delete.svg')
const editIcon = require('../../img/icons/edit.svg')
const closeIcon = require('../../img/icons/close.svg')
const tiktokIcon = require('../../img/icons/tiktok-logo-small.svg')

const TikTokLinksTable = () => {
    return (
        <PageWithSidebar>
            <TikTokLinksTableComponent/>
        </PageWithSidebar>
    )
}

const TikTokLinksTableComponent = () => {
    const tikTokAccount = useAppSelector(selectTikTokAccount)
    const [editMode, setEditMode] = useState(false)
    const [visibleRowsCount, setVisibleRowsCount] = useState(0)
    const [visibleRows, setVisibleRows] = useState<any[]>([])
    const tableLinks = useAppSelector(selectTikTokTableLinks)
    const dispatch = useAppDispatch()
    const isSendLinkModalOpened = useAppSelector(selectIsSendLinkModalOpened)
    const [isColumnVisible, setIsColumnVisible] = useState(true);
    const [screenWidth, setScreenWidth] = useState<number>(0)
    const navigate = useNavigate()
    const currentScreenHeight = useAppSelector(selectCurrentScreenHeight)
    useEffect(() => {
        const handleResize = () => {
            setIsColumnVisible(window.innerWidth > 1160); // Adjust the breakpoint as needed
            setScreenWidth(window.innerWidth)
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        setVisibleRowsCount(tableLinks.length)
        setVisibleRows(tableLinks)
    }, [tableLinks])

    useEffect(() => {
        dispatch(GetTikTokTableLinksThunk())
        dispatch(GetTikTokTablePermissionsThunk())
    }, [dispatch])

    const onDeleteTikTokLink = (configId: number) => {
        dispatch(DeleteTikTokTableLinkThunk(configId))
    }

    const onGetTikTokLinkConfigById = (configId: number) => {
        dispatch(GetTikTokTableLinkByIdThunk(configId))
        dispatch(setTikTokTableModalOpen(true))
        setEditMode(true)
    }


    const onOpenSendModalLink = (linkData: any) => {
        dispatch(setIsSendLinkModalOpened(true))
        dispatch(setSendLinkModalCurrentLink({...linkData, platform: 'TikTok'}))
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
        },
        {
            field: 'serviceType',
            headerName: 'Service',
            flex: 0.45,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            renderCell: () => <tiktokIcon.ReactComponent style={{marginLeft: '10px'}}/>,
            hide: !isColumnVisible,
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 0.8,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
        },
        // {
        //     field: 'url',
        //     headerName: 'Link URL',
        //     flex: screenWidth > 1400 ? 2 : 1,
        //     fontFamily: 'Inter, sans-serif',
        //     fontWeight: 600,
        //     color: '#6B7280',
        //     fontSize: '12px',
        //     lineHeight: '20px',
        //     renderCell: (data: any) => (
        //         <div style={{color: '#667085', marginLeft: '10px'}}>
        //             <span style={{display: screenWidth < 768 ? 'none' : 'block'}}>{data.row.url}</span>
        //             <span style={{display: screenWidth >= 768 ? 'none' : 'block'}}>Link</span>
        //         </div>
        //     )
        // },
        {
            field: 'date_created',
            headerName: 'Date Created',
            flex: screenWidth > 768 ? 1 : 0.5,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            renderCell: (data: any) => (
                <div style={{color: '#667085', marginLeft: '10px'}}>
                    {
                        moment(data.row.date_created, "DD-MM-YYYY HH:mm:ss").format('DD MMM YYYY (hh:mm A)')
                    }
                </div>
            )
        },
        {
            field: '',
            headerName: '',
            flex: 0.5,
            renderCell: (data: any) => {
                return (
                    <button
                        onClick={() => onOpenSendModalLink(data.row)}
                        className={s.sendButton}
                        style={{
                            color: '#075CEB',
                            marginLeft: '10px',
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: '20px',
                            backgroundColor: 'transparent',
                            border: 'none',
                            outline: 'none',
                            cursor: 'pointer'
                        }}>
                        Send
                    </button>
                )
            }
        },
        {
            field: 'Action',
            headerName: '',
            flex: 0.6,
            disableExport: true,
            hide: screenWidth < 768,
            renderCell: (data: any) => {
                return (
                    <div>
                        {/*<copyIcon.ReactComponent*/}
                        {/*    style={*/}
                        {/*        {*/}
                        {/*            marginRight: '15px',*/}
                        {/*            cursor: 'pointer',*/}
                        {/*            fontSize: '18px'*/}
                        {/*        }*/}
                        {/*    }*/}
                        {/*    color="action"*/}
                        {/*    onClick={() => {*/}
                        {/*        onCopyTikTokLink(data.row.url)*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <Popconfirm
                            title="Are you sure to delete this link?"
                            onConfirm={() => onDeleteTikTokLink(data.row.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <deleteIcon.ReactComponent
                                style={
                                    {
                                        marginRight: '15px',
                                        cursor: 'pointer',
                                        fontSize: '18px'
                                    }
                                }
                                color="action"
                            />
                        </Popconfirm>
                        <editIcon.ReactComponent
                            color="action"
                            style={
                                {
                                    marginRight: '15px',
                                    cursor: 'pointer',
                                    fontSize: '18px'
                                }
                            }
                            onClick={() => {
                                onGetTikTokLinkConfigById(data.row.id)
                            }}
                        />
                    </div>

                )
            }
        }
    ]
    const apiRef = useGridApiRef();

    if (Object.keys(tikTokAccount).length === 0 || tikTokAccount.email === null) {
        return <Navigate to='/'/>
    }

    const onMobileRowClick = (value: any) => {
        if (screenWidth < 768) {
            dispatch(GetTikTokTableLinkByIdThunk(value.row.id))
            userDataHelper.setMobileLinkServiceType('TikTok')
            navigate('/mobileLink')
        }
    }


    return (
        <div style={{
            padding: screenWidth > 1160 ? '32px' : screenWidth > 480 ? '10px 20px' : '10px 8px'
        }}>
            <Breadcrumbs/>
            <div className={s.inner__header}>
                <PageTitle
                    title='TikTok table'
                    description='Create links for your customers'
                    onAddLink={() => dispatch(setTikTokTableModalOpen(true))}
                />
            </div>
            <RequirementSearchInput
                apiRef={apiRef}
                setVisibleRowsCount={(count: number) => setVisibleRowsCount(count)}
                setVisibleRows={(rows: any[]) => setVisibleRows(rows)}
                allRows={tableLinks}
            />
            <div className={s.tableInner}>
                <h2>
                    All links <span>{visibleRowsCount} links</span>
                </h2>
                <Box>
                    <DataGridPro
                        sx={{
                            borderRight: 'none',
                            borderLeft: 'none',
                            borderBottom: 'none',
                            borderRadius: '0px',
                            borderTop: '1px solid transparent',
                            "& .MuiDataGrid-columnHeader": {
                                background: '#F9FAFB',
                                padding: '0px 20px',
                                minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                            },
                            "& .MuiDataGrid-columnHeader:last-child": {
                                background: '#F9FAFB',
                                padding: '0px 20px',
                                // minWidth: "540px !important"
                            },
                            "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                display: "none"
                            },
                            "& .MuiDataGrid-cell": {
                                minWidth: screenWidth < 650 ? "201px !important" : 'auto',
                                outline: 'none'
                            },
                            "& .MuiDataGrid-cell:last-child": {
                                // minWidth: "540px !important"
                            },
                            "& .MuiDataGrid-cellContent": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#111827',
                                padding: '0px 10px',
                                // minWidth: '540px'
                            },
                            "& .MuiDataGrid-cellContent:last-child": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#111827',
                                padding: '0px 10px',
                                // minWidth: '540px'
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 600,
                                color: '#6B7280',
                                fontSize: '12px',
                                lineHeight: '20px',
                            },
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-row.Mui-selected": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-row.Mui-selected:hover": {
                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                            },
                            "& .MuiDataGrid-cell:focus": {
                                outline: 'none'
                            },
                            "& .MuiDataGrid-toolbarContainer": {
                                color: '#454545',
                                position: 'absolute',
                                top: '-62px',
                                right: '0px'
                            },
                            "& .MuiButtonBase-root": {
                                fontFamily: 'Inter, sans-serif !important',
                                fontWeight: 600,
                                color: '#6B7280',
                                fontSize: '12px',
                                lineHeight: '20px',
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginBottom: 0
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                overflowY: 'scroll !important',
                                overflowX: 'scroll !important',
                                height: `${currentScreenHeight - 420}px !important`,
                            },
                        }}
                        apiRef={apiRef}
                        columns={columns}
                        rows={visibleRows}
                        {...visibleRows}
                        rowHeight={58}
                        headerHeight={39}
                        pagination={true}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        hideFooterSelectedRowCount
                        autoHeight={true}
                        onRowClick={onMobileRowClick}
                    />
                </Box>
            </div>
            <TikTokModal editMode={editMode} setEditMode={(mode: boolean) => setEditMode(mode)}/>
            {
                isSendLinkModalOpened
                &&
                <SendLinkModal/>
            }
        </div>
    );
};

export const TikTokModal: React.FC<{ editMode?: boolean, setEditMode?: (editMode: boolean) => void, isMultiStep?: boolean, onStepChange?: () => void, isMobileLink?: boolean }> = ({
                                                                                                                                                                                       editMode,
                                                                                                                                                                                       setEditMode,
                                                                                                                                                                                       isMultiStep,
                                                                                                                                                                                       onStepChange,
                                                                                                                                                                                       isMobileLink
                                                                                                                                                                                   }) => {

    const dispatch = useAppDispatch()
    const isModalOpen = useAppSelector(selectIsTikTokTableModalOpen)
    const tablePermissions = useAppSelector(selectTikTokTablePermissions)
    const currentTikTokLink = useAppSelector(selectCurrentTikTokModalLink)
    const currentPlatformIndex = useAppSelector(selectCurrentPlatformIndex)
    const [checked, setChecked] = useState<any>([1, 2, 3, 4]);
    const [form] = Form.useForm()
    const multiStageServices = useAppSelector(selectMultiStageServices)
    const multiStepReqData = useAppSelector(selectMultiStepLinksReqData)
    const currentMultiStepLink = useAppSelector(selectMultiStepCurrentLinkData)
    const businessPermissions = useAppSelector(selectPermissionCatalog)
    const currentServiceType = userDataHelper.getMobileLinkServiceType()
    const navigate = useNavigate()
    const isMultiStepLinkFinished = useAppSelector(selectIsMultiStepLinkFinished)

    useEffect(() => {
        if (isMultiStep && currentMultiStepLink) {
            form.setFieldsValue(currentMultiStepLink && currentMultiStepLink.tiktok_configuration)
        } else if (editMode) {
            form.setFieldsValue(currentTikTokLink)
        } else if (isMultiStep && !editMode && !isMultiStepLinkFinished) {
            form.setFieldsValue({name: ''})
            form.setFieldsValue({business: ''})
            form.setFieldsValue({description: ''})
        } else {
            form.setFieldsValue({name: ''})
            form.setFieldsValue({business: ''})
            form.setFieldsValue({description: ''})
            userMultiStepHelper.setIsUserMultiStepLogin(false)
        }
    }, [form, currentTikTokLink, currentMultiStepLink, isMultiStep, editMode, isMultiStepLinkFinished])
    useEffect(() => {
        setChecked([1, 2, 3, 4])
    }, [])


    const onCloseModal = () => {
        if (!isMobileLink) {
            if (!isMultiStep) {
                // form.setFieldsValue({name: ''})
                form.setFieldsValue({description: ''})
                if (setEditMode) {
                    setEditMode(false)
                }
                setChecked([])
                dispatch(setTikTokTableModalOpen(false))
                dispatch(setAddAccountConfigurations(businessPermissions.AD_ACCOUNT))
                dispatch(setCatalogAccountConfigurations(businessPermissions.CATALOG))
                dispatch(setInstagramAccountConfigurations(businessPermissions.INSTAGRAM))
                dispatch(setPageAccountConfigurations(businessPermissions.PAGE))
                dispatch(setPixelAccountConfigurations(businessPermissions.PIXEL))
                dispatch(setSelectedConfigurations({isEdit: false, emptyData: []}))
                dispatch(setCurrentTikTokModalLink({}))
                dispatch(setCurrentTikTokModalLinkPermissions({permissions: []}))
                dispatch(setCurrentGoogleModalLinkPermissions({permissions: []}))
                dispatch(setMultiStageServices([]))
                dispatch(setBeforeAddingLinksModalOpen(false))
                dispatch(onSetCurrentMultiStepLink(null))
                dispatch(onSetCurrentPlatformIndex(0))
            } else {
                form.resetFields();
                dispatch(setIsMultiStepLinkFinished(false))
                dispatch(resetFacebookModalConfigurations())
                dispatch(setMultiStageServices([]))
                dispatch(setCurrentGoogleModalLinkPermissions({permissions: []}))
                dispatch(setCurrentTikTokModalLinkPermissions({permissions: []}))
                dispatch(onSetCurrentMultiStepLink(null))
                dispatch(setTikTokTableModalOpen(false))
            }
        }
        if (isMultiStep) {
            window.location.reload()
        } else {
            dispatch(setTikTokTableModalOpen(false))
            dispatch(setMobileIsMultiLinkOpened(false))
        }
    }
    const onFinish = (formData: any, button?: string) => {
        let values = form.getFieldsValue();
        if (!isMultiStep) {
            const requestBody: any = {
                name: formData.name,
                description: formData.description,
                // permission_id_list: checked,
                is_multi_service_configuration: false
            }
            if (!editMode) {
                dispatch(CreateTikTokAccountLinkThunk({request: requestBody}))
            } else {
                console.log(requestBody)
                dispatch(UpdateTikTokAccountLinkThunk({req: requestBody, config_id: currentTikTokLink.id}))
            }
            setChecked([])
            form.setFieldsValue({name: ''})
            dispatch(setIsMultiStepLinkFinished(true))
            form.setFieldsValue({description: ''})
        } else {
            if (button === 'Next') {
                if (onStepChange) {
                    const requestBody = {
                        name: values.name,
                        description: values.description,
                        // permission_id_list: checked,
                        is_multi_service_configuration: true
                    }
                    dispatch(setTikTokStepData({
                        name: values.name,
                        description: values.description,
                        // permission_id_list: checked,
                        is_multi_service_configuration: true
                    }))
                    if (!editMode) {
                        dispatch(CreateTikTokAccountLinkThunk({request: requestBody, isMultiStep: true}))
                        onStepChange()
                    } else if (editMode) {
                        dispatch(UpdateTikTokAccountLinkThunk({
                            req: requestBody,
                            config_id: currentMultiStepLink && currentMultiStepLink.tiktok_configuration.id,
                            isMultiStep: true
                        }))
                    }
                    onStepChange()
                }
            } else {
                if (onStepChange)
                    onStepChange()
            }
        }
        if (isMobileLink && !isMultiStep) {
            navigate('/tiktokLinksTable')
        }
    }

    const onSendAllLinksData = () => {
        dispatch(setMobileIsMultiLinkOpened(false))
        let values = form.getFieldsValue();
        const requestBody: CreateGoogleAndTikTokLink = {
            name: values.name,
            description: values.description,
            permission_id_list: checked,
            is_multi_service_configuration: true,
            client_id: 0
        }
        if (!editMode) {
            dispatch(CreateTikTokAccountLinkThunk({
                request: requestBody,
                isMultiStep: true,
                multiStepReqData: multiStepReqData
            }))
        } else if (editMode) {
            dispatch(UpdateTikTokAccountLinkThunk({
                req: requestBody,
                config_id: currentMultiStepLink && currentMultiStepLink.tiktok_configuration.id,
                isMultiStep: true,
                multiStepReqData: multiStepReqData
            }))
        }
        dispatch(setBeforeAddingLinksModalOpen(false))
        dispatch(setMultiStageServices([]))
        dispatch(setSelectedConfigurations({isEdit: false, emptyData: []}))
        dispatch(setCurrentGoogleModalLinkPermissions({permissions: []}))
        dispatch(setCurrentTikTokModalLinkPermissions({permissions: []}))
        dispatch(switchAllConfigurationsCheckboxes(false))
        dispatch(setFacebookStepData({}))
        dispatch(setGoogleStepData({}))
        dispatch(setTikTokStepData({}))
        // setTimeout(() => {
        //     window.location.reload()
        // }, 500)
        if (isMobileLink && !isMultiStep) {
            navigate('/tiktokLinksTable')
        } else if (isMobileLink && isMultiStep && currentServiceType === 'All') {
            dispatch(setMobileIsMultiLinkOpened(false))
            navigate('/allLinksTable')
        } else if (isMobileLink && isMultiStep && currentServiceType === 'Common') {
            dispatch(setMobileIsMultiLinkOpened(false))
            navigate('/commonLinksTable')
        }
    }

    return (
        <CommonModal active={isModalOpen} setEditMode={(editMode) => setEditMode && setEditMode(editMode)}
                     height={'400px'}>
            <div className={`${s.modal} facebookModal`}>
                <button className={s.modal__closeBtn} onClick={onCloseModal}>
                    <closeIcon.ReactComponent/>
                </button>
                <h2>{editMode ? 'Edit Link' : 'Add Link'}</h2>
                <MultiStepIndicator currentStage={'TikTok'} editMode={editMode && editMode}/>
                <Form
                    name='facebookLinksTable'
                    onFinish={onFinish}
                    form={form}
                    autoComplete='off'
                    validateTrigger='onBlur'
                    initialValues={currentTikTokLink}
                >
                    <div>
                        <p className={s.modal__subtitle}>Name:</p>


                        <Form.Item
                            name='name'
                            rules={[
                                {required: true, message: 'Enter configuration name!'},
                            ]}
                        >

                            <Input
                                placeholder={'Enter name please'}
                                className={s.modal__input}


                            />

                        </Form.Item>

                    </div>
                    <div>
                        <p className={s.modal__subtitle}>Configuration description:</p>

                        <Form.Item
                            name='description'
                            rules={[
                                {required: true, message: 'Enter configuration description!'},
                            ]}
                        >

                            <Input
                                placeholder={'Enter configuration description please'}
                                className={s.modal__input}

                            />

                        </Form.Item>


                    </div>
                    {tablePermissions?.map((p: any) => {
                        return (
                            <div style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                                 key={p.id}
                            >
                                <p className={s.modal__checkbox}
                                   style={{
                                       marginRight: '5px',
                                       marginBottom: '0'
                                   }}
                                >
                                    {p.name}
                                </p>
                                <Tooltip title={p.description}>
                                    <QuestionCircleOutlined/>
                                </Tooltip>
                            </div>
                        )
                    })}
                    {!isMultiStep
                        ?
                        <Form.Item>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <button
                                    className={s.modal__btn}
                                    type={"submit"}
                                >Confirm
                                </button>
                            </div>
                        </Form.Item>
                        :
                        <div>
                            {
                                currentPlatformIndex !== multiStageServices.length - 1
                                    ?
                                    <Form.Item>
                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <button
                                                className={s.modal__btn}
                                                type={"button"}
                                                onClick={(formData: any) => onFinish(formData, 'Next')}
                                            >Next
                                            </button>
                                        </div>
                                    </Form.Item>
                                    :
                                    <Form.Item>
                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <button
                                                className={s.modal__btn}
                                                type={"button"}
                                                onClick={onSendAllLinksData}
                                            >Add link
                                            </button>
                                        </div>
                                    </Form.Item>
                            }
                        </div>

                    }

                </Form>

            </div>
        </CommonModal>
    )
}

export default TikTokLinksTable;
