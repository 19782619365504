import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

export type ClientsTabType = 'All clients' | 'Active' | 'Archive'

interface InitialStateType {
    isFacebookModalOpen: boolean | null
    googleConfigModalOpen: boolean | null
    googleTableConfigModal: boolean | null
    tiktokTableConfigModal: boolean | null
    feedbackModalOpened: boolean | null
    commonLinksModal: boolean | null
    beforeAddingLinksModal: boolean | null
    beforeAllStagesState: boolean | null
    isMobileMultiLinkOpened: boolean | null
    isMultiStepLinkFinished: boolean | null
    currentScreenWidth: number
    currentScreenHeight: number
    isSendLinkModalOpened: boolean
    sendLinkModalCurrentLinkData: any
    isCopyLinkModalOpened: boolean | null
    clientsTabs: ClientsTabType
    isClientModalOpen: boolean | null
    clientModalSteps: 'Create client' | 'Create link for client' | 'Send link' | 'Pre' | 'Copy link' | 'Configs'
}

const initialState: InitialStateType = {
    isFacebookModalOpen: null,
    googleConfigModalOpen: null,
    googleTableConfigModal: null,
    tiktokTableConfigModal: null,
    commonLinksModal: null,
    beforeAddingLinksModal: null,
    beforeAllStagesState: null,
    isMobileMultiLinkOpened: null,
    isMultiStepLinkFinished: null,
    currentScreenWidth: 1440,
    isSendLinkModalOpened: false,
    sendLinkModalCurrentLinkData: {},
    isCopyLinkModalOpened: false,
    currentScreenHeight: 1024,
    clientsTabs: 'All clients',
    isClientModalOpen: null,
    clientModalSteps: 'Create client',
    feedbackModalOpened: null

}

export const utilitySlice = createSlice({
    name: 'utility',
    initialState,
    reducers: {
        setFacebookModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isFacebookModalOpen = action.payload
        },
        setGoogleModalOpen: (state, action: PayloadAction<boolean>) => {
            state.googleConfigModalOpen = action.payload
        },
        setGoogleTableModalOpen: (state, action: PayloadAction<boolean>) => {
            state.googleTableConfigModal = action.payload
        },
        setTikTokTableModalOpen: (state, action: PayloadAction<boolean>) => {
            state.tiktokTableConfigModal = action.payload
        },
        setCommonLinksModalOpen: (state, action: PayloadAction<boolean>) => {
            state.commonLinksModal = action.payload
        },
        setBeforeAddingLinksModalOpen: (state, action: PayloadAction<boolean | null>) => {
            state.beforeAddingLinksModal = action.payload
        },
        setMobileIsMultiLinkOpened: (state, action: PayloadAction<boolean | null>) => {
            state.isMobileMultiLinkOpened = action.payload
        },
        setIsMultiStepLinkFinished: (state, action: PayloadAction<boolean | null>) => {
            state.isMultiStepLinkFinished = action.payload
        },
        setCurrentScreenWidth: (state, action: PayloadAction<number>) => {
            state.currentScreenWidth = action.payload
        },
        setCurrentScreenHeight: (state, action: PayloadAction<number>) => {
            state.currentScreenHeight = action.payload
        },
        setIsSendLinkModalOpened: (state, action: PayloadAction<boolean>) => {
            state.isSendLinkModalOpened = action.payload
        },
        setSendLinkModalCurrentLink: (state, action: PayloadAction<any>) => {
            state.sendLinkModalCurrentLinkData = action.payload
        },
        setIsCopyLinkModalOpened: (state, action: PayloadAction<boolean>) => {
            state.isCopyLinkModalOpened = action.payload
        },
        setClientsTableCurrentTab: (state, action: PayloadAction<ClientsTabType>) => {
            state.clientsTabs = action.payload
        },
        setClientModalOpened: (state, action: PayloadAction<boolean | null>) => {
            state.isClientModalOpen = action.payload
        },
        setCurrentClientModalStep: (state, action: PayloadAction<'Create client' | 'Create link for client' | 'Send link' | 'Pre' | 'Copy link' | 'Configs'>) => {
            state.clientModalSteps = action.payload
        },
        setFeedbackModalOpen: (state, action: PayloadAction<boolean>) => {
            state.feedbackModalOpened = action.payload
        },
    },
    extraReducers: () => {


    }
})

export const {
    setIsMultiStepLinkFinished,
    setFacebookModalOpen,
    setGoogleModalOpen,
    setGoogleTableModalOpen,
    setTikTokTableModalOpen,
    setMobileIsMultiLinkOpened,
    setBeforeAddingLinksModalOpen,
    setCurrentScreenWidth,
    setIsSendLinkModalOpened,
    setSendLinkModalCurrentLink,
    setIsCopyLinkModalOpened,
    setCurrentScreenHeight,
    setClientsTableCurrentTab,
    setClientModalOpened,
    setCurrentClientModalStep,
    setFeedbackModalOpen

} = utilitySlice.actions


export const selectIsFacebookModalOpen = (state: RootState): boolean | null => state.utility.isFacebookModalOpen
export const selectIsGoogleModalOpen = (state: RootState): boolean | null => state.utility.googleConfigModalOpen
export const selectIsGoogleTableModalOpen = (state: RootState): boolean | null => state.utility.googleTableConfigModal
export const selectIsTikTokTableModalOpen = (state: RootState): boolean | null => state.utility.tiktokTableConfigModal
export const selectBeforeAddingLinksTableModalOpen = (state: RootState): boolean | null => state.utility.beforeAddingLinksModal
export const selectIsMobileMultiLinkOpened = (state: RootState): boolean | null => state.utility.isMobileMultiLinkOpened
export const selectIsMultiStepLinkFinished = (state: RootState): boolean | null => state.utility.isMultiStepLinkFinished
export const selectCurrentScreenWidth = (state: RootState): number => state.utility.currentScreenWidth
export const selectIsSendLinkModalOpened = (state: RootState): boolean => state.utility.isSendLinkModalOpened
export const selectSendLinkModalCurrentLink = (state: RootState): any => state.utility.sendLinkModalCurrentLinkData
export const selectIsCopyLinkModalOpened = (state: RootState): boolean | null => state.utility.isCopyLinkModalOpened
export const selectCurrentScreenHeight = (state: RootState): number => state.utility.currentScreenHeight
export const selectClientsTableCurrentTab = (state: RootState): ClientsTabType => state.utility.clientsTabs
export const selectIsClientModalOpen = (state: RootState): boolean | null => state.utility.isClientModalOpen
export const selectFeedbackModalOpened = (state: RootState): boolean | null => state.utility.feedbackModalOpened
export const selectCurrentClientModalStep = (state: RootState): 'Create client' | 'Create link for client' | 'Send link' | 'Pre' | 'Copy link' | 'Configs' => state.utility.clientModalSteps

export default utilitySlice.reducer