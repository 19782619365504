import React, { useEffect } from 'react';
import IntegrationCard from "../IntegrationCard/IntegrationCard"
import s from './IntegrationCards.module.scss'
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectTikTokAccount } from '../../store/tikTokReducer';
import { selectGoogleAccount } from '../../store/googleReducer';
import { GetUserBusinessesThunk, selectUserBusinesses } from '../../store/businessAssetReducer';
import {userDataHelper} from "../../helpers/localStorageHelper";
const facebookLogo = require('../../img/icons/Facebook.svg')
const googleLogo = require('../../img/icons/google.svg')
const tiktokLogo = require('../../img/icons/tiktok.svg')


type CardType = {
    img: any
    title: 'Facebook' | 'Google' | 'Tik Tok' | 'All links' | 'General link'
    text: string
    path: string
}

type IntegrationCardsPropsType = {
    title: string
}

const IntegrationCards = ({title} : IntegrationCardsPropsType) => {
    const dispatch = useAppDispatch()
    const tikTokAccount = useAppSelector(selectTikTokAccount)
    const googleAccount = useAppSelector(selectGoogleAccount)
    const userBusinesses = useAppSelector(selectUserBusinesses)
    const userData = userDataHelper.getUserData()

    useEffect(() => {
        dispatch(GetUserBusinessesThunk(userData.user_id))
    }, [dispatch, userData.user_id])

    const cards: CardType[] = [
        {img: facebookLogo.default, title: 'Facebook', text: `Integrate your clients Facebook accounts to track likes, engagement, reach and posts`, path: '/facebookLinksTable'},
        {img: googleLogo.default, title: 'Google', text: `Integrate your clients with services such as Google Maps, Google Drive, and Google Analytics`, path: '/googleLinksTable'},
        {img: tiktokLogo.default, title: 'Tik Tok', text: `Integrate your clients with TikTok services like TikTok Advertiser, TikTok Catalog, TikTok Shop, TikTok Storefront, TikTok Pixel`, path: '/tiktokLinksTable'},
        // {img: generalLogo, title: 'General link', text: `Create a common link for all services to integrate your customers' accounts to track likes, engagement, reach, and posts.`, path: '/commonLinksTable'},
    ]

    const checkIsIntegrated: (title: string) => boolean = (title) => {
        if (title === 'Tik Tok') {
            return !(Object.keys(tikTokAccount).length === 0 || tikTokAccount.email === null)
        } else if (title === 'Google') {
            return Object.keys(googleAccount).length !== 0
        } else if (title === 'Facebook'){
            return !!userBusinesses.length
        } else {
            return !(Object.keys(tikTokAccount).length === 0 || tikTokAccount.email === null)
            ||
            Object.keys(googleAccount).length !== 0
            ||
            !!userBusinesses.length
        }
    }


    return (
        <div>
            <h2 className={s.title}>{title}</h2>
            <div className={s.description}>
                View the services through which you can create links for clients.
            </div>
            <div className={s.wrapper}>
                {cards.map((c: CardType) => {
                    const isIntegrated = checkIsIntegrated(c.title)
                    return (
                        <IntegrationCard
                            image={c.img}
                            title={c.title}
                            text={c.text}
                            isIntegrated={isIntegrated}
                            key={c.path}
                            link={isIntegrated ? c.path : ''}
                            allowIntegration={userData?.roles?.some(r => r.name.toLowerCase() === 'admin')}
                        />
                    )
                })}
            </div>
        </div>
    );
};

export default IntegrationCards;
